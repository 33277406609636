import React, { PureComponent } from 'react';
import { Box } from '@material-ui/core';
import { shape, string, oneOfType, number, arrayOf, func } from 'prop-types';
import Checkbox from './Checkbox';
import Button from '../../Button';

class Columns extends PureComponent {
  constructor(props) {
    super(props);
    const { pinned, unpinned } = this.props;

    this.state = {
      pinned,
      unpinned,
      settings: {},
    };
  }

  render() {
    const { pinned = [], unpinned = [], settings } = this.state;
    const { actions = [], onChange } = this.props;

    return (
      <>
        <Box
          style={{
            columns: '2',
            marginBottom: '24px',
          }}
        >
          {pinned.map(({ size, ...column }) => {
            return (
              <Checkbox
                key={column.key}
                size={size}
                column={column}
                src={pinned}
                onToggle={(value, setting) =>
                  this.setState({
                    pinned: value,
                    settings: { ...settings, ...setting },
                  })
                }
                pinned
              />
            );
          })}
          {unpinned.map(({ size, ...column }) => {
            return (
              <Checkbox
                key={column.key}
                size={size}
                column={column}
                src={unpinned}
                onToggle={(value, setting) =>
                  this.setState({
                    unpinned: value,
                    settings: { ...settings, ...setting },
                  })
                }
              />
            );
          })}
          {actions.map(({ size, ...column }) => {
            return (
              <Checkbox
                key={column.key}
                size={size}
                column={column}
                src={unpinned}
                actions
              />
            );
          })}
        </Box>
        <Button
          btnType="primary"
          onClick={() =>
            onChange([...pinned, ...unpinned, ...actions], settings)
          }
          fullWidth
        >
          Apply Changes
        </Button>
      </>
    );
  }
}

const HeaderColumn = shape({
  key: string.isRequired,
  label: string,
  size: oneOfType([string, number]),
});

Columns.propTypes = {
  pinned: arrayOf(HeaderColumn),
  unpinned: arrayOf(HeaderColumn),
  actions: arrayOf(HeaderColumn),
  onChange: func,
};

export default Columns;
