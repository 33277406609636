/* eslint-disable no-unused-vars */
import { ofType } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';

import { quoteSchedulesService, businessUnitsService } from '../../services';
import { dispatch } from '../../common/helpers';

import {
  FETCH_QUOTE_SCHEDULE_LIST_REQUEST,
  FETCH_QUOTE_SCHEDULE_LIST_SUCCESS,
  FETCH_QUOTE_SCHEDULE_LIST_ERROR,
  UPDATE_QUOTE_SCHEDULE_CALENDER,
  UPDATE_CALENDER_DATE,
  INCREMENT_AND_DECREMENT_DATE,
  UPDATE_LIST_DATE,
  ADD_NEW_SCHEDULE,
  ADD_SCHEDULE,
  FETCH_QUOTE_SCHEDULE_REQUEST,
  FETCH_QUOTE_SCHEDULE_DATA,
} from './actions';

export const fetchQuoteScheduleTabs = action$ =>
  action$.pipe(
    ofType(FETCH_QUOTE_SCHEDULE_LIST_REQUEST),
    mergeMap(({ payload }) =>
      businessUnitsService.getAllBusinessUnits().pipe(
        mergeMap(({ data }) => {
          if (data.length) {
            const getSelectedTab = data.find(
              val =>
                val.name.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase() ===
                payload.id
            );
            const id = (getSelectedTab && getSelectedTab.id) || data[0].id;
            return [
              dispatch(FETCH_QUOTE_SCHEDULE_LIST_SUCCESS, data),
              id && dispatch(FETCH_QUOTE_SCHEDULE_REQUEST, { id }),
            ];
          }
          return [dispatch(FETCH_QUOTE_SCHEDULE_LIST_ERROR)];
        }),
        catchError(error => [dispatch(FETCH_QUOTE_SCHEDULE_LIST_ERROR, error)])
      )
    )
  );

export const fetchQuoteSchedule = action$ =>
  action$.pipe(
    ofType(FETCH_QUOTE_SCHEDULE_REQUEST),
    mergeMap(({ payload }) =>
      quoteSchedulesService
        .getDataByTab(payload)
        .pipe(
          mergeMap(response => [dispatch(FETCH_QUOTE_SCHEDULE_DATA, response)])
        )
    )
  );

export const updateDateValue = (action$, store) =>
  action$.pipe(
    ofType(UPDATE_QUOTE_SCHEDULE_CALENDER),
    mergeMap(
      ({
        payload: {
          type,
          quoteSchedule,
          updateDate,
          effectiveMonth,
          effectiveYear,
          clearStatus,
        },
      }) =>
        quoteSchedulesService
          .updateCalenderDate({
            quoteSchedule,
            updateDate,
            effectiveMonth,
            effectiveYear,
            clearStatus,
          })
          .pipe(
            mergeMap(() => {
              return [dispatch(FETCH_QUOTE_SCHEDULE_REQUEST, { id: type })];
            }),
            catchError(error => {
              return [dispatch(FETCH_QUOTE_SCHEDULE_LIST_ERROR)];
            })
          )
    )
  );
