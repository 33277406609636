import {
  FETCH_COST_SEQUENCE_LIST_REQUEST,
  FETCH_COST_SEQUENCE_LIST_SUCCESS,
  FETCH_COST_SEQUENCE_LIST_ERROR,
  RESET_SEQUENCE_SUCCESS,
  UPDATE_COST_SEQUENCE_REQUEST,
  UPDATE_COST_SEQUENCE_ERROR,
  DELETE_COST_SEQUENCE_REQUEST,
  DELETE_COST_SEQUENCE_ERROR,
} from './actions';
import {
  REDUCERS,
  convertCalculationRuleToString,
} from '../../common/constants';
import { createReducer } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  list: {
    loading: false,
    error: false,
    success: false,
    data: [],
  },
};

const reducer = {
  [FETCH_COST_SEQUENCE_LIST_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_COST_SEQUENCE_LIST_SUCCESS]: (state, { payload }) => {
    const filteredList = payload
      ? payload.map(val => {
          val.calculationRule = convertCalculationRuleToString(
            val.calculationRule
          );
          return val;
        })
      : [];
    return {
      ...state,
      list: {
        ...REDUCERS.SUCCESS,
        success: false,
        data: filteredList,
      },
    };
  },
  [FETCH_COST_SEQUENCE_LIST_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),

  [RESET_SEQUENCE_SUCCESS]: state => {
    return {
      ...state,
      list: {
        ...REDUCERS.SUCCESS,
        success: false,
        data: [],
      },
    };
  },
  [UPDATE_COST_SEQUENCE_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [UPDATE_COST_SEQUENCE_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [DELETE_COST_SEQUENCE_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [DELETE_COST_SEQUENCE_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
};

export default createReducer(schema, reducer);
