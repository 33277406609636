/* eslint-disable no-unused-vars */
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { dispatch } from '../../common/helpers';
import { reportsService, materialsService } from '../../services';
import { MESSAGES, AUTHORIZED_ACCESS_TO_REPORT } from '../../common/constants';

import {
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_ERROR,
  FETCH_REPORT_DETAIL_REQUEST,
  FETCH_REPORT_DETAIL_SUCCESS,
  FETCH_REPORT_DETAIL_ERROR,
  DOWNLOAD_REPORT_REQUEST,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR,
  FETCH_REPORT_FILTER_REQUEST,
  FETCH_REPORT_FILTER_SUCCESS,
  FETCH_REPORT_FILTER_ERROR,
  RESET_REPORT_DATA_REQUEST,
  RESET_REPORT_DATA_SUCCESS,
  FETCH_REPORT_PROFIT_CENTER_REQUEST,
  FETCH_REPORT_TYPE_REQUEST,
  FILTER_REPORT_API_SUCCESS,
  FILTER_REPORT_API_ERROR,
  FETCH_REPORT_PROCUREMENT_TYPE_REQUEST,
  FETCH_REPORT_X_PLANT_STATUS_REQUEST,
  FETCH_REPORT_PLANT_SPECIFIC_MATERIAL_REQUEST,
  NEGOTIATED_COST_DATE_REQUEST,
  NEGOTIATED_COST_DATE_SUCCESS,
  NEGOTIATED_COST_DATE_ERROR,
  FETCH_RUN_DATE_REQUEST,
  FETCH_RUN_DATE_SUCCESS,
  FETCH_RUN_DATE_ERROR,
  FETCH_TOTAL_COUNT_REQUEST,
  FETCH_TOTAL_COUNT_SUCCESS,
  FETCH_TOTAL_COUNT_ERROR,
  UPDATE_TOTAL_COUNT_REQUEST,
  UPDATE_TOTAL_COUNT_SUCCESS,
  EMAIL_REPORT_REQUEST,
  DOWNLOAD_SAVED_REPORT_REQUEST,
  DOWNLOAD_SAVED_REPORT_ERROR,
  DOWNLOAD_SAVED_REPORT_SUCCESS,
  EMAIL_CONFIRMATION_STATUS,
  RESET_EMAIL_CONFIRMATION_REQUEST,
  RESET_EMAIL_CONFIRMATION_SUCCESS,
  DOWNLOAD_MASTER_DATA_REPORT_REQUEST,
  DOWNLOAD_MASTER_DATA_REPORT_ERROR,
  DOWNLOAD_MASTER_DATA_REPORT_SUCCESS,
} from './actions';
import {
  ERROR_NOTIFICATION_REQUEST,
  SUCCESS_NOTIFICATION_REQUEST,
} from '../notifications/actions';

export const fetchReportList = (action$, store) => {
  return action$.pipe(
    ofType(FETCH_REPORT_REQUEST),
    mergeMap(() => {
      return reportsService.getAllReports().pipe(
        map(data =>
          dispatch(FETCH_REPORT_SUCCESS, {
            ...data,
          })
        )
      );
    }),
    catchError(err => [dispatch(FETCH_REPORT_ERROR, err)])
  );
};

export const fetchReportDetail = (action$, store) => {
  return action$.pipe(
    ofType(FETCH_REPORT_DETAIL_REQUEST),
    mergeMap(({ payload }) => {
      return reportsService.getReportDetail(payload).pipe(
        map(({ response }) => {
          if (response?.response?.statusCode) {
            return dispatch(EMAIL_CONFIRMATION_STATUS);
          }
          return dispatch(FETCH_REPORT_DETAIL_SUCCESS, {
            ...response,
            payload,
          });
        }),
        catchError(err => [dispatch(FETCH_REPORT_DETAIL_ERROR, err)])
      );
    })
  );
};

export const fetchReportFilter = (action$, store) => {
  return action$.pipe(
    ofType(FETCH_REPORT_FILTER_REQUEST),
    mergeMap(({ payload }) =>
      reportsService.getReportFilter(payload).pipe(
        map(response =>
          dispatch(FETCH_REPORT_FILTER_SUCCESS, {
            ...response,
          })
        )
      )
    ),
    catchError(err => [dispatch(FETCH_REPORT_FILTER_ERROR, err)])
  );
};

export const downloadReportRequest = (action$, store) => {
  return action$.pipe(
    ofType(DOWNLOAD_REPORT_REQUEST),
    mergeMap(({ payload }) => {
      const {
        user: {
          data: { emailAddress, commonName },
        },
      } = store.value;
      payload.email = emailAddress;
      payload.userName = commonName;
      return reportsService.downloadReport(payload).pipe(
        map(({ response }) => {
          if (response?.statusCode === 204) {
            return dispatch(EMAIL_CONFIRMATION_STATUS);
          }
          let blob;
          if (payload.id === 'structured-quote') {
            blob = new Blob([response]);
          } else {
            blob = new Blob([response.response], { type: 'text/csv' });
          }
          const url = URL.createObjectURL(blob);
          const element = document.createElement('a');
          const today = new Date();
          const date = `${today.getDate()}-${today.getMonth() +
            1}-${today.getFullYear()}`;
          const time = `${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;
          if (payload.id === 'negotiated-cost') {
            const selectedType = payload.filter.find(
              val => val.field === 'negotiatedCostCategory'
            );
            const negotiateType = selectedType.value;
            element.download = `${negotiateType}-${payload.id}-${date}-${time}.csv`; // file name should have report, time, some more info
          } else if (payload.id === 'material-lowest-cost') {
            const selectedType = payload.filter.find(
              val => val.field === 'materialNumber'
            );
            const materialNumber = selectedType.value;
            element.download = `${materialNumber}-${payload.id}-${date}-${time}.csv`; // file name should have report, time, some more info
          } else if (payload.id === 's4-template') {
            element.download = `S4-Template-${date}-${time}.csv`;
          } else if (payload.id === 'historical-approved-quotes') {
            element.download = `quote-${date}-${time}.csv`;
          } else if (payload.id === 'quote-data') {
            element.download = `CCS-Template-${date}-${time}.csv`;
          } else if (payload.id === 'structured-quote') {
            element.download = `Structured-Quote-${date}-${time}.xlsx`;
          } else {
            element.download = `${payload.id}-${date}-${time}.csv`; // file name should have report, time, some more info
          }
          element.href = url; // window.open()
          element.click();
          return dispatch(DOWNLOAD_REPORT_SUCCESS);
        }),
        catchError(error => [
          dispatch(DOWNLOAD_REPORT_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      );
    })
  );
};

export const emailReportRequest = (action$, store) => {
  return action$.pipe(
    ofType(EMAIL_REPORT_REQUEST),
    mergeMap(({ payload }) => {
      const {
        user: {
          data: { emailAddress, commonName },
        },
      } = store.value;
      payload.email = emailAddress;
      payload.userName = commonName;
      payload.emailConfirmation = true;
      return reportsService.emailReport(payload).pipe(
        mergeMap(({ response }) => {
          return [
            dispatch(DOWNLOAD_REPORT_SUCCESS),
            dispatch(SUCCESS_NOTIFICATION_REQUEST, {
              message: MESSAGES.REPORT_REQUEST_SUBMIIITED,
            }),
          ];
        }),
        catchError(error => [
          dispatch(DOWNLOAD_REPORT_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      );
    })
  );
};

export const resetReportData = (action$, store) =>
  action$.pipe(
    ofType(RESET_REPORT_DATA_REQUEST),
    mergeMap(() => [dispatch(RESET_REPORT_DATA_SUCCESS)])
  );

export const resetEmailConfirmation = action$ =>
  action$.pipe(
    ofType(RESET_EMAIL_CONFIRMATION_REQUEST),
    mergeMap(() => [dispatch(RESET_EMAIL_CONFIRMATION_SUCCESS)])
  );

export const updateTotalCountRequest = (action$, store) =>
  action$.pipe(
    ofType(UPDATE_TOTAL_COUNT_REQUEST),
    mergeMap(({ payload }) => [dispatch(UPDATE_TOTAL_COUNT_SUCCESS, payload)])
  );

export const fetchReportProfitCenterData = (action$, store) => {
  return action$.pipe(
    ofType(FETCH_REPORT_PROFIT_CENTER_REQUEST),
    mergeMap(() =>
      materialsService.getMaterialProfitCenter().pipe(
        map(response => {
          return dispatch(FILTER_REPORT_API_SUCCESS, {
            profitCenter: response.data,
          });
        })
      )
    ),
    catchError(err => [dispatch(FILTER_REPORT_API_ERROR, err)])
  );
};

export const fetchReportTypeData = (action$, store) => {
  return action$.pipe(
    ofType(FETCH_REPORT_TYPE_REQUEST),
    mergeMap(() =>
      materialsService.getTypeDl().pipe(
        map(response => {
          return dispatch(FILTER_REPORT_API_SUCCESS, {
            typeDl: response.data,
          });
        })
      )
    ),
    catchError(err => [dispatch(FILTER_REPORT_API_ERROR, err)])
  );
};

export const fetchReportProcurementTypeData = (action$, store) => {
  return action$.pipe(
    ofType(FETCH_REPORT_PROCUREMENT_TYPE_REQUEST),
    mergeMap(() =>
      materialsService.getProcurementTypeData().pipe(
        map(response => {
          return dispatch(FILTER_REPORT_API_SUCCESS, {
            procurementType: response.data,
          });
        })
      )
    ),
    catchError(err => [dispatch(FILTER_REPORT_API_ERROR, err)])
  );
};

export const fetchReportPlantStatusData = (action$, store) => {
  return action$.pipe(
    ofType(FETCH_REPORT_X_PLANT_STATUS_REQUEST),
    mergeMap(() =>
      materialsService.getPlantStatusData().pipe(
        map(response => {
          return dispatch(FILTER_REPORT_API_SUCCESS, {
            plantStatus: response.data,
          });
        })
      )
    ),
    catchError(err => [dispatch(FILTER_REPORT_API_ERROR, err)])
  );
};

export const fetchReportPlantSpecificMaterialData = (action$, store) => {
  return action$.pipe(
    ofType(FETCH_REPORT_PLANT_SPECIFIC_MATERIAL_REQUEST),
    mergeMap(() =>
      materialsService.getPlantSpecificMaterialData().pipe(
        map(response => {
          return dispatch(FILTER_REPORT_API_SUCCESS, {
            plantSpecificMaterial: response.data,
          });
        })
      )
    ),
    catchError(err => [dispatch(FILTER_REPORT_API_ERROR, err)])
  );
};

export const getNegotiatedCostDates = action$ => {
  return action$.pipe(
    ofType(NEGOTIATED_COST_DATE_REQUEST),
    mergeMap(({ payload }) =>
      reportsService.getNegotiatedCostDates(payload).pipe(
        mergeMap(({ data }) => [
          dispatch(NEGOTIATED_COST_DATE_SUCCESS, { negotiatedDates: data }),
        ]),
        catchError(err => [dispatch(NEGOTIATED_COST_DATE_ERROR, err)])
      )
    )
  );
};

export const fetchRunDateForReports = action$ =>
  action$.pipe(
    ofType(FETCH_RUN_DATE_REQUEST),
    mergeMap(() =>
      reportsService.fetchRunDateForReports().pipe(
        map(response =>
          dispatch(FETCH_RUN_DATE_SUCCESS, {
            ...response,
          })
        )
      )
    ),
    catchError(err => [dispatch(FETCH_RUN_DATE_ERROR, err)])
  );

export const fetchTotalCountForReports = action$ =>
  action$.pipe(
    ofType(FETCH_TOTAL_COUNT_REQUEST),
    mergeMap(({ payload }) =>
      reportsService.fetchTotalCountForReports(payload).pipe(
        mergeMap(response => [
          dispatch(FETCH_TOTAL_COUNT_SUCCESS, {
            ...response,
          }),
        ]),
        catchError(err => [dispatch(FETCH_TOTAL_COUNT_ERROR, err)])
      )
    )
  );

export const downloadSavedReport = (action$, store) => {
  return action$.pipe(
    ofType(DOWNLOAD_SAVED_REPORT_REQUEST),
    mergeMap(({ payload }) => {
      return reportsService.downloadSavedReport(payload).pipe(
        mergeMap(({ response }) => {
          let blob;
          let downlodedFileName;
          const fileExtn = payload.split('.').pop();
          if (fileExtn === 'xlsx') {
            blob = new Blob([response]);
            downlodedFileName = `${payload}`;
          } else {
            blob = new Blob([response], { type: 'text/csv' });
            downlodedFileName = `${payload}.csv`;
          }
          const url = URL.createObjectURL(blob);
          const element = document.createElement('a');
          element.download = downlodedFileName;
          element.href = url;
          element.click();
          return [dispatch(DOWNLOAD_SAVED_REPORT_SUCCESS)];
        }),
        catchError(err => {
          if (err.status === 404) {
            const {
              user: {
                data: { id },
              },
            } = store.value;
            err.response.error = { message: AUTHORIZED_ACCESS_TO_REPORT };
            return [
              dispatch(DOWNLOAD_SAVED_REPORT_ERROR, err),
              dispatch(ERROR_NOTIFICATION_REQUEST, err),
            ];
          }
          return [dispatch(DOWNLOAD_SAVED_REPORT_ERROR, err)];
        })
      );
    })
  );
};

export const downloadMasterDataReport = (action$, store) => {
  return action$.pipe(
    ofType(DOWNLOAD_MASTER_DATA_REPORT_REQUEST),
    mergeMap(({ payload }) => {
      return reportsService.downloadMasterDataReport(payload.filter).pipe(
        mergeMap(({ response }) => {
          const today = new Date();
          const date = `${today.getDate()}-${today.getMonth() +
            1}-${today.getFullYear()}`;
          const time = `${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;
          const blob = new Blob([response], { type: 'text/csv' });
          const url = URL.createObjectURL(blob);
          const element = document.createElement('a');
          element.download = `${payload.filter}-${date}-${time}.csv`;
          element.href = url;
          element.click();
          return [dispatch(DOWNLOAD_MASTER_DATA_REPORT_SUCCESS)];
        }),
        catchError(err => {
          if (err.status === 404) {
            const {
              user: {
                data: { id },
              },
            } = store.value;
            err.response.error = { message: AUTHORIZED_ACCESS_TO_REPORT };
            return [
              dispatch(DOWNLOAD_SAVED_REPORT_ERROR, err),
              dispatch(ERROR_NOTIFICATION_REQUEST, err),
            ];
          }
          return [dispatch(DOWNLOAD_MASTER_DATA_REPORT_ERROR, err)];
        })
      );
    })
  );
};
