/* eslint-disable react/jsx-filename-extension */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import theme from './common/theme';

import App from './App';
import { VERSION_KEY } from './common/constants';

if (localStorage.getItem('versionKey') !== VERSION_KEY) {
  localStorage.clear();
  localStorage.setItem('versionKey', VERSION_KEY);
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <ThemeProvider theme={theme}>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
