import { createMuiTheme } from '@material-ui/core';
import { themeColor } from './constants';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: themeColor,
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#444444',
      contrastText: '#ffffff',
    },
    accent: {
      main: '#fd9a69',
      contrastText: '#ffffff',
    },
    accent_2: {
      main: '#2ad2c9',
      contrastText: '#ffffff',
    },
    accent_3: {
      main: '#614767',
      contrastText: '#ffffff',
    },
    neutral: {
      main: '#425563',
      contrastText: '#ffffff',
    },
    neutral_2: {
      main: '#5f7a76',
      contrastText: '#ffffff',
    },
    neutral_3: {
      main: '#80746e',
      contrastText: '#ffffff',
    },
    neutral_4: {
      main: '#777777',
      contrastText: '#ffffff',
    },
    error: {
      main: '#ca2b4e',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ffe75e',
    },
    info: {
      main: '#2196f3',
    },
    success: {
      main: themeColor,
    },
  },
  typography: {
    iconSize: '1.5rem',
    appTitle: '1.25rem',
    groupedTableFont: '0.75rem',
    sidebarFontSize: '0.75rem',
  },
  props: {
    ButtonBase: {
      disableRipple: true,
    },
    MuiTextField: {
      color: 'primary',
    },
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application!
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
        boxShadow: 'none !important',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '20px',
      },
    },

    MuiDialogContent: {
      root: {
        padding: '20px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
    },
    Button: {
      root: {
        borderRadius: 0,
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
      colorDefault: {
        backgroundColor: '#ffffff',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: '$labelcolor',
        },
        '&:focus': {
          outline: 'none',
        },
      },
    },
  },
});

export default theme;
