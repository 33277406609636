/* eslint-disable no-unused-vars */
import {
  FETCH_QUOTE_LIST_REQUEST,
  FETCH_QUOTE_LIST_SUCCESS,
  FETCH_QUOTE_LIST_ERROR,
  FETCH_MYQUOTES_DISTRIBUTION_LIST_ERROR,
  FETCH_MYQUOTES_DISTRIBUTION_LIST_REQUEST,
  FETCH_MYQUOTES_DISTRIBUTION_LIST_SUCCESS,
  FETCH_MYQUOTES_MANUFACTURING_LIST_ERROR,
  FETCH_MYQUOTES_MANUFACTURING_LIST_REQUEST,
  FETCH_MYQUOTES_MANUFACTURING_LIST_SUCCESS,
  FETCH_MYQUOTES_PROCUREMENT_LIST_ERROR,
  FETCH_MYQUOTES_PROCUREMENT_LIST_REQUEST,
  FETCH_MYQUOTES_PROCUREMENT_LIST_SUCCESS,
  FETCH_CROSS_PLANT_COMPARISSON_ERROR,
  FETCH_CROSS_PLANT_COMPARISSON_REQUEST,
  FETCH_CROSS_PLANT_COMPARISSON_SUCCESS,
  REJECT_ERROR_ACTION_VARIANCE_COST,
  REJECT_SUCCESS_ACTION_VARIANCE_COST,
  REJECT_REQUEST_ACTION_VARIANCE_COST,
  ACCEPT_ERROR_ACTION_VARIANCE_COST,
  ACCEPT_SUCCESS_ACTION_VARIANCE_COST,
  ACCEPT_REQUEST_ACTION_VARIANCE_COST,
  UPDATE_ERROR_ACTION_VARIANCE_COST,
  UPDATE_SUCCESS_ACTION_VARIANCE_COST,
  UPDATE_REQUEST_ACTION_VARIANCE_COST,
  CREATE_COST_VARIANCE_REQUEST,
  CREATE_COST_VARIANCE_SUCCESS,
  CREATE_COST_VARIANCE_ERROR,
  ACCEPT_SUCCESS_DECREASE_VARIANCE_TYPE,
  ACCEPT_ERROR_DECREASE_VARIANCE_TYPE,
  ACCEPT_REQUEST_DECREASE_VARIANCE_TYPE,
} from './actions';

import { REDUCERS } from '../../common/constants';
import { createReducer, varianceLoadingStatus } from '../../common/helpers';

const defautLoadingStatus = state => ({
  ...state,
  distribution: {
    ...state.distribution,
    ...REDUCERS.LOADING,
  },
  manufacturing: {
    ...state.manufacturing,
    ...REDUCERS.LOADING,
  },
  procurement: {
    ...state.procurement,
    ...REDUCERS.LOADING,
  },
});

const schema = {
  loading: false,
  error: false,
  quotes: {
    loading: false,
    error: false,
    response: { data: [], pagination: {} },
  },
  procurement: {
    loading: false,
    error: false,
    response: { data: [], pagination: {} },
  },
  manufacturing: {
    loading: false,
    error: false,
    response: { data: [], pagination: {} },
  },
  distribution: {
    loading: false,
    error: false,
    response: { data: [], pagination: {} },
  },
  crossPlantComparisson: {
    loading: false,
    error: false,
    response: { data: [], pagination: {} },
  },
};

const reducer = {
  // QUOTE List
  [FETCH_QUOTE_LIST_REQUEST]: state => ({
    ...state,
    quotes: {
      ...state.quotes,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_QUOTE_LIST_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => ({
    ...state,
    quotes: {
      ...REDUCERS.SUCCESS,
      response: {
        pagination,
        data: payload?.pagination?.infinite
          ? [...state.quotes.response.data, ...data]
          : data,
        payload,
      },
    },
  }),
  [FETCH_QUOTE_LIST_ERROR]: (state, { payload: error }) => ({
    ...state,
    quotes: {
      ...state.quotes,
      ...REDUCERS.ERROR,
      error,
    },
  }), // QUOTE Procurement List
  [FETCH_MYQUOTES_PROCUREMENT_LIST_REQUEST]: state => ({
    ...state,
    procurement: {
      ...state.procurement,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_MYQUOTES_PROCUREMENT_LIST_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => ({
    ...state,
    procurement: {
      ...REDUCERS.SUCCESS,
      response: {
        pagination,
        data: payload?.pagination?.infinite
          ? [...state.procurement.response.data, ...data]
          : data,
        payload,
      },
    },
  }),
  [FETCH_MYQUOTES_PROCUREMENT_LIST_ERROR]: (state, { payload: error }) => ({
    ...state,
    procurement: {
      ...state.procurement,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  // QUOTE Manufacturing List
  [FETCH_MYQUOTES_MANUFACTURING_LIST_REQUEST]: state => ({
    ...state,
    manufacturing: {
      ...state.manufacturing,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_MYQUOTES_MANUFACTURING_LIST_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => ({
    ...state,
    manufacturing: {
      ...REDUCERS.SUCCESS,
      response: {
        pagination,
        data: payload?.pagination?.infinite
          ? [...state.manufacturing.response.data, ...data]
          : data,
        payload,
      },
    },
  }),
  [FETCH_MYQUOTES_MANUFACTURING_LIST_ERROR]: (state, { payload: error }) => ({
    ...state,
    manufacturing: {
      ...state.manufacturing,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  // QUOTE Distribution List
  [FETCH_MYQUOTES_DISTRIBUTION_LIST_REQUEST]: state => ({
    ...state,
    distribution: {
      ...state.distribution,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_MYQUOTES_DISTRIBUTION_LIST_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => ({
    ...state,
    distribution: {
      ...REDUCERS.SUCCESS,
      response: {
        pagination,
        data: payload?.pagination?.infinite
          ? [...state.distribution.response.data, ...data]
          : data,
        payload,
      },
    },
  }),
  [FETCH_MYQUOTES_DISTRIBUTION_LIST_ERROR]: (state, { payload: error }) => ({
    ...state,
    distribution: {
      ...state.distribution,
      ...REDUCERS.ERROR,
      error,
    },
  }),

  [FETCH_CROSS_PLANT_COMPARISSON_REQUEST]: state => ({
    ...state,
    crossPlantComparisson: {
      response: { data: [], pagination: {} },
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_CROSS_PLANT_COMPARISSON_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => ({
    ...state,
    crossPlantComparisson: {
      ...REDUCERS.SUCCESS,
      response: {
        pagination,
        data: payload?.pagination?.infinite
          ? [...state.crossPlantComparisson.response.data, ...data]
          : data,
        payload,
      },
    },
  }),
  [FETCH_CROSS_PLANT_COMPARISSON_ERROR]: (state, { payload: error }) => ({
    ...state,
    crossPlantComparisson: {
      ...state.crossPlantComparisson,
      ...REDUCERS.ERROR,
      error,
    },
  }),

  // Cost Variance Actions
  [CREATE_COST_VARIANCE_REQUEST]: varianceLoadingStatus(),
  [CREATE_COST_VARIANCE_SUCCESS]: varianceLoadingStatus(true),
  [CREATE_COST_VARIANCE_ERROR]: varianceLoadingStatus(false),
  // Variance Update
  [UPDATE_REQUEST_ACTION_VARIANCE_COST]: varianceLoadingStatus(),
  [UPDATE_SUCCESS_ACTION_VARIANCE_COST]: varianceLoadingStatus(true),
  [UPDATE_ERROR_ACTION_VARIANCE_COST]: varianceLoadingStatus(false),
  // Variance Decrease Approval
  [ACCEPT_REQUEST_DECREASE_VARIANCE_TYPE]: varianceLoadingStatus(),
  [ACCEPT_SUCCESS_DECREASE_VARIANCE_TYPE]: varianceLoadingStatus(true),
  [ACCEPT_ERROR_DECREASE_VARIANCE_TYPE]: varianceLoadingStatus(false),
  // Variance Approval
  [ACCEPT_REQUEST_ACTION_VARIANCE_COST]: varianceLoadingStatus(),
  [ACCEPT_SUCCESS_ACTION_VARIANCE_COST]: varianceLoadingStatus(true),
  [ACCEPT_ERROR_ACTION_VARIANCE_COST]: varianceLoadingStatus(false),
  // Variance Rejection
  [REJECT_REQUEST_ACTION_VARIANCE_COST]: varianceLoadingStatus(),
  [REJECT_SUCCESS_ACTION_VARIANCE_COST]: varianceLoadingStatus(false),
  [REJECT_ERROR_ACTION_VARIANCE_COST]: varianceLoadingStatus(true),
};

export default createReducer(schema, reducer);
