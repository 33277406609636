import React from 'react';
import { string, func } from 'prop-types';
import { Box } from '@material-ui/core';
import Select from '../../../Select';
import { DATE_FORMAT_STRINGS } from '../../../../common/constants';

import { getFormattedDateForForm } from '../../../../common/helpers';
import Calendar from '../../../Calendar';

const textOperatorsOptions = [
  { id: 'all', value: 'All' },
  { id: 'dateEq', value: 'Equal' },
  { id: 'dateGt', value: ' Greater Than' },
  { id: 'lt', value: 'Lesser Than' },
  { id: 'between', value: 'Between' },
];

const DateFilter = ({ onChange, operator, value, from, to }) => {
  return (
    <Box>
      <Select
        options={textOperatorsOptions}
        valueKey="id"
        valueLabel="value"
        onChange={val => onChange({ operator: val })}
        value={operator || ''}
        variant="outlined"
        fullWidth
      />
      <Box pt={2}>
        {operator !== 'between' && (
          <Calendar
            id="launchDate"
            name="launchDate"
            inputVariant="outlined"
            variant="inline"
            autoOk
            format={DATE_FORMAT_STRINGS.FORM_DATE_FORMAT}
            value={value || null}
            onChange={val =>
              onChange({
                value: getFormattedDateForForm(
                  val,
                  DATE_FORMAT_STRINGS.SHORT_YEAR_FORMAT
                ),
              })
            }
          />
        )}
        {operator === 'between' && (
          <>
            <Calendar
              id="from"
              name="from"
              inputVariant="outlined"
              variant="inline"
              autoOk
              format={DATE_FORMAT_STRINGS.FORM_DATE_FORMAT}
              value={from || null}
              onChange={val =>
                onChange({
                  from: getFormattedDateForForm(
                    val,
                    DATE_FORMAT_STRINGS.SHORT_YEAR_FORMAT
                  ),
                })
              }
            />
            To
            <Calendar
              id="to"
              name="to"
              inputVariant="outlined"
              variant="inline"
              autoOk
              format={DATE_FORMAT_STRINGS.FORM_DATE_FORMAT}
              value={to || null}
              onChange={val =>
                onChange({
                  to: getFormattedDateForForm(
                    val,
                    DATE_FORMAT_STRINGS.SHORT_YEAR_FORMAT
                  ),
                })
              }
            />
          </>
        )}
      </Box>
    </Box>
  );
};
DateFilter.propTypes = {
  onChange: func,
  operator: string,
  value: string,
  from: string,
  to: string,
};

export default DateFilter;
