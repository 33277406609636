/* eslint-disable no-unused-vars */
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of, merge } from 'rxjs';
import { dispatch } from '../../common/helpers';

import {
  GET_COMMENT_LIST_REQUEST,
  GET_COMMENT_LIST_SUCCESS,
  GET_COMMENT_LIST_ERROR,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_ERROR,
  GET_COMMENTS_LIST_REQUEST,
  GET_COMMENTS_LIST_ERROR,
  GET_COMMENTS_LIST_SUCCESS,
} from './actions';
import { commentsService } from '../../services';
import {
  SUCCESS_NOTIFICATION_REQUEST,
  ERROR_NOTIFICATION_REQUEST,
} from '../notifications/actions';

export const getCommentList = action$ =>
  action$.pipe(
    ofType(GET_COMMENT_LIST_REQUEST),
    mergeMap(({ payload }) =>
      commentsService.createOrGetThread(payload).pipe(
        mergeMap(({ response }) =>
          commentsService
            .getMessagesList({
              filter: [
                {
                  value: response.response.id,
                  field: 'threadId',
                  operator: 'eq',
                },
              ],
            })
            .pipe(
              map(({ data, pagination }) =>
                dispatch(GET_COMMENT_LIST_SUCCESS, {
                  data: { messages: data, threadId: response.response.id },
                  pagination,
                })
              )
            )
        ),
        catchError(err => [dispatch(GET_COMMENT_LIST_ERROR, err)])
      )
    )
  );

export const addComment = action$ =>
  action$.pipe(
    ofType(ADD_COMMENT_REQUEST),
    mergeMap(({ payload }) => {
      const updateLoading = of(dispatch(ADD_COMMENT_SUCCESS));
      const message = {
        messages: [...payload.messages],
      };
      const commentUpdate = commentsService.addComment(message).pipe(
        mergeMap(data => {
          if (Array.isArray(payload.entities) && payload.entities.length > 0) {
            return [
              dispatch(GET_COMMENTS_LIST_REQUEST, payload.entities),
              dispatch(SUCCESS_NOTIFICATION_REQUEST, {
                message: 'COMMENTS ADDED',
              }),
            ];
          }

          if (payload && payload.entityId) {
            return [
              dispatch(GET_COMMENT_LIST_REQUEST, {
                entityId: payload.entityId,
                entityType: payload.entityType,
              }),
              dispatch(SUCCESS_NOTIFICATION_REQUEST, {
                message: 'COMMENT ADDED',
              }),
            ];
          }
          return [];
        }),
        catchError(err => {
          return [
            dispatch(ADD_COMMENT_ERROR, err),
            dispatch(ERROR_NOTIFICATION_REQUEST, err),
          ];
        })
      );
      return merge(commentUpdate, updateLoading);
    })
  );

export const getThreadsList = action$ =>
  action$.pipe(
    ofType(GET_COMMENTS_LIST_REQUEST),
    mergeMap(({ payload }) =>
      commentsService.createOrGetMultiThread(payload).pipe(
        map(data => {
          return dispatch(GET_COMMENTS_LIST_SUCCESS, {
            ...data.response,
            payload,
          });
        })
      )
    ),
    catchError(err => {
      return [dispatch(GET_COMMENTS_LIST_ERROR, err)];
    })
  );
