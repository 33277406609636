import { combineReducers } from 'redux';
import programs from './programs/reducer';
import user from './user/reducer';
import data from './data/reducer';
import shared from './shared/reducer';
import bom from './bom/reducer';
import quoteSchedule from './quoteSchedule/reducer';
import cost from './cost/reducer';
import variances from './variances/reducer';
import pcc from './pcc/reducer';
import quotes from './quotes/reducer';
import plants from './plants/reducer';
import notifications from './notifications/reducer';
import financialBurden from './financialBurden/reducer';
import plantPref from './plantPreferences/reducers';
import conditions from './conditions/reducer';
import comments from './comments/reducer';
import audits from './audits/reducer';
import rebates from './rebates/reducer';
import report from './report/reducer';
import commodity from './commodity/reducer';
import dashboard from './dashboard/reducer';
import costGroupSequence from './costGroupSequence/reducer';
import vendorMaps from './vendorMap/reducer';

export default combineReducers({
  programs,
  user,
  data,
  shared,
  bom,
  quoteSchedule,
  cost,
  variances,
  pcc,
  quotes,
  plants,
  notifications,
  financialBurden,
  plantPref,
  conditions,
  comments,
  audits,
  rebates,
  report,
  commodity,
  dashboard,
  costGroupSequence,
  vendorMaps,
});
