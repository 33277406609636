import React from 'react';
import { Box, makeStyles, Icon } from '@material-ui/core';
import { bool } from 'prop-types';
import logo from '../../assets/hpeRobot.svg';

const useStyles = makeStyles({
  '@keyframes blink': {
    '0%': {
      opacity: 0.2,
    },
    '20%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.2,
    },
  },
  loading: {
    fontSize: '50px',
    animationName: '$blink',
    animationDuration: '1.4s',
    animationIterationCount: 'infinite',
    animationFillMode: 'both',
    animationDelay: '.2s',

    '&:nth-child(2)': {
      animationDelay: '.2s',
    },
    '&:nth-child(3)': {
      animationDelay: '.4s',
    },
  },
});

const Loader = ({ hideIcon = false, ...props }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="300px"
      {...props}
    >
      <Box
        m="auto"
        mt={0}
        mb={0}
        pl={4}
        pr={4}
        style={{
          backgroundColor: 'white',
          borderRadius: '25px',
          width: '180px',
          zIndex: '99',
        }}
      >
        {!hideIcon && (
          <Box component={Icon} ml="10px">
            <img src={logo} alt="hpeRobot" />
          </Box>
        )}

        <Box
          component="p"
          pb={1}
          align="center"
          fontSize="large"
          fontWeight="700"
          lineHeight={0.5}
        >
          Loading
          <span className={classes.loading}>.</span>
          <span className={classes.loading}>.</span>
          <span className={classes.loading}>.</span>
        </Box>
      </Box>
    </Box>
  );
};
Loader.propTypes = {
  hideIcon: bool,
};
export default Loader;
