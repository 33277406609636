import React, { PureComponent } from 'react';
import { Box, styled } from '@material-ui/core';
import {
  shape,
  string,
  arrayOf,
  oneOfType,
  number,
  object,
  bool,
  func,
} from 'prop-types';
import Cell from './Cell';

const Stock = styled(Box)({
  display: 'flex',
  position: 'relative',
  '&::before': {
    content: '" "',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  '&:nth-child(even)': {
    background: '#EEE',
  },
  '&:nth-child(odd)': {
    background: '#FFF',
  },
  '&:hover': {
    filter: 'brightness(85%)',
  },
});

const Highlighted = styled(Stock)({
  '&:nth-child(even)': {
    background: '#EEEE58',
  },
  '&:nth-child(odd)': {
    background: '#FCFC91',
  },
});

// eslint-disable-next-line react/prop-types
const Base = ({ highlighted, ...props }) =>
  highlighted ? <Highlighted {...props} /> : <Stock {...props} />;

const stickyBaseStyles = {
  position: 'sticky',
  top: '0',
  height: 'auto',
  display: 'flex',
  zIndex: 5,

  background: 'inherit',
  '&::after': {
    background: 'inherit',
  },
};
const LeftPinnedColumns = styled(Box)({
  left: '0',
  borderRight: 'solid 2px #BBB',
  ...stickyBaseStyles,
});

const RightPinnedColumns = styled(Box)({
  right: '0',
  borderLeft: 'solid 4px #BBB',
  ...stickyBaseStyles,
});

const UnPinnedColumns = styled(Box)({
  zIndex: 1,
  display: 'flex',
});

class Row extends PureComponent {
  componentDidMount() {
    const { isDraggable } = this.props;
    if (isDraggable) {
      window.addEventListener('mousedown', this.onMouseDown);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.onMouseDown);
  }

  onMouseDown = evt => {
    const { isDraggable } = this.props;
    if (
      isDraggable &&
      evt.target.closest('div') &&
      evt.target.closest('div').getAttribute('draggablediv')
    ) {
      const selectedRow = evt.target.closest('div').parentElement;
      selectedRow.setAttribute('draggable', 'true');
    }
  };

  render() {
    const {
      pinned,
      unpinned,
      actions,
      data,
      open,
      handleCollapsible,
      isDraggable,
      dragHandlers,
      dropboundry,
      fullWidth,
    } = this.props;
    const activeUnpinnedCols = unpinned.filter(column => column.size !== 0);
    const activePinnedCols = pinned.filter(column => column.size !== 0);
    return (
      <Base
        highlighted={data?.isHighlighted}
        className={isDraggable ? 'js-dataTable-rows' : ''}
        data-dropboundry={isDraggable && dropboundry(data)}
        data-rowid={data.id}
        onDragStart={e => isDraggable && dragHandlers.dragStartHandler(e)}
        onDragOver={e => isDraggable && dragHandlers.dragOverHandler(e)}
        onDragEnd={() => isDraggable && dragHandlers.dragEndHandler()}
        onDrop={e => isDraggable && dragHandlers.dropHandler(e)}
        style={fullWidth ? { width: '100%' } : {}}
      >
        {activePinnedCols.length !== 0 && (
          <LeftPinnedColumns>
            {activePinnedCols.map(column => (
              <Cell
                key={column.key}
                column={column}
                data={data}
                open={open}
                handleCollapsible={handleCollapsible}
              />
            ))}
          </LeftPinnedColumns>
        )}
        <UnPinnedColumns style={fullWidth ? { width: '100%' } : {}}>
          {activeUnpinnedCols.map((column, index) => (
            <Cell
              key={column.key}
              column={column}
              unPinned
              isLastElement={activeUnpinnedCols.length - 1 === index}
              data={data}
              open={open}
              handleCollapsible={handleCollapsible}
            />
          ))}
        </UnPinnedColumns>
        {actions.length !== 0 && (
          <RightPinnedColumns>
            {actions
              .filter(column => column.size !== 0)
              .map(column => (
                <Cell
                  key={column.key}
                  column={column}
                  actions
                  isLastElement
                  data={data}
                  open={open}
                  handleCollapsible={handleCollapsible}
                />
              ))}
          </RightPinnedColumns>
        )}
      </Base>
    );
  }
}
const HeaderColumn = shape({
  key: string.isRequired,
  label: string,
  size: oneOfType([string, number]),
});

Row.propTypes = {
  pinned: arrayOf(HeaderColumn),
  unpinned: arrayOf(HeaderColumn),
  actions: arrayOf(HeaderColumn),
  fullWidth: bool,
  // eslint-disable-next-line
  data: object,
  open: bool,
  handleCollapsible: func,
  dropboundry: func,
  isDraggable: bool,
  dragHandlers: shape({
    dragStartHandler: func,
    dragOverHandler: func,
    dragEndHandler: func,
    dropHandler: func,
  }),
};

export default Row;
