/* eslint-disable no-unused-vars */
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { dispatch } from '../../common/helpers';
import { rebatesService } from '../../services';
import { MESSAGES, DEFAULT_PAGINATION_OBJECT } from '../../common/constants';

import {
  FETCH_REBATE_LIST_REQUEST,
  FETCH_REBATE_LIST_SUCCESS,
  FETCH_REBATE_LIST_ERROR,
  CREATE_COMBO_REBATE_REQUEST,
  CREATE_COMBO_REBATE_ERROR,
  DELETE_REBATE_REQUEST,
  DELETE_REBATE_ERROR,
  UPDATE_REBATE_REQUEST,
  UPDATE_REBATE_SUCCESS,
  UPDATE_REBATE_ERROR,
  FETCH_MATERIAL_IDENTIFIER_REQUEST,
  FETCH_MATERIAL_IDENTIFIER_SUCCESS,
  FETCH_MATERIAL_IDENTIFIER_ERROR,
} from './actions';

import {
  SUCCESS_NOTIFICATION_REQUEST,
  ERROR_NOTIFICATION_REQUEST,
} from '../notifications/actions';

export const createRebate = action$ =>
  action$.pipe(
    ofType(CREATE_COMBO_REBATE_REQUEST),
    mergeMap(({ payload: { values, payload } }) =>
      rebatesService.createRebates(values).pipe(
        mergeMap(() => [
          dispatch(FETCH_REBATE_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.FORM_SUBMITTED_SUCCESSFULLY,
          }),
        ]),
        catchError(error => [
          dispatch(CREATE_COMBO_REBATE_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );

export const fetchRebateList = action$ =>
  action$.pipe(
    ofType(FETCH_REBATE_LIST_REQUEST),
    mergeMap(({ payload }) =>
      rebatesService.getAllRebates(payload).pipe(
        map(data =>
          dispatch(FETCH_REBATE_LIST_SUCCESS, {
            ...data,
            payload,
          })
        )
      )
    ),
    catchError(err => [dispatch(FETCH_REBATE_LIST_ERROR, err)])
  );

export const deleteRebate = (action$, store) => {
  return action$.pipe(
    ofType(DELETE_REBATE_REQUEST),
    mergeMap(({ payload: { id, payload } }) =>
      rebatesService.deleteRebates(id).pipe(
        mergeMap(() => [
          dispatch(FETCH_REBATE_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.DATA_DELETED_SUCCESSFULLY,
          }),
        ]),
        catchError(error => [
          dispatch(DELETE_REBATE_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );
};

export const updateRebate = action$ =>
  action$.pipe(
    ofType(UPDATE_REBATE_REQUEST),
    mergeMap(({ payload: { values, payload } }) =>
      rebatesService.updateRebates(values.id, values).pipe(
        mergeMap(() => [
          dispatch(FETCH_REBATE_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.FORM_UPDATED_SUCCESSFULLY,
          }),
        ]),
        catchError(error => [
          dispatch(UPDATE_REBATE_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );

export const fetchAllMaterialIdentifers = (action$, store) => {
  return action$.pipe(
    ofType(FETCH_MATERIAL_IDENTIFIER_REQUEST),
    mergeMap(({ payload }) =>
      rebatesService.getAllMaterialIdentifiers(payload).pipe(
        map(data => {
          return dispatch(FETCH_MATERIAL_IDENTIFIER_SUCCESS, [...data]);
        })
      )
    ),
    catchError(err => [dispatch(FETCH_MATERIAL_IDENTIFIER_ERROR, err)])
  );
};
