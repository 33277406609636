import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/rebates`;
const materialIdentifiersAPI = `${QNP}/material-identifiers`;

const rebateService = {
  getAllRebates: payload => {
    return service.get(`${api}`, payload);
  },
  createRebates: data => {
    return service.post(`${api}`, data);
  },
  updateRebates: (id, data) => {
    return service.put(`${api}/${id}`, data);
  },
  deleteRebates: id => {
    return service.remove(`${api}/${id}`);
  },

  getAllMaterialIdentifiers: subString => {
    return service.get(`${materialIdentifiersAPI}/${subString}`);
  },
};

export default rebateService;
