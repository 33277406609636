import React from 'react';
import { Box } from '@material-ui/core';

import { arrayOf, object } from 'prop-types';
import TabItem from './TabItem';

const TabHeader = ({ tabItems, ...props }) => {
  return (
    <Box
      justifyContent="start"
      flexDirection="row"
      display="flex"
      mb={2}
      {...props}
    >
      {tabItems.map(val => (
        <TabItem
          active={val.active}
          label={val.label}
          to={val.to}
          key={val.to}
        />
      ))}
    </Box>
  );
};

TabHeader.propTypes = {
  tabItems: arrayOf(object),
};

export default TabHeader;
