/* eslint-disable no-unused-vars */
import {
  FETCH_VARIANCE_COST_REQUEST,
  FETCH_VARIANCE_COST_SUCCESS,
  FETCH_VARIANCE_COST_ERROR,
  FETCH_VARIANCE_COST_HISTORY_SUCCESS,
  FETCH_VARIANCE_COST_HISTORY_REQUEST,
  FETCH_VARIANCE_COST_HISTORY_ERROR,
  FETCH_VARIANCE_QUANTITY_ERROR,
  FETCH_VARIANCE_QUANTITY_REQUEST,
  FETCH_VARIANCE_QUANTITY_SUCCESS,
  FETCH_VARIANCE_QUANTITY_HISTORY_ERROR,
  FETCH_VARIANCE_QUANTITY_HISTORY_REQUEST,
  FETCH_VARIANCE_QUANTITY_HISTORY_SUCCESS,
  CREATE_QUANTITY_VARIANCE_REQUEST,
  CREATE_COST_VARIANCE_REQUEST,
  FETCH_PRIMARY_ALTERNATE_REQUEST,
  FETCH_PRIMARY_ALTERNATE_SUCCESS,
  FETCH_PRIMARY_ALTERNATE_ERROR,
  REJECT_ERROR_ACTION_VARIANCE_QUANTITY,
  REJECT_SUCCESS_ACTION_VARIANCE_QUANTITY,
  REJECT_REQUEST_ACTION_VARIANCE_QUANTITY,
  ACCEPT_ERROR_ACTION_VARIANCE_QUANTITY,
  ACCEPT_SUCCESS_ACTION_VARIANCE_QUANTITY,
  ACCEPT_REQUEST_ACTION_VARIANCE_QUANTITY,
  UPDATE_ERROR_ACTION_VARIANCE_QUANTITY,
  UPDATE_SUCCESS_ACTION_VARIANCE_QUANTITY,
  UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY,
  REJECT_ERROR_ACTION_VARIANCE_COST,
  REJECT_SUCCESS_ACTION_VARIANCE_COST,
  REJECT_REQUEST_ACTION_VARIANCE_COST,
  ACCEPT_ERROR_ACTION_VARIANCE_COST,
  ACCEPT_SUCCESS_ACTION_VARIANCE_COST,
  ACCEPT_REQUEST_ACTION_VARIANCE_COST,
  UPDATE_ERROR_ACTION_VARIANCE_COST,
  UPDATE_SUCCESS_ACTION_VARIANCE_COST,
  UPDATE_REQUEST_ACTION_VARIANCE_COST,
  ACCEPT_REQUEST_DECREASE_VARIANCE_TYPE,
  FETCH_SHARED_PROCURED_MATERIALS_SUCESSS,
  FETCH_SHARED_PROCURED_MATERIALS_REQUEST,
  FETCH_SHARED_PROCURED_MATERIALS_ERROR,
  ACCEPT_SUCCESS_DECREASE_VARIANCE_TYPE,
  ACCEPT_ERROR_DECREASE_VARIANCE_TYPE,
  CREATE_COST_VARIANCE_SUCCESS,
  CREATE_COST_VARIANCE_ERROR,
  CREATE_QUANTITY_VARIANCE_SUCESS,
  CREATE_QUANTITY_VARIANCE_ERROR,
  FETCH_VARIANCE_COST_UPDATE_LINE_REQUEST,
  FETCH_VARIANCE_COST_UPDATE_LINE_SUCCESS,
  FETCH_VARIANCE_COST_UPDATE_LINE_ERROR,
  FETCH_VARIANCE_QUANTITY_UPDATE_LINE_REQUEST,
  FETCH_VARIANCE_QUANTITY_UPDATE_LINE_SUCCESS,
  FETCH_VARIANCE_QUANTITY_UPDATE_LINE_ERROR,
} from './actions';
import { REDUCERS } from '../../common/constants';
import { createReducer, varianceLoadingStatus } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  varianceCost: {
    loading: false,
    error: false,
    response: {
      data: [],
      pagination: {},
      payload: { filter: [], orderBy: [], pagination: {} },
    },
  },
  varianceCostHistory: {
    loading: false,
    error: false,
    response: {
      data: [],
      pagination: {},
      payload: { filter: [], orderBy: [], pagination: {} },
    },
  },
  varianceQuantity: {
    loading: false,
    error: false,
    response: {
      data: [],
      pagination: {},
      payload: { filter: [], orderBy: [], pagination: {} },
    },
  },
  varianceQuantityHistory: {
    loading: false,
    error: false,
    response: {
      data: [],
      pagination: {},
      payload: { filter: [], orderBy: [], pagination: {} },
    },
  },
  primaryAlternate: {
    loading: false,
    error: false,
    response: {
      data: [],
      pagination: {},
      payload: { filter: [], orderBy: [], pagination: {} },
    },
  },
  sharedMaterials: {
    loading: false,
    error: false,
    response: {
      data: [],
      pagination: {},
      payload: { filter: [], orderBy: [], pagination: {} },
    },
  },
  uuids: new Set(),
};

const reducer = {
  // Variance List
  [FETCH_VARIANCE_COST_REQUEST]: state => ({
    ...state,
    varianceCost: {
      ...state.varianceCost,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_VARIANCE_COST_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => ({
    ...state,
    varianceCost: {
      ...REDUCERS.SUCCESS,
      response: {
        pagination,
        data: payload?.pagination?.infinite
          ? [...state.varianceCost.response.data, ...data]
          : data,
        payload,
      },
    },
  }),
  [FETCH_VARIANCE_COST_ERROR]: (state, { payload: error }) => ({
    ...state,
    varianceCost: {
      ...state.varianceCost,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  // Variance HISTORY List
  [FETCH_VARIANCE_COST_HISTORY_REQUEST]: state => ({
    ...state,
    varianceCostHistory: {
      ...state.varianceCostHistory,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_VARIANCE_COST_HISTORY_SUCCESS]: (state, { payload: response }) => ({
    ...state,
    varianceCostHistory: {
      ...REDUCERS.SUCCESS,
      response,
    },
  }),
  [FETCH_VARIANCE_COST_HISTORY_ERROR]: (state, { payload: error }) => ({
    ...state,
    varianceCostHistory: {
      ...state.varianceCostHistory,
      ...REDUCERS.ERROR,
      error,
    },
  }),

  // Variance Quantity List
  [FETCH_VARIANCE_QUANTITY_REQUEST]: state => ({
    ...state,
    varianceQuantity: {
      ...state.varianceQuantity,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_VARIANCE_QUANTITY_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => ({
    ...state,
    varianceQuantity: {
      ...REDUCERS.SUCCESS,
      response: {
        pagination,
        data: payload?.pagination?.infinite
          ? [...state.varianceQuantity.response.data, ...data]
          : data,
        payload,
      },
    },
  }),
  [FETCH_VARIANCE_QUANTITY_ERROR]: (state, { payload: error }) => ({
    ...state,
    varianceQuantity: {
      ...state.varianceQuantity,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  // Variance Quantity List
  [FETCH_VARIANCE_QUANTITY_HISTORY_REQUEST]: state => ({
    ...state,
    varianceQuantityHistory: {
      ...state.varianceQuantityHistory,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_VARIANCE_QUANTITY_HISTORY_SUCCESS]: (state, { payload: response }) => {
    // this because BE send not flat object
    const normalizedResponse = response.data[0]?.bomMaster?.bomVersions?.map(
      x => ({
        effectiveFrom: x.effectiveFrom,
        quantity: x.bomLines?.length !== 0 ? x.bomLines[0].quantity : 0,
      })
    );
    return {
      ...state,
      varianceQuantityHistory: {
        ...REDUCERS.SUCCESS,
        response: {
          ...response,
          data: normalizedResponse || [],
        },
      },
    };
  },
  [FETCH_VARIANCE_QUANTITY_HISTORY_ERROR]: (state, { payload: error }) => ({
    ...state,
    varianceQuantityHistory: {
      ...state.varianceQuantityHistory,
      ...REDUCERS.ERROR,
      error,
    },
  }),

  [FETCH_PRIMARY_ALTERNATE_REQUEST]: state => ({
    ...state,
    primaryAlternate: {
      ...state.primaryAlternate,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_PRIMARY_ALTERNATE_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => ({
    ...state,
    primaryAlternate: {
      ...REDUCERS.SUCCESS,
      response: {
        pagination,
        data: payload?.pagination?.infinite
          ? [...state.primaryAlternate.response.data, ...data]
          : data,
        payload,
      },
    },
  }),
  [FETCH_PRIMARY_ALTERNATE_ERROR]: (state, { payload: error }) => ({
    ...state,
    primaryAlternate: {
      ...state.primaryAlternate,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  // Shared Materials
  [FETCH_SHARED_PROCURED_MATERIALS_REQUEST]: state => ({
    ...state,
    sharedMaterials: {
      ...state.sharedMaterials,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_SHARED_PROCURED_MATERIALS_SUCESSS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => ({
    ...state,
    sharedMaterials: {
      ...REDUCERS.SUCCESS,
      response: {
        pagination,
        data,
        payload,
      },
    },
  }),
  [FETCH_SHARED_PROCURED_MATERIALS_ERROR]: (state, { payload: error }) => ({
    ...state,
    sharedMaterials: {
      ...state.sharedMaterials,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  // Cost Variance Actions
  [CREATE_COST_VARIANCE_REQUEST]: varianceLoadingStatus(),
  [CREATE_COST_VARIANCE_SUCCESS]: varianceLoadingStatus(true),
  [CREATE_COST_VARIANCE_ERROR]: varianceLoadingStatus(false),
  // Variance Update
  [UPDATE_REQUEST_ACTION_VARIANCE_COST]: varianceLoadingStatus(),
  [UPDATE_SUCCESS_ACTION_VARIANCE_COST]: varianceLoadingStatus(true),
  [UPDATE_ERROR_ACTION_VARIANCE_COST]: varianceLoadingStatus(true),
  // Variance Decrease Approval
  [ACCEPT_REQUEST_DECREASE_VARIANCE_TYPE]: varianceLoadingStatus(),
  [ACCEPT_SUCCESS_DECREASE_VARIANCE_TYPE]: varianceLoadingStatus(true),
  [ACCEPT_ERROR_DECREASE_VARIANCE_TYPE]: varianceLoadingStatus(false),
  // Variance Approval
  [ACCEPT_REQUEST_ACTION_VARIANCE_COST]: varianceLoadingStatus(),
  [ACCEPT_SUCCESS_ACTION_VARIANCE_COST]: varianceLoadingStatus(true),
  [ACCEPT_ERROR_ACTION_VARIANCE_COST]: varianceLoadingStatus(false),
  // Variance Rejection
  [REJECT_REQUEST_ACTION_VARIANCE_COST]: varianceLoadingStatus(),
  [REJECT_SUCCESS_ACTION_VARIANCE_COST]: varianceLoadingStatus(false),
  [REJECT_ERROR_ACTION_VARIANCE_COST]: varianceLoadingStatus(true),

  // Quantity Variance Actions
  [CREATE_QUANTITY_VARIANCE_REQUEST]: varianceLoadingStatus(),
  [CREATE_QUANTITY_VARIANCE_SUCESS]: varianceLoadingStatus(true),
  [CREATE_QUANTITY_VARIANCE_ERROR]: varianceLoadingStatus(false),
  // Variance Approval
  [ACCEPT_REQUEST_ACTION_VARIANCE_QUANTITY]: varianceLoadingStatus(),
  [ACCEPT_SUCCESS_ACTION_VARIANCE_QUANTITY]: varianceLoadingStatus(true),
  [ACCEPT_ERROR_ACTION_VARIANCE_QUANTITY]: varianceLoadingStatus(false),
  // Variance Update
  [UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY]: varianceLoadingStatus(),
  [UPDATE_SUCCESS_ACTION_VARIANCE_QUANTITY]: varianceLoadingStatus(true),
  [UPDATE_ERROR_ACTION_VARIANCE_QUANTITY]: varianceLoadingStatus(true),
  // Variance Rejection
  [REJECT_REQUEST_ACTION_VARIANCE_QUANTITY]: varianceLoadingStatus(),
  [REJECT_SUCCESS_ACTION_VARIANCE_QUANTITY]: varianceLoadingStatus(false),
  [REJECT_ERROR_ACTION_VARIANCE_QUANTITY]: varianceLoadingStatus(true),
  // BLAH

  [UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY]: varianceLoadingStatus(),

  // Variance List
  [FETCH_VARIANCE_COST_UPDATE_LINE_REQUEST]: (state, { payload: { id } }) => {
    const currentData = state?.varianceCost?.response?.data || [];
    let idArr = id;
    if (!Array.isArray(id)) {
      idArr = [id];
    }
    idArr.forEach(element => {
      const updatedObjectIndex = currentData.findIndex(
        x => x.quoteVarianceId === element
      );
      if (updatedObjectIndex !== -1) {
        // eslint-disable-next-line
        currentData[updatedObjectIndex].messages = null;
      }
    });

    return {
      ...state,
    };
  },
  [FETCH_VARIANCE_COST_UPDATE_LINE_SUCCESS]: (state, { payload: { data } }) => {
    const currentData = state?.varianceCost?.response?.data || [];
    data.forEach(element => {
      const updatedObjectIndex = currentData.findIndex(
        x => x.quoteVarianceId === element?.quoteVarianceId
      );
      if (updatedObjectIndex !== -1) {
        // eslint-disable-next-line
        currentData[updatedObjectIndex] = element;
      }
    });
    return {
      ...state,
      varianceCost: {
        ...REDUCERS.SUCCESS,
        response: {
          ...state.varianceCost.response,
          data: currentData,
        },
      },
    };
  },
  [FETCH_VARIANCE_COST_UPDATE_LINE_ERROR]: (state, { payload: error }) => ({
    ...state,
    varianceCost: {
      ...state.varianceCost,
    },
  }),

  // Quantity variance
  [FETCH_VARIANCE_QUANTITY_UPDATE_LINE_REQUEST]: (
    state,
    { payload: { id } }
  ) => {
    console.log('state -> ', state);
    const currentData = state?.varianceQuantity?.response?.data || [];
    let idArr = id;
    if (!Array.isArray(id)) {
      idArr = [id];
    }
    idArr.forEach(element => {
      const updatedObjectIndex = currentData.findIndex(x => x.id === element);
      if (updatedObjectIndex !== -1) {
        // eslint-disable-next-line
        currentData[updatedObjectIndex].messages = null;
      }
    });

    return {
      ...state,
    };
  },
  [FETCH_VARIANCE_QUANTITY_UPDATE_LINE_SUCCESS]: (
    state,
    { payload: { data } }
  ) => {
    const currentData = state?.varianceQuantity?.response?.data || [];
    data.forEach(element => {
      const updatedObjectIndex = currentData.findIndex(
        x => x.id === element?.id
      );
      if (updatedObjectIndex !== -1) {
        // eslint-disable-next-line
        currentData[updatedObjectIndex] = element;
      }
    });
    return {
      ...state,
      varianceQuantity: {
        ...REDUCERS.SUCCESS,
        response: {
          ...state.varianceQuantity.response,
          data: currentData,
        },
      },
    };
  },
  [FETCH_VARIANCE_QUANTITY_UPDATE_LINE_ERROR]: (state, { payload: error }) => ({
    ...state,
    varianceQuantity: {
      ...state.varianceQuantity,
    },
  }),
};
export default createReducer(schema, reducer);
