import React from 'react';
import { Box } from '@material-ui/core';
import { string, bool } from 'prop-types';

const DisplayLabel = ({
  label,
  value,
  color = 'text.primary',
  first,
  ...props
}) => {
  return (
    <>
      <Box display="flex" mt={first ? 0 : 2} ml={2} {...props}>
        <Box
          component="span"
          color="text.secondary"
          pr={1}
          pt={0.7}
          fontSize={16}
        >
          {label}
        </Box>
        <Box component="span" fontSize={21} color={color}>
          {value}
        </Box>
      </Box>
    </>
  );
};

DisplayLabel.propTypes = {
  label: string,
  value: string,
  color: string,
  first: bool,
};

export default DisplayLabel;
