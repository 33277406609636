export const GET_BOM_LIST_REQUEST = 'GET_BOM_LIST_REQUEST';
export const GET_BOM_LIST_SUCCESS = 'GET_BOM_LIST_SUCCESS';
export const GET_BOM_LIST_ERROR = 'GET_BOM_LIST_ERROR';

export const REJECT_ERROR_ACTION_VARIANCE_QUANTITY =
  'REJECT_ERROR_ACTION_VARIANCE_QUANTITY';
export const REJECT_SUCCESS_ACTION_VARIANCE_QUANTITY =
  'REJECT_SUCCESS_ACTION_VARIANCE_QUANTITY';
export const REJECT_REQUEST_ACTION_VARIANCE_QUANTITY =
  'REJECT_REQUEST_ACTION_VARIANCE_QUANTITY';

export const ACCEPT_ERROR_ACTION_VARIANCE_QUANTITY =
  'ACCEPT_ERROR_ACTION_VARIANCE_QUANTITY';
export const ACCEPT_SUCCESS_ACTION_VARIANCE_QUANTITY =
  'ACCEPT_SUCCESS_ACTION_VARIANCE_QUANTITY';
export const ACCEPT_REQUEST_ACTION_VARIANCE_QUANTITY =
  'ACCEPT_REQUEST_ACTION_VARIANCE_QUANTITY';

export const UPDATE_ERROR_ACTION_VARIANCE_QUANTITY_BOM =
  'UPDATE_ERROR_ACTION_VARIANCE_QUANTITY_BOM';
export const UPDATE_SUCCESS_ACTION_VARIANCE_QUANTITY_BOM =
  'UPDATE_SUCCESS_ACTION_VARIANCE_QUANTITY_BOM';
export const UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY_BOM =
  'UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY_BOM';

export const CREATE_QUANTITY_VARIANCE_REQUEST_BOM =
  'CREATE_QUANTITY_VARIANCE_REQUEST_BOM';
export const CREATE_QUANTITY_VARIANCE_SUCESS_BOM =
  'CREATE_QUANTITY_VARIANCE_SUCESS_BOM';
export const CREATE_QUANTITY_VARIANCE_ERROR_BOM =
  'CREATE_QUANTITY_VARIANCE_ERROR_BOM';
