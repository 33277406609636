import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { merge } from 'rxjs';
import { MESSAGES, DEFAULT_PAGINATION_OBJECT } from '../../common/constants';

import {
  GET_FINANCIAL_BURDEN_ERROR,
  GET_FINANCIAL_BURDEN_REQUEST,
  GET_FINANCIAL_BURDEN_SUCCESS,
  CREATE_FINANCIAL_BURDEN_REQUEST,
  CREATE_FINANCIAL_BURDEN_ERROR,
  UPDATE_FINANCIAL_BURDEN_REQUEST,
  UPDATE_FINANCIAL_BURDEN_ERROR,
  DELETE_FINANCIAL_BURDEN_REQUEST,
  DELETE_FINANCIAL_BURDEN_SUCCESS,
  DELETE_FINANCIAL_BURDEN_ERROR,
} from './actions';

import {
  SUCCESS_NOTIFICATION_REQUEST,
  ERROR_NOTIFICATION_REQUEST,
} from '../notifications/actions';

import { financialBurdenService } from '../../services/financialBurden';
import { dispatch } from '../../common/helpers';

export const getFinancialBurden = action$ =>
  action$.pipe(
    ofType(GET_FINANCIAL_BURDEN_REQUEST),
    mergeMap(({ payload }) => {
      return financialBurdenService.getAllFB(payload).pipe(
        map(data =>
          dispatch(GET_FINANCIAL_BURDEN_SUCCESS, {
            ...data,
            payload,
          })
        )
      );
    }),
    catchError(err => [dispatch(GET_FINANCIAL_BURDEN_ERROR, err)])
  );

export const createFinancialBurden = action$ =>
  action$.pipe(
    ofType(CREATE_FINANCIAL_BURDEN_REQUEST),
    mergeMap(({ payload: { values, payload } }) =>
      financialBurdenService.createFB(values).pipe(
        mergeMap(() => [
          dispatch(GET_FINANCIAL_BURDEN_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.FORM_SUBMITTED_SUCCESSFULLY,
          }),
        ]),
        catchError(error => [
          dispatch(CREATE_FINANCIAL_BURDEN_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );

export const updateFinancialBurden = action$ =>
  action$.pipe(
    ofType(UPDATE_FINANCIAL_BURDEN_REQUEST),
    mergeMap(({ payload: { id, data, payload } }) =>
      financialBurdenService.updateFB(id, data).pipe(
        mergeMap(() =>
          merge(
            [
              dispatch(GET_FINANCIAL_BURDEN_REQUEST, {
                ...payload,
                pagination: DEFAULT_PAGINATION_OBJECT,
              }),
            ],
            [
              dispatch(SUCCESS_NOTIFICATION_REQUEST, {
                message: MESSAGES.FORM_UPDATED_SUCCESSFULLY,
              }),
            ]
          )
        ),
        catchError(err => [
          dispatch(UPDATE_FINANCIAL_BURDEN_ERROR, err),
          dispatch(ERROR_NOTIFICATION_REQUEST, err),
        ])
      )
    )
  );

export const removeFinancialBurden = action$ =>
  action$.pipe(
    ofType(DELETE_FINANCIAL_BURDEN_REQUEST),
    mergeMap(({ payload: { id, payload } }) =>
      financialBurdenService.deleteFB(id).pipe(
        mergeMap(() =>
          merge(
            [dispatch(GET_FINANCIAL_BURDEN_REQUEST)],
            [
              dispatch(DELETE_FINANCIAL_BURDEN_SUCCESS, {
                ...payload,
                pagination: DEFAULT_PAGINATION_OBJECT,
              }),
              dispatch(SUCCESS_NOTIFICATION_REQUEST, {
                message: MESSAGES.DATA_DELETED_SUCCESSFULLY,
              }),
            ]
          )
        ),
        catchError(err => [
          dispatch(DELETE_FINANCIAL_BURDEN_ERROR, err),
          dispatch(ERROR_NOTIFICATION_REQUEST, err),
        ])
      )
    )
  );
