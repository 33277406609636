import {
  GET_BOM_LIST_REQUEST,
  GET_BOM_LIST_SUCCESS,
  GET_BOM_LIST_ERROR,
  ACCEPT_SUCCESS_ACTION_VARIANCE_QUANTITY,
  ACCEPT_ERROR_ACTION_VARIANCE_QUANTITY,
  ACCEPT_REQUEST_ACTION_VARIANCE_QUANTITY,
  REJECT_REQUEST_ACTION_VARIANCE_QUANTITY,
  REJECT_SUCCESS_ACTION_VARIANCE_QUANTITY,
  REJECT_ERROR_ACTION_VARIANCE_QUANTITY,
  CREATE_QUANTITY_VARIANCE_ERROR_BOM,
  CREATE_QUANTITY_VARIANCE_SUCESS_BOM,
  CREATE_QUANTITY_VARIANCE_REQUEST_BOM,
  UPDATE_ERROR_ACTION_VARIANCE_QUANTITY_BOM,
  UPDATE_SUCCESS_ACTION_VARIANCE_QUANTITY_BOM,
  UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY_BOM,
} from './actions';
import { REDUCERS } from '../../common/constants';
import { createReducer, varianceLoadingStatus } from '../../common/helpers';

const schema = {
  bom: {
    loading: false,
    response: { data: [], pagination: {} },
  },
};

const reducer = {
  [GET_BOM_LIST_REQUEST]: state => ({
    ...state,
    bom: { ...state.bom, ...REDUCERS.LOADING },
  }),
  [GET_BOM_LIST_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => {
    return {
      ...state,
      bom: {
        ...REDUCERS.SUCCESS,
        response: {
          pagination,
          data: payload?.pagination?.infinite
            ? [...state.bom.response.data, ...data]
            : data,
          payload,
        },
      },
    };
  },
  [GET_BOM_LIST_ERROR]: (state, { payload: error }) => ({
    ...state,
    bom: {
      ...state.bom,
      ...REDUCERS.ERROR,
      error,
    },
  }),

  // Quantity Variance Actions
  [CREATE_QUANTITY_VARIANCE_REQUEST_BOM]: varianceLoadingStatus(),
  [CREATE_QUANTITY_VARIANCE_SUCESS_BOM]: varianceLoadingStatus(true),
  [CREATE_QUANTITY_VARIANCE_ERROR_BOM]: varianceLoadingStatus(false),

  [UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY_BOM]: varianceLoadingStatus(),
  [UPDATE_SUCCESS_ACTION_VARIANCE_QUANTITY_BOM]: varianceLoadingStatus(true),
  [UPDATE_ERROR_ACTION_VARIANCE_QUANTITY_BOM]: varianceLoadingStatus(false),

  // Variance Approval
  [ACCEPT_REQUEST_ACTION_VARIANCE_QUANTITY]: varianceLoadingStatus(),

  [ACCEPT_SUCCESS_ACTION_VARIANCE_QUANTITY]: varianceLoadingStatus(true),
  [ACCEPT_ERROR_ACTION_VARIANCE_QUANTITY]: varianceLoadingStatus(false),
  // Variance Rejection
  [REJECT_REQUEST_ACTION_VARIANCE_QUANTITY]: varianceLoadingStatus(),
  [REJECT_SUCCESS_ACTION_VARIANCE_QUANTITY]: varianceLoadingStatus(false),
  [REJECT_ERROR_ACTION_VARIANCE_QUANTITY]: varianceLoadingStatus(true),
};

export default createReducer(schema, reducer);
