import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/bom`;

const bomService = {
  getAllBom: (programQuoteId, plantId, params) => {
    return service.get(`${api}/list/view/${programQuoteId}/plant/${plantId}`, {
      ...params,
    });
  },
  getBom: id => {
    return service.get(`${api}/${id}`);
  },
  createBom: data => {
    return service.post(`${api}`, data);
  },
  updateBom: (id, data) => {
    return service.put(`${api}/${id}`, data);
  },
  deleteBom: id => {
    return service.remove(`${api}/${id}`);
  },
  processBom: id => {
    return service.post(`${api}/process/${id}`);
  },
};
export default bomService;
