import React, { useState, useEffect } from 'react';
import { string, bool, arrayOf } from 'prop-types';
import { TextField, Box, Switch } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Tooltip } from 'grommet-icons';
import moment from 'moment';
import {
  GET_COMMENT_LIST_REQUEST,
  ADD_COMMENT_REQUEST,
} from '../../redux/comments/actions';

import { GET_AUDIT_LIST_REQUEST } from '../../redux/audits/actions';
import { useCallbackDispatch, useComposeDispatcher } from '../../common/hooks';
import Button from '../Button';
import {
  USER_ROLES,
  VARIANCE_RESOLUTION_ACTIONS,
} from '../../common/constants';
import Loader from '../Loader';

const Base = ({ entityId, entityType, userId, userRoles, disabled }) => {
  const [getCommentList, getAuditList] = useComposeDispatcher(
    GET_COMMENT_LIST_REQUEST,
    GET_AUDIT_LIST_REQUEST
  );
  const addCommentList = useCallbackDispatch(ADD_COMMENT_REQUEST);
  const [showComment, setShowComment] = useState(false);
  const [comment, setComment] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [isAdding, setAdding] = useState(false);
  const {
    comments: {
      loading: loadingOnAdding,
      response: { data, loading: loadingComments },
    },
    audits: {
      response: { data: auditList, loading: loadingAudits },
    },
  } = useSelector(({ comments, audits }) => ({
    comments,
    audits,
  }));

  const currentThreadId = data ? data.threadId : null;
  const threadComments = data ? data.messages : [];
  const auditComments = auditList.reduce((acc, auditItem) => {
    let msj = {};
    const type =
      entityType === 'QuoteVariance'
        ? auditItem.quoteVarianceId
        : auditItem.materialVarianceId;
    if (type === entityId) {
      if (
        auditItem.action === 'variance-approved-manually' ||
        auditItem.action === 'variance-rejected-manually' ||
        auditItem.action === 'cost-variance-approved'
      ) {
        msj = [
          ...acc,
          {
            sender: auditItem.userResponsable,
            id: auditItem.id,
            action: VARIANCE_RESOLUTION_ACTIONS[auditItem.action],
            message: auditItem.changeType,
            createdAt: auditItem.createdAt,
          },
        ];
      } else {
        msj = [
          ...acc,
          {
            id: auditItem.id,
            sender: auditItem.userResponsable,
            action: VARIANCE_RESOLUTION_ACTIONS[auditItem.action],
            message: auditItem.changeType,
            createdAt: auditItem.createdAt,
            justification: auditItem.justification,
            previousValue: JSON.parse(auditItem.previousValue),
            currentValue: JSON.parse(auditItem.value),
          },
        ];
      }

      return msj;
    }

    return acc;
  }, []);

  const allComments = auditComments.concat(threadComments || []) || [];

  const commentsList = comments => {
    return comments
      .sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      })
      .map(obj => {
        return (
          <Box key={`${obj.id}`}>
            {obj.justification ? (
              <>
                <Box display="flex" ml={2} mr={2} mt={1}>
                  <Box
                    fontSize={16}
                    style={{ fontStyle: 'italic' }}
                    color="success.main"
                  >
                    QBert 2024
                  </Box>
                  <Box flexGrow={1} />
                  <Box fontSize={16} color="success.main">
                    {moment(obj.createdAt).format('MM/DD/YYYY')}
                  </Box>
                </Box>
                <Box key={obj.id} ml={2} mr={2} mt={1} fontSize={16}>
                  {obj.message}
                </Box>
                <Box display="flex" ml={2} mr={2} mt={1}>
                  <Box
                    fontSize={16}
                    style={{ fontStyle: 'italic' }}
                    color="success.main"
                  >
                    {obj.sender.name}
                  </Box>
                  <Box flexGrow={1} />
                  <Box fontSize={16} color="success.main">
                    {moment(obj.createdAt).format('HH:mm MM/DD/YYYY')}
                  </Box>
                </Box>
                <Box ml={2} mr={2} mt={1} fontSize={16}>
                  <Box component="span" fontWeight="bold">
                    Action:
                  </Box>
                  {` ${obj.action}`}
                </Box>
                <Box display="flex">
                  <Box ml={2} mr={2} mt={1} fontSize={16}>
                    <Box component="span" fontWeight="bold">
                      Justification:
                    </Box>
                    {` ${obj.justification}`}
                  </Box>
                  <Box ml={2} mr={2} mt={1} fontSize={16}>
                    <Box component="span" fontWeight="bold">
                      Previous:
                    </Box>
                    {` ${obj.previousValue}`}
                  </Box>
                  <Box ml={2} mr={2} mt={1} fontSize={16}>
                    <Box component="span" fontWeight="bold">
                      Current:
                    </Box>
                    {` ${obj.currentValue}`}
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box display="flex" ml={2} mr={2} mt={1}>
                  <Box
                    fontSize={16}
                    style={{ fontStyle: 'italic' }}
                    color="success.main"
                  >
                    {obj.sender.name}
                  </Box>
                  <Box flexGrow={1} />
                  <Box fontSize={16} color="success.main">
                    {moment(obj.createdAt).format('HH:mm MM/DD/YYYY')}
                  </Box>
                </Box>
                <Box key={obj.id} ml={2} mr={2} mt={1} fontSize={16}>
                  {obj.message}
                </Box>
              </>
            )}
          </Box>
        );
      });
  };

  useEffect(
    () => {
      if (entityId) {
        getCommentList({
          entityType,
          entityId,
        });
        getAuditList({
          filter: [
            {
              value: entityId,
              field:
                entityType === 'QuoteVariance'
                  ? 'varianceAuditQuoteVarianceId'
                  : 'varianceAuditMaterialVarianceId',
              operator: 'eq',
            },
          ],
        });
      }
    }, // eslint-disable-next-line
    [entityId, entityType]
  );

  useEffect(() => {
    if (loadingOnAdding) {
      setAdding(false);
    }
  }, [loadingOnAdding]);

  const isLoading = isAdding || loadingComments || loadingAudits;
  return (
    <>
      <Box m={2} display="block" flexDirection="row" position="relative">
        {isLoading ? (
          <Loader
            style={{
              zIndex: '1000',
              position: 'absolute',
              right: '40%',
            }}
            hideIcon
          />
        ) : (
          <></>
        )}
        <Box m={2} component="span" color="text.secondary" pr={1}>
          Comments
        </Box>
        <Box mr={2} ml={2} mb={1} mt={1}>
          {showComment ? (
            <Box display="block">
              <Box>
                <TextField
                  autoFocus
                  multiline
                  rows={2}
                  rowsMax={4}
                  id="outlined-multiline-static"
                  variant="outlined"
                  fullWidth
                  onChange={e => setComment(e.target.value)}
                  value={comment}
                />
              </Box>
              <Box mt={2} display="flex" alignItems="flex-start">
                {userRoles.includes(USER_ROLES.SPM) ? (
                  <Box>
                    <Switch
                      color="primary"
                      checked={isPublic}
                      onChange={() => setIsPublic(!isPublic)}
                    />{' '}
                    Public Comment
                  </Box>
                ) : null}
                <Box flexGrow={1} />
                <Button
                  btnType="primary"
                  disabled={isLoading}
                  onClick={() => {
                    setAdding(true);
                    setComment('');
                    addCommentList({
                      entityType,
                      entityId,
                      messages: [
                        {
                          message: comment,
                          senderId: userId,
                          timestamp: '2020/03/01',
                          threadId: currentThreadId,
                          isPrivate: !isPublic,
                        },
                      ],
                    });
                  }}
                >
                  Add
                </Button>
              </Box>
            </Box>
          ) : (
            <Box display="Flex">
              <Box mt="6px" mr={2}>
                <Tooltip size="30px" />
              </Box>
              <Box width={700}>
                <TextField
                  disabled={disabled || isLoading}
                  placeholder="Add a comment"
                  size="small"
                  onClick={
                    disabled || isLoading ? null : () => setShowComment(true)
                  }
                  id="outlined-multiline-static"
                  variant="outlined"
                  fullWidth
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box m={2} display="block" flexDirection="row">
        {commentsList(allComments)}
      </Box>
    </>
  );
};
Base.propTypes = {
  entityId: string,
  entityType: string,
  userId: string,
  userRoles: arrayOf(string),
  disabled: bool,
};

export default Base;
