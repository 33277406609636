import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { merge } from 'rxjs';
import { MESSAGES, DEFAULT_PAGINATION_OBJECT } from '../../common/constants';
import { plantPrefService } from '../../services';
import {
  CREATE_PLANT_PREF_REQUEST,
  CREATE_PLANT_PREF_SUCCESS,
  CREATE_PLANT_PREF_ERROR,
  FETCH_PLANT_PREF_REQUEST,
  FETCH_PLANT_PREF_SUCCESS,
  FETCH_PLANT_PREF_ERROR,
  UPDATE_PLANT_PREF_REQUEST,
  UPDATE_PLANT_PREF_SUCCESS,
  UPDATE_PLANT_PREF_ERROR,
  UPDATE_PLANT_PREF_SORT_REQUEST,
  UPDATE_PLANT_PREF_SORT_ERROR,
  DELETE_PLANT_PREF_REQUEST,
  DELETE_PLANT_PREF_ERROR,
} from './actions';
import { dispatch } from '../../common/helpers';
import {
  SUCCESS_NOTIFICATION_REQUEST,
  ERROR_NOTIFICATION_REQUEST,
} from '../notifications/actions';

export const createPlantPref = action$ =>
  action$.pipe(
    ofType(CREATE_PLANT_PREF_REQUEST),
    mergeMap(({ payload: { values, payload } }) =>
      plantPrefService.createPlantPref(values).pipe(
        mergeMap(
          () => [
            dispatch(SUCCESS_NOTIFICATION_REQUEST, {
              message: MESSAGES.FORM_SUBMITTED_SUCCESSFULLY,
            }),
            dispatch(FETCH_PLANT_PREF_REQUEST, {
              ...payload,
              pagination: DEFAULT_PAGINATION_OBJECT,
            }),
          ],
          [dispatch(CREATE_PLANT_PREF_SUCCESS)]
        ),
        catchError(error => [
          dispatch(CREATE_PLANT_PREF_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );

export const fetchPlantPrefList = action$ =>
  action$.pipe(
    ofType(FETCH_PLANT_PREF_REQUEST),
    mergeMap(({ payload }) => plantPrefService.getAllPlantPref(payload)),
    map(data => dispatch(FETCH_PLANT_PREF_SUCCESS, data)),
    catchError(err => [dispatch(FETCH_PLANT_PREF_ERROR, err)])
  );

export const updatePlantPrefList = action$ =>
  action$.pipe(
    ofType(UPDATE_PLANT_PREF_REQUEST),
    mergeMap(({ payload: { values, payload } }) =>
      plantPrefService.updatePlantPref(values.id, values).pipe(
        mergeMap(() =>
          merge(
            [
              dispatch(FETCH_PLANT_PREF_REQUEST, {
                ...payload,
                pagination: DEFAULT_PAGINATION_OBJECT,
              }),
              dispatch(SUCCESS_NOTIFICATION_REQUEST, {
                message: MESSAGES.FORM_UPDATED_SUCCESSFULLY,
              }),
            ][
              dispatch(UPDATE_PLANT_PREF_SUCCESS, {
                ...payload,
                pagination: DEFAULT_PAGINATION_OBJECT,
              })
            ]
          )
        ),
        catchError(err => [
          dispatch(UPDATE_PLANT_PREF_ERROR, err),
          dispatch(ERROR_NOTIFICATION_REQUEST, err),
        ])
      )
    )
  );

export const deletePlantPref = action$ => {
  return action$.pipe(
    ofType(DELETE_PLANT_PREF_REQUEST),
    mergeMap(({ payload: { id, payload } }) =>
      plantPrefService.deletePlantPref(id).pipe(
        mergeMap(() => [
          dispatch(FETCH_PLANT_PREF_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.DATA_DELETED_SUCCESSFULLY,
          }),
        ]),
        catchError(error => [
          dispatch(DELETE_PLANT_PREF_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );
};

export const updatePlantPrefSortList = action$ =>
  action$.pipe(
    ofType(UPDATE_PLANT_PREF_SORT_REQUEST),
    mergeMap(({ payload: { id, type, plantId, regionId, preference } }) => {
      return plantPrefService.updatePlantPref(id, {
        type,
        plantId,
        regionId,
        preference,
      });
    }),
    mergeMap(() => {
      return merge();
    }),
    catchError(err => {
      return [
        dispatch(UPDATE_PLANT_PREF_SORT_ERROR, err),
        dispatch(ERROR_NOTIFICATION_REQUEST, err),
      ];
    })
  );
