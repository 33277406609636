import {
  SUCCESS_NOTIFICATION_REQUEST,
  SHOW_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  RESET_NOTIFICATION_SUCCESS,
} from './actions';
import { createReducer } from '../../common/helpers';
import { MESSAGES } from '../../common/constants';

const schema = {
  success: false,
  error: false,
  message: '',
};

const reducer = {
  [SUCCESS_NOTIFICATION_REQUEST]: state => ({
    state,
  }),
  [SHOW_SUCCESS_MESSAGE]: (state, { payload }) => {
    return { ...state.state, success: true, message: payload.message };
  },
  [SHOW_ERROR_MESSAGE]: (state, { payload: { response } }) => {
    const msg =
      response && response.error && response.error.message
        ? response.error.message
        : MESSAGES.SOMETHING_WENT_WRONG;
    return {
      ...state.state,
      success: false,
      error: true,
      message: msg,
    };
  },
  [RESET_NOTIFICATION_SUCCESS]: state => {
    return { ...state.state, success: false, message: '', error: false };
  },
};

export default createReducer(schema, reducer);
