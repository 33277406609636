// QUOTE List Actions
export const FETCH_QUOTE_LIST_REQUEST = 'FETCH_QUOTE_LIST_REQUEST';
export const FETCH_QUOTE_LIST_SUCCESS = 'FETCH_QUOTE_LIST_SUCCESS';
export const FETCH_QUOTE_LIST_ERROR = 'FETCH_QUOTE_LIST_ERROR';

export const FETCH_MYQUOTES_DISTRIBUTION_LIST_REQUEST =
  'FETCH_MYQUOTES_DISTRIBUTION_LIST_REQUEST';
export const FETCH_MYQUOTES_DISTRIBUTION_LIST_SUCCESS =
  'FETCH_MYQUOTES_DISTRIBUTION_LIST_SUCCESS';
export const FETCH_MYQUOTES_DISTRIBUTION_LIST_ERROR =
  'FETCH_MYQUOTES_DISTRIBUTION_LIST_ERROR';

export const FETCH_MYQUOTES_PROCUREMENT_LIST_REQUEST =
  'FETCH_MYQUOTES_PROCUREMENT_LIST_REQUEST';
export const FETCH_MYQUOTES_PROCUREMENT_LIST_SUCCESS =
  'FETCH_MYQUOTES_PROCUREMENT_LIST_SUCCESS';
export const FETCH_MYQUOTES_PROCUREMENT_LIST_ERROR =
  'FETCH_MYQUOTES_PROCUREMENT_LIST_ERROR';

export const FETCH_MYQUOTES_MANUFACTURING_LIST_REQUEST =
  'FETCH_MYQUOTES_MANUFACTURING_LIST_REQUEST';
export const FETCH_MYQUOTES_MANUFACTURING_LIST_SUCCESS =
  'FETCH_MYQUOTES_MANUFACTURING_LIST_SUCCESS';
export const FETCH_MYQUOTES_MANUFACTURING_LIST_ERROR =
  'FETCH_MYQUOTES_MANUFACTURING_LIST_ERROR';

export const FETCH_CROSS_PLANT_COMPARISSON_REQUEST =
  'FETCH_CROSS_PLANT_COMPARISSON_REQUEST';
export const FETCH_CROSS_PLANT_COMPARISSON_SUCCESS =
  'FETCH_CROSS_PLANT_COMPARISSON_SUCCESS';
export const FETCH_CROSS_PLANT_COMPARISSON_ERROR =
  'FETCH_CROSS_PLANT_COMPARISSON_ERROR';

export const REJECT_ERROR_ACTION_VARIANCE_COST =
  'REJECT_ERROR_ACTION_VARIANCE_COST';
export const REJECT_SUCCESS_ACTION_VARIANCE_COST =
  'REJECT_SUCCESS_ACTION_VARIANCE_COST';
export const REJECT_REQUEST_ACTION_VARIANCE_COST =
  'REJECT_REQUEST_ACTION_VARIANCE_COST';

export const ACCEPT_ERROR_ACTION_VARIANCE_COST =
  'ACCEPT_ERROR_ACTION_VARIANCE_COST';
export const ACCEPT_SUCCESS_ACTION_VARIANCE_COST =
  'ACCEPT_SUCCESS_ACTION_VARIANCE_COST';
export const ACCEPT_REQUEST_ACTION_VARIANCE_COST =
  'ACCEPT_REQUEST_ACTION_VARIANCE_COST';

export const UPDATE_ERROR_ACTION_VARIANCE_COST =
  'UPDATE_ERROR_ACTION_VARIANCE_COST';
export const UPDATE_SUCCESS_ACTION_VARIANCE_COST =
  'UPDATE_SUCCESS_ACTION_VARIANCE_COST';
export const UPDATE_REQUEST_ACTION_VARIANCE_COST =
  'UPDATE_REQUEST_ACTION_VARIANCE_COST';

export const CREATE_QUANTITY_VARIANCE_REQUEST =
  'CREATE_QUANTITY_VARIANCE_REQUEST';
export const CREATE_COST_VARIANCE_REQUEST = 'CREATE_COST_VARIANCE_REQUEST';
export const CREATE_COST_VARIANCE_SUCCESS = 'CREATE_COST_VARIANCE_SUCCESS';
export const CREATE_COST_VARIANCE_ERROR = 'CREATE_COST_VARIANCE_ERROR';

export const ACCEPT_SUCCESS_DECREASE_VARIANCE_TYPE =
  'ACCEPT_SUCCESS_DECREASE_VARIANCE_TYPE';
export const ACCEPT_ERROR_DECREASE_VARIANCE_TYPE =
  'ACCEPT_ERROR_DECREASE_VARIANCE_TYPE';
export const ACCEPT_REQUEST_DECREASE_VARIANCE_TYPE =
  'ACCEPT_REQUEST_DECREASE_VARIANCE_TYPE';
