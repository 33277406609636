import { useDispatch, useSelector } from 'react-redux';
import { useReducer, useEffect, useState, useCallback } from 'react';
import { USER_ROLES } from './constants';
import { native } from './service';
import { cleanVarianceFilters } from './helpers';

export const useCallbackDispatch = type => {
  const dispatch = useDispatch();
  return useCallback(payload => dispatch({ type, payload }), [dispatch, type]);
};

export const useComposeDispatcher = (...args) => {
  const dispatch = useDispatch();
  return args.map(type => payload => dispatch({ type, payload }));
};

export const useDefaultReducer = initialState => {
  const [state, _dispatch] = useReducer(
    (_state, { type, payload }) => ({ ..._state, [type]: payload }),
    initialState
  );
  const dispatch = type => payload => _dispatch({ type, payload });

  return [state, dispatch, _dispatch];
};

export const useUserData = () => {
  const { data } = useSelector(({ user }) => user);
  return data || {};
};

export const useForSearchRequest = (value, delay) => {
  const [searchValue, setSearchValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return searchValue;
};

const loadBatch = async (
  url,
  { field },
  filter,
  onlyOwned,
  pageNumber = 1,
  type
) => {
  const payload = { filter };
  cleanVarianceFilters(payload);
  const {
    data,
    pagination: { hasNext, totalCount, quantity },
  } = await native.get(url, {
    pagination: {
      pageNumber,
      pageSize: 100,
      allItems: false,
    },
    onlyOwned,
    filter: payload.filter.filter(x => x.field !== field),
    ...(type ? { type } : {}),
  });
  return {
    data: data.map(({ id, approved, quoteVariance }) => ({
      id: quoteVariance?.id || id,
      approved: quoteVariance ? quoteVariance?.approved : approved,
    })),
    totalCount,
    hasNext,
    quantity,
  };
};

const acceptBatch = async (url, data) => {
  await native.post(url, {
    variances: data,
  });
  return true;
};

export const useApproveAll = (
  { get, post, field, postSupplier, type = '' },
  role
) => {
  const approved = {
    field,
    type: 'bool',
    operator: 'eq',
    value: false,
  };

  const [total, setTotal] = useState(null);
  const [processed, setProcessed] = useState(0);
  const reset = useCallback(() => {
    setTotal(null);
    setProcessed(0);
  }, []);
  const request = useCallback(
    async (filter, onlyOwned) => {
      let next = true;
      let done = 0;
      // eslint-disable-next-line
      for (let pageNumber = 1; next; pageNumber++) {
        // eslint-disable-next-line
        const { data, totalCount, quantity, hasNext } = await loadBatch(
          get,
          approved,
          filter,
          onlyOwned,
          pageNumber,
          type
        );
        if (pageNumber === 1) {
          setTotal(totalCount);
        }

        // eslint-disable-next-line
        await acceptBatch(
          role === USER_ROLES.SUPPLIER ? postSupplier : post,
          data.filter(x => !x.approved)
        );
        done += quantity;
        setProcessed(done);
        next = hasNext;
      }
    },
    // eslint-disable-next-line
    []
  );
  return { request, reset, total, processed };
};
