import React from 'react';
import { Menu, MenuItem, styled } from '@material-ui/core';
import { arrayOf, bool, func, objectOf, number } from 'prop-types';
import { themeColor } from '../../common/constants';

const CustomMenu = styled(MenuItem)({
  '&:focus': {
    backgroundColor: themeColor,
    color: 'white',
  },
});

const MenuOptions = ({
  options,
  open = false,
  selectedOption,
  closeMenu,
  anchorEl,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={() => {
        closeMenu();
      }}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {options.map(data => (
        <CustomMenu
          key={data}
          value={data}
          onClick={() => {
            selectedOption(data);
          }}
        >
          {data}
        </CustomMenu>
      ))}
    </Menu>
  );
};
MenuOptions.propTypes = {
  open: bool,
  closeMenu: func,
  selectedOption: func,
  options: arrayOf(String).isRequired,
  anchorEl: objectOf(number),
};

export default MenuOptions;
