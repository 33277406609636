import * as service from '../common/service';
import { QNP, CALCULATIONS } from '../common/constants';

const api = `${QNP}/material-quote/cost-variance`;
const historyApi = `${QNP}/material-quote/cost-variance/history`;
const quantityApi = `${QNP}/material-variances`;
const varianceResolutionApi = `${QNP}/variance-resolution`;
const materialNegotiatedCostsApi = `${QNP}/material-negotiated-costs`;
const quoteVariancesService = {
  getAllQuoteVariances: params => {
    return service.get(`${api}`, params);
  },
  getAllHistoryVariances: params => {
    return service.get(`${historyApi}`, params);
  },
  getSharedProcuredMaterials: (materialId, plantId) => {
    return service.get(
      `${QNP}/programs/shared-procured-materials/material/${materialId}/plant/${plantId}`
    );
  },
  getAllQuantityVariances: params => {
    return service.get(`${quantityApi}`, params);
  },
  getAllQuantityVariancesHistory: ({ id }) => {
    return service.get(`${QNP}/material-variances/${id}/history`, {});
  },
  getQuoteVariance: id => {
    return service.get(`${api}/${id}`);
  },
  createQuoteVariance: data => {
    return service.post(`${api}`, data);
  },
  updateQuoteVariance: (id, data) => {
    return service.put(`${api}/${id}`, data);
  },
  deleteQuoteVariance: id => {
    return service.remove(`${api}/${id}`);
  },
  updateVarianceCost: data => {
    return service.post(`${varianceResolutionApi}/quote`, data);
  },
  acceptVarianceCost: data => {
    return service.post(`${varianceResolutionApi}/quote/approve`, data);
  },
  acceptDecreaseVarianceType: data => {
    return service.post(
      `${varianceResolutionApi}/quote/cost-decreased/approve`,
      data
    );
  },
  rejectVarianceCost: data => {
    return service.post(`${varianceResolutionApi}/quote/reject`, data);
  },
  updateVarianceQuantity: data => {
    return service.post(`${varianceResolutionApi}/material`, data);
  },
  acceptVarianceQuantity: data => {
    return service.post(`${varianceResolutionApi}/material/approve`, data);
  },
  rejectVarianceQuantity: data => {
    return service.post(`${varianceResolutionApi}/material/reject`, data);
  },
  getMaterialNegotiatedCostCategory: () => {
    return service.get(`${materialNegotiatedCostsApi}/costCategory`);
  },
  createQuantityVariance: data => {
    return service.post(`${CALCULATIONS}/material-variance`, data);
  },
  createCostVariance: data => {
    return service.post(`${CALCULATIONS}/quote-variance`, data);
  },
  getAllPrimaryAlternate: params => {
    params.type = 'Alternate Material';
    return service.get(`${quantityApi}`, params);
  },
};
export default quoteVariancesService;
