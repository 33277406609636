import {
  shape,
  bool,
  string,
  number,
  oneOf,
  node,
  oneOfType,
  func,
  element,
  arrayOf,
  any,
  object,
} from 'prop-types';

export const CSSUnit = oneOf(['px', '%']);
export const Column = shape({
  key: string,
  type: string,
  size: oneOfType([number, string]),
  editable: bool,
  pinnable: bool,
  scrollable: bool,
  render: oneOfType([func, node, element]),
});

export const Pagination = shape({
  pageNumber: number,
  pageSize: number,
  quantity: number,
  totalCount: number,
  hasNext: bool,
  allItems: bool,
});

export const Payload = shape({
  orderBy: arrayOf(object),
  pagination: Pagination,
  filter: arrayOf(object),
});

export const VarianceHistory = shape({
  data: arrayOf(object),
  fetchHistory: func,
  pagination: Pagination,
});

export const SharedMaterials = shape({
  data: arrayOf(object),
  fetchHistory: func,
  pagination: Pagination,
});

export const Sort = shape({
  payload: arrayOf(object),
  sort: func,
});

export const Filter = shape({
  current: object,
  set: func,
  apply: func,
  reset: func,
  options: arrayOf(
    shape({
      key: string,
      label: string,
      options: arrayOf(
        shape({
          key: string,
          value: any,
        })
      ),
    })
  ),
});

export const CommoditiesPayload = shape({
  commodities: shape({
    response: shape({
      data: arrayOf({
        id: string,
        commodity: string,
        longName: string,
        commodityGroup: string,
        email: string,
        location: string,
      }),
      pagination: Pagination,
      orderBy: arrayOf(object),
    }),
    error: bool,
    loading: bool,
  }),
});

export const ProgramsPayload = shape({
  list: shape({
    response: shape({
      data: arrayOf(
        shape({
          name: string,
          codeName: string,
          officialName: string,
          programType: string,
          businessUnit: shape({ name: string }),
          owner: shape({ name: string }),
          backup: shape({ name: string }),
          eandoOwner: shape({ name: string }),
          eandoBackup: shape({ name: string }),
        })
      ),
      pagination: Pagination,
      orderBy: arrayOf(object),
    }),
    error: oneOfType([bool, object]),
    loading: bool,
  }),
});

export const PlantsPayload = shape({
  list: shape({
    response: shape({
      data: arrayOf(
        shape({
          partner: shape({ name: string }),
          region: shape({ name: string }),
          currency: shape({ code: string }),
          code: string,
        })
      ),
      pagination: Pagination,
      orderBy: arrayOf(object),
    }),
    error: bool,
    loading: bool,
  }),
});

export const ExchangePayload = shape({
  list: shape({
    response: shape({
      data: arrayOf(
        shape({
          code: string,
          usdConversion: string,
          effectiveDate: string,
          baseCurrency: string,
        })
      ),
      pagination: Pagination,
      orderBy: arrayOf(object),
    }),
    error: bool,
    loading: bool,
    success: bool,
  }),
});

export const PccPayload = shape({
  list: shape({
    pcc: shape({
      data: arrayOf(
        shape({
          amount: string,
          comment: string,
          // costField: oneOfType([
          //   string,
          //   shape({
          //     description: string,
          //     name: string,
          //   }),
          // ]),
          costField: string,
          createdByUser: string,
          effectiveFrom: string,
          effectiveTo: string,
          isActive: bool,
          createdBy: shape({
            email: string,
            fullName: string,
            name: string,
          }),
          material: shape({
            additionalInfo: string,
            commodityCode: string,
            description: string,
            type: string,
          }),
          materialNumber: string,
          modifiedEffectiveFrom: string,
          modifiedEffectiveTo: string,
          mpn: string,
          plant: shape({
            code: string,
            description: string,
          }),
          plantName: string,
          spn: string,
        })
      ),
      pagination: Pagination,
      orderBy: arrayOf(object),
    }),
    error: bool,
    loading: bool,
    success: bool,
  }),
});

export const EditPccPayload = shape({
  amount: string,
  comment: string,
  costField: string,
  createdByUser: string,
  effectiveFrom: string,
  effectiveTo: string,
  isActive: bool,
  createdBy: shape({
    email: string,
    fullName: string,
    name: string,
  }),
  material: shape({
    additionalInfo: string,
    commodityCode: string,
    description: string,
    type: string,
  }),
  materialNumber: string,
  modifiedEffectiveFrom: string,
  modifiedEffectiveTo: string,
  mpn: string,
  plant: shape({
    code: string,
    description: string,
  }),
  plantName: string,
  spn: string,
});

export const FBPayload = shape({
  data: arrayOf(
    shape({
      alternateFbRate: string,
      alternateRate: string,
      ap: string,
      ar: string,
      dofi: string,
      effectiveFrom: string,
      fbRate: string,
      formulaDoi: string,
      liborRate: string,
      plants: shape({
        code: string,
        description: string,
      }),
      rate: string,
    })
  ),
  error: bool,
  loading: bool,
  success: bool,
});

export const GenericOptionsPayload = shape({
  error: oneOfType([bool, object]),
  loading: bool,
  options: shape({
    error: oneOfType([bool, object]),
    loading: bool,
    businessUnits: arrayOf(object),
    commodities: arrayOf(object),
    costFields: arrayOf(object),
    costGroups: arrayOf(object),
    currencies: arrayOf(object),
    materialTypes: arrayOf(object),
    pmMaterialTypes: arrayOf(object),
    costSourceTypes: arrayOf(object),
    operationTypes: arrayOf(object),
    costVarianceTypes: arrayOf(object),
    bomOwnerRoleTypes: arrayOf(object),
    quantityVarianceTypes: arrayOf(object),
    partners: arrayOf(object),
    plants: arrayOf(object),
    programTypes: arrayOf(object),
    programs: arrayOf(object),
    quoteTypes: arrayOf(object),
    regions: arrayOf(object),
    users: arrayOf(object),
    plantPrefMaterialTypes: arrayOf(string),
  }),
});

export const GenericDashboardPayload = shape({
  error: oneOfType([bool, object]),
  loading: bool,
  dashboard: shape({
    error: oneOfType([bool, object]),
    loading: bool,
    exchangeRate: arrayOf(object),
    finanicalBurden: arrayOf(object),
    reportsData: arrayOf(object),
    quotesData: arrayOf(object),
  }),
});

export const EditExchangePayload = shape({
  baseCurrency: string,
  code: string,
  effectiveDate: string,
  usdConversion: string,
});

export const EditCostSequencePayload = shape({
  id: number,
  name: string,
  sequence: string,
  quoteType: string,
  operation: string,
  costType: string,
  calculationRule: string,
  uiBehavior: string,
});

export const EditRebatePayload = shape({
  materialIds: [],
  plantId: string,
  amount: string,
  programId: string,
  startDate: string,
  endDate: string,
  rebateType: string,
  description: string,
});

export const GenericPagination = shape({
  allItems: bool,
  changeSize: func,
  first: func,
  hasNext: bool,
  last: func,
  next: func,
  pageNumber: number,
  pageSize: number,
  payload: shape({ pageNumber: number, pageSize: number, allItems: bool }),
  previous: func,
  quantity: number,
  totalCount: number,
});

export const EditFBPayload = shape({
  alternateFbRate: string,
  alternateRate: string,
  ap: string,
  ar: string,
  dofi: string,
  effectiveFrom: string,
  fbRate: string,
  formulaDoi: string,
  liborRate: string,
  plants: shape({
    code: string,
    description: string,
  }),
  rate: string,
});

export const Name = shape({
  id: string,
  name: string,
});

export const Description = shape({
  id: string,
  name: string,
  description: string,
});

export const CurrencyCode = shape({
  id: string,
  name: string,
  description: string,
  code: string,
});

export const Commodity = shape({
  commodity: string,
  commodityGroup: string,
  email: string,
  id: string,
  location: string,
  longName: string,
});

export const Program = shape({
  active: bool,
  annualVolume: string,
  backup: oneOfType([string, object]),
  businessProcessId: string,
  businessSegment: string,
  businessUnit: Description,
  codeName: string,
  costGroupId: string,
  eandoBackup: oneOfType([string, object]),
  eandoOwner: oneOfType([string, object]),
  eolDate: string,
  generation: string,
  id: string,
  launchDate: string,
  name: string,
  officialName: string,
  owner: oneOfType([string, object]),
  partner: Name,
  processScheduleId: string,
  programType: string,
  status: string,
});

export const User = shape({
  id: string,
  name: string,
  email: string,
  fullName: string,
  roles: arrayOf(string),
  isNPIEnvironment: bool, // ---- Added property for NPI environment flag ----
});

export const Plant = shape({
  code: string,
  currency: any,
  description: string,
  id: string,
  partner: any,
  region: any,
});

export const Options = shape({
  error: bool,
  loading: bool,
  businessUnits: arrayOf(Name),
  commodities: arrayOf(Commodity),
  costFields: arrayOf(Description),
  costGroups: arrayOf(object),
  currencies: arrayOf(CurrencyCode),
  materialTypes: arrayOf(Name),
  pmMaterialTypes: arrayOf(Name),
  costSourceTypes: arrayOf(object),
  operationTypes: arrayOf(Name),
  partners: arrayOf(Name),
  plants: arrayOf(Plant),
  programTypes: arrayOf(Name),
  programs: arrayOf(Program),
  quoteTypes: arrayOf(Name),
  regions: arrayOf(Name),
  users: arrayOf(User),
});

export const ResponseData = shape({
  loading: bool,
  error: oneOfType([bool, object]),
  response: shape({
    data: arrayOf(object),
    pagination: object,
  }),
});

export const PlantPrefPayload = shape({
  list: shape({
    plantPref: shape({
      data: arrayOf(object),
      pagination: Pagination,
      orderBy: arrayOf(object),
    }),
    error: oneOfType([bool, object]),
    loading: bool,
    success: bool,
  }),
});

export const EditPlantPrefPayload = shape({
  list: shape({
    plantPref: shape({
      data: arrayOf(shape()),
      pagination: Pagination,
      orderBy: arrayOf(object),
    }),
    error: oneOfType([bool, object]),
    loading: bool,
    success: bool,
  }),
});

export const CostGroupSequencePayload = shape({
  list: shape({
    response: shape({
      data: arrayOf(
        shape({
          partner: shape({ name: string }),
          region: shape({ name: string }),
          currency: shape({ code: string }),
          code: string,
        })
      ),
      pagination: Pagination,
      orderBy: arrayOf(object),
    }),
    error: bool,
    loading: bool,
  }),
});
export const VendorMapPayload = shape({
  list: shape({
    response: shape({
      data: arrayOf(
        shape({
          plant: shape({ name: string }),
          distributedPlant: shape({ name: string }),
          outputType: string,
          code: string,
        })
      ),
      pagination: Pagination,
      orderBy: arrayOf(object),
    }),
    error: bool,
    loading: bool,
  }),
});
