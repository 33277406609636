import React, { PureComponent, createRef } from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import { string } from 'prop-types';

class Base extends PureComponent {
  constructor(props) {
    super(props);

    this.currentRef = createRef();
    this.state = {
      currentOffsetWidth: undefined,
      currentScrollWidth: undefined,
    };
  }

  componentDidMount() {
    if (this.currentRef.current) {
      this.currentRef.current.addEventListener(
        'mouseenter',
        this.handleMouseEnter
      );
    }
  }

  componentWillUnmount() {
    if (this.currentRef.current) {
      this.currentRef.current.removeEventListener(
        'mouseenter',
        this.handleMouseEnter
      );
    }
  }

  handleMouseEnter = () => {
    this.setState({
      currentOffsetWidth: this.currentRef.current.offsetWidth,
      currentScrollWidth: this.currentRef.current.scrollWidth,
    });
  };

  render() {
    const { currentOffsetWidth, currentScrollWidth } = this.state;
    const { text } = this.props;
    const showTooltip = currentOffsetWidth < currentScrollWidth;

    return showTooltip ? (
      <Tooltip title={text}>
        <Typography ref={this.currentRef} noWrap>
          {text}
        </Typography>
      </Tooltip>
    ) : (
      <Typography ref={this.currentRef} noWrap>
        {text}
      </Typography>
    );
  }
}

Base.propTypes = {
  text: string,
};

export default Base;
