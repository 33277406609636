import { GET_AUDIT_LIST_REQUEST, GET_AUDIT_LIST_SUCCESS } from './actions';
import { REDUCERS } from '../../common/constants';
import { createReducer } from '../../common/helpers';

const schema = {
  loading: false,
  response: { data: [], pagination: {} },
};

const reducer = {
  [GET_AUDIT_LIST_REQUEST]: state => ({
    ...state,
    response: {
      ...state.response,
      ...REDUCERS.LOADING,
    },
  }),
  [GET_AUDIT_LIST_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      response: { ...payload, ...REDUCERS.SUCCESS },
    };
  },
};

export default createReducer(schema, reducer);
