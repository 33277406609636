import {
  FETCH_DASHBOARD_REQUEST,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_ERROR,
  SUMMARY_DATA_FECTH_REQUEST,
  SUMMARY_DATA_FECTH_SUCCESS,
  SUMMARY_DATA_FECTH_ERROR,
} from './actions';
import { REDUCERS } from '../../common/constants';
import { createReducer } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  dashboard: {
    loading: false,
    error: false,
    exchangeRate: [],
    finanicalBurden: [],
    reportsData: [],
    quotesData: [],
    ingestData: [],
    cssData: {},
    rebateData: {},
  },
  summary: {
    loading: false,
    error: false,
    summaryMonthlyData: {
      response: {},
    },
  },
};

const reducer = {
  // Program Options
  [FETCH_DASHBOARD_REQUEST]: state => {
    return {
      ...state,
      dashboard: {
        ...state.dashboard,
        ...REDUCERS.LOADING,
      },
    };
  },
  [FETCH_DASHBOARD_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      dashboard: {
        ...REDUCERS.SUCCESS,
        ...payload,
      },
    };
  },
  [FETCH_DASHBOARD_ERROR]: (state, { payload: error }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      ...REDUCERS.ERROR,
      error,
    },
  }),

  // Summary Options
  [SUMMARY_DATA_FECTH_REQUEST]: state => {
    return {
      ...state,
      summary: {
        ...state.summary,
        ...REDUCERS.LOADING,
      },
    };
  },
  [SUMMARY_DATA_FECTH_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      summary: {
        ...REDUCERS.SUCCESS,
        summaryMonthlyData: {
          response: payload.response,
        },
      },
    };
  },
  [SUMMARY_DATA_FECTH_ERROR]: (state, { payload: error }) => ({
    ...state,
    summary: {
      ...state.summary,
      ...REDUCERS.ERROR,
      error,
    },
  }),
};

export default createReducer(schema, reducer);
