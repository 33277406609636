import React from 'react';
import { NavLink } from 'react-router-dom';
import { string, node } from 'prop-types';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  makeStyles,
} from '@material-ui/core';
import { themeColor } from '../../../common/constants';

const styles = () => ({
  navLink: {
    color: '#F8F8F8',
    '&.active div': {
      background: themeColor,
      transition: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
      color: '#F8F8F8',
    },
    '&:focus div': {
      textDecoration: 'none',
      color: '#F8F8F8',
      background: themeColor,
      transition: 'none',
    },
    '&:hover div': {
      textDecoration: 'none',
      color: '#F8F8F8',
      background: themeColor,
      transition: 'none',
    },
  },

  sidebarText: {
    '& span': {
      fontSize: '12px',
    },
  },

  smallSidebarText: {
    '& span': {
      fontSize: '11px',
    },
  },
});

const useStyles = makeStyles(styles);

const NavItem = ({ icon: Icon, label, path, size = 'medium' }) => {
  const classes = useStyles();

  return (
    <Box
      component={NavLink}
      to={path}
      className={classes.navLink}
      activeClassName="active"
      key={label}
    >
      <ListItem button>
        <Box
          display="flex"
          flexDirection="column"
          textAlign="center"
          mx="auto"
          py={1}
        >
          <Box component={ListItemIcon} justifyContent="center">
            {Icon}
          </Box>
          <ListItemText
            // disableTypography={true}
            className={
              size === 'small' ? classes.smallSidebarText : classes.sidebarText
            }
            primary={label}
          />
        </Box>
      </ListItem>
    </Box>
  );
};

NavItem.propTypes = {
  icon: node,
  label: string,
  path: string,
  size: string,
};

export default NavItem;
