/* eslint-disable no-unused-vars */
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, merge } from 'rxjs/operators';
import { dispatch } from '../../common/helpers';
import { conditionsService } from '../../services';
import { MESSAGES, DEFAULT_PAGINATION_OBJECT } from '../../common/constants';

import {
  FETCH_CONDITIONS_LIST_REQUEST,
  FETCH_CONDITIONS_LIST_SUCCESS,
  FETCH_CONDITIONS_LIST_ERROR,
  CREATE_CONDITION_REQUEST,
  CREATE_CONDITION_SUCCESS,
  CREATE_CONDITION_ERROR,
  DELETE_CONDITION_REQUEST,
  DELETE_CONDITION_SUCCESS,
  DELETE_CONDITION_ERROR,
  UPDATE_CONDITION_REQUEST,
  UPDATE_CONDITION_ERROR,
} from './actions';

import {
  SUCCESS_NOTIFICATION_REQUEST,
  ERROR_NOTIFICATION_REQUEST,
} from '../notifications/actions';

export const fetchConditionsList = (action$, store) => {
  return action$.pipe(
    ofType(FETCH_CONDITIONS_LIST_REQUEST),
    mergeMap(({ payload }) =>
      conditionsService.getAll(payload).pipe(
        map(data =>
          dispatch(FETCH_CONDITIONS_LIST_SUCCESS, {
            ...data,
            payload,
          })
        )
      )
    ),
    catchError(err => {
      return [dispatch(FETCH_CONDITIONS_LIST_ERROR, err)];
    })
  );
};

export const addCondition = action$ =>
  action$.pipe(
    ofType(CREATE_CONDITION_REQUEST),
    mergeMap(({ payload: { values, payload } }) =>
      conditionsService.createCondition(values).pipe(
        mergeMap(() => [
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.FORM_SUBMITTED_SUCCESSFULLY,
          }),
          dispatch(FETCH_CONDITIONS_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
            filter: [
              {
                field: 'constConditionCostConditionType',
                operator: 'eq',
                value: 'QUOTE',
              },
            ],
          }),
        ]),
        catchError(error => [
          dispatch(CREATE_CONDITION_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );

export const updateCondition = action$ =>
  action$.pipe(
    ofType(UPDATE_CONDITION_REQUEST),
    mergeMap(({ payload: { values, payload } }) =>
      conditionsService.updateCondition(values.id, values).pipe(
        mergeMap(() => {
          const submitType =
            values.type === 'FORM'
              ? MESSAGES.FORM_UPDATED_SUCCESSFULLY
              : MESSAGES.ROW_UPDATED_SUCCESSFULLY;
          return [
            dispatch(FETCH_CONDITIONS_LIST_REQUEST, {
              ...payload,
              pagination: DEFAULT_PAGINATION_OBJECT,
              filter: [
                {
                  field: 'constConditionCostConditionType',
                  operator: 'eq',
                  value: 'QUOTE',
                },
              ],
            }),
            dispatch(SUCCESS_NOTIFICATION_REQUEST, { message: submitType }),
          ];
        }),
        catchError(error => [
          dispatch(CREATE_CONDITION_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );

export const deleteCondition = action$ =>
  action$.pipe(
    ofType(DELETE_CONDITION_REQUEST),
    mergeMap(({ payload: { id, payload } }) =>
      conditionsService.deleteCondition(id).pipe(
        mergeMap(() => [
          dispatch(FETCH_CONDITIONS_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
            filter: [
              {
                field: 'constConditionCostConditionType',
                operator: 'eq',
                value: 'QUOTE',
              },
            ],
          }),
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.DATA_DELETED_SUCCESSFULLY,
          }),
        ]),
        catchError(error => [
          dispatch(CREATE_CONDITION_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );
