import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/currencies`;

const currenciesService = {
  getAllCurrencies: (params = { limit: 10, offset: 0 }) => {
    return service.get(`${api}?limit=${params.limit}&offset=${params.offset}`);
  },
  getCurrency: id => {
    return service.get(`${api}/${id}`);
  },
  getCurrencyByCode: code => {
    return service.get(`${api}/currency/${code}`);
  },
  createCurrency: data => {
    return service.post(`${api}`, data);
  },
  updateCurrency: (id, data) => {
    return service.put(`${api}/${id}`, data);
  },
  deleteCurrency: id => {
    return service.remove(`${api}/${id}`);
  },
};
export default currenciesService;
