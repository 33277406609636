import React, { useCallback } from 'react';
import {
  Box,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { FormClose } from 'grommet-icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { func, object, arrayOf, bool, string } from 'prop-types';
import Button from '../Button';
import DataTable from '../DataTable';
import DisplayLabel from './Components/DisplayLabel';
import { useCallbackDispatch } from '../../common/hooks';
import {
  VarianceHistory,
  Pagination,
  Payload,
  User,
  SharedMaterials,
} from '../../common/prop-types';
import Comments from '../Comments';
import {
  ACCEPT_REQUEST_ACTION_VARIANCE_QUANTITY,
  REJECT_REQUEST_ACTION_VARIANCE_QUANTITY,
} from '../../redux/variances/actions';
import { USER_ROLES } from '../../common/constants';
import Loader from '../Loader';

import { showMaterialVarianceType } from '../../common/helpers';

const entityType = 'MaterialVariance';

const PrimaryAlternateModal = ({
  closeDrawer,
  selectedVariance,
  plants,
  historyProps: { historyData, fetchHistory, loading: historyLoading },
  sharedMaterialsProps: {
    sharedMaterialsData,
    fetchSharedProcuredMaterials,
    sharedMaterialsLoading,
  },
  userData,
  payload,
  loading,
  type,
}) => {
  const { targetRole } = selectedVariance || {};
  const { id, roles } = userData;
  const { pagination, filter, orderBy } = payload;
  pagination.pageNumber = 1;
  pagination.infinite = false;
  const acceptVarianceQuantity = useCallbackDispatch(
    ACCEPT_REQUEST_ACTION_VARIANCE_QUANTITY
  );
  const rejectVarianceQuantity = useCallbackDispatch(
    REJECT_REQUEST_ACTION_VARIANCE_QUANTITY
  );

  const onUpdateHistory = useCallback(() => {
    return fetchHistory({
      params: {
        pagination: { pageNumber: 1, pageSize: 6, allItems: false },
      },
      id: selectedVariance.id,
    });
  }, [selectedVariance.id, fetchHistory]);

  const component = selectedVariance.newBom.component.materialIdentifiers.find(
    item => {
      return item.ownedBy === 'hpe';
    }
  );

  const supplier = selectedVariance.newBom.component.materialIdentifiers.find(
    item => {
      return item.ownedBy === 'supplier';
    }
  );

  const programName = selectedVariance?.programMaterial?.program?.name;

  const materialCategory = selectedVariance.newBom?.component?.type;
  const { description } = selectedVariance?.parentMaterial;

  const onUpdateSharedMaterials = useCallback(() => {
    return fetchSharedProcuredMaterials({
      materialId: selectedVariance?.component?.id,
      plantId: selectedVariance?.newBom?.plantId,
    });
  }, [selectedVariance, fetchSharedProcuredMaterials]);

  const plant = plants.find(x => x.id === selectedVariance.newBom.plantId)
    ?.code;

  return (
    <Box width={800} role="presentation">
      <Box display="flex" justifyContent="flex-end" m={2}>
        <IconButton onClick={closeDrawer}>
          <FormClose />
        </IconButton>
      </Box>
      <Box ml={2} mr={2} mb={1} display="flex" flexDirection="row">
        {loading && (
          <Loader
            hideIcon
            style={{
              zIndex: '1000',
              position: 'fixed',
              right: '350px',
              top: '30%',
            }}
          />
        )}
        <Box width="50%">
          <DisplayLabel
            label="Status:"
            first
            value={selectedVariance.approved ? 'Approved' : 'Pending Approval'}
            color={selectedVariance.approved ? 'primary.main' : 'error.main'}
          />
          <DisplayLabel
            label="Component P/N:"
            value={component?.identifier || '--'}
          />
          <DisplayLabel
            label="Supplier P/N:"
            value={supplier?.identifier || '--'}
          />
          <DisplayLabel label="Program Name:" value={programName} />
          <DisplayLabel
            label="Owner Name:"
            value={selectedVariance?.programMaterial?.bomOwner || '--'}
          />
        </Box>
        <Box width="50%">
          <DisplayLabel
            first
            label="Material Category:"
            value={materialCategory || '--'}
          />
          <DisplayLabel label="Plant:" value={plant} />
          <DisplayLabel
            label="Variance Type:"
            value={showMaterialVarianceType(selectedVariance?.varianceType)}
          />
          <DisplayLabel label="Description:" value={description} />
        </Box>
      </Box>
      <Box ml={2} mr={2} mb={1} display="flex" flexDirection="row">
        <Box ml={2} mr={2} width="50%">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="historyPanelbh-content"
              id="historyPanelbh-header"
            >
              Quantity History
            </AccordionSummary>
            <AccordionDetails>
              <DataTable
                style={{ maxHeight: '240px' }}
                onUpdate={onUpdateHistory}
                columns={[
                  { key: 'effectiveFrom', type: 'date', size: 155 },
                  {
                    key: 'quantity',
                    size: 155,
                  },
                ]}
                data={historyData?.slice(0, 6)}
                loading={historyLoading}
                base
              />
            </AccordionDetails>
          </Accordion>
        </Box>
        {(userData.roles.includes(USER_ROLES.SPM) ||
          userData.roles.includes(USER_ROLES.SUPPLIER)) && (
          <Box ml={2} mr={2} width="50%">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="sharedMaterialsbh-content"
                id="sharedMaterialsbh-header"
              >
                Shared Materials
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <DataTable
                    style={{ maxHeight: '240px' }}
                    onUpdate={onUpdateSharedMaterials}
                    columns={[
                      { key: 'name', label: 'Program Name', size: 145 },
                      {
                        key: 'owner.name',
                        label: 'SPM',
                        size: 145,
                      },
                    ]}
                    data={sharedMaterialsData}
                    loading={sharedMaterialsLoading}
                    base
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Box>
      <div
        role="tabpanel"
        hidden={!roles.includes(USER_ROLES.SPM)}
        id="scrollable-force-tabpanel-1"
        aria-labelledby="scrollable-force-tab-1"
      >
        <Box mr={4} ml={4} mb={1} display="flex" justifyContent="flex-end">
          <Box mr={1}>
            <Button
              disabled={
                loading ||
                ((selectedVariance.approved ||
                  USER_ROLES.SUPPLIER !== targetRole) &&
                  USER_ROLES.SPM !== targetRole)
              }
              btnType="primary"
              type="submit"
              onClick={() => {
                acceptVarianceQuantity({
                  materialVarianceId: selectedVariance.id,
                  roles,
                  userId: id,
                  action: {
                    pagination,
                    filter,
                    orderBy,
                  },
                  type,
                });
                closeDrawer();
              }}
            >
              Approve
            </Button>
          </Box>
          <Box>
            <Button
              disabled={
                loading ||
                ((!selectedVariance.approved ||
                  USER_ROLES.SUPPLIER !== targetRole) &&
                  USER_ROLES.SPM !== targetRole)
              }
              btnType="primary"
              type="submit"
              onClick={() => {
                rejectVarianceQuantity({
                  materialVarianceId: selectedVariance.id,
                  roles,
                  userId: id,
                  action: {
                    pagination,
                    filter,
                    orderBy,
                  },
                  type,
                });
                closeDrawer();
              }}
            >
              Reject
            </Button>
          </Box>
        </Box>
      </div>
      <Comments
        entityId={selectedVariance.id}
        entityType={entityType}
        userId={id}
        userRoles={roles}
      />
    </Box>
  );
};
PrimaryAlternateModal.propTypes = {
  closeDrawer: func,
  historyProps: VarianceHistory,
  sharedMaterialsProps: SharedMaterials,
  plants: arrayOf(object),
  // eslint-disable-next-line react/forbid-prop-types
  selectedVariance: object,
  pagination: Pagination,
  userData: User,
  payload: Payload,
  loading: bool,
  type: string,
};

export default PrimaryAlternateModal;
