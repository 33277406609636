import { ofType } from 'redux-observable';
import { merge } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { MESSAGES, DEFAULT_PAGINATION_OBJECT } from '../../common/constants';
import { commodityService } from '../../services';
import {
  FETCH_COMMODITY_LIST_REQUEST,
  FETCH_COMMODITY_LIST_SUCCESS,
  FETCH_COMMODITY_LIST_ERROR,
  UPDATE_COMMODITY_REQUEST,
  UPDATE_COMMODITY_ERROR,
  DELETE_COMMODITY_REQUEST,
  DELETE_COMMODITY_ERROR,
  CREATE_COMMODITY_REQUEST,
  CREATE_COMMODITY_ERROR,
} from './actions';
import {
  SUCCESS_NOTIFICATION_REQUEST,
  ERROR_NOTIFICATION_REQUEST,
} from '../notifications/actions';

import { dispatch } from '../../common/helpers';

// Commodity
export const fetchCommodityList = action$ =>
  action$.pipe(
    ofType(FETCH_COMMODITY_LIST_REQUEST),
    mergeMap(({ payload }) =>
      commodityService.getAllCommodities(payload).pipe(
        map(data =>
          dispatch(FETCH_COMMODITY_LIST_SUCCESS, {
            ...data,
            payload,
          })
        )
      )
    ),
    catchError(err => [dispatch(FETCH_COMMODITY_LIST_ERROR, err)])
  );

export const updateCommodity = action$ =>
  action$.pipe(
    ofType(UPDATE_COMMODITY_REQUEST),
    mergeMap(({ payload: { id, data: { values, payload } } }) =>
      commodityService.updateCommodity(id, values).pipe(
        mergeMap(() => [
          dispatch(FETCH_COMMODITY_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.FORM_UPDATED_SUCCESSFULLY,
          }),
        ]),
        catchError(err => [
          dispatch(UPDATE_COMMODITY_ERROR, err),
          dispatch(ERROR_NOTIFICATION_REQUEST, err),
        ])
      )
    )
  );

export const removeCommodity = action$ =>
  action$.pipe(
    ofType(DELETE_COMMODITY_REQUEST),
    mergeMap(({ payload: { id, payload } }) =>
      commodityService.deleteCommodity(id).pipe(
        mergeMap(() => {
          return merge(
            [
              dispatch(FETCH_COMMODITY_LIST_REQUEST, {
                ...payload,
                pagination: DEFAULT_PAGINATION_OBJECT,
              }),
            ],
            [
              dispatch(SUCCESS_NOTIFICATION_REQUEST, {
                message: MESSAGES.DATA_DELETED_SUCCESSFULLY,
              }),
            ]
          );
        }),
        catchError(err => [
          dispatch(DELETE_COMMODITY_ERROR, err),
          dispatch(ERROR_NOTIFICATION_REQUEST, err),
        ])
      )
    )
  );

export const createCommodity = action$ =>
  action$.pipe(
    ofType(CREATE_COMMODITY_REQUEST),
    mergeMap(({ payload: { values, payload } }) =>
      commodityService.createCommodity(values).pipe(
        mergeMap(() => [
          dispatch(FETCH_COMMODITY_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.FORM_SUBMITTED_SUCCESSFULLY,
          }),
        ]),
        catchError(error => [
          dispatch(CREATE_COMMODITY_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );
