import {
  FETCH_DATA_FILES_REQUEST,
  FETCH_DATA_FILES_SUCCESS,
  FETCH_DATA_FILES_ERROR,
  UPLOAD_DATA_FILES_SUCCESS,
  UPLOAD_DATA_FILES_ERROR,
} from './actions';
import { REDUCERS, DATE_FORMAT_STRINGS } from '../../common/constants';
import { createReducer, getFormattedDateForForm } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  files: {
    loading: false,
    error: false,
    response: { data: [], pagination: {} },
  },
};

const reducer = {
  // Data files
  [FETCH_DATA_FILES_REQUEST]: state => ({
    ...state,
    files: {
      ...state.files,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_DATA_FILES_SUCCESS]: (
    state,
    { payload: { data = {}, pagination, payload } }
  ) => {
    const files = data
      ? data.map(val => {
          val.createdAt = getFormattedDateForForm(
            val.createdAt,
            DATE_FORMAT_STRINGS.TABLE_DATE_TIME_FORMAT
          );
          return val;
        })
      : [];
    return {
      ...state,
      files: {
        ...REDUCERS.SUCCESS,
        response: {
          pagination: pagination || state.files.response.pagination,
          data: payload?.pagination?.infinite
            ? [...state.files.response.data, ...files]
            : files,
          payload,
        },
      },
    };
  },
  [FETCH_DATA_FILES_ERROR]: (state, { payload: error }) => ({
    ...state,
    files: {
      ...state.files,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [UPLOAD_DATA_FILES_SUCCESS]: (state, { payload: id }) => {
    return {
      ...state,
      files: {
        ...REDUCERS.SUCCESS,
        response: {
          ...state.files.response,
          data: state.files.response.data.map(file => {
            if (file.id === id) {
              return { ...file, status: 'Uploaded' };
            }
            return file;
          }),
        },
      },
    };
  },
  [UPLOAD_DATA_FILES_ERROR]: (state, { payload: id }) => {
    return {
      ...state,
      files: {
        ...REDUCERS.SUCCESS,
        response: {
          ...state.files.response,
          data: state.files.response.data.map(file => {
            if (file.id === id) {
              return { ...file, status: 'ERROR' };
            }
            return file;
          }),
        },
      },
    };
  },
};

export default createReducer(schema, reducer);
