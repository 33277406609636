import {
  CREATE_VENDOR_MAP_REQUEST,
  CREATE_VENDOR_MAP_SUCCESS,
  CREATE_VENDOR_MAP_ERROR,
  FETCH_VENDOR_MAP_LIST_SUCCESS,
  FETCH_VENDOR_MAP_LIST_ERROR,
  FETCH_VENDOR_MAP_LIST_REQUEST,
  DELETE_VENDOR_MAP_REQUEST,
  DELETE_VENDOR_MAP_SUCCESS,
  DELETE_VENDOR_MAP_ERROR,
  UPDATE_VENDOR_MAP_REQUEST,
  UPDATE_VENDOR_MAP_SUCCESS,
  UPDATE_VENDOR_MAP_ERROR,
} from './actions';
import { REDUCERS } from '../../common/constants';
import { createReducer } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  list: {
    loading: false,
    error: false,
    response: { data: [], pagination: {}, payload: {} },
  },
};

const reducer = {
  [CREATE_VENDOR_MAP_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [CREATE_VENDOR_MAP_SUCCESS]: state => {
    return {
      ...state,
      list: {
        ...state.list,
        ...REDUCERS.SUCCESS,
      },
    };
  },
  [CREATE_VENDOR_MAP_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [FETCH_VENDOR_MAP_LIST_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_VENDOR_MAP_LIST_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => {
    const filteredList = data
      ? data.map(val => {
          val.plantId = val.plant ? val.plant.id : '';
          val.distributedPlantId = (val.plant && val.plant.id) || '';
          val.updatedBy = val.updatedBy ? val.updatedBy.name : '';
          return val;
        })
      : [];
    return {
      ...state,
      list: {
        ...REDUCERS.SUCCESS,
        response: {
          data: payload?.pagination?.infinite
            ? [...state.list.response.data, ...filteredList]
            : filteredList,
          pagination,
          payload,
        },
      },
    };
  },
  [FETCH_VENDOR_MAP_LIST_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [UPDATE_VENDOR_MAP_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [UPDATE_VENDOR_MAP_SUCCESS]: state => {
    return {
      ...state,
      list: {
        ...state.list,
        ...REDUCERS.SUCCESS,
      },
    };
  },
  [UPDATE_VENDOR_MAP_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [DELETE_VENDOR_MAP_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [DELETE_VENDOR_MAP_SUCCESS]: state => {
    return {
      ...REDUCERS.SUCCESS,
      ...state,
    };
  },
  [DELETE_VENDOR_MAP_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
};

export default createReducer(schema, reducer);
