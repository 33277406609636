import React from 'react';
import { styled, Box } from '@material-ui/core';

const Base = styled(Box)({
  animationName: 'fade',
  animationDuration: '0.5s',
  animationIterationCount: 1,
  animationFillMode: 'both',
  animationDelay: '.2s',
});

const styles = `
@keyframes fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
`;

const FadeIn = Component => props => (
  /* eslint-disable react/no-children-prop */
  <>
    <style children={styles} />
    <Base>
      <Component {...props} />
    </Base>
  </>
);

export default FadeIn;
