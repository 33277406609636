import { ofType } from 'redux-observable';
import { forkJoin, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { dispatch } from '../../common/helpers';
import {
  exchangeService,
  reportsService,
  programQuotesService,
  dashboardService,
  suppliersService,
} from '../../services';
import { financialBurdenService } from '../../services/financialBurden';

import {
  FETCH_DASHBOARD_REQUEST,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_ERROR,
  SUMMARY_DATA_FECTH_REQUEST,
  SUMMARY_DATA_FECTH_SUCCESS,
  SUMMARY_DATA_FECTH_ERROR,
} from './actions';

export const fetchDashboardData = action$ =>
  action$.pipe(
    ofType(FETCH_DASHBOARD_REQUEST),
    mergeMap(({ payload }) => {
      return forkJoin(
        [
          exchangeService.getAllExchange(payload),
          financialBurdenService.getAllFB(payload),
          reportsService.getAllReports(),
          programQuotesService.getAllProgramQuotes(payload),
          suppliersService.partnerWithRelation(),
          dashboardService.getingestedDates(),
          dashboardService.getCSSData(),
          dashboardService.getSummaryRebatesData(),
        ].map(el => el.pipe(catchError(() => of({ data: [] }))))
      );
    }),
    map(
      ([
        exchangeData,
        financialBurdenData,
        reports,
        quotes,
        suppliers,
        summaryIngest,
        summaryCss,
        summaryRebate,
      ]) => {
        return dispatch(FETCH_DASHBOARD_SUCCESS, {
          exchangeRate: exchangeData?.data,
          finanicalBurden: financialBurdenData?.data,
          reportsData: reports,
          quotesData: quotes?.data,
          suppliersData: suppliers?.data.reduce((acc, val) => {
            if (!acc.find(data => val.partner_name === data.partner_name)) {
              return [...acc, val];
            }
            return acc;
          }, []),
          ingestData: summaryIngest,
          cssData: summaryCss,
          rebateData: summaryRebate,
        });
      }
    ),
    catchError(err => [dispatch(FETCH_DASHBOARD_ERROR, err)])
  );

export const getSummaryData = action$ => {
  return action$.pipe(
    ofType(SUMMARY_DATA_FECTH_REQUEST),
    mergeMap(({ payload }) =>
      dashboardService.getSummaryData(payload).pipe(
        map(data => {
          return dispatch(SUMMARY_DATA_FECTH_SUCCESS, {
            ...data.response,
          });
        })
      )
    ),
    catchError(err => {
      return [dispatch(SUMMARY_DATA_FECTH_ERROR, err)];
    })
  );
};
