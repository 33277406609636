import { ajax } from 'rxjs/ajax';
import { catchError, mergeMap } from 'rxjs/operators';
import { throwError, empty, of } from 'rxjs';
import Cookies from 'js-cookie';
import { BASE_URL, ACCESS_TOKEN, REFRESH_TOKEN, ROLES } from './constants';
import { isURL, objToParams, isIEBrowser } from './helpers';
import { LOGIN } from './routes';
import { check } from './session';

const getHeaders = () => {
  const { id, roles } = check();
  const headers = {
    ckaccesstoken: decodeURIComponent(Cookies.get(ACCESS_TOKEN)),
    ckrefreshtoken: decodeURIComponent(Cookies.get(REFRESH_TOKEN)),
  };

  if (process.env.REACT_APP_DISABLE_UAM) {
    headers['user-info'] = JSON.stringify({ userId: id, roles });
  }
  return headers;
};

const unathorized = err => {
  if (err.status === 401) {
    Cookies.remove(ACCESS_TOKEN);
    Cookies.remove(REFRESH_TOKEN);
    Cookies.remove(ROLES);
    window.location.replace(`${window.location.origin}${LOGIN}`);
    return empty();
  }
  return throwError(err);
};

export const get = (url, params = {}, headers = {}) => {
  return ajax({
    url: isIEBrowser()
      ? `${isURL(url) ? url : `${BASE_URL}${url}`}?${objToParams({
          ...params,
          ts: Date.now(),
        })}`
      : `${isURL(url) ? url : `${BASE_URL}${url}`}${
          Object.keys(params).length > 0 ? `?${objToParams({ ...params })}` : ''
        }`,
    method: 'GET',
    responseType: 'json',
    headers: {
      ...getHeaders(),
      ...headers,
    },
    crossDomain: true,
  }).pipe(
    mergeMap(({ response: data }) => {
      return of(data.response);
    }),
    catchError(unathorized)
  );
};

export const native = {
  get: async (url, params) => {
    const response = await fetch(`${BASE_URL}${url}?${objToParams(params)}`, {
      method: 'GET',
      headers: getHeaders(),
    });
    const data = await response.json();
    return data.response;
  },
  post: async (url, body) => {
    const response = await fetch(`${BASE_URL}${url}`, {
      method: 'POST',
      headers: { ...getHeaders(), 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data.response;
  },
};

export const fetchFile = (url, name) => {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(),
  };
  fetch(url, requestOptions)
    .then(response => response.text())
    .then(response => {
      const test = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = test;
      link.setAttribute('download', `${name}-template.csv`);
      link.click();
    })
    .catch(unathorized);
};

export const remove = (url, headers = {}) => {
  return ajax({
    url: isURL(url) ? url : `${BASE_URL}${url}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...getHeaders(),
      ...headers,
    },
    responseType: 'json',
    crossDomain: true,
  }).pipe(
    mergeMap(({ response: data }) => of(data.response)),
    catchError(unathorized)
  );
};

export const file = (url, body = {}, headers = {}) => {
  return ajax({
    url: isURL(url) ? url : `${BASE_URL}${url}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...getHeaders(),
      ...headers,
    },
    body,
    // responseType: 'arraybuffer',
    crossDomain: true,
  }).pipe(catchError(unathorized));
};

export const fileBlob = (url, body = {}, headers = {}) => {
  return ajax({
    url: isURL(url) ? url : `${BASE_URL}${url}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...getHeaders(),
      ...headers,
    },
    body,
    responseType: 'blob',
    crossDomain: true,
  }).pipe(catchError(unathorized));
};

export const post = (url, body = {}, headers = {}) => {
  return ajax({
    url: isURL(url) ? url : `${BASE_URL}${url}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...getHeaders(),
      ...headers,
    },
    body,
    responseType: 'json',
    crossDomain: true,
  }).pipe(catchError(unathorized));
};

export const put = (url, body = {}, headers = {}) => {
  return ajax({
    url: isURL(url) ? url : `${BASE_URL}${url}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...getHeaders(),
      ...headers,
    },
    body,
    responseType: 'json',
    crossDomain: true,
  }).pipe(catchError(unathorized));
};

export const patch = (url, body = {}, headers = {}) => {
  return ajax({
    url: isURL(url) ? url : `${BASE_URL}${url}`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      ...getHeaders(),
      ...headers,
    },
    body,
    responseType: 'json',
    crossDomain: true,
  }).pipe(catchError(unathorized));
};

export const postFile = (url, body = {}, headers) => {
  const href = isURL(url) ? url : `${BASE_URL}${url}`;
  return ajax({
    url: href,
    method: 'POST',
    body,
    headers: {
      ...getHeaders(),
      ...headers,
    },
    crossDomain: true,
  }).pipe(catchError(unathorized));
};

export const getFile = (url, body = {}, headers) => {
  const href = isURL(url) ? url : `${BASE_URL}${url}`;
  return ajax({
    url: href,
    method: 'GET',
    body,
    headers: {
      ...getHeaders(),
      ...headers,
    },
    responseType: 'arraybuffer',
    crossDomain: true,
  }).pipe(catchError(unathorized));
};
