// Admin Plant List Actions
export const FETCH_VENDOR_MAP_LIST_REQUEST = 'FETCH_VENDOR_MAP_LIST_REQUEST';
export const FETCH_VENDOR_MAP_LIST_SUCCESS = 'FETCH_VENDOR_MAP_LIST_SUCCESS';
export const FETCH_VENDOR_MAP_LIST_ERROR = 'FETCH_VENDOR_MAP_LIST_ERROR';

// Create Plant
export const CREATE_VENDOR_MAP_REQUEST = 'CREATE_VENDOR_MAP_REQUEST';
export const CREATE_VENDOR_MAP_SUCCESS = 'CREATE_VENDOR_MAP_SUCCESS';
export const CREATE_VENDOR_MAP_ERROR = 'CREATE_VENDOR_MAP_ERROR';

// Delete Plant
export const DELETE_VENDOR_MAP_REQUEST = 'DELETE_VENDOR_MAP_REQUEST';
export const DELETE_VENDOR_MAP_SUCCESS = 'DELETE_VENDOR_MAP_SUCCESS';
export const DELETE_VENDOR_MAP_ERROR = 'DELETE_VENDOR_MAP_ERROR';

// Update Plant
export const UPDATE_VENDOR_MAP_REQUEST = 'UPDATE_VENDOR_MAP_REQUEST';
export const UPDATE_VENDOR_MAP_SUCCESS = 'UPDATE_VENDOR_MAP_SUCCESS';
export const UPDATE_VENDOR_MAP_ERROR = 'UPDATE_VENDOR_MAP_ERROR';
