import Cookies from 'js-cookie';
import jwt from 'jwt-decode';
import {
  BASE_URL,
  USER_ROLES,
  QNP,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  ROLES,
} from './constants';
import { AUTH } from './routes';
import { titleCase } from './helpers';

const path = `${BASE_URL}${QNP}${AUTH}`;

const { REACT_APP_DISABLE_UAM } = process.env;
const disableUAM = JSON.parse(REACT_APP_DISABLE_UAM);
// const disableUAM = true;

const defaultUser = {
  commonName: 'S',
  emailAddress: 'arivarasu.v@hpe.com',
  id: '74b2111c-60fb-456e-b821-7e5336795d32',
  roles: [USER_ROLES.SUPER_USER],
  isNPIEnvironment: true,
};

export const open = () => {
  if (!disableUAM) {
    window.location.replace(`${path}?url=${window.location.origin}`);
  }
};

export const check = () => {
  if (disableUAM) {
    const raw = localStorage.getItem('userClaims');
    const userClaims = JSON.parse(raw) || defaultUser;

    // if (!raw) {
    //   window.setUserClaims(userClaims);
    // }

    return userClaims;
  }
  try {
    const cookie = Cookies.get(ACCESS_TOKEN);
    const roles = Cookies.get(ROLES);
    const result = jwt(cookie);
    const commonName =
      result.userClaims.commonName ||
      titleCase(
        result.userClaims.emailAddress
          .split('@')[0]
          .split('.')
          .join(' ')
      );
    const isNPIEnvironmentProperty = {
      isNPIEnvironment: process.env.NPI_ENVIRONMENT
        ? process.env.NPI_ENVIRONMENT === 'true'
        : false,
    };
    return {
      ...result.userClaims,
      commonName,
      roles: JSON.parse(roles),
      ...isNPIEnvironmentProperty,
    };
  } catch (ex) {
    return {};
  }
};

export const set = ({ accessToken, refreshToken, roles }) => {
  if (accessToken) {
    Cookies.set(ACCESS_TOKEN, accessToken);
  }

  if (refreshToken) {
    Cookies.set(REFRESH_TOKEN, refreshToken);
  }

  if (roles) {
    Cookies.set(ROLES, roles);
  }
};

export const roles = () => {};

export const close = () => {
  Cookies.remove(ACCESS_TOKEN);
  Cookies.remove(REFRESH_TOKEN);
  Cookies.remove(ROLES);
};

// Temp for Demo

window.setUserClaims = userClaims => {
  localStorage.setItem('userClaims', JSON.stringify({ ...userClaims }));
  window.location.reload();
};

window.getUserClaims = () => JSON.parse(localStorage.getItem('userClaims'));

// if (!window.getUserClaims()) {
//   window.setUserClaims(defaultUser);
// }
