import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/material-quote`;

const quoteLinesService = {
  getQuoteLines: params => {
    return service.get(`${api}`, params);
  },
  createQuoteLines: data => {
    return service.post(`${api}`, data);
  },
  updateQuoteLines: (id, data) => {
    return service.put(`${api}/${id}`, data);
  },
  deleteQuoteLines: id => {
    return service.remove(`${api}/${id}`);
  },
};
export default quoteLinesService;
