import React from 'react';
import { string, func } from 'prop-types';
import { TextField, Box } from '@material-ui/core';
import Select from '../../../Select';

const textOperatorsOptions = [
  { id: 'all', value: 'All' },
  { id: 'startsWith', value: 'Starts With' },
  { id: 'endsWith', value: 'Ends With' },
  { id: 'substring', value: 'Contains' },
  { id: 'eq(case insensitive)', value: 'Equal' },
];

const TextFilter = ({ onChange, operator, value }) => {
  return (
    <Box>
      Operator
      <Select
        options={textOperatorsOptions}
        valueKey="id"
        valueLabel="value"
        onChange={val => onChange({ operator: val })}
        value={operator}
        variant="outlined"
        fullWidth
      />
      <Box pt={2}>
        <TextField
          variant="outlined"
          value={value}
          onChange={event => onChange({ value: event.target.value })}
          fullWidth
        />
      </Box>
    </Box>
  );
};
TextFilter.propTypes = {
  onChange: func,
  operator: string,
  value: string,
};

export default TextFilter;
