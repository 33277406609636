import {
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_ERROR,
  FETCH_REPORT_DETAIL_REQUEST,
  FETCH_REPORT_DETAIL_SUCCESS,
  FETCH_REPORT_DETAIL_ERROR,
  DOWNLOAD_REPORT_REQUEST,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR,
  FETCH_REPORT_FILTER_REQUEST,
  FETCH_REPORT_FILTER_SUCCESS,
  FETCH_REPORT_FILTER_ERROR,
  RESET_REPORT_DATA_SUCCESS,
  FETCH_REPORT_PROFIT_CENTER_REQUEST,
  FETCH_REPORT_TYPE_REQUEST,
  FILTER_REPORT_API_ERROR,
  FILTER_REPORT_API_SUCCESS,
  FETCH_RUN_DATE_SUCCESS,
  FETCH_RUN_DATE_ERROR,
  FETCH_TOTAL_COUNT_SUCCESS,
  UPDATE_TOTAL_COUNT_SUCCESS,
  NEGOTIATED_COST_DATE_REQUEST,
  NEGOTIATED_COST_DATE_SUCCESS,
  NEGOTIATED_COST_DATE_ERROR,
  FETCH_TOTAL_COUNT_REQUEST,
  DOWNLOAD_SAVED_REPORT_REQUEST,
  DOWNLOAD_SAVED_REPORT_SUCCESS,
  DOWNLOAD_SAVED_REPORT_ERROR,
  EMAIL_CONFIRMATION_STATUS,
  RESET_EMAIL_CONFIRMATION_SUCCESS,
  DOWNLOAD_MASTER_DATA_REPORT_ERROR,
  DOWNLOAD_MASTER_DATA_REPORT_SUCCESS,
  DOWNLOAD_MASTER_DATA_REPORT_REQUEST,
} from './actions';
import { REDUCERS } from '../../common/constants';
import { createReducer } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  emailConfirmationStatus: false,
  options: {
    loading: false,
    error: false,
    offset: 1,
    reports: [],
    details: [],
    columns: [],
    pagination: {},
    currentPage: {},
    totalCount: 0,
    startLoading: false,
  },
  download: {
    loading: false,
    error: false,
  },
  filter: {
    loading: false,
    error: false,
    data: {
      filterFields: [],
      orderByFields: [],
    },
  },
  data: {
    loading: false,
    error: false,
    dateLoading: false,
    profitCenter: [],
    typeDl: [],
    procurementType: [],
    plantStatus: [],
    plantSpecificMaterial: [],
    negotiatedDates: [],
    componentCostRunDate: [],
    skuRebateRunDate: [],
  },
  downloadSavedReport: {
    loading: false,
    error: false,
    success: false,
  },
};

const reducer = {
  [FETCH_REPORT_REQUEST]: state => {
    return {
      ...state,
      options: {
        ...state.options,
        ...REDUCERS.LOADING,
      },
    };
  },
  [FETCH_REPORT_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      options: {
        ...state.options,
        ...REDUCERS.SUCCESS,
        reports: Object.keys(payload).reduce(
          (acc, val) => [...acc, payload[val]],
          []
        ),
        details: [],
        columns: [],
        pagination: {},
        currentPage: {},
      },
    };
  },
  [FETCH_REPORT_ERROR]: (state, { payload: error }) => ({
    ...state,
    options: {
      ...state.options,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [FETCH_REPORT_DETAIL_REQUEST]: state => {
    return {
      ...state,
      options: {
        ...state.options,
        ...REDUCERS.LOADING,
      },
    };
  },
  [FETCH_REPORT_DETAIL_SUCCESS]: (
    state,
    { payload: { response, payload } }
  ) => {
    const columns =
      state.options.details.length === 0 && response.data.length > 0
        ? Object.keys(response.data[0]).map(val => ({
            key: val.replace('.', '').replace(' ', ''),
            label: val,
            size: 120,
          }))
        : state.options.columns;

    response.data =
      response.data.length > 0
        ? response.data.reduce((acc, data) => {
            const updatedData = Object.keys(data).reduce((keyAcc, val) => {
              keyAcc[val.replace('.', '').replace(' ', '')] = data[val];
              return keyAcc;
            }, {});
            return [...acc, updatedData];
          }, [])
        : [];

    const pagination = state.options.startLoading
      ? { ...response.pagination, ...{ totalCountLoading: true } }
      : response.pagination;

    if (
      state.options.pagination?.hasNext &&
      (payload.id === 'material-where-used' ||
        payload.id === 'component-cost' ||
        payload.id === 'costed-bom-details' ||
        payload.id === 'costed-bom-summary' ||
        payload.id === 'variance-data')
    ) {
      response.pagination.totalCount = state.options.pagination.totalCount;
    }

    return {
      ...state,
      options: {
        ...state.options,
        ...REDUCERS.SUCCESS,
        columns,
        offset: response?.offset,
        details: [...state.options.details, ...response.data],
        pagination,
        currentPage: payload.payload.pagination,
      },
    };
  },
  [FETCH_REPORT_DETAIL_ERROR]: (state, { payload: error }) => ({
    ...state,
    options: {
      ...state.options,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [DOWNLOAD_REPORT_REQUEST]: state => {
    return {
      ...state,
      download: {
        ...state.download,
        ...REDUCERS.LOADING,
      },
    };
  },
  [DOWNLOAD_REPORT_SUCCESS]: state => {
    return {
      ...state,
      download: {
        ...state.download,
        ...REDUCERS.SUCCESS,
      },
    };
  },
  [DOWNLOAD_REPORT_ERROR]: state => ({
    ...state,
    download: {
      ...state.download,
      ...REDUCERS.ERROR,
    },
  }),
  [FETCH_REPORT_FILTER_REQUEST]: state => {
    return {
      ...state,
      filter: {
        ...state.filter,
        ...REDUCERS.LOADING,
      },
      options: {
        ...state.options,
        details: [],
        pagination: {},
      },
    };
  },
  [FETCH_REPORT_FILTER_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      filter: {
        ...state.filter,
        ...REDUCERS.SUCCESS,
        data: payload,
      },
    };
  },
  [FETCH_REPORT_FILTER_ERROR]: state => ({
    ...state,
    filter: {
      ...state.filter,
      ...REDUCERS.ERROR,
    },
  }),
  [RESET_REPORT_DATA_SUCCESS]: state => ({
    ...state,
    options: {
      details: [],
      pagination: {},
      currentPage: {},
      columns: [],
    },
  }),
  [FETCH_REPORT_PROFIT_CENTER_REQUEST]: state => ({
    ...state,
    data: {
      ...state.data,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_REPORT_TYPE_REQUEST]: state => ({
    ...state,
    data: {
      ...state.data,
      ...REDUCERS.LOADING,
    },
  }),
  [FILTER_REPORT_API_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        ...REDUCERS.SUCCESS,
        ...payload,
      },
    };
  },
  [FILTER_REPORT_API_ERROR]: state => {
    return {
      ...state,
      data: {
        ...state.data,
        ...REDUCERS.ERROR,
      },
    };
  },
  [FETCH_RUN_DATE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        ...REDUCERS.SUCCESS,
        ...payload,
      },
    };
  },
  [FETCH_RUN_DATE_ERROR]: state => {
    return {
      ...state,
      data: {
        ...state.data,
        ...REDUCERS.ERROR,
      },
    };
  },
  [FETCH_TOTAL_COUNT_REQUEST]: state => {
    return {
      ...state,
      options: {
        ...state.options,
        startLoading: true,
        pagination: {
          ...state.options.pagination,
          totalCountLoading: true,
        },
      },
    };
  },
  [FETCH_TOTAL_COUNT_SUCCESS]: (state, { payload: { response } }) => {
    return {
      ...state,
      options: {
        ...state.options,
        totalCount: response?.response,
        startLoading: false,
        pagination: {
          ...state.options.pagination,
          totalCountLoading: false,
        },
      },
    };
  },
  [UPDATE_TOTAL_COUNT_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      options: {
        ...state.options,
        pagination: {
          ...state.options.pagination,
          totalCount: payload.totalCount,
        },
      },
    };
  },
  [NEGOTIATED_COST_DATE_REQUEST]: state => ({
    ...state,
    data: {
      ...state.data,
      ...REDUCERS.LOADING,
      dateLoading: true,
    },
  }),
  [NEGOTIATED_COST_DATE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        ...REDUCERS.SUCCESS,
        ...payload,
        dateLoading: false,
      },
    };
  },
  [NEGOTIATED_COST_DATE_ERROR]: state => {
    return {
      ...state,
      data: {
        ...state.data,
        ...REDUCERS.ERROR,
        dateLoading: false,
      },
    };
  },
  [DOWNLOAD_SAVED_REPORT_REQUEST]: state => ({
    ...state,
    downloadSavedReport: {
      ...state.downloadSavedReport,
      ...REDUCERS.LOADING,
    },
  }),
  [DOWNLOAD_SAVED_REPORT_SUCCESS]: state => ({
    ...state,
    downloadSavedReport: {
      ...state.downloadSavedReport,
      ...REDUCERS.SUCCESS,
      success: true,
    },
  }),
  [DOWNLOAD_SAVED_REPORT_ERROR]: state => ({
    ...state,
    downloadSavedReport: {
      ...state.downloadSavedReport,
      ...REDUCERS.ERROR,
    },
  }),
  [EMAIL_CONFIRMATION_STATUS]: state => ({
    ...state,
    emailConfirmationStatus: true,
    options: {
      ...state.options,
      ...REDUCERS.SUCCESS,
    },
    download: {
      ...state.download,
      ...REDUCERS.SUCCESS,
    },
  }),
  [RESET_EMAIL_CONFIRMATION_SUCCESS]: state => ({
    ...state,
    emailConfirmationStatus: false,
    download: {
      ...state.download,
      ...REDUCERS.SUCCESS,
    },
  }),
  [DOWNLOAD_MASTER_DATA_REPORT_REQUEST]: state => {
    return {
      ...state,
      download: {
        ...state.download,
        ...REDUCERS.LOADING,
      },
    };
  },
  [DOWNLOAD_MASTER_DATA_REPORT_SUCCESS]: state => {
    return {
      ...state,
      download: {
        ...state.download,
        ...REDUCERS.SUCCESS,
      },
    };
  },
  [DOWNLOAD_MASTER_DATA_REPORT_ERROR]: state => ({
    ...state,
    download: {
      ...state.download,
      ...REDUCERS.ERROR,
    },
  }),
};

export default createReducer(schema, reducer);
