import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';

import Title from './Title';

import {
  DASHBOARD,
  PROGRAM_ROOT,
  PROGRAM_MATERIALS,
  ADMIN_ROOT,
  DATA_ROOT,
  BOM_ROOT,
  QUOTES_SCHEDULES_ROOT,
  COST_ROOT,
  QUOTE_ROOT,
  VARIANCE_ROOT,
  REPORT_ROOT,
} from '../../../common/routes';

import { IS_NPI_ENVIROMENT } from '../../../common/constants';

const Breadcrumbs = () => {
  const theme = useTheme();
  const { typography, palette } = theme;
  const captionText = IS_NPI_ENVIROMENT
    ? `QBERT 2024 Release 4 NPI:`
    : `QBert 2024:`;

  return (
    <>
      <Box fontSize={typography.appTitle} mb={1} fontWeight="bold">
        <span> {captionText} </span>
        <span style={{ color: palette.primary.main }}>
          <Switch>
            <Route path={DASHBOARD}>
              <Title label="Dashboard" />
            </Route>
            <Route path={PROGRAM_ROOT}>
              <Title label="My Programs" />
            </Route>
            <Route path={PROGRAM_MATERIALS}>
              <Title label="Program Materials" />
            </Route>
            <Route path={ADMIN_ROOT}>
              <Title label="Admin" />
            </Route>
            <Route path={DATA_ROOT}>
              <Title label="Data Upload" />
            </Route>
            <Route path={BOM_ROOT}>
              <Title label="BOM" />
            </Route>
            <Route path={QUOTES_SCHEDULES_ROOT}>
              <Title label="Quote Schedules" />
            </Route>
            <Route path={COST_ROOT}>
              <Title label="Cost Management" />
            </Route>
            <Route path={QUOTE_ROOT}>
              <Title label="Quote" />
            </Route>
            <Route path={VARIANCE_ROOT}>
              <Title label="Variance" />
            </Route>
            <Route path={REPORT_ROOT}>
              <Title label="Reports" />
            </Route>
            <Route default>
              <Title />
            </Route>
          </Switch>
        </span>
      </Box>
    </>
  );
};

export default Breadcrumbs;
