import React from 'react';
import { Box, Paper, CardContent, Typography, Card } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { Table } from 'grommet-icons';

import { useHistory } from 'react-router-dom';
import { shape, string, func, bool } from 'prop-types';
import { REPORT_DETAILS } from '../../common/routes';
import { buildRouteParams } from '../../common/helpers';

const ReportCardRoot = styled(Card)({
  width: 250,
  height: 78,
});
const ReportCardPaper = styled(Paper)({
  margin: '10px',
});
const ReportCardTitle = styled(Typography)({
  color: '#666666',
});

const ReportCard = ({ cardDetail, setCardId, favourite }) => {
  const history = useHistory();

  const endpoint = cardDetail?.endpoint && cardDetail?.endpoint.substr(1);

  const updateFavouriteList = () => {
    if (JSON.parse(localStorage.getItem('reportsTab'))) {
      const updateData = [
        ...JSON.parse(localStorage.getItem('reportsTab')),
        cardDetail,
      ];
      localStorage.setItem('reportsTab', JSON.stringify(updateData));
    } else {
      localStorage.setItem('reportsTab', JSON.stringify([cardDetail]));
    }
  };

  const removeFromFavouriteList = () => {
    const updatedData = JSON.parse(localStorage.getItem('reportsTab')).filter(
      val =>
        val.name !== cardDetail.name && val.endpoint !== cardDetail.endpoint
    );
    localStorage.setItem('reportsTab', JSON.stringify(updatedData));
  };

  const redirectToReportPage = () => {
    localStorage.setItem(endpoint, cardDetail?.name);
    history.push(buildRouteParams(REPORT_DETAILS, endpoint));
  };

  return (
    <ReportCardPaper elevation={1}>
      <ReportCardRoot>
        <CardContent>
          <Box display="flex" flexDirection="row">
            <Box
              width="50px"
              pr={1}
              onClick={() => redirectToReportPage()}
              style={{ cursor: 'pointer' }}
              textAlign="center"
            >
              <Table color="plain" size="medium" />
            </Box>
            <Box
              onClick={() => redirectToReportPage()}
              style={{ cursor: 'pointer' }}
              width="200px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <ReportCardTitle>{cardDetail && cardDetail.name}</ReportCardTitle>
            </Box>
            <Box>
              {favourite ? (
                <StarIcon
                  color="primary"
                  fontSize="large"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setCardId(Date.now());
                    removeFromFavouriteList(cardDetail);
                  }}
                />
              ) : (
                <StarBorderIcon
                  fontSize="large"
                  color="secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setCardId(Date.now());
                    updateFavouriteList(cardDetail);
                  }}
                />
              )}
            </Box>
          </Box>
        </CardContent>
      </ReportCardRoot>
    </ReportCardPaper>
  );
};
ReportCard.propTypes = {
  cardDetail: shape({ category: string, reportName: string }),
  setCardId: func,
  favourite: bool,
};

export default ReportCard;
