import React from 'react';
import { string, func } from 'prop-types';
import { TextField, Box } from '@material-ui/core';
import Select from '../../../Select';

const textOperatorsOptions = [
  { id: 'all', value: 'All' },
  { id: 'eq', value: ' Equal' },
  { id: 'gt', value: 'Greater Than' },
  { id: 'lt', value: 'Lesser Than' },
  { id: 'between', value: 'Between' },
];

const NumberFilter = ({ onChange, operator, value, from, to }) => {
  return (
    <Box>
      Operator
      <Select
        options={textOperatorsOptions}
        valueKey="id"
        valueLabel="value"
        onChange={val => onChange({ operator: val })}
        value={operator || ''}
        variant="outlined"
        fullWidth
      />
      <Box pt={2}>
        {operator !== 'between' && (
          <TextField
            type="number"
            onChange={event => onChange({ value: event.target.value })}
            value={value || ''}
            variant="outlined"
            fullWidth
          />
        )}

        {operator === 'between' && (
          <>
            <TextField
              type="number"
              onChange={event => onChange({ from: event.target.value })}
              value={from || ''}
              variant="outlined"
              fullWidth
            />
            To
            <TextField
              type="number"
              onChange={event => onChange({ to: event.target.value })}
              value={to || ''}
              variant="outlined"
              fullWidth
            />
          </>
        )}
      </Box>
    </Box>
  );
};
NumberFilter.propTypes = {
  onChange: func,
  operator: string,
  value: string,
  from: string,
  to: string,
};

export default NumberFilter;
