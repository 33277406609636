import React, { PureComponent, forwardRef, createRef } from 'react';
import { Box, styled } from '@material-ui/core';
import {
  shape,
  string,
  oneOfType,
  number,
  arrayOf,
  object,
  func,
  bool,
} from 'prop-types';
import Column from './Column';
import { isIEBrowser } from '../../../common/helpers';

const UnpinnedColumns = styled(Box)({
  display: 'flex',
  top: 0,
  background: 'white',
  position: 'sticky',
  borderBottom: 'solid 2px #BBB',
});
const stickyBaseStyles = {
  position: 'sticky',
  top: '0',
  zIndex: '50',
  height: 'auto',
  display: 'flex',
  background: 'white',
  borderBottom: 'solid 2px #BBB',
};
const LeftPinnedColumns = styled(Box)({
  left: '0',
  top: 0,
  borderRight: 'solid 2px #BBB',

  ...stickyBaseStyles,
});

const RightPinnedColumns = styled(Box)({
  right: '0',
  top: 0,
  borderLeft: 'solid 4px #BBB',
  ...stickyBaseStyles,
});

const Base = styled(Box)({
  top: 0,
  position: isIEBrowser() ? 'fixed' : 'sticky',
  zIndex: '50',
  display: 'inline-flex',
  height: '26px',
});

const DATA_TYPE = isIEBrowser() ? 'Text' : 'text/plain';

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.IEWrapper = createRef();
  }

  componentDidMount() {
    if (isIEBrowser()) {
      const {
        innerRef: {
          current: { parentNode: parent },
        },
      } = this.props;
      parent.addEventListener('scroll', this.onHorizontalScroll);
      this.onHorizontalScroll({ target: parent });
    }
  }

  componentDidUpdate() {
    if (isIEBrowser()) {
      const {
        innerRef: {
          current: { parentNode: parent },
        },
      } = this.props;
      this.onHorizontalScroll({ target: parent });
    }
  }

  componentWillUnmount() {
    if (isIEBrowser()) {
      const {
        innerRef: {
          current: { parentNode: parent },
        },
      } = this.props;
      parent.removeEventListener('scroll', this.onHorizontalScroll);
    }
  }

  onHorizontalScroll = ({ target }) => {
    const { left, width } = target.getBoundingClientRect();
    const {
      innerRef: { current: parent },
    } = this.props;
    const { current } = this.IEWrapper;
    parent.style.left = left;
    parent.style.width = `${width}px`;
    parent.style.overflow = 'hidden';
    current.style.position = 'absolute';
    current.style.left = `-${target.scrollLeft}px`;
  };

  onResize = (obj, settings) => {
    const { pinned, unpinned, actions, onResize } = this.props;
    const all = { pinned, unpinned, actions, ...obj };
    const columns = Object.entries(all).reduce(
      (acc, current) => acc.concat(current[1]),
      []
    );
    onResize(columns, settings);
  };

  onDragOver = evt => {
    evt.preventDefault();
  };

  onDrop = (evt, isPinnedDroppable, droppableItemkey) => {
    const { id } = JSON.parse(evt.dataTransfer.getData(DATA_TYPE));
    const { pinned, unpinned, actions, onUpdate } = this.props;
    const columns = [...pinned, ...unpinned, ...actions];
    const draggedElementIndex = columns.findIndex(x => x.key === id);
    const droppableElementIndex = columns.findIndex(
      x => x.key === droppableItemkey
    );

    columns[draggedElementIndex].pinned = isPinnedDroppable;

    const shiftedElement = columns.splice(draggedElementIndex, 1)[0]; // cut the element at index 'from'
    columns.splice(droppableElementIndex, 0, shiftedElement); // insert it at index 'to'

    onUpdate(columns);
  };

  onDragStart = (evt, id, pinned) => {
    const transferObj = JSON.stringify({ id, pinned });
    evt.dataTransfer.setData(DATA_TYPE, transferObj);
  };

  render() {
    const {
      pinned,
      unpinned,
      actions,
      onUpdate,
      onFiltering,
      onSorting,
      orderBy,
      filters,
      fullWidth,
      innerRef,
    } = this.props;

    const activeUnpinnedCols = unpinned.filter(column => column.size !== 0);
    const activePinnedCols = pinned.filter(column => column.size !== 0);
    const data = (
      <>
        {activePinnedCols.length !== 0 && (
          <LeftPinnedColumns>
            {activePinnedCols.map(({ key, ...column }) => (
              <Box
                className="droppable"
                key={key}
                onDragOver={this.onDragOver}
                onDrop={evt => {
                  this.onDrop(evt, true, key);
                }}
              >
                <Column
                  component={Column}
                  key={key}
                  id={key}
                  {...column}
                  onUpdate={onUpdate}
                  onFiltering={onFiltering}
                  filters={filters}
                  onSorting={onSorting}
                  orderBy={orderBy}
                  draggable
                  onDragStart={this.onDragStart}
                  onResize={size => {
                    this.onResize(
                      {
                        pinned: pinned.map(current =>
                          current.key === key
                            ? {
                                ...current,
                                size,
                              }
                            : current
                        ),
                      },
                      { [key]: { size } }
                    );
                  }}
                />
              </Box>
            ))}
          </LeftPinnedColumns>
        )}

        <UnpinnedColumns style={fullWidth ? { width: '100%' } : {}}>
          {activeUnpinnedCols.map(({ key, ...column }, index) => {
            if (pinned.length === 0 && index === 0) {
              return (
                <Box
                  className="droppable"
                  key={key}
                  onDragOver={this.onDragOver}
                  onDrop={evt => {
                    this.onDrop(evt, true, key);
                  }}
                >
                  <Column
                    component={Column}
                    key={key}
                    id={key}
                    {...column}
                    onUpdate={onUpdate}
                    onFiltering={onFiltering}
                    filters={filters}
                    onSorting={onSorting}
                    orderBy={orderBy}
                    draggable
                    onDragStart={this.onDragStart}
                    onResize={size => {
                      this.onResize(
                        {
                          unpinned: unpinned.map(current =>
                            current.key === key
                              ? {
                                  ...current,
                                  size,
                                }
                              : current
                          ),
                        },
                        { [key]: { size } }
                      );
                    }}
                  />
                </Box>
              );
            }
            return (
              <Box
                className="droppable"
                key={key}
                onDragOver={this.onDragOver}
                onDrop={evt => {
                  this.onDrop(evt, false, key);
                }}
              >
                <Column
                  key={key}
                  id={key}
                  {...column}
                  onUpdate={onUpdate}
                  draggable
                  onFiltering={onFiltering}
                  filters={filters}
                  onSorting={onSorting}
                  orderBy={orderBy}
                  onDragStart={this.onDragStart}
                  isLastElement={activeUnpinnedCols.length - 1 === index}
                  onResize={size => {
                    this.onResize(
                      {
                        unpinned: unpinned.map(current =>
                          current.key === key
                            ? {
                                ...current,
                                size,
                              }
                            : current
                        ),
                      },
                      { [key]: { size } }
                    );
                  }}
                />
              </Box>
            );
          })}
        </UnpinnedColumns>
        {actions.length !== 0 && (
          <RightPinnedColumns>
            {actions.map(({ key, ...column }) => (
              <Column
                key={key}
                id={key}
                filters={filters}
                orderBy={orderBy}
                {...column}
                actions
                onUpdate={onUpdate}
              />
            ))}
          </RightPinnedColumns>
        )}
      </>
    );

    return (
      <Base ref={innerRef} style={fullWidth ? { width: '100%' } : {}}>
        {isIEBrowser() ? <Box ref={this.IEWrapper}>{data}</Box> : data}
      </Base>
    );
  }
}

const HeaderColumn = shape({
  key: string.isRequired,
  label: string,
  size: oneOfType([string, number]),
});

Header.propTypes = {
  pinned: arrayOf(HeaderColumn),
  unpinned: arrayOf(HeaderColumn),
  actions: arrayOf(HeaderColumn),
  onUpdate: func,
  onFiltering: func,
  filters: arrayOf(object),
  onSorting: func,
  orderBy: arrayOf(object),
  onResize: func,
  fullWidth: bool,
  // eslint-disable-next-line
  innerRef: object,
};

export default forwardRef((props, ref) => <Header {...props} innerRef={ref} />);
