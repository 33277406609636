/* eslint-disable no-unused-vars */
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { dispatch } from '../../common/helpers';
import { exchangeService } from '../../services';
import { MESSAGES, DEFAULT_PAGINATION_OBJECT } from '../../common/constants';

import {
  FETCH_EXCHANGE_LIST_REQUEST,
  FETCH_EXCHANGE_LIST_SUCCESS,
  FETCH_EXCHANGE_LIST_ERROR,
  CREATE_EXCHANGE_REQUEST,
  CREATE_EXCHANGE_SUCCESS,
  CREATE_EXCHANGE_ERROR,
  UPDATE_EXCHANGE_REQUEST,
  UPDATE_EXCHANGE_SUCCESS,
  UPDATE_EXCHANGE_ERROR,
  DELETE_EXCHANGE_REQUEST,
  DELETE_EXCHANGE_SUCCESS,
  DELETE_EXCHANGE_ERROR,
} from './actions';

import {
  SUCCESS_NOTIFICATION_REQUEST,
  ERROR_NOTIFICATION_REQUEST,
} from '../notifications/actions';

export const createExchange = action$ =>
  action$.pipe(
    ofType(CREATE_EXCHANGE_REQUEST),
    mergeMap(({ payload: { values, payload } }) =>
      exchangeService.createExchange(values).pipe(
        mergeMap(() => [
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.FORM_SUBMITTED_SUCCESSFULLY,
          }),
          dispatch(FETCH_EXCHANGE_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
        ]),
        catchError(error => [
          dispatch(CREATE_EXCHANGE_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );

export const fetchExchangeList = (action$, store) => {
  return action$.pipe(
    ofType(FETCH_EXCHANGE_LIST_REQUEST),
    mergeMap(({ payload }) =>
      exchangeService.getAllExchange(payload).pipe(
        map(data => {
          return dispatch(FETCH_EXCHANGE_LIST_SUCCESS, {
            ...data,
            payload,
          });
        })
      )
    ),
    catchError(err => {
      return [dispatch(FETCH_EXCHANGE_LIST_ERROR, err)];
    })
  );
};

export const updateExchange = action$ =>
  action$.pipe(
    ofType(UPDATE_EXCHANGE_REQUEST),
    mergeMap(({ payload: { values, payload } }) =>
      exchangeService.updateExchange(values.id, values).pipe(
        mergeMap(() => [
          dispatch(FETCH_EXCHANGE_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.FORM_UPDATED_SUCCESSFULLY,
          }),
        ]),
        catchError(error => [
          dispatch(UPDATE_EXCHANGE_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );

export const removeExchange = (action$, store) =>
  action$.pipe(
    ofType(DELETE_EXCHANGE_REQUEST),
    mergeMap(({ payload: { id, payload } }) =>
      exchangeService.deleteExchange(id).pipe(
        mergeMap(() => {
          return [
            dispatch(FETCH_EXCHANGE_LIST_REQUEST, {
              ...payload,
              pagination: DEFAULT_PAGINATION_OBJECT,
            }),
            dispatch(SUCCESS_NOTIFICATION_REQUEST, {
              message: MESSAGES.DATA_DELETED_SUCCESSFULLY,
            }),
          ];
        }),
        catchError(error => [
          dispatch(DELETE_EXCHANGE_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );
