/* eslint-disable no-unused-vars */
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { dispatch } from '../../common/helpers';

import {
  GET_AUDIT_LIST_REQUEST,
  GET_AUDIT_LIST_SUCCESS,
  GET_AUDIT_LIST_ERROR,
} from './actions';
import { auditsService } from '../../services';

export const getAuditList = action$ =>
  action$.pipe(
    ofType(GET_AUDIT_LIST_REQUEST),
    mergeMap(({ payload }) => auditsService.getAuditList(payload)),
    map(data => dispatch(GET_AUDIT_LIST_SUCCESS, data)),
    catchError(err => [dispatch(GET_AUDIT_LIST_ERROR, err)])
  );
