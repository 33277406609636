import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { dispatch } from '../../common/helpers';

import {
  FETCH_COST_SEQUENCE_LIST_REQUEST,
  FETCH_COST_SEQUENCE_LIST_SUCCESS,
  FETCH_COST_SEQUENCE_LIST_ERROR,
  RESET_SEQUENCE_DATA,
  RESET_SEQUENCE_SUCCESS,
  CREATE_COST_SEQUENCE_REQUEST,
  CREATE_COST_SEQUENCE_ERROR,
  UPDATE_COST_SEQUENCE_REQUEST,
  UPDATE_COST_SEQUENCE_ERROR,
  DELETE_COST_SEQUENCE_REQUEST,
  DELETE_COST_SEQUENCE_ERROR,
} from './actions';
import { MESSAGES } from '../../common/constants';

import {
  SUCCESS_NOTIFICATION_REQUEST,
  ERROR_NOTIFICATION_REQUEST,
} from '../notifications/actions';

import { costGroupSequenceService } from '../../services';

export const fetchCostGroupSequence = action$ => {
  return action$.pipe(
    ofType(FETCH_COST_SEQUENCE_LIST_REQUEST),
    mergeMap(({ payload }) =>
      costGroupSequenceService.getCostGroupSequenceData(payload).pipe(
        map(data => {
          return dispatch(FETCH_COST_SEQUENCE_LIST_SUCCESS, [...data]);
        })
      )
    ),
    catchError(err => {
      return [dispatch(FETCH_COST_SEQUENCE_LIST_ERROR, err)];
    })
  );
};

export const resetSequenceData = action$ =>
  action$.pipe(
    ofType(RESET_SEQUENCE_DATA),
    mergeMap(() => [dispatch(RESET_SEQUENCE_SUCCESS)])
  );

export const createCostSequence = action$ =>
  action$.pipe(
    ofType(CREATE_COST_SEQUENCE_REQUEST),
    mergeMap(({ payload: { values, payload } }) =>
      costGroupSequenceService.createCostSequenceGroup(values).pipe(
        mergeMap(() => [
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.FORM_SUBMITTED_SUCCESSFULLY,
          }),
          dispatch(FETCH_COST_SEQUENCE_LIST_REQUEST, {
            ...payload,
          }),
        ]),
        catchError(error => [
          dispatch(CREATE_COST_SEQUENCE_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );

export const updateCostSequence = action$ =>
  action$.pipe(
    ofType(UPDATE_COST_SEQUENCE_REQUEST),
    mergeMap(({ payload: { values, payload } }) => {
      return costGroupSequenceService
        .updateCostSequenceGroup(values.id, values)
        .pipe(
          mergeMap(() => [
            dispatch(FETCH_COST_SEQUENCE_LIST_REQUEST, {
              ...payload,
            }),
            dispatch(SUCCESS_NOTIFICATION_REQUEST, {
              message: MESSAGES.FORM_UPDATED_SUCCESSFULLY,
            }),
          ]),
          catchError(error => [
            dispatch(UPDATE_COST_SEQUENCE_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, error),
          ])
        );
    })
  );

export const deleteCostSequence = action$ =>
  action$.pipe(
    ofType(DELETE_COST_SEQUENCE_REQUEST),
    mergeMap(({ payload: { id, payload } }) =>
      costGroupSequenceService.deleteCostSequenceGroup(id).pipe(
        mergeMap(() => {
          return [
            dispatch(FETCH_COST_SEQUENCE_LIST_REQUEST, {
              ...payload,
            }),
            dispatch(SUCCESS_NOTIFICATION_REQUEST, {
              message: MESSAGES.DATA_DELETED_SUCCESSFULLY,
            }),
          ];
        }),
        catchError(error => [
          dispatch(DELETE_COST_SEQUENCE_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );
