import React, { PureComponent } from 'react';
import { Box } from '@material-ui/core';
import {
  shape,
  string,
  oneOfType,
  number,
  arrayOf,
  object,
  bool,
  func,
} from 'prop-types';
import Row from './Row';
import HierarchicalRow from './HierarchicalRow';
import FadeIn from '../../FadeIn';
import { isIEBrowser } from '../../../common/helpers';

class Body extends PureComponent {
  render() {
    const {
      data,
      isHierarchical,
      pinned,
      unpinned,
      actions,
      isDraggable,
      dragHandlers,
      dropboundry,
      fullWidth,
    } = this.props;

    return (
      <Box
        style={{
          display: 'grid',
          position: 'absolute',
          width: fullWidth ? '100%' : 'auto',
          marginTop: isIEBrowser() ? '24px' : 0,
        }}
      >
        {isHierarchical
          ? data
              .filter(x => x.isParent)
              .map(parentRow => {
                return (
                  <HierarchicalRow
                    key={parentRow.id}
                    pinned={pinned}
                    unpinned={unpinned}
                    actions={actions}
                    parentData={parentRow}
                    fullWidth={fullWidth}
                    childrenData={data.filter(
                      x => !x.isParent && x.parentId === parentRow.id
                    )}
                  />
                );
              })
          : data.map((datum, index) => {
              return (
                <Row
                  key={datum.id || index}
                  pinned={pinned}
                  unpinned={unpinned}
                  actions={actions}
                  data={datum}
                  isDraggable={isDraggable}
                  dragHandlers={dragHandlers}
                  dropboundry={dropboundry}
                  fullWidth={fullWidth}
                />
              );
            })}
      </Box>
    );
  }
}

const HeaderColumn = shape({
  key: string.isRequired,
  label: string,
  size: oneOfType([string, number]),
});

Body.propTypes = {
  data: arrayOf(object),
  isHierarchical: bool,
  pinned: arrayOf(HeaderColumn),
  unpinned: arrayOf(HeaderColumn),
  actions: arrayOf(HeaderColumn),
  dropboundry: func,
  isDraggable: bool,
  dragHandlers: shape({
    dragStartHandler: func,
    dragOverHandler: func,
    dragEndHandler: func,
    dropHandler: func,
  }),
  fullWidth: bool,
};

export default FadeIn(Body);
