import React from 'react';
import { Fab as MuiFab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { func, bool } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 10,
  },
}));

export default function Fab({ onClick, disabled = false, ...props }) {
  const classes = useStyles();
  return (
    <MuiFab
      aria-label="Add"
      className={classes.fab}
      color="primary"
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      <AddIcon />
    </MuiFab>
  );
}

Fab.propTypes = {
  onClick: func,
  disabled: bool,
};
