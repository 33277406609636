import React, { PureComponent, createRef } from 'react';
import { Box, Switch, Typography, styled, Tooltip } from '@material-ui/core';
import {
  shape,
  string,
  oneOfType,
  number,
  object,
  bool,
  func,
} from 'prop-types';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import moment from 'moment';

const Base = styled(Box)({
  display: 'flex',
  padding: '4px',
  textAlign: 'justify',
  flexShrink: 0,
  alignItems: 'center',
});

class Cell extends PureComponent {
  constructor(props) {
    super(props);

    this.currentRef = createRef();
    this.state = {
      currentOffsetWidth: undefined,
      currentScrollWidth: undefined,
    };
  }

  componentDidMount() {
    if (this.currentRef.current) {
      this.currentRef.current.addEventListener(
        'mouseenter',
        this.handleMouseEnter
      );
    }
  }

  componentWillUnmount() {
    if (this.currentRef.current) {
      this.currentRef.current.removeEventListener(
        'mouseenter',
        this.handleMouseEnter
      );
    }
  }

  handleMouseEnter = () => {
    this.setState({
      currentOffsetWidth: this.currentRef.current.offsetWidth,
      currentScrollWidth: this.currentRef.current.scrollWidth,
    });
  };

  render() {
    const { currentOffsetWidth, currentScrollWidth } = this.state;
    let current = '';
    let tooltipContent;
    const showTooltip = currentOffsetWidth < currentScrollWidth;

    const {
      column: { size, key, type, render, draggable },
      data,
      open,
      handleCollapsible,
      isLastElement,
    } = this.props;
    if (key?.includes('.')) {
      try {
        const levels = key.split('.');
        current = levels.reduce((acc, curr) => acc[curr], data);
      } catch (ex) {
        current = null;
      }
    } else {
      current = data[key];
    }
    let content = (
      <Typography ref={this.currentRef} noWrap>
        {current}
      </Typography>
    );
    if (render) {
      content = render(data, data.id);
    }
    if (Array.isArray(current)) {
      content = (
        <Typography ref={this.currentRef} noWrap>
          {current.join(', ')}
        </Typography>
      );
    }

    if (typeof current === 'boolean' && !render) {
      content = <Switch size="small" color="primary" checked={current} />;
    }

    if (type === 'collapsible' && !render) {
      content = data.isParent ? (
        <Box
          onClick={handleCollapsible}
          p={0}
          component={open ? ExpandLess : ExpandMore}
        />
      ) : (
        <></>
      );
    }
    if (type === 'date' && !render) {
      content = (
        <Typography ref={this.currentRef} noWrap>
          {current && moment(current).format('MM/DD/YYYY')}
        </Typography>
      );
    }

    if (showTooltip) {
      const title =
        type === 'date' ? moment(current).format('MM/DD/YYYY') : current;
      tooltipContent = <Tooltip title={title}>{content}</Tooltip>;
    }

    return (
      <Base
        style={{
          width: size,
          borderRight: isLastElement ? 'none' : 'solid 2px #BBB',
        }}
        draggablediv={draggable && draggable.toString()}
      >
        {showTooltip ? tooltipContent : content}
      </Base>
    );
  }
}

Cell.propTypes = {
  column: shape({
    key: string.isRequired,
    label: string,
    size: oneOfType([string, number]),
  }),
  open: bool,
  isLastElement: bool,
  handleCollapsible: func,
  // eslint-disable-next-line
  data: object,
};

export default Cell;
