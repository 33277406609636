/* eslint-disable no-unused-vars */
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, delay } from 'rxjs/operators';
import { v1 } from 'uuid';
import { of, merge } from 'rxjs';
import { MESSAGES, QNP } from '../../common/constants';
import {
  FETCH_DATA_FILES_REQUEST,
  FETCH_DATA_FILES_SUCCESS,
  FETCH_DATA_FILES_ERROR,
  UPLOAD_DATA_FILES_REQUEST,
  UPLOAD_DATA_FILES_SUCCESS,
  UPLOAD_DATA_FILES_ERROR,
} from './actions';
import {
  SUCCESS_NOTIFICATION_REQUEST,
  ERROR_NOTIFICATION_REQUEST,
} from '../notifications/actions';
import { get, postFile } from '../../common/service';
import { dataUploadService } from '../../services';
import { dispatch } from '../../common/helpers';
import { useUserData } from '../../common/hooks';

export const fetchDataFiles = (action$, store) =>
  action$.pipe(
    ofType(FETCH_DATA_FILES_REQUEST),
    mergeMap(({ payload }) =>
      dataUploadService.getAllUploadFiles(payload).pipe(
        map(data => {
          return dispatch(FETCH_DATA_FILES_SUCCESS, {
            ...data,
            payload,
          });
        })
      )
    ),
    catchError(err => {
      return [dispatch(FETCH_DATA_FILES_ERROR, err)];
    })
  );

export const uploadDataFiles = (action$, state) =>
  action$.pipe(
    ofType(UPLOAD_DATA_FILES_REQUEST),
    mergeMap(
      ({
        payload: {
          formState: { partner, dataType, files },
          payload,
        },
      }) => {
        const {
          shared: {
            options: { partners: _partners },
          },
          data: {
            files: {
              response: { data: fileList },
            },
          },
          user: {
            data: { emailAddress, commonName },
          },
        } = state.value;

        const body = new FormData();
        body.append('partner', partner);
        body.append('file', files[0]);
        body.append('email', emailAddress);
        body.append('userName', commonName);

        const partners = _partners.find(({ id }) => id === partner);
        const dataTypes = {
          bom: 'BOM',
          'program-materials': 'Program Materials',
          'material-partner-costs': 'Quoted Costs',
          'material-negotiated-costs': 'Material Negotiated Costs',
        };
        const details = {
          id: v1(),
          file: files && files[0] && files[0].name,
          // eslint-disable-next-line
          dataType: dataTypes[dataType],
          status: 'Uploading',
          partner: partners && partners.name,
          uploadedBy: { email: emailAddress },
        };
        const updateList = of(
          dispatch(FETCH_DATA_FILES_SUCCESS, {
            data: [details].concat(fileList),
          })
        );
        const fileUpload = postFile(`${QNP}/${dataType}/upload`, body).pipe(
          mergeMap(data => {
            return [
              dispatch(FETCH_DATA_FILES_REQUEST, payload),
              dispatch(UPLOAD_DATA_FILES_SUCCESS, details.id),
              dispatch(SUCCESS_NOTIFICATION_REQUEST, {
                message: MESSAGES.FILE_UPLOADED_SUCCESSFULLY,
              }),
            ];
          }),
          catchError(err => [
            dispatch(UPLOAD_DATA_FILES_ERROR, details.id),
            dispatch(ERROR_NOTIFICATION_REQUEST, err),
          ])
        );
        return merge(updateList, fileUpload);
      }
    )
  );
