import { ofType } from 'redux-observable';
import { forkJoin, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { dispatch } from '../../common/helpers';
import {
  regionsService,
  suppliersService,
  currenciesService,
  costFieldsService,
  plantsService,
  commodityService,
  usersService,
  businessUnitsService,
  costGroupService,
  programsService,
  plantPrefService,
  materialsService,
  quoteVariancesService,
  conditionsService,
} from '../../services';

import {
  PROGRAM_TYPES,
  QUOTE_TYPES,
  OPERATION_TYPES,
  COST_VARIANCE_TYPES,
  QUANTITY_VARIANCE_TYPES,
  BOM_OWNER_ROLE_TYPES,
  COST_SOURCE_TYPES,
  NEGOTIATED_COST_SOURCE_TYPES,
  COST_SOURCE_TYPES_NPI,
  IS_NPI_ENVIROMENT,
} from '../../common/constants';
import {
  FETCH_OPTIONS_REQUEST,
  FETCH_OPTIONS_SUCCESS,
  FETCH_OPTIONS_ERROR,
} from './actions';

export const fetchOptions = action$ =>
  action$.pipe(
    ofType(FETCH_OPTIONS_REQUEST),
    mergeMap(() =>
      forkJoin(
        [
          usersService.getAllUsers(),
          businessUnitsService.getAllBusinessUnits(),
          suppliersService.getAllSuppliers(),
          regionsService.getAllRegions(),
          currenciesService.getAllCurrencies(),
          commodityService.getAllCommodities(),
          plantsService.getAllPlants(),
          costGroupService.getAllCostGroups(),
          costFieldsService.getAll(),
          programsService.getAllPrograms(),
          plantPrefService.getAllPlantPrefMaterialTypes(),
          materialsService.getAllMaterialTypes(),
          programsService.getAllPMMaterialTypes(),
          quoteVariancesService.getMaterialNegotiatedCostCategory(),
          conditionsService.getAll(),
          suppliersService.partnerWithRelation(),
          costFieldsService.getFilteredList(),
        ].map(el => el.pipe(catchError(() => of({ data: [] }))))
      )
    ),
    map(
      ([
        users,
        businessUnits,
        partners,
        regions,
        currencies,
        commodities,
        plants,
        costGroups,
        costFields,
        programs,
        plantPrefMaterialTypes,
        materialTypes,
        pmMaterialTypes,
        costCategory,
        constConditions,
        partnerReports,
        updatedCostFields,
      ]) =>
        dispatch(FETCH_OPTIONS_SUCCESS, {
          users: users?.data,
          businessUnits: businessUnits?.data,
          partners: partners?.data,
          regions: regions?.data,
          currencies: currencies?.data,
          programTypes: PROGRAM_TYPES,
          quoteTypes: QUOTE_TYPES,
          operationTypes: OPERATION_TYPES,
          costVarianceTypes: COST_VARIANCE_TYPES,
          costSourceTypes: IS_NPI_ENVIROMENT
            ? COST_SOURCE_TYPES_NPI
            : COST_SOURCE_TYPES,
          quantityVarianceTypes: QUANTITY_VARIANCE_TYPES,
          bomOwnerRoleTypes: BOM_OWNER_ROLE_TYPES,
          commodities: commodities?.data,
          plants: plants?.data,
          costGroups: costGroups?.data,
          costFields: costFields?.data,
          programs: programs?.data,
          plantPrefMaterialTypes,
          materialTypes:
            materialTypes?.data
              .filter(x => x)
              .map(x => ({ id: x, value: x })) || [],
          pmMaterialTypes:
            pmMaterialTypes?.data
              .filter(x => x)
              .map(x => ({ id: x, value: x })) || [],
          costCategory,
          constConditions: constConditions?.data,
          updatedCostFields: updatedCostFields?.data,
          partnerReports: partnerReports?.data.reduce((acc, val) => {
            if (!acc.find(data => val.partner_name === data.partner_name)) {
              return [...acc, val];
            }
            return acc;
          }, []),
          negotiatedCostSource: NEGOTIATED_COST_SOURCE_TYPES,
        })
    ),
    catchError(err => [dispatch(FETCH_OPTIONS_ERROR, err)])
  );
