import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/quote-schedules`;

const quoteSchedulesService = {
  getAllQuoteSchedulesTabs: () => {
    return service.get(`${api}`);
  },
  getDataByTab: payload => {
    return service.get(`${api}/type`, payload);
  },
  updateCalenderDate: payload => {
    return service.post(`${api}`, payload);
  },
};

export default quoteSchedulesService;
