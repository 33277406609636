import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/business-units`;

const BusinessUnitsService = {
  getAllBusinessUnits: (params = { limit: 10, offset: 0 }) => {
    return service.get(`${api}?limit=${params.limit}&offset=${params.offset}`);
  },
  getBusinessUnit: id => {
    return service.get(`${api}/${id}`);
  },
  createBusinessUnit: data => {
    return service.post(`${api}`, data);
  },
  updateBusinessUnit: (id, data) => {
    return service.put(`${api}/${id}`, data);
  },
  deleteBusinessUnit: id => {
    return service.remove(`${api}/${id}`);
  },
};

export default BusinessUnitsService;
