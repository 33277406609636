const common = () => ({
  fontBold: {
    fontSize: '16px',
    fontWeight: '700',
  },
  DialogLabelStyle: {
    fontSize: '18px',
    fontWeight: '700',
    textAlign: 'start',
  },
  underlineStyle: {
    textDecoration: 'underline',
    textDecorationStyle: 'solid',
    borderStyle: 'none',
  },
  exchangeFont: {
    fontSize: '16px',
    fontWeight: '700',
    textAlign: 'center',
  },
});
export default common;
