import React, { useState, useEffect, useCallback } from 'react';
import { Box, TextField } from '@material-ui/core';
import { object, func } from 'prop-types';
import { FormClose } from 'grommet-icons';

const ENTER = 13;

const debounce = (fn, wait, immediate) => {
  let timeout;
  return (...args) => {
    const later = () => {
      timeout = null;
      if (!immediate) fn(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (immediate && !timeout) fn(...args);
  };
};

const ColumnFilter = ({ filter, onFiltering, onClose }) => {
  const [current, setCurrent] = useState(filter.value || '');
  const onChange = useCallback(
    debounce(value => {
      if (value.trim()) {
        onFiltering({ ...filter, operator: 'substring', value });
      }
    }, 250),
    []
  );

  useEffect(() => {
    onChange(current);
  }, [onChange, current]);

  return (
    <Box width="250px">
      <Box p={3}>
        <Box>
          {filter.type === 'input' && (
            <TextField
              autoFocus
              variant="outlined"
              value={current}
              onChange={({ target: { value } }) => setCurrent(value)}
              onKeyUp={({ keyCode }) => {
                if (keyCode === ENTER) {
                  onClose();
                  onFiltering({
                    ...filter,
                    operator: 'substring',
                    value: current,
                  });
                }
              }}
              InputProps={{
                endAdornment: (
                  <FormClose
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      onClose();
                      onFiltering({
                        ...filter,
                        operator: 'substring',
                        value: 'all',
                      });
                    }}
                  />
                ),
              }}
              fullWidth
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

ColumnFilter.propTypes = {
  onFiltering: func,
  onClose: func,
  // eslint-disable-next-line react/forbid-prop-types
  filter: object,
};
export default ColumnFilter;
