import {
  FETCH_REBATE_LIST_ERROR,
  FETCH_REBATE_LIST_SUCCESS,
  FETCH_REBATE_LIST_REQUEST,
  CREATE_COMBO_REBATE_REQUEST,
  CREATE_COMBO_REBATE_SUCCESS,
  CREATE_COMBO_REBATE_ERROR,
  UPDATE_REBATE_ERROR,
  UPDATE_REBATE_REQUEST,
  DELETE_REBATE_ERROR,
  DELETE_REBATE_REQUEST,
  DELETE_REBATE_SUCCESS,
  FETCH_MATERIAL_IDENTIFIER_SUCCESS,
} from './actions';
import { REDUCERS, DATE_FORMAT_STRINGS } from '../../common/constants';
import { createReducer, getFormattedDateForForm } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  list: {
    loading: false,
    error: false,
    success: false,
    response: { data: [], pagination: {}, materialIdentifers: [] },
  },
};

const reducer = {
  [CREATE_COMBO_REBATE_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
      success: false,
    },
  }),
  [CREATE_COMBO_REBATE_SUCCESS]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.SUCCESS,
      success: true,
      error: false,
    },
  }),
  [CREATE_COMBO_REBATE_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
      success: false,
    },
  }),
  [FETCH_REBATE_LIST_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_REBATE_LIST_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => {
    const filteredList = data
      ? data.map(val => {
          val.startDate = getFormattedDateForForm(
            val.startDate,
            DATE_FORMAT_STRINGS.TABLE_DATE_FORMAT
          );
          val.endDate = getFormattedDateForForm(
            val.endDate,
            DATE_FORMAT_STRINGS.TABLE_DATE_FORMAT
          );
          val.rebateType = val.rebateType === 1 ? 'Special' : 'Combo';
          val.switchStatus = true;
          return val;
        })
      : [];
    return {
      ...state,
      list: {
        ...REDUCERS.SUCCESS,
        success: false,
        response: {
          data: payload?.pagination?.infinite
            ? [...state.list.response.data, ...filteredList]
            : filteredList,
          pagination,
          payload,
        },
      },
    };
  },

  [FETCH_REBATE_LIST_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),

  [UPDATE_REBATE_REQUEST]: state => ({
    ...state,
    list: {
      ...REDUCERS.LOADING,
      response: state.list.response,
    },
  }),
  [UPDATE_REBATE_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [DELETE_REBATE_REQUEST]: state => ({
    ...state,
    list: {
      ...REDUCERS.LOADING,
      response: state.list.response,
    },
  }),

  [DELETE_REBATE_SUCCESS]: (state, { payload: data }) => {
    return {
      ...state,
      list: {
        ...REDUCERS.SUCCESS,
        response: {
          data: state.list.response.data.filter(item => {
            return item.id !== data.id;
          }),
        },
      },
    };
  },

  [DELETE_REBATE_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),

  [FETCH_MATERIAL_IDENTIFIER_SUCCESS]: (state, { payload }) => {
    const data = {
      ...state,
      list: {
        ...REDUCERS.SUCCESS,
        success: false,
        response: {
          ...state.list.response,
          materialIdentifers: payload,
        },
      },
    };
    return data;
  },
};

export default createReducer(schema, reducer);
