/* eslint-disable react/forbid-prop-types */

import React from 'react';
import { Box, Button } from '@material-ui/core';
import { func, number, bool } from 'prop-types';
import {
  ChapterPrevious,
  FormPrevious,
  ChapterNext,
  FormNext,
} from 'grommet-icons';
import { PAGE_SIZE } from '../../common/constants';
import Select from '../Select';
import { Pagination } from '../../common/prop-types';

const EMPTY = '--';

const DataTablePagination = ({
  pagination: { totalCount, hasNext, quantity, pageSize, pageNumber },
  onPagination,
  loading,
}) => {
  const options = PAGE_SIZE.map(size => ({
    id: `${size}`,
    value: `${size}`,
  }));

  const from = 1 + (pageNumber - 1) * pageSize;
  const to = (pageNumber - 1) * pageSize + quantity;
  const previous = pageNumber - 1;
  const next = pageNumber + 1;
  const last = Math.ceil(totalCount / parseInt(pageSize, 10));
  return (
    <Box
      p={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={loading ? { pointerEvent: 'none', opacity: 0.5 } : undefined}
    >
      <Button
        onClick={() => onPagination({ pageNumber: 1 })}
        disabled={pageNumber === 1}
      >
        <ChapterPrevious />
      </Button>
      <Button
        onClick={() => onPagination({ pageNumber: previous })}
        disabled={pageNumber === 1}
      >
        <FormPrevious />
      </Button>
      <Box fontWeight="bold" display="inline">
        {totalCount ? `${from} - ${totalCount > to ? to : totalCount}` : EMPTY}
      </Box>
      <Box display="inline" pl={1} pr={1}>
        of
      </Box>
      <Box display="inline">{totalCount || EMPTY} results</Box>
      <Button
        onClick={() => onPagination({ pageNumber: next })}
        disabled={!hasNext}
      >
        <FormNext />
      </Button>
      <Button
        onClick={() => onPagination({ pageNumber: last })}
        disabled={!hasNext}
      >
        <ChapterNext />
      </Button>
      <Box ml={2} />
      <Select
        options={options}
        valueKey="id"
        valueLabel="value"
        onChange={value => onPagination({ pageSize: value })}
        value={`${pageSize}`}
        variant="outlined"
      />
      <Box ml={1} display="inline">
        results per page
      </Box>
    </Box>
  );
};
DataTablePagination.propTypes = {
  pageNumber: number,
  pageSize: number,
  quantity: number,
  totalCount: number,
  hasNext: bool,
  pagination: Pagination,
  loading: bool,
  onPagination: func,
};

export default DataTablePagination;
