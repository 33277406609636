import React, { useMemo, useState, useEffect } from 'react';
import { string, arrayOf, object, func, oneOfType, array } from 'prop-types';
import Select from './Select';
import AutoComplete from './AutoComplete';

const Base = ({
  variant = 'outlined',
  type = 'select',
  options: _options = [],
  onChange = () => {},
  valueKey,
  valueLabel,
  value,
  ...props
}) => {
  const [options, setOptions] = useState(_options);
  const isMultiple = type === 'multi-select';
  const selected = useMemo(() => {
    if (type === 'multi-select') {
      return options.filter(option => value.includes(option[valueKey])) || [];
    }
    const obj = options.find(option => option[valueKey] === value);
    return obj || '';
  }, [options, value, valueKey, type]);

  useEffect(() => {
    setOptions(_options);
  }, [_options]);

  return type === 'autocomplete' || type === 'multi-select' ? (
    <AutoComplete
      multiple={isMultiple}
      selected={selected}
      options={options}
      valueKey={valueKey}
      valueLabel={valueLabel}
      onChange={onChange}
      variant={variant}
      {...props}
    />
  ) : (
    <Select
      type={type}
      selected={selected}
      options={options}
      valueKey={valueKey}
      valueLabel={valueLabel}
      onChange={onChange}
      value={value}
      variant={variant}
      multiple={isMultiple}
      {...props}
    />
  );
};
Base.propTypes = {
  options: arrayOf(object).isRequired,
  value: oneOfType([array, string]),
  onChange: func,
  valueKey: string,
  valueLabel: string,
  type: string,
  variant: string,
};

export default Base;
