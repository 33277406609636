import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import Breadcrumbs from './Breadcrumbs';
// import { useUserData } from '../../../common/hooks';
import { IS_NPI_ENVIROMENT, themeColor } from '../../../common/constants';

const Header = () => {
  // const { isNPIEnvironment } = useUserData();
  const theme = useTheme();
  const { typography } = theme;
  // const npiStyle = isNPIEnvironment ? `block` : `none`;
  const npiStyle = IS_NPI_ENVIROMENT ? `block` : `none`;
  return (
    <Box display="flex" p={2}>
      <Box flexGrow={1}>
        <Breadcrumbs />
      </Box>
      <Box fontSize={typography.appTitle} mb={1} fontWeight="bold">
        <span style={{ color: themeColor, display: npiStyle }}>
          NPI Environment
        </span>
      </Box>
    </Box>
  );
};

export default Header;
