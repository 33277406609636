import moment from 'moment';

import {
  FETCH_QUOTE_SCHEDULE_LIST_REQUEST,
  FETCH_QUOTE_SCHEDULE_LIST_SUCCESS,
  FETCH_QUOTE_SCHEDULE_LIST_ERROR,
  FETCH_QUOTE_SCHEDULE_DATA,
  UPDATE_CALENDER_DATE,
  UPDATE_LIST_DATE,
  ADD_SCHEDULE,
} from './actions';

import { REDUCERS } from '../../common/constants';
import { createReducer } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  list: {
    loading: false,
    error: false,
    tabs: [],
    data: [],
    rows: [],
    columns: [],
    filter: {},
  },
};

const reducer = {
  // Quote Options
  [FETCH_QUOTE_SCHEDULE_LIST_REQUEST]: state => {
    return {
      ...state,
      list: {
        ...state.list,
        loading: true,
      },
    };
  },
  [FETCH_QUOTE_SCHEDULE_LIST_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      list: {
        ...REDUCERS.SUCCESS,
        tabs: payload,
      },
    };
  },
  [FETCH_QUOTE_SCHEDULE_DATA]: (state, { payload }) => {
    const columns = payload.reduce(
      (acc, data, index) => {
        const actionValue =
          data.eventName.replace(/\s/g, '') === 'EffectiveFrom'
            ? data.eventName.replace(/\s/g, '')
            : `${data.eventName.replace(/\s/g, '')}${index}`;
        return [
          ...acc,
          {
            eventName: data.eventName,
            sequence: data.priority,
            action: actionValue,
            displayData: true,
          },
        ];
      },
      [
        {
          eventName: 'Year',
          action: 'year',
          type: 'string',
          displayData: false,
        },
        {
          eventName: 'Month',
          action: 'month',
          displayData: true,
          type: 'string',
        },
      ]
    );

    const nextMonth = moment().add(1, 'M');

    const obj = {
      year: moment().year(),
      month: moment().month() + 1,
      actions: [],
      eventArray: [],
      clearedStatus: [],
    };

    payload.forEach((data, index) => {
      data.dayOfMonth = data.dayOfMonth ? data.dayOfMonth : 1;
      const overrideData = data.quoteScheduleOverrides.find(
        res =>
          res.effectiveYear === obj.year && res.effectiveMonth === obj.month
      );
      data.action =
        data.eventName.replace(/\s/g, '') === 'EffectiveFrom'
          ? data.eventName.replace(/\s/g, '')
          : `${data.eventName.replace(/\s/g, '')}${index}`;
      if (overrideData && !overrideData.day) {
        overrideData.day = 1;
        obj.clearedStatus = [...obj.clearedStatus, data.action];
      }
      obj.type = data.businessUnit.id;

      if (data.eventName.replace(/\s/g, '') === 'EffectiveFrom') {
        obj[data.action] = overrideData
          ? `${overrideData.month}/${overrideData.day}/${overrideData.year}`
          : `${nextMonth.month() + 1}/${data.dayOfMonth}/${nextMonth.year()}`;
      } else {
        obj[data.action] = overrideData
          ? `${overrideData.month}/${overrideData.day}/${overrideData.year}`
          : `${obj.month}/${data.dayOfMonth}/${obj.year}`;
      }

      obj.actions = [...obj.actions, data.action];
      obj.eventArray = [...obj.eventArray, data.eventName];
      obj[`${data.action}-quoteScheduleOverrides`] =
        data.quoteScheduleOverrides;
      obj[`${data.action}-id`] = data.id;
      obj[`${data.action}-dayOfMonth`] = data.dayOfMonth ? data.dayOfMonth : 1;
    });

    return {
      ...state,
      list: {
        ...state.list,
        columns,
        rows: obj,
      },
    };
  },
  [FETCH_QUOTE_SCHEDULE_LIST_ERROR]: state => {
    return {
      ...state,
      list: {
        ...REDUCERS.SUCCESS,
      },
    };
  },
  [UPDATE_CALENDER_DATE]: (state, { payload: { event, item, val } }) => {
    const updatedDate = moment(event).format('M/D/YY');

    const { list } = state;

    const rows = list.rows.map(row => {
      if (row.id === item.id) {
        row[val] = updatedDate;
        return row;
      }
      return row;
    });

    return { ...state, list: { ...state.list, rows } };
  },
  [UPDATE_LIST_DATE]: (state, { payload: { item, val, counter } }) => {
    const { list } = state;

    const rows = list.rows.map(row => {
      if (row.id === item.id) {
        row[val] = counter
          ? moment(row[val])
              .add(1, 'days')
              .format('M/D/YY')
          : moment(row[val])
              .subtract(1, 'days')
              .format('M/D/YY');
        return row;
      }
      return row;
    });

    return { ...state, list: { ...state.list, rows } };
  },
  [ADD_SCHEDULE]: (state, { payload: { payload: value } }) => {
    const data = {
      id: moment().unix(),
      type: value.businessUnit,
      bomUpload: moment(value.bomUploadReminder).format('M/D/YY'),
      bomCutoff: moment(value.bomUploadCutoff).format('M/D/YY'),
      costUpload: moment(value.costUploadReminder).format('M/D/YY'),
      costCutoff: moment(value.costUploadCutoff).format('M/D/YY'),
      varianceOpen: moment(value.varianceResolutionReminder).format('M/D/YY'),
      varianceCutoff: moment(value.varianceResolutionCutoff).format('M/D/YY'),
      ccsCutoff: moment(value.ccsUploadCutoff).format('M/D/YY'),
      quoteEffective: moment(value.quoteEffectiveDate).format('M/D/YY'),
      year: moment(value.standardMonth).format('YYYY'),
      month: moment(value.standardMonth).format('MMMM'),
      standardMonth: value.standardMonth,
    };

    return {
      ...state,
      list: { ...state.list, rows: [...state.list.rows, data] },
    };
  },
};

export default createReducer(schema, reducer);
