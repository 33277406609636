import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

const WrappedPickerProvider = props => <MuiPickersUtilsProvider {...props} />;
WrappedPickerProvider.muiName = 'MuiPickersUtilsProvider';

const WrappedDatapicker = props => <KeyboardDatePicker {...props} />;
WrappedDatapicker.muiName = 'KeyboardDatePicker';

export default function Datepicker(props) {
  return (
    <WrappedPickerProvider libInstance={moment} utils={MomentUtils}>
      <WrappedDatapicker {...props} />
    </WrappedPickerProvider>
  );
}
