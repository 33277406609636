import {
  FETCH_COMMODITY_LIST_REQUEST,
  FETCH_COMMODITY_LIST_SUCCESS,
  FETCH_COMMODITY_LIST_ERROR,
  UPDATE_COMMODITY_REQUEST,
  UPDATE_COMMODITY_SUCCESS,
  UPDATE_COMMODITY_ERROR,
  DELETE_COMMODITY_REQUEST,
  DELETE_COMMODITY_SUCCESS,
  DELETE_COMMODITY_ERROR,
} from './actions';
import { REDUCERS } from '../../common/constants';
import { createReducer } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  list: {
    loading: false,
    error: false,
    response: { data: [], pagination: {} },
  },
};

const reducer = {
  // Commodity
  [FETCH_COMMODITY_LIST_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_COMMODITY_LIST_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => ({
    ...state,
    list: {
      ...REDUCERS.SUCCESS,
      response: {
        pagination,
        data: payload?.pagination?.infinite
          ? [...state.list.response.data, ...data]
          : data,
        payload,
      },
    },
  }),
  [FETCH_COMMODITY_LIST_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),

  [UPDATE_COMMODITY_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [UPDATE_COMMODITY_SUCCESS]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.SUCCESS,
    },
  }),
  [UPDATE_COMMODITY_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),

  [DELETE_COMMODITY_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),

  [DELETE_COMMODITY_SUCCESS]: state => ({
    ...state,
    ...REDUCERS.SUCCESS,
  }),

  [DELETE_COMMODITY_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
};

export default createReducer(schema, reducer);
