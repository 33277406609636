import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, Drawer, Box, makeStyles } from '@material-ui/core';
import { string } from 'prop-types';
import logo from '../../../assets/hpeLogo.svg';
import NavItem from './NavItem';
import { getAuthenticatedRoutesForUserRoles } from '../../../common/helpers';
import { useUserData } from '../../../common/hooks';

const styles = () => ({
  drawerList: {
    backgroundColor: '#435664',
    '& .MuiListItem-gutters': {
      paddingLeft: '12px',
    },
  },
  drawerPaper: {
    backgroundColor: '#435664',
    border: 'none',
    height: '100%',
  },
  listIcon: {
    margin: 'auto',
  },
  sidebarIcon: {
    padding: 'auto',
  },
  employeeIcon: {
    margin: '3px',
  },
  employeeSection: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    '& p': {
      color: '#F8F8F8',
      textAlign: 'center',
    },
  },
});

const useStyles = makeStyles(styles);

const Sidebar = ({ className }) => {
  const classes = useStyles();

  const { commonName, roles } = useUserData();
  const [firstName = '', lastName = ''] = commonName.split(' ');
  const initials = `${firstName[0] || ''}${lastName[0] || ''}`.toUpperCase();
  return (
    <Drawer
      className={className}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <Box component={NavLink} to="/" flexGrow={0} py={4} textAlign="center">
        <div
          style={{
            height: '84px',
            width: '84px',
          }}
        >
          <img style={{ width: '50%' }} src={logo} alt="hpeRobot" />
        </div>
      </Box>
      <List className={classes.drawerList}>
        {getAuthenticatedRoutesForUserRoles(roles).map(data => (
          <NavItem
            icon={<data.icon color="#ffffff" size="small" aling="center" />}
            label={data.name}
            path={data.path}
            key={data.name}
          />
        ))}
      </List>
      <NavLink
        to="/"
        className={classes.employeeSection}
        style={{
          position: 'fixed',
          left: '0',
          bottom: '0',
        }}
      >
        <Box
          p={1}
          m={2}
          mt={4}
          bgcolor="primary.main"
          color="#ffffff"
          borderRadius="50%"
          textAlign="center"
        >
          <h5 weight="bold" size="large" className={classes.employeeIcon}>
            {initials}
          </h5>
        </Box>
      </NavLink>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: string,
};

export default Sidebar;
