import {
  PROGRAM_ROOT, // '/programs'
  PROGRAM_DETAILS, // '/programs/:id'
  PROGRAM_MATERIALS, // '/programs/:id/materials'
  ADMIN_ROOT, // '/admin'
  ADMIN_PROGRAMS, // '/admin/programs'
  ADMIN_PLANTS, // '/admin/plants'
  ADMIN_COST_GROUP, // '/admin/cost_group'
  ADMIN_NEGOTIATED_COST_UPLOAD, // '/admin/negotiated_cost_upload'
  BOM_ROOT, // '/bom'
  COST_ROOT, // '/cost'
  COST_EXCHANGE, // '/cost/exchange'
  COST_FINANCIAL_BURDEN, // '/cost/financial_burden'
  COST_PCC, // '/cost/pcc'
  COST_REBATES, // '/cost/rebates'
  COST_PLANT_PREF, // '/cost/plant_preference'
  COST_CONDITIONS, // '/cost/conditions'
  COST_COMMODITIES, // '/admin/commodities'
  DATA_ROOT, // '/data'
  QUOTE_ROOT, // '/quotes'
  QUOTE, // '/quotes/:id/view'
  QUOTE_PROCUREMENT, // '/quotes/:id/view/procurement'
  QUOTE_MANUFACTURING, // '/quotes/:id/view/manufacturing'
  QUOTE_MANUFACTURING_BOM_VIEW, // '/quotes/:programId/view/:materialId/bom'
  QUOTE_DISTRIBUTION,
  QUOTE_VARIANCE,
  REPORT_TEMPLATES,
  REPORT_DETAILS,
  VARIANCE_ROOT,
  ADMIN_VENDOR_MAPS, // '/variances'
  // QUOTES_SCHEDULES, // '/quote-schedules/:id?'
  // QUOTE_SCHEDULES_LIST, // '/schedule-list'
} from './routes';

const MY_PROGRAMS = { label: 'My Programs', link: PROGRAM_ROOT };
const ADMIN = { label: 'Admin', link: ADMIN_ROOT };
const COST = { label: 'Cost', link: COST_ROOT };
const MY_QUOTES = { label: 'My Quotes', link: QUOTE_ROOT };
const MY_REPORTS = { label: 'Reports', link: REPORT_TEMPLATES };

export const URL_ALIASES = [
  { url: PROGRAM_ROOT, levels: ['My Programs'] },
  {
    url: PROGRAM_DETAILS,
    levels: [MY_PROGRAMS, { id: null }],
  },
  {
    url: PROGRAM_MATERIALS,
    levels: [MY_PROGRAMS, { id: null, link: PROGRAM_DETAILS }, 'Materials'],
  },
  { url: ADMIN_ROOT, levels: ['Admin'] },
  { url: ADMIN_PROGRAMS, levels: [ADMIN, 'Programs'] },
  { url: ADMIN_PLANTS, levels: [ADMIN, 'Plants'] },
  { url: ADMIN_COST_GROUP, levels: [ADMIN, 'Cost Sequnce Group'] },
  {
    url: ADMIN_NEGOTIATED_COST_UPLOAD,
    levels: [ADMIN, 'Negotiated Cost Upload '],
  },
  { url: BOM_ROOT, levels: ['BOM'] },
  { url: COST_ROOT, levels: ['Cost'] },
  { url: COST_EXCHANGE, levels: [COST, 'Exchange'] },
  { url: COST_FINANCIAL_BURDEN, levels: [COST, 'Financial Burden'] },
  { url: COST_PCC, levels: [COST, 'PCC'] },
  { url: COST_REBATES, levels: [COST, 'Combo Rebates'] },
  { url: COST_PLANT_PREF, levels: [COST, 'Plant Preferences'] },
  { url: COST_CONDITIONS, levels: [COST, 'Conditions'] },
  { url: COST_COMMODITIES, levels: [COST, 'Commodities'] },
  { url: DATA_ROOT, levels: ['Data'] },
  { url: QUOTE_ROOT, levels: ['My Quotes'] },
  { url: QUOTE, levels: [MY_QUOTES, { id: null }] },
  { url: VARIANCE_ROOT, levels: ['My Variances'] },
  {
    url: QUOTE_VARIANCE,
    levels: ['My Variances', 'My quotes', { id: null, link: QUOTE }],
  },
  { url: REPORT_DETAILS, levels: [MY_REPORTS, { type: null }] },
  {
    url: QUOTE_PROCUREMENT,
    levels: [MY_QUOTES, { id: null, link: QUOTE }, 'Procurement'],
  },
  {
    url: QUOTE_MANUFACTURING,
    levels: [MY_QUOTES, { id: null, link: QUOTE }, 'Manufacturing'],
  },
  {
    url: QUOTE_MANUFACTURING_BOM_VIEW,
    levels: [
      MY_QUOTES,
      { programId: null, link: QUOTE, alias: { programId: 'id' } },
      'Material',
      { materialId: null },
    ],
  },
  {
    url: QUOTE_DISTRIBUTION,
    levels: [MY_QUOTES, { id: null, link: QUOTE }, 'Distribution'],
  },
  {
    url: ADMIN_VENDOR_MAPS,
    levels: [ADMIN, 'Vendor Maps '],
  },
];
