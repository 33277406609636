import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/commodities`;

const commodityService = {
  getAllCommodities: payload => {
    return service.get(`${api}`, payload);
  },
  getCommodity: id => {
    return service.get(`${api}/${id}`);
  },
  createCommodity: data => {
    return service.post(`${api}`, data);
  },
  updateCommodity: (id, data) => {
    return service.patch(`${api}/${id}`, data);
  },
  deleteCommodity: id => {
    return service.remove(`${api}/${id}`);
  },
};
export default commodityService;
