import React, { useEffect, useMemo, useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';

import { TextField, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const styles = () => ({
  input: { fontWeight: '600' },
});

const SearchAutoComplete = ({
  variant = 'outlined',
  options: _options = [],
  onChange = () => {},
  valueKey,
  value,
  valueLabel,
  ...props
}) => {
  const [options, setOptions] = useState(_options);
  const selected = useMemo(() => {
    const index = options.findIndex(option => option[valueKey] === value);
    return index >= 0 ? index : undefined;
  }, [options, value, valueKey]);

  useEffect(() => {
    setOptions(_options);
  }, [_options]);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={option => option.name}
      inputValue={
        selected !== undefined ? options[selected][valueLabel] : undefined
      }
      renderInput={params => <TextField {...params} variant={variant} />}
      onChange={(event, values) => {
        onChange(values[valueKey]);
      }}
      {...props}
    />
  );
};
SearchAutoComplete.propTypes = {
  variant: string,
  disabledStatus: bool,
  options: arrayOf(object).isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
  valueKey: string,
  valueLabel: string,
};

export default withStyles(styles)(SearchAutoComplete);
