import * as service from '../common/service';
import { CALCULATIONS } from '../common/constants';

const api = `${CALCULATIONS}/cost-fields`;

const costFieldsService = {
  getAll: () => {
    return service.get(`${api}`);
  },
  getFilteredList: () => {
    return service.get(`${api}/filter-list`);
  },
};

export default costFieldsService;
