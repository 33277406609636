import * as service from '../common/service';
import { COLLABORATION } from '../common/constants';

const threadAPI = `${COLLABORATION}/threads`;
const createMultiThreadAPI = `${COLLABORATION}/threads/createMultiThreads`;
const messagesAPI = `${COLLABORATION}/messages`;
const messagesCreateAPI = `${COLLABORATION}/messages/batch`;
const commentsService = {
  getCommentsList: params => {
    return service.get(`${threadAPI}`, params);
  },
  createOrGetThread: params => {
    return service.post(`${threadAPI}`, params);
  },
  getMessagesList: params => {
    return service.get(`${messagesAPI}`, params);
  },
  addComment: params => {
    return service.post(`${messagesCreateAPI}`, params);
  },
  createOrGetMultiThread: params => {
    return service.post(`${createMultiThreadAPI}`, params);
  },
};
export default commentsService;
