import * as service from '../common/service';
import { CALCULATIONS } from '../common/constants';

const api = `${CALCULATIONS}/cost-group-sequences`;

const costGroupSequenceService = {
  getCostGroupSequenceData: payload => {
    return service.get(`${api}/admin`, payload);
  },
  createCostSequenceGroup: data => {
    return service.post(`${api}`, data);
  },
  updateCostSequenceGroup: (id, data) => {
    return service.put(`${api}/${id}`, data);
  },
  deleteCostSequenceGroup: id => {
    return service.remove(`${api}/${id}`);
  },
};

export default costGroupSequenceService;
