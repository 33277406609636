import React from 'react';
import {
  string,
  object,
  arrayOf,
  func,
  bool,
  oneOfType,
  array,
} from 'prop-types';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const getDefaultValue = (selected, multiple) => {
  if (selected !== undefined) {
    return selected;
  }
  return multiple ? [] : {};
};

const Base = ({
  selected,
  options = [],
  multiple,
  valueKey,
  valueLabel,
  onChange,
  variant,
  ...props
}) => {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={option => option[valueLabel]}
      multiple
      onChange={(event, values) => {
        if (multiple) {
          onChange(
            values.map(el => {
              return el[valueKey];
            })
          );
        } else {
          onChange(values && values[valueKey] ? values[valueKey] : '');
        }
      }}
      value={getDefaultValue(selected, multiple)}
      renderInput={params => <TextField {...params} variant={variant} />}
      {...props}
    />
  );
};
Base.propTypes = {
  selected: oneOfType([array, string, object]),
  onChange: func,
  options: arrayOf(object).isRequired,
  valueKey: string,
  valueLabel: string,
  variant: string,
  multiple: bool,
  value: oneOfType([array, string]),
};
export default Base;
