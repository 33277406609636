// QUOTE List Actions
export const FETCH_QUOTE_SCHEDULE_LIST_REQUEST =
  'FETCH_QUOTE_SCHEDULE_LIST_REQUEST';
export const FETCH_QUOTE_SCHEDULE_LIST_SUCCESS =
  'FETCH_QUOTE_SCHEDULE_LIST_SUCCESS';
export const FETCH_QUOTE_SCHEDULE_LIST_ERROR =
  'FETCH_QUOTE_SCHEDULE_LIST_ERROR';

export const UPDATE_QUOTE_SCHEDULE_CALENDER = 'UPDATE_QUOTE_SCHEDULE_CALENDER';
export const UPDATE_CALENDER_DATE = 'UPDATE_CALENDER_DATE';

export const INCREMENT_AND_DECREMENT_DATE = 'INCREMENT_AND_DECREMENT_DATE';
export const UPDATE_LIST_DATE = 'UPDATE_LIST_DATE';
export const ADD_NEW_SCHEDULE = 'ADD_NEW_SCHEDULE';
export const ADD_SCHEDULE = 'ADD_SCHEDULE';

export const FETCH_QUOTE_SCHEDULE_REQUEST = 'FETCH_QUOTE_SCHEDULE_REQUEST';
export const FETCH_QUOTE_SCHEDULE_DATA = 'FETCH_QUOTE_SCHEDULE_DATA';
