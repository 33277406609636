/* eslint-disable no-unused-vars */
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, delay } from 'rxjs/operators';

import { quoteVariancesService, quoteLinesService } from '../../services';
import {
  dispatch,
  buildRouteParams,
  capitalizeFirstLetter,
} from '../../common/helpers';

import {
  FETCH_VARIANCE_COST_REQUEST,
  FETCH_VARIANCE_COST_SUCCESS,
  FETCH_VARIANCE_COST_ERROR,
  FETCH_VARIANCE_COST_HISTORY_REQUEST,
  FETCH_VARIANCE_COST_HISTORY_SUCCESS,
  FETCH_VARIANCE_COST_HISTORY_ERROR,
  FETCH_VARIANCE_QUANTITY_ERROR,
  FETCH_VARIANCE_QUANTITY_REQUEST,
  FETCH_VARIANCE_QUANTITY_SUCCESS,
  FETCH_VARIANCE_QUANTITY_HISTORY_ERROR,
  FETCH_VARIANCE_QUANTITY_HISTORY_REQUEST,
  FETCH_VARIANCE_QUANTITY_HISTORY_SUCCESS,
  ACCEPT_REQUEST_ACTION_VARIANCE_COST,
  REJECT_REQUEST_ACTION_VARIANCE_COST,
  REJECT_SUCCESS_ACTION_VARIANCE_COST,
  REJECT_ERROR_ACTION_VARIANCE_COST,
  UPDATE_REQUEST_ACTION_VARIANCE_COST,
  ACCEPT_REQUEST_ACTION_VARIANCE_QUANTITY,
  REJECT_REQUEST_ACTION_VARIANCE_QUANTITY,
  UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY,
  CREATE_COST_VARIANCE_REQUEST,
  CREATE_QUANTITY_VARIANCE_REQUEST,
  FETCH_PRIMARY_ALTERNATE_REQUEST,
  FETCH_PRIMARY_ALTERNATE_SUCCESS,
  FETCH_PRIMARY_ALTERNATE_ERROR,
  ACCEPT_REQUEST_DECREASE_VARIANCE_TYPE,
  FETCH_SHARED_PROCURED_MATERIALS_REQUEST,
  FETCH_SHARED_PROCURED_MATERIALS_SUCESSS,
  FETCH_SHARED_PROCURED_MATERIALS_ERROR,
  ACCEPT_SUCCESS_ACTION_VARIANCE_COST,
  ACCEPT_ERROR_ACTION_VARIANCE_COST,
  ACCEPT_SUCCESS_ACTION_VARIANCE_QUANTITY,
  ACCEPT_ERROR_ACTION_VARIANCE_QUANTITY,
  REJECT_SUCCESS_ACTION_VARIANCE_QUANTITY,
  UPDATE_SUCCESS_ACTION_VARIANCE_QUANTITY,
  UPDATE_ERROR_ACTION_VARIANCE_QUANTITY,
  UPDATE_SUCCESS_ACTION_VARIANCE_COST,
  UPDATE_ERROR_ACTION_VARIANCE_COST,
  ACCEPT_SUCCESS_DECREASE_VARIANCE_TYPE,
  ACCEPT_ERROR_DECREASE_VARIANCE_TYPE,
  CREATE_COST_VARIANCE_SUCCESS,
  CREATE_QUANTITY_VARIANCE_SUCESS,
  CREATE_COST_VARIANCE_ERROR,
  CREATE_QUANTITY_VARIANCE_ERROR,
  FETCH_VARIANCE_COST_UPDATE_LINE_REQUEST,
  FETCH_VARIANCE_COST_UPDATE_LINE_SUCCESS,
  FETCH_VARIANCE_COST_UPDATE_LINE_ERROR,
  FETCH_VARIANCE_QUANTITY_UPDATE_LINE_ERROR,
  FETCH_VARIANCE_QUANTITY_UPDATE_LINE_SUCCESS,
  FETCH_VARIANCE_QUANTITY_UPDATE_LINE_REQUEST,
} from './actions';

import {
  SUCCESS_NOTIFICATION_REQUEST,
  ERROR_NOTIFICATION_REQUEST,
} from '../notifications/actions';
import {
  MESSAGES,
  DEFAULT_PAGINATION_OBJECT,
  REDUCER_VARIANCES_TYPES,
} from '../../common/constants';

const dataAdapter = updatedObject => ({
  ...updatedObject,
  ...Object.fromEntries(
    updatedObject.material?.materialIdentifiers?.map(
      ({ ownedBy, identifier }) => [`${ownedBy}Identifier`, identifier]
    )
  ),
  materialDescription: updatedObject?.material?.description,
  materialType: updatedObject?.material?.type,
  plantCode: updatedObject?.plant?.code,
  sourcePlantCode: updatedObject?.sourcePlant?.code,
  costFieldName: updatedObject?.costField?.name,
  isHighlighted: updatedObject?.costGroupSequence?.uiBehavior === 'highlight',
});

export const fetchVarianceCost = (action$, store) =>
  action$.pipe(
    ofType(FETCH_VARIANCE_COST_REQUEST),
    mergeMap(({ payload }) =>
      quoteVariancesService.getAllQuoteVariances(payload).pipe(
        map(data =>
          dispatch(FETCH_VARIANCE_COST_SUCCESS, {
            ...data,
            payload,
          })
        )
      )
    ),
    catchError(err => [dispatch(FETCH_VARIANCE_COST_ERROR, err)])
  );

export const updateVarianceCostLine = (action$, store) =>
  action$.pipe(
    ofType(FETCH_VARIANCE_COST_UPDATE_LINE_REQUEST),
    mergeMap(({ payload }) =>
      quoteVariancesService
        .getAllQuoteVariances({
          filter: [
            {
              field: 'quoteVarianceId',
              value: payload.id,
              operator: payload.operator,
            },
          ],
        })
        .pipe(
          map(data =>
            dispatch(FETCH_VARIANCE_COST_UPDATE_LINE_SUCCESS, {
              ...data,
            })
          )
        )
    ),
    catchError(err => [dispatch(FETCH_VARIANCE_COST_UPDATE_LINE_ERROR, err)])
  );

export const fetchVarianceCostHistoryList = (action$, store) =>
  action$.pipe(
    ofType(FETCH_VARIANCE_COST_HISTORY_REQUEST),
    mergeMap(({ payload }) =>
      quoteVariancesService.getAllHistoryVariances(payload)
    ),
    map(data => dispatch(FETCH_VARIANCE_COST_HISTORY_SUCCESS, data)),
    catchError(err => [dispatch(FETCH_VARIANCE_COST_HISTORY_ERROR, err)])
  );

export const fetchVarianceQuantity = (action$, store) =>
  action$.pipe(
    ofType(FETCH_VARIANCE_QUANTITY_REQUEST),
    mergeMap(({ payload }) =>
      quoteVariancesService.getAllQuantityVariances(payload).pipe(
        map(data =>
          dispatch(FETCH_VARIANCE_QUANTITY_SUCCESS, {
            ...data,
            payload,
          })
        )
      )
    ),
    catchError(err => [dispatch(FETCH_VARIANCE_QUANTITY_ERROR, err)])
  );

export const fetchVarianceQuantityHistoryList = (action$, store) =>
  action$.pipe(
    ofType(FETCH_VARIANCE_QUANTITY_HISTORY_REQUEST),
    mergeMap(({ payload }) =>
      quoteVariancesService.getAllQuantityVariancesHistory(payload)
    ),
    map(data => dispatch(FETCH_VARIANCE_QUANTITY_HISTORY_SUCCESS, data)),
    catchError(err => [dispatch(FETCH_VARIANCE_QUANTITY_HISTORY_ERROR, err)])
  );

export const updateVarianceCost = (action$, store) =>
  action$.pipe(
    ofType(UPDATE_REQUEST_ACTION_VARIANCE_COST),
    mergeMap(({ payload }) => {
      return quoteVariancesService.updateVarianceCost(payload).pipe(
        map(({ response }) => {
          let updatedObject = response?.response?.updatedObject;
          if (REDUCER_VARIANCES_TYPES.COST !== payload.type) {
            updatedObject = dataAdapter(updatedObject);
          }
          return dispatch(UPDATE_SUCCESS_ACTION_VARIANCE_COST, {
            ...payload,
            updatedObject,
          });
        }),
        catchError(err => [
          dispatch(UPDATE_ERROR_ACTION_VARIANCE_COST, payload),
        ])
      );
    })
  );

export const acceptVarianceCost = (action$, store) =>
  action$.pipe(
    ofType(ACCEPT_REQUEST_ACTION_VARIANCE_COST),
    mergeMap(({ payload }) =>
      quoteVariancesService.acceptVarianceCost(payload).pipe(
        map(data => dispatch(ACCEPT_SUCCESS_ACTION_VARIANCE_COST, payload)),
        catchError(err => [
          dispatch(ACCEPT_ERROR_ACTION_VARIANCE_COST, payload),
        ])
      )
    )
  );

export const rejectVarianceCost = (action$, store) =>
  action$.pipe(
    ofType(REJECT_REQUEST_ACTION_VARIANCE_COST),
    mergeMap(({ payload }) =>
      quoteVariancesService.rejectVarianceCost(payload).pipe(
        map(data => {
          return dispatch(REJECT_SUCCESS_ACTION_VARIANCE_COST, payload);
        }),
        catchError(err => [
          dispatch(REJECT_ERROR_ACTION_VARIANCE_COST, payload),
        ])
      )
    )
  );

export const updateVarianceQuantity = (action$, store) =>
  action$.pipe(
    ofType(UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY),
    mergeMap(({ payload }) => {
      return quoteVariancesService.updateVarianceQuantity(payload).pipe(
        map(({ response }) => {
          const updatedObject = response?.response?.updatedObject;
          return dispatch(UPDATE_SUCCESS_ACTION_VARIANCE_QUANTITY, {
            ...payload,
            updatedObject,
          });
        }),
        catchError(err => [
          dispatch(UPDATE_ERROR_ACTION_VARIANCE_QUANTITY, payload),
        ])
      );
    })
  );

export const acceptVarianceQuantity = (action$, store) =>
  action$.pipe(
    ofType(ACCEPT_REQUEST_ACTION_VARIANCE_QUANTITY),
    mergeMap(({ payload }) =>
      quoteVariancesService.acceptVarianceQuantity(payload).pipe(
        map(data => dispatch(ACCEPT_SUCCESS_ACTION_VARIANCE_QUANTITY, payload)),
        catchError(err => [
          dispatch(ACCEPT_ERROR_ACTION_VARIANCE_QUANTITY, payload),
        ])
      )
    )
  );

export const rejectVarianceQuantity = (action$, store) =>
  action$.pipe(
    ofType(REJECT_REQUEST_ACTION_VARIANCE_QUANTITY),
    mergeMap(({ payload }) =>
      quoteVariancesService.rejectVarianceQuantity(payload).pipe(
        map(data => dispatch(REJECT_SUCCESS_ACTION_VARIANCE_QUANTITY, payload)),
        catchError(err => [
          dispatch(REJECT_SUCCESS_ACTION_VARIANCE_QUANTITY, payload),
        ])
      )
    )
  );

export const acceptDecreaseVarianceType = (action$, store) =>
  action$.pipe(
    ofType(ACCEPT_REQUEST_DECREASE_VARIANCE_TYPE),
    mergeMap(({ payload }) => {
      return quoteVariancesService.acceptDecreaseVarianceType(payload).pipe(
        map(data => dispatch(ACCEPT_SUCCESS_DECREASE_VARIANCE_TYPE, payload)),
        catchError(err => [
          dispatch(ACCEPT_ERROR_DECREASE_VARIANCE_TYPE, payload),
        ])
      );
    })
  );

export const createCostVariance = action$ =>
  action$.pipe(
    ofType(CREATE_COST_VARIANCE_REQUEST),
    mergeMap(({ payload: { values, payload, type } }) => {
      return quoteVariancesService.createCostVariance(values).pipe(
        mergeMap(() =>
          quoteLinesService
            .getQuoteLines({
              filter: [
                {
                  field: 'materialQuoteId',
                  value: values.materialQuoteId,
                  operator: 'eq',
                },
                ...(type !== REDUCER_VARIANCES_TYPES.COST
                  ? [
                      {
                        field: 'materialQuoteOperation',
                        value: capitalizeFirstLetter(type),
                        operator: 'eq',
                      },
                    ]
                  : []),
              ],
            })
            .pipe(
              map(response =>
                dispatch(CREATE_COST_VARIANCE_SUCCESS, {
                  ...payload,
                  quoteId: values.materialQuoteId,
                  type,
                  updatedObject: dataAdapter(response?.data[0] || {}),
                })
              )
            )
        ),
        catchError(err => [dispatch(CREATE_COST_VARIANCE_ERROR, payload)])
      );
    })
  );

export const createQuantityVariance = action$ =>
  action$.pipe(
    ofType(CREATE_QUANTITY_VARIANCE_REQUEST),
    mergeMap(({ payload: { values, payload, requestType } }) => {
      return quoteVariancesService.createQuantityVariance(values).pipe(
        map(({ response }) => {
          const updatedObject = response?.response?.updatedObject;
          return dispatch(CREATE_QUANTITY_VARIANCE_SUCESS, {
            ...payload,
            updatedObject,
          });
        }),
        catchError(err => [dispatch(CREATE_QUANTITY_VARIANCE_ERROR, payload)])
      );
    })
  );

export const fetchPrimaryAlternate = action$ =>
  action$.pipe(
    ofType(FETCH_PRIMARY_ALTERNATE_REQUEST),
    mergeMap(({ payload }) =>
      quoteVariancesService.getAllPrimaryAlternate(payload).pipe(
        map(data =>
          dispatch(FETCH_PRIMARY_ALTERNATE_SUCCESS, {
            ...data,
            payload,
          })
        )
      )
    ),
    catchError(err => [dispatch(FETCH_PRIMARY_ALTERNATE_ERROR, err)])
  );

export const fetchSharedProcuredMaterials = (action$, store) =>
  action$.pipe(
    ofType(FETCH_SHARED_PROCURED_MATERIALS_REQUEST),
    mergeMap(({ payload: { materialId, plantId } }) =>
      quoteVariancesService.getSharedProcuredMaterials(materialId, plantId)
    ),
    map(data => dispatch(FETCH_SHARED_PROCURED_MATERIALS_SUCESSS, data)),
    catchError(err => [dispatch(FETCH_SHARED_PROCURED_MATERIALS_ERROR, err)])
  );

export const updateVarianceMaterialLine = (action$, store) =>
  action$.pipe(
    ofType(FETCH_VARIANCE_QUANTITY_UPDATE_LINE_REQUEST),
    mergeMap(({ payload }) =>
      quoteVariancesService
        .getAllQuantityVariances({
          filter: [
            {
              field: 'materialVarianceVarianceId',
              value: payload.id,
              operator: payload.operator,
            },
          ],
        })
        .pipe(
          map(data =>
            dispatch(FETCH_VARIANCE_QUANTITY_UPDATE_LINE_SUCCESS, {
              ...data,
            })
          )
        )
    ),
    catchError(err => [
      dispatch(FETCH_VARIANCE_QUANTITY_UPDATE_LINE_ERROR, err),
    ])
  );
