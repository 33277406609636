import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/plants`;

export const getAllPlants = payload => service.get(`${api}`, payload);

export const getPlant = id => service.get(`${api}/${id}`);

export const createPlant = data => service.post(`${api}`, data);

export const updatePlant = (id, data) => service.put(`${api}/${id}`, data);

export const deletePlant = id => service.remove(`${api}/${id}`);

export default {
  getAllPlants,
  getPlant,
  createPlant,
  updatePlant,
  deletePlant,
};
