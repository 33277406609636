/* eslint-disable no-unused-vars */
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { bomService, quoteVariancesService } from '../../services';
import { dispatch } from '../../common/helpers';

import {
  GET_BOM_LIST_REQUEST,
  GET_BOM_LIST_SUCCESS,
  GET_BOM_LIST_ERROR,
  UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY_BOM,
  UPDATE_SUCCESS_ACTION_VARIANCE_QUANTITY_BOM,
  UPDATE_ERROR_ACTION_VARIANCE_QUANTITY_BOM,
  CREATE_QUANTITY_VARIANCE_REQUEST_BOM,
  CREATE_QUANTITY_VARIANCE_SUCESS_BOM,
  CREATE_QUANTITY_VARIANCE_ERROR_BOM,
} from './actions';

export const getBomList = action$ => {
  return action$.pipe(
    ofType(GET_BOM_LIST_REQUEST),
    mergeMap(({ payload: qPayload }) => {
      const { programQuoteId, plantId, payload } = qPayload;
      return bomService.getAllBom(programQuoteId, plantId, payload).pipe(
        map(({ data, ...response }) =>
          dispatch(GET_BOM_LIST_SUCCESS, {
            ...response,
            data,
            payload,
          })
        )
      );
    }),
    catchError(err => [dispatch(GET_BOM_LIST_ERROR, err)])
  );
};

export const updateBomVarianceQuantity = (action$, store) =>
  action$.pipe(
    ofType(UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY_BOM),
    mergeMap(({ payload }) => {
      return quoteVariancesService.updateVarianceQuantity(payload).pipe(
        mergeMap(() =>
          bomService
            .getAllBom(payload.action.programQuoteId, payload.action.plantId, {
              filter: [
                {
                  field: 'materialId',
                  value: payload.materialId,
                  operator: 'eq',
                },
                {
                  field: 'bomLineId',
                  operator: 'eq',
                  value: payload.newBomId,
                },
              ],
            })
            .pipe(
              map(response =>
                dispatch(UPDATE_SUCCESS_ACTION_VARIANCE_QUANTITY_BOM, {
                  ...payload,
                  updatedObject: response.data.find(x => !x.isParent),
                })
              )
            )
        ),
        catchError(err => [
          dispatch(UPDATE_ERROR_ACTION_VARIANCE_QUANTITY_BOM, payload),
        ])
      );
    })
  );

export const createBomQuantityVariance = action$ =>
  action$.pipe(
    ofType(CREATE_QUANTITY_VARIANCE_REQUEST_BOM),
    mergeMap(({ payload: { values, payload, bomLineId, type } }) => {
      return quoteVariancesService.createQuantityVariance(values).pipe(
        mergeMap(() =>
          bomService
            .getAllBom(payload.programQuoteId, payload.plantId, {
              filter: [
                {
                  field: 'materialId',
                  value: values.materialId,
                  operator: 'eq',
                },
                {
                  field: 'bomLineId',
                  operator: 'eq',
                  value: values.newBomId,
                },
              ],
            })
            .pipe(
              map(response =>
                dispatch(CREATE_QUANTITY_VARIANCE_SUCESS_BOM, {
                  ...payload,
                  bomLineId,
                  type,
                  updatedObject: response.data.find(x => !x.isParent),
                })
              )
            )
        ),
        catchError(err => [
          dispatch(CREATE_QUANTITY_VARIANCE_ERROR_BOM, payload),
        ])
      );
    })
  );
