import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/financial-burden`;

export const financialBurdenService = {
  getAllFB: payload => service.get(`${api}`, payload),
  getFBbyId: id => {
    return service.get(`${api}/${id}`);
  },
  createFB: data => {
    return service.post(`${api}`, data);
  },
  updateFB: (id, data) => {
    return service.patch(`${api}/${id}`, data);
  },
  deleteFB: id => {
    return service.remove(`${api}/${id}`);
  },
};
