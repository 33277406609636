import React from 'react';
import { element } from 'prop-types';
import { Switch, Route } from 'react-router';
import { useSelector } from 'react-redux';
import { Container, Box, AppBar, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import * as ROUTES from '../../common/routes';
import Sidebar from './Sidebar';
import Header from './Header';
import Breadcrumb from './Breadcrumb';
import { useComposeDispatcher } from '../../common/hooks';
import { RESET_NOTIFICATION_DATA } from '../../redux/notifications/actions';

const routes = Object.entries(ROUTES);

const Alert = props => <MuiAlert elevation={6} variant="filled" {...props} />;

const vertical = `top`;
const horizontal = `right`;
const sidebarWidth = '84px';

const useStyles = makeStyles({
  root: { width: '100vw', maxWidth: '100vw' },
  sidebar: {
    width: sidebarWidth,
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
  },
  content: {
    maxWidth: `calc(100vw - ${sidebarWidth})`,
  },
});

const Layout = ({ children }) => {
  const { success, message, error } = useSelector(
    ({ notifications }) => notifications
  );
  const [resetNotificationData] = useComposeDispatcher(RESET_NOTIFICATION_DATA);
  const classes = useStyles();
  return (
    <Container className={classes.root} disableGutters>
      <Box display="flex">
        <Box>
          <Sidebar className={classes.sidebar} />
        </Box>
        <Box flexGrow={1}>
          <AppBar position="static" color="default">
            <Header />
            <Switch>
              {routes.map(([key, value]) => (
                <Route key={key} path={value} exact>
                  <Breadcrumb route={value} />
                </Route>
              ))}
            </Switch>
          </AppBar>
          {message && (
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              key={`${vertical},${horizontal}`}
              onClose={resetNotificationData}
              open={(success || error) && !!message}
              autoHideDuration={2000}
            >
              <Alert severity={success ? `success` : 'error'}>{message}</Alert>
            </Snackbar>
          )}
          <Box className={classes.content}>{children}</Box>
        </Box>
      </Box>
    </Container>
  );
};

Layout.propTypes = {
  children: element,
};

export default Layout;
