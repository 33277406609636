// Conditions List Actions
export const FETCH_CONDITIONS_LIST_REQUEST = 'FETCH_CONDITIONS_LIST_REQUEST';
export const FETCH_CONDITIONS_LIST_SUCCESS = 'FETCH_CONDITIONS_LIST_SUCCESS';
export const FETCH_CONDITIONS_LIST_ERROR = 'FETCH_CONDITIONS_LIST_ERROR';

// Create CONDITIONS
export const CREATE_CONDITION_REQUEST = 'CREATE_CONDITION_REQUEST';
export const CREATE_CONDITION_SUCCESS = 'CREATE_CONDITION_SUCCESS';
export const CREATE_CONDITION_ERROR = 'CREATE_CONDITION_ERROR';

// Delete PCC
export const DELETE_CONDITION_REQUEST = 'DELETE_CONDITION_REQUEST';
export const DELETE_CONDITION_SUCCESS = 'DELETE_CONDITION_SUCCESS';
export const DELETE_CONDITION_ERROR = 'DELETE_CONDITION_ERROR';

// Update CONDITION
export const UPDATE_CONDITION_REQUEST = 'UPDATE_CONDITION_REQUEST';
export const UPDATE_CONDITION_SUCCESS = 'UPDATE_CONDITION_SUCCESS';
export const UPDATE_CONDITION_ERROR = 'UPDATE_CONDITION_ERROR';
