import React, { PureComponent } from 'react';
import { Drawer, Typography, Box } from '@material-ui/core';
import { shape, string, oneOfType, number, arrayOf, func } from 'prop-types';
import Button from '../../Button';
import Columns from './Columns';

class Settings extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  toggle = open => () => this.setState({ open });

  render() {
    const { pinned, unpinned, actions, onResize } = this.props;
    const { open } = this.state;

    return (
      <>
        <Button btnType="primary" onClick={this.toggle(true)}>
          Settings
        </Button>
        <Drawer anchor="right" open={open} onClose={this.toggle(false)}>
          <Box width="400px" m={2}>
            <Typography variant="h5">Settings</Typography>
            <Box mt={2}>
              <Columns
                pinned={pinned}
                unpinned={unpinned}
                actions={actions}
                onChange={(columns, settings) =>
                  this.setState({ open: false }, () =>
                    onResize(columns, settings)
                  )
                }
              />
            </Box>
          </Box>
        </Drawer>
      </>
    );
  }
}

const HeaderColumn = shape({
  key: string.isRequired,
  label: string,
  size: oneOfType([string, number]),
});

Settings.propTypes = {
  pinned: arrayOf(HeaderColumn),
  unpinned: arrayOf(HeaderColumn),
  actions: arrayOf(HeaderColumn),
  onResize: func,
};

export default Settings;
