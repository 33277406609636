import React from 'react';
import { Ascend, Descend } from 'grommet-icons';
import { Box } from '@material-ui/core';
import { string } from 'prop-types';
import { Transaction } from '../../../../common/constants';

const SortIcon = ({ orderBy }) => {
  let component = Transaction;
  if (orderBy) {
    component = orderBy === 'ASC' ? Ascend : Descend;
  }

  return <Box component={component} size="small" mb={0.5} ml={0.5} />;
};

SortIcon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  orderBy: string,
};

export default SortIcon;
