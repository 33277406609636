// Admin Plant List Actions
export const FETCH_PLANT_LIST_REQUEST = 'FETCH_PLANT_LIST_REQUEST';
export const FETCH_PLANT_LIST_SUCCESS = 'FETCH_PLANT_LIST_SUCCESS';
export const FETCH_PLANT_LIST_ERROR = 'FETCH_PLANT_LIST_ERROR';

// Create Plant
export const CREATE_PLANT_REQUEST = 'CREATE_PLANT_REQUEST';
export const CREATE_PLANT_SUCCESS = 'CREATE_PLANT_SUCCESS';
export const CREATE_PLANT_ERROR = 'CREATE_PLANT_ERROR';

// Delete Plant
export const DELETE_PLANT_REQUEST = 'DELETE_PLANT_REQUEST';
export const DELETE_PLANT_SUCCESS = 'DELETE_PLANT_SUCCESS';
export const DELETE_PLANT_ERROR = 'DELETE_PLANT_ERROR';

// Update Plant
export const UPDATE_PLANT_REQUEST = 'UPDATE_PLANT_REQUEST';
export const UPDATE_PLANT_SUCCESS = 'UPDATE_PLANT_SUCCESS';
export const UPDATE_PLANT_ERROR = 'UPDATE_PLANT_ERROR';
