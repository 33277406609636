import {
  GET_FINANCIAL_BURDEN_ERROR,
  GET_FINANCIAL_BURDEN_REQUEST,
  GET_FINANCIAL_BURDEN_SUCCESS,
  CREATE_FINANCIAL_BURDEN_REQUEST,
  CREATE_FINANCIAL_BURDEN_SUCCESS,
  CREATE_FINANCIAL_BURDEN_ERROR,
  UPDATE_FINANCIAL_BURDEN_REQUEST,
  UPDATE_FINANCIAL_BURDEN_SUCCESS,
  UPDATE_FINANCIAL_BURDEN_ERROR,
  DELETE_FINANCIAL_BURDEN_REQUEST,
  DELETE_FINANCIAL_BURDEN_SUCCESS,
  DELETE_FINANCIAL_BURDEN_ERROR,
} from './actions';

import { REDUCERS } from '../../common/constants';
import { createReducer } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  list: {
    loading: false,
    error: false,
    data: {
      data: [],
      pagination: {},
    },
  },
};

const reducer = {
  [GET_FINANCIAL_BURDEN_REQUEST]: state => ({
    ...state,
    list: {
      ...REDUCERS.LOADING,
      data: state.list.data,
    },
  }),
  [GET_FINANCIAL_BURDEN_SUCCESS]: (
    state,
    { payload: { data: newData, pagination, payload } }
  ) => ({
    ...state,
    list: {
      ...REDUCERS.SUCCESS,
      data: {
        pagination,
        data: payload?.pagination?.infinite
          ? [...state.list.data.data, ...newData]
          : newData,
        payload,
      },
    },
  }),
  [GET_FINANCIAL_BURDEN_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [CREATE_FINANCIAL_BURDEN_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [CREATE_FINANCIAL_BURDEN_SUCCESS]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.SUCCESS,
    },
  }),
  [CREATE_FINANCIAL_BURDEN_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [UPDATE_FINANCIAL_BURDEN_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [UPDATE_FINANCIAL_BURDEN_SUCCESS]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.SUCCESS,
    },
  }),
  [UPDATE_FINANCIAL_BURDEN_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [DELETE_FINANCIAL_BURDEN_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [DELETE_FINANCIAL_BURDEN_SUCCESS]: state => ({
    ...state,
    ...REDUCERS.SUCCESS,
  }),
  [DELETE_FINANCIAL_BURDEN_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
};

export default createReducer(schema, reducer);
