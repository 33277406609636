import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { dispatch } from '../../common/helpers';
import { programQuotesService, quoteLinesService } from '../../services';
import {
  FETCH_QUOTE_LIST_REQUEST,
  FETCH_QUOTE_LIST_SUCCESS,
  FETCH_QUOTE_LIST_ERROR,
  FETCH_MYQUOTES_DISTRIBUTION_LIST_REQUEST,
  FETCH_MYQUOTES_DISTRIBUTION_LIST_SUCCESS,
  FETCH_MYQUOTES_DISTRIBUTION_LIST_ERROR,
  FETCH_MYQUOTES_PROCUREMENT_LIST_SUCCESS,
  FETCH_MYQUOTES_PROCUREMENT_LIST_REQUEST,
  FETCH_MYQUOTES_PROCUREMENT_LIST_ERROR,
  FETCH_MYQUOTES_MANUFACTURING_LIST_REQUEST,
  FETCH_MYQUOTES_MANUFACTURING_LIST_SUCCESS,
  FETCH_MYQUOTES_MANUFACTURING_LIST_ERROR,
  FETCH_CROSS_PLANT_COMPARISSON_REQUEST,
  FETCH_CROSS_PLANT_COMPARISSON_SUCCESS,
  FETCH_CROSS_PLANT_COMPARISSON_ERROR,
} from './actions';

const mapData = src => ({
  ...src,
  data: src?.data
    ?.filter(
      x => !x.costGroupSequence || x.costGroupSequence?.uiBehavior !== 'hidden'
    )
    .map(data => ({
      ...data,
      ...Object.fromEntries(
        data.material?.materialIdentifiers?.map(({ ownedBy, identifier }) => [
          `${ownedBy}Identifier`,
          identifier,
        ])
      ),
      materialDescription: data?.material?.description,
      materialType: data?.material?.type,
      plantCode: data?.plant?.code,
      sourcePlantCode: data?.sourcePlant?.code,
      costFieldName: data?.costField?.name,
      isHighlighted: data?.costGroupSequence?.uiBehavior === 'highlight',
    })),
});

export const fetchQuoteList = action$ =>
  action$.pipe(
    ofType(FETCH_QUOTE_LIST_REQUEST),
    mergeMap(({ payload }) =>
      programQuotesService.getAllProgramQuotes(payload).pipe(
        map(data =>
          dispatch(FETCH_QUOTE_LIST_SUCCESS, {
            ...data,
            payload,
          })
        )
      )
    ),
    catchError(err => [dispatch(FETCH_QUOTE_LIST_ERROR, err)])
  );

export const fetchMyQuotesDistributionList = action$ =>
  action$.pipe(
    ofType(FETCH_MYQUOTES_DISTRIBUTION_LIST_REQUEST),
    mergeMap(({ payload }) =>
      quoteLinesService.getQuoteLines(payload).pipe(
        map(data =>
          dispatch(FETCH_MYQUOTES_DISTRIBUTION_LIST_SUCCESS, {
            ...mapData(data),
            payload,
          })
        )
      )
    ),
    catchError(err => [dispatch(FETCH_MYQUOTES_DISTRIBUTION_LIST_ERROR, err)])
  );

export const fetchCrossPlantComparisson = action$ =>
  action$.pipe(
    ofType(FETCH_CROSS_PLANT_COMPARISSON_REQUEST),
    mergeMap(({ payload }) =>
      quoteLinesService.getQuoteLines(payload).pipe(
        map(data =>
          dispatch(FETCH_CROSS_PLANT_COMPARISSON_SUCCESS, {
            ...data,
            payload,
          })
        )
      )
    ),
    catchError(err => [dispatch(FETCH_CROSS_PLANT_COMPARISSON_ERROR, err)])
  );

export const fetchMyQuotesProcurementList = action$ =>
  action$.pipe(
    ofType(FETCH_MYQUOTES_PROCUREMENT_LIST_REQUEST),
    mergeMap(({ payload }) =>
      quoteLinesService.getQuoteLines(payload).pipe(
        map(data =>
          dispatch(FETCH_MYQUOTES_PROCUREMENT_LIST_SUCCESS, {
            ...mapData(data),
            payload,
          })
        ),
        catchError(err => [
          dispatch(FETCH_MYQUOTES_PROCUREMENT_LIST_ERROR, err),
        ])
      )
    )
  );

export const fetchMyQuotesManufacturingList = action$ =>
  action$.pipe(
    ofType(FETCH_MYQUOTES_MANUFACTURING_LIST_REQUEST),
    mergeMap(({ payload }) =>
      quoteLinesService.getQuoteLines(payload).pipe(
        map(data =>
          dispatch(
            FETCH_MYQUOTES_MANUFACTURING_LIST_SUCCESS,
            mapData({
              ...mapData(data),
              payload,
            })
          )
        )
      )
    ),
    catchError(err => [dispatch(FETCH_MYQUOTES_MANUFACTURING_LIST_ERROR, err)])
  );
