import React from 'react';
import { string, arrayOf, object, func } from 'prop-types';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

const Base = ({
  options = [],
  onChange = () => {},
  valueKey,
  valueLabel,
  value,
  ...props
}) => {
  const [selectedvalue, updateValue] = React.useState([value]);

  const handleChange = (event, toggleVal) => {
    updateValue(toggleVal);
    onChange(toggleVal || null);
  };
  return (
    <ToggleButtonGroup
      size="large"
      value={selectedvalue}
      exclusive
      onChange={(event, val) => {
        return handleChange(event, val);
      }}
      {...props}
    >
      {options.map(data => (
        <ToggleButton key={data[valueKey]} value={data[valueKey]}>
          {data[valueLabel]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
Base.propTypes = {
  options: arrayOf(object).isRequired,
  value: string.isRequired,
  onChange: func,
  valueKey: string,
  valueLabel: string,
};

export default Base;
