import * as service from '../common/service';
import { CALCULATIONS } from '../common/constants';

const api = `${CALCULATIONS}/cost-group`;

const costGroupService = {
  getAllCostGroups: () => {
    return service.get(`${api}`);
  },
};
export default costGroupService;
