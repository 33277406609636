import React from 'react';
import { string } from 'prop-types';
import { useRouteMatch, useLocation } from 'react-router-dom';

const Title = ({ label, path }) => {
  const location = useLocation();
  const match = useRouteMatch(path);
  const unmatched = `[${location.pathname}]`;
  return <>{match ? label : unmatched}</>;
};

Title.propTypes = {
  label: string,
  path: string,
};

export default Title;
