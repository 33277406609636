import React, { createRef } from 'react';
import { string, func } from 'prop-types';
import Button from '../Button';

export default function UploadFileField({ id, text, onChange, ...props }) {
  const fileInput = createRef();
  const selectFile = () => fileInput.current.click();
  return (
    <>
      <Button btnType="secondary" onClick={() => selectFile()}>
        {text}
      </Button>
      <input
        id={id}
        type="file"
        ref={fileInput}
        onChange={evt => {
          onChange(evt.target.files, evt);
        }}
        hidden
        {...props}
      />
    </>
  );
}
UploadFileField.propTypes = {
  id: string,
  text: string,
  onChange: func,
};
