import React from 'react';
import { Route as Base, Redirect } from 'react-router-dom';
import { bool, string, elementType } from 'prop-types';
import { useSelector } from 'react-redux';
import { getAuthenticatedRoutesForUserRoles } from '../../common/helpers';
import { LOGIN, SAML, ROOT } from '../../common/routes';

const Route = ({
  component: Component,
  path,
  name,
  redirectedLink,
  ...props
}) => {
  const { data } = useSelector(({ user }) => user);
  if (path !== LOGIN && path !== SAML) {
    sessionStorage.setItem('redirectedLink', redirectedLink);
  }
  if (data?.roles) {
    if (path === LOGIN || path === SAML) {
      return <Redirect from={path} to={ROOT} />;
    }

    const authRoutes = getAuthenticatedRoutesForUserRoles(data.roles);
    const isAuthenticated = authRoutes.some(
      x => x.path === path || x.compoundPaths?.includes(path)
    );

    if (authRoutes && authRoutes.length > 0) {
      sessionStorage.setItem('defaultRoute', authRoutes[0].path);
    }
    if (isAuthenticated || path === ROOT) {
      return (
        <Base path={path} {...props}>
          <Component userData={data} />
        </Base>
      );
    }
  }
  let currentPath = path;
  const redirectPath = sessionStorage?.getItem('redirectedLink');
  if (redirectPath && redirectPath !== 'undefined') {
    currentPath = redirectPath;
  }
  return path === LOGIN || path === SAML ? (
    <Base path={path} {...props}>
      <Component userData={data} redirectedLink={currentPath} />
    </Base>
  ) : (
    <Redirect from={path} to={LOGIN} />
  );
};

Route.propTypes = {
  authorized: bool,
  path: string,
  name: string,
  component: elementType,
  redirectedLink: string,
};

export default Route;
