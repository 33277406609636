import React from 'react';
import { string, bool } from 'prop-types';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { themeColor } from '../../common/constants';

const TabItem = ({ active, label, ...props }) => {
  return (
    <NavLink
      {...props}
      style={{
        marginRight: '15px',
        textDecoration: 'none',
        borderBottom: `solid 2px ${themeColor}`,
        minHeight: '36px',
        minWidth: '120px',
        fontWeight: '600',
        color: themeColor,
      }}
      activeStyle={{
        borderBottom: 'solid 2px #4D4D4D',
        color: '#4D4D4D',
        textDecoration: 'none',
      }}
    >
      <Box display="flex" justifyContent="center">
        <Box fontSize={16}>{label}</Box>
      </Box>
    </NavLink>
  );
};

TabItem.propTypes = {
  label: string,
  active: bool,
};

export default TabItem;
