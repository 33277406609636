import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { merge } from 'rxjs';
import { MESSAGES, DEFAULT_PAGINATION_OBJECT } from '../../common/constants';

import {
  CREATE_VENDOR_MAP_REQUEST,
  CREATE_VENDOR_MAP_ERROR,
  FETCH_VENDOR_MAP_LIST_REQUEST,
  FETCH_VENDOR_MAP_LIST_SUCCESS,
  FETCH_VENDOR_MAP_LIST_ERROR,
  DELETE_VENDOR_MAP_REQUEST,
  DELETE_VENDOR_MAP_SUCCESS,
  DELETE_VENDOR_MAP_ERROR,
  UPDATE_VENDOR_MAP_REQUEST,
  UPDATE_VENDOR_MAP_ERROR,
} from './actions';
import { dispatch } from '../../common/helpers';

import {
  SUCCESS_NOTIFICATION_REQUEST,
  ERROR_NOTIFICATION_REQUEST,
} from '../notifications/actions';

import vendorMap from '../../services/vendorMap';

export const createVendorMap = action$ =>
  action$.pipe(
    ofType(CREATE_VENDOR_MAP_REQUEST),
    mergeMap(({ payload: { values, payload } }) =>
      vendorMap.createVendorMap(values).pipe(
        mergeMap(() => [
          dispatch(FETCH_VENDOR_MAP_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.FORM_SUBMITTED_SUCCESSFULLY,
          }),
        ]),
        catchError(error => [
          dispatch(CREATE_VENDOR_MAP_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );

export const fetchVendorMapList = action$ =>
  action$.pipe(
    ofType(FETCH_VENDOR_MAP_LIST_REQUEST),
    mergeMap(({ payload }) =>
      vendorMap.getAllVendorMaps(payload).pipe(
        map(data =>
          dispatch(FETCH_VENDOR_MAP_LIST_SUCCESS, {
            ...data,
            payload,
          })
        )
      )
    ),
    catchError(err => [dispatch(FETCH_VENDOR_MAP_LIST_ERROR, err)])
  );

export const removeVendorMap = action$ =>
  action$.pipe(
    ofType(DELETE_VENDOR_MAP_REQUEST),
    mergeMap(({ payload: { id, payload } }) =>
      vendorMap.deleteVendorMap(id).pipe(
        mergeMap(data =>
          merge(
            [
              dispatch(FETCH_VENDOR_MAP_LIST_REQUEST, {
                ...payload,
                pagination: DEFAULT_PAGINATION_OBJECT,
              }),
              dispatch(SUCCESS_NOTIFICATION_REQUEST, {
                message: MESSAGES.DATA_DELETED_SUCCESSFULLY,
              }),
            ],
            [dispatch(DELETE_VENDOR_MAP_SUCCESS, data)]
          )
        )
      )
    ),
    catchError(err => [
      dispatch(DELETE_VENDOR_MAP_ERROR, err),
      dispatch(ERROR_NOTIFICATION_REQUEST, err),
    ])
  );

export const updateVendorMap = action$ =>
  action$.pipe(
    ofType(UPDATE_VENDOR_MAP_REQUEST),
    mergeMap(({ payload: { id, data, payload } }) =>
      vendorMap.updateVendorMap(id, data).pipe(
        mergeMap(() =>
          merge([
            dispatch(FETCH_VENDOR_MAP_LIST_REQUEST, {
              ...payload,
              pagination: DEFAULT_PAGINATION_OBJECT,
            }),
            dispatch(SUCCESS_NOTIFICATION_REQUEST, {
              message: MESSAGES.FORM_UPDATED_SUCCESSFULLY,
            }),
          ])
        ),
        catchError(err => [
          dispatch(UPDATE_VENDOR_MAP_ERROR, err),
          dispatch(ERROR_NOTIFICATION_REQUEST, err),
        ])
      )
    )
  );
