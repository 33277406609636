import React from 'react';
import {
  CloudUpload,
  StatusCriticalSmall,
  StatusGood,
  Storage,
  Services,
  SubtractCircle,
  Install,
  InProgress,
} from 'grommet-icons';
import { string } from 'prop-types';
import { themeColor } from '../common/constants';

const STATUS_LIST = {
  UPLOADED: <Storage color={themeColor} />,
  UPLOADING: <CloudUpload color={themeColor} />,
  PREPROCESSING: <Install color={themeColor} />,
  CALCULATING: <InProgress color={themeColor} />,
  FAILED: <StatusCriticalSmall color="#ca2b4e" />,
  FINISHED: <StatusGood color={themeColor} />,
  PROCESSING: <Services color={themeColor} />,
  CHECKED: <StatusGood color={themeColor} />,
  UNCHECKED: <SubtractCircle color="#999999" />,
  DONE: <StatusGood color={themeColor} />,
  ERROR: <StatusCriticalSmall color="#ca2b4e" />,
};

const Status = ({ type }) =>
  STATUS_LIST[type] || <CloudUpload color="#01a982" />;

Status.propTypes = {
  type: string,
};

export default Status;
