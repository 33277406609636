import React from 'react';
import { string, bool } from 'prop-types';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

const style = {
  color: '#777777',
  textDecoration: 'none',
  cursor: 'pointer',
};

const BreadcrumbItem = ({ link, label, last, ...props }) => {
  return link ? (
    <>
      <Link to={link} style={style} {...props}>
        {label}
      </Link>
      {!last && (
        <Box mr={1} color="#777777" display="inline">
          {' > '}
        </Box>
      )}
    </>
  ) : (
    <Box mr={1} color="#777777" display="inline">
      {label}{' '}
      {!last && (
        <Box mr={1} color="#777777" display="inline">
          {' > '}
        </Box>
      )}
    </Box>
  );
};

export default BreadcrumbItem;

BreadcrumbItem.propTypes = {
  link: string,
  label: string,
  last: bool,
};
