import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/pcc`;

const pccService = {
  getAllPCC: payload => {
    return service.get(`${api}`, payload);
  },
  getAll: () => {
    return service.get(`${api}`);
  },
  getPCC: id => {
    return service.get(`${api}/${id}`);
  },
  createPCC: data => {
    return service.post(`${api}`, data);
  },
  updatePCC: (id, data) => {
    return service.put(`${api}/${id}`, data);
  },
  deletePCC: id => {
    return service.remove(`${api}/${id}`);
  },
};

export default pccService;
