import React from 'react';
import { string, node, number } from 'prop-types';
import { Box } from '@material-ui/core';

const Label = ({
  text,
  children,
  contentHeight = 0,
  contentItem = 'flex-start',
  ...props
}) => (
  <Box
    flex={1}
    display="flex"
    flexDirection="column"
    {...props}
    height={contentHeight}
    justifyContent={contentItem}
  >
    <Box component="span" m={1}>
      {text}
    </Box>
    {children}
  </Box>
);

Label.propTypes = {
  text: string,
  children: node,
  contentHeight: number,
  contentItem: string,
};

export default Label;
