// Fetch Rebate
export const FETCH_REBATE_LIST_REQUEST = 'FETCH_REBATE_LIST_REQUEST';
export const FETCH_REBATE_LIST_SUCCESS = 'FETCH_REBATE_LIST_SUCCESS';
export const FETCH_REBATE_LIST_ERROR = 'FETCH_REBATE_LIST_ERROR';

// Create Rebate
export const CREATE_COMBO_REBATE_REQUEST = 'CREATE_COMBO_REBATE_REQUEST';
export const CREATE_COMBO_REBATE_SUCCESS = 'CREATE_COMBO_REBATE_SUCCESS';
export const CREATE_COMBO_REBATE_ERROR = 'CREATE_COMBO_REBATE_ERROR';

// Delete Rebate
export const DELETE_REBATE_REQUEST = 'DELETE_REBATE_REQUEST';
export const DELETE_REBATE_SUCCESS = 'DELETE_REBATE_SUCCESS';
export const DELETE_REBATE_ERROR = 'DELETE_REBATE_ERROR';

// Update Rebate
export const UPDATE_REBATE_REQUEST = 'UPDATE_REBATE_REQUEST';
export const UPDATE_REBATE_SUCCESS = 'UPDATE_REBATE_SUCCESS';
export const UPDATE_REBATE_ERROR = 'UPDATE_REBATE_ERROR';

// Fetch Material Identifers
export const FETCH_MATERIAL_IDENTIFIER_REQUEST =
  ' FETCH_MATERIAL_IDENTIFIER_LIST_REQUEST';
export const FETCH_MATERIAL_IDENTIFIER_SUCCESS =
  ' FETCH_MATERIAL_IDENTIFIER_SUCCESS';
export const FETCH_MATERIAL_IDENTIFIER_ERROR =
  'FETCH_MATERIAL_IDENTIFIER_ERROR';
