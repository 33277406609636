import moment from 'moment';
import {
  FETCH_PCC_LIST_REQUEST,
  FETCH_PCC_LIST_SUCCESS,
  FETCH_PCC_LIST_ERROR,
  CREATE_PCC_REQUEST,
  CREATE_PCC_SUCCESS,
  CREATE_PCC_ERROR,
  DELETE_PCC_REQUEST,
  DELETE_PCC_ERROR,
  UPDATE_PCC_REQUEST,
} from './actions';
import { REDUCERS } from '../../common/constants';
import { createReducer } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  list: {
    loading: false,
    error: false,
    success: false,
    response: { data: [], pagination: {} },
    plants: [],
    costFields: [],
    pcc: { data: [], pagination: {} },
  },
};

const reducer = {
  // // COST Options
  [FETCH_PCC_LIST_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
      success: false,
    },
  }),
  [FETCH_PCC_LIST_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => {
    const filteredList = data
      ? data.map(val => {
          val.modifiedEffectiveFrom = moment(val.effectiveFrom).format(
            'MMMM DD, YYYY'
          );
          val.modifiedEffectiveTo = moment(val.effectiveTo).format(
            'MMMM DD, YYYY'
          );
          val.createdByUser = val.createdBy ? val.createdBy.name : '';
          val.plantName = (val.plant && val.plant.code) || 'HPE';
          val.costField = (val.costField && val.costField.name) || '';
          return val;
        })
      : [];
    return {
      ...state,
      list: {
        ...REDUCERS.SUCCESS,
        success: false,
        pcc: {
          data: payload?.pagination?.infinite
            ? [...state.list.pcc.data, ...filteredList]
            : filteredList,
          pagination,
          payload,
        },
      },
    };
  },
  [FETCH_PCC_LIST_ERROR]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
    },
  }),
  [CREATE_PCC_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
      success: false,
    },
  }),
  [CREATE_PCC_SUCCESS]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.SUCCESS,
      success: true,
      error: false,
    },
  }),
  [CREATE_PCC_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
      success: false,
    },
  }),
  [DELETE_PCC_REQUEST]: state => ({
    ...state,
    list: {
      ...REDUCERS.LOADING,
      pcc: state.list.pcc,
    },
  }),
  [DELETE_PCC_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [UPDATE_PCC_REQUEST]: state => ({
    ...state,
    list: {
      ...REDUCERS.LOADING,
      pcc: state.list.pcc,
    },
  }),
};

export default createReducer(schema, reducer);
