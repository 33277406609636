// Create Commodity
export const CREATE_COMMODITY_REQUEST = 'CREATE_COMMODITY_REQUEST';
export const CREATE_COMMODITY_SUCCESS = 'CREATE_COMMODITY_SUCCESS';
export const CREATE_COMMODITY_ERROR = 'CREATE_COMMODITY_ERROR';

// View Commodity
export const FETCH_COMMODITY_LIST_REQUEST = 'FETCH_COMMODITY_LIST_REQUEST';
export const FETCH_COMMODITY_LIST_SUCCESS = 'FETCH_COMMODITY_LIST_SUCCESS';
export const FETCH_COMMODITY_LIST_ERROR = 'FETCH_COMMODITY_LIST_ERROR';

// Update Commodity
export const UPDATE_COMMODITY_REQUEST = 'UPDATE_COMMODITY_REQUEST';
export const UPDATE_COMMODITY_SUCCESS = 'UPDATE_COMMODITY_SUCCESS';
export const UPDATE_COMMODITY_ERROR = 'UPDATE_COMMODITY_ERROR';

// Delete Commodity
export const DELETE_COMMODITY_REQUEST = 'DELETE_COMMODITY_REQUEST';
export const DELETE_COMMODITY_SUCCESS = 'DELETE_COMMODITY_SUCCESS';
export const DELETE_COMMODITY_ERROR = 'DELETE_COMMODITY_ERROR';
