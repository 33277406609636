import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/users`;

const usersService = {
  getAllUsers: (params = { limit: 10, offset: 0 }) => {
    return service.get(`${api}?limit=${params.limit}&offset=${params.offset}`);
  },
  getUser: id => {
    return service.get(`${api}/${id}`);
  },
  createUser: data => {
    return service.post(`${api}`, data);
  },
  updateUser: (id, data) => {
    return service.put(`${api}/${id}`, data);
  },
  deleteUser: id => {
    return service.remove(`${api}/${id}`);
  },
};
export default usersService;
