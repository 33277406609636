import {
  CREATE_PLANT_REQUEST,
  CREATE_PLANT_SUCCESS,
  CREATE_PLANT_ERROR,
  FETCH_PLANT_LIST_SUCCESS,
  FETCH_PLANT_LIST_ERROR,
  FETCH_PLANT_LIST_REQUEST,
  DELETE_PLANT_REQUEST,
  DELETE_PLANT_SUCCESS,
  DELETE_PLANT_ERROR,
  UPDATE_PLANT_REQUEST,
  UPDATE_PLANT_SUCCESS,
  UPDATE_PLANT_ERROR,
} from './actions';
import { REDUCERS } from '../../common/constants';
import { createReducer } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  list: {
    loading: false,
    error: false,
    response: { data: [], pagination: {}, payload: {} },
  },
};

const reducer = {
  [CREATE_PLANT_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [CREATE_PLANT_SUCCESS]: state => {
    return {
      ...state,
      list: {
        ...state.list,
        ...REDUCERS.SUCCESS,
      },
    };
  },
  [CREATE_PLANT_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [FETCH_PLANT_LIST_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_PLANT_LIST_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => {
    const filteredList = data
      ? data.map(val => {
          val.regionName = val.region ? val.region.name : '';
          val.partnerName = (val.partner && val.partner.name) || '';
          val.currencyCode = (val.currency && val.currency.code) || '';
          return val;
        })
      : [];
    return {
      ...state,
      list: {
        ...REDUCERS.SUCCESS,
        response: {
          data: payload?.pagination?.infinite
            ? [...state.list.response.data, ...filteredList]
            : filteredList,
          pagination,
          payload,
        },
      },
    };
  },
  [FETCH_PLANT_LIST_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [UPDATE_PLANT_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [UPDATE_PLANT_SUCCESS]: state => {
    return {
      ...state,
      list: {
        ...state.list,
        ...REDUCERS.SUCCESS,
      },
    };
  },
  [UPDATE_PLANT_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [DELETE_PLANT_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [DELETE_PLANT_SUCCESS]: state => {
    return {
      ...REDUCERS.SUCCESS,
      ...state,
    };
  },
  [DELETE_PLANT_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
};

export default createReducer(schema, reducer);
