// VARIANCE List Actions
export const FETCH_VARIANCE_COST_REQUEST = 'FETCH_VARIANCE_COST_REQUEST';
export const FETCH_VARIANCE_COST_SUCCESS = 'FETCH_VARIANCE_COST_SUCCESS';
export const FETCH_VARIANCE_COST_ERROR = 'FETCH_VARIANCE_COST_ERROR';

// VARIANCE history actions
export const FETCH_VARIANCE_COST_HISTORY_REQUEST =
  'FETCH_VARIANCE_COST_HISTORY_REQUEST';
export const FETCH_VARIANCE_COST_HISTORY_SUCCESS =
  'FETCH_VARIANCE_COST_HISTORY_SUCCESS';
export const FETCH_VARIANCE_COST_HISTORY_ERROR =
  'FETCH_VARIANCE_COST_HISTORY_ERROR';

// VARIANCE Quantity Actions
export const FETCH_VARIANCE_QUANTITY_REQUEST =
  'FETCH_VARIANCE_QUANTITY_REQUEST';
export const FETCH_VARIANCE_QUANTITY_SUCCESS =
  'FETCH_VARIANCE_QUANTITY_SUCCESS';
export const FETCH_VARIANCE_QUANTITY_ERROR = 'FETCH_VARIANCE_QUANTITY_ERROR';

// VARIANCE Quantity Actions
export const FETCH_VARIANCE_QUANTITY_HISTORY_REQUEST =
  'FETCH_VARIANCE_QUANTITY_HISTORY_REQUEST';
export const FETCH_VARIANCE_QUANTITY_HISTORY_SUCCESS =
  'FETCH_VARIANCE_QUANTITY_HISTORY_SUCCESS';
export const FETCH_VARIANCE_QUANTITY_HISTORY_ERROR =
  'FETCH_VARIANCE_QUANTITY_HISTORY_ERROR';

export const ACCEPT_ALL_VARIANCE_COST = 'ACCEPT_ALL_VARIANCE_COST';
export const ACCEPT_ALL_VARIANCE_QUANTITY = 'ACCEPT_ALL_VARIANCE_QUANTITY';

export const REJECT_ERROR_ACTION_VARIANCE_QUANTITY =
  'REJECT_ERROR_ACTION_VARIANCE_QUANTITY';
export const REJECT_SUCCESS_ACTION_VARIANCE_QUANTITY =
  'REJECT_SUCCESS_ACTION_VARIANCE_QUANTITY';
export const REJECT_REQUEST_ACTION_VARIANCE_QUANTITY =
  'REJECT_REQUEST_ACTION_VARIANCE_QUANTITY';

export const ACCEPT_ERROR_ACTION_VARIANCE_QUANTITY =
  'ACCEPT_ERROR_ACTION_VARIANCE_QUANTITY';
export const ACCEPT_SUCCESS_ACTION_VARIANCE_QUANTITY =
  'ACCEPT_SUCCESS_ACTION_VARIANCE_QUANTITY';
export const ACCEPT_REQUEST_ACTION_VARIANCE_QUANTITY =
  'ACCEPT_REQUEST_ACTION_VARIANCE_QUANTITY';

export const UPDATE_ERROR_ACTION_VARIANCE_QUANTITY =
  'UPDATE_ERROR_ACTION_VARIANCE_QUANTITY';
export const UPDATE_SUCCESS_ACTION_VARIANCE_QUANTITY =
  'UPDATE_SUCCESS_ACTION_VARIANCE_QUANTITY';
export const UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY =
  'UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY';

export const REJECT_ERROR_ACTION_VARIANCE_COST =
  'REJECT_ERROR_ACTION_VARIANCE_COST';
export const REJECT_SUCCESS_ACTION_VARIANCE_COST =
  'REJECT_SUCCESS_ACTION_VARIANCE_COST';
export const REJECT_REQUEST_ACTION_VARIANCE_COST =
  'REJECT_REQUEST_ACTION_VARIANCE_COST';

export const ACCEPT_ERROR_ACTION_VARIANCE_COST =
  'ACCEPT_ERROR_ACTION_VARIANCE_COST';
export const ACCEPT_SUCCESS_ACTION_VARIANCE_COST =
  'ACCEPT_SUCCESS_ACTION_VARIANCE_COST';
export const ACCEPT_REQUEST_ACTION_VARIANCE_COST =
  'ACCEPT_REQUEST_ACTION_VARIANCE_COST';

export const UPDATE_ERROR_ACTION_VARIANCE_COST =
  'UPDATE_ERROR_ACTION_VARIANCE_COST';
export const UPDATE_SUCCESS_ACTION_VARIANCE_COST =
  'UPDATE_SUCCESS_ACTION_VARIANCE_COST';
export const UPDATE_REQUEST_ACTION_VARIANCE_COST =
  'UPDATE_REQUEST_ACTION_VARIANCE_COST';

export const CREATE_QUANTITY_VARIANCE_REQUEST =
  'CREATE_QUANTITY_VARIANCE_REQUEST';
export const CREATE_QUANTITY_VARIANCE_SUCESS =
  'CREATE_QUANTITY_VARIANCE_SUCESS';
export const CREATE_QUANTITY_VARIANCE_ERROR = 'CREATE_QUANTITY_VARIANCE_ERROR';

export const CREATE_COST_VARIANCE_REQUEST = 'CREATE_COST_VARIANCE_REQUEST';
export const CREATE_COST_VARIANCE_SUCCESS = 'CREATE_COST_VARIANCE_SUCCESS';
export const CREATE_COST_VARIANCE_ERROR = 'CREATE_COST_VARIANCE_ERROR';

export const FETCH_PRIMARY_ALTERNATE_REQUEST =
  'FETCH_PRIMARY_ALTERNATE_REQUEST';
export const FETCH_PRIMARY_ALTERNATE_SUCCESS =
  'FETCH_PRIMARY_ALTERNATE_SUCCESS';
export const FETCH_PRIMARY_ALTERNATE_ERROR = 'FETCH_PRIMARY_ALTERNATE_ERROR';

export const ACCEPT_REQUEST_DECREASE_VARIANCE_TYPE =
  'ACCEPT_REQUEST_DECREASE_VARIANCE_TYPE';
export const ACCEPT_SUCCESS_DECREASE_VARIANCE_TYPE =
  'ACCEPT_SUCCESS_DECREASE_VARIANCE_TYPE';
export const ACCEPT_ERROR_DECREASE_VARIANCE_TYPE =
  'ACCEPT_ERROR_DECREASE_VARIANCE_TYPE';
export const ACCEPT_ALL_DECREASE_VARIANCE_TYPE = 'ACCEPT_ALL_VARIANCE_QUANTITY';

// shared procured materials
export const FETCH_SHARED_PROCURED_MATERIALS_REQUEST =
  'FETCH_PROCURED_MATERIALS_REQUEST';
export const FETCH_SHARED_PROCURED_MATERIALS_SUCESSS =
  'FETCH_PROCURED_MATERIALS_SUCESSS';
export const FETCH_SHARED_PROCURED_MATERIALS_ERROR =
  'FETCH_PROCURED_MATERIALS_ERROR';

export const FETCH_VARIANCE_COST_UPDATE_LINE_REQUEST =
  'FETCH_VARIANCE_COST_UPDATE_LINE_REQUEST';

export const FETCH_VARIANCE_COST_UPDATE_LINE_SUCCESS =
  'FETCH_VARIANCE_COST_UPDATE_LINE_SUCCESS';

export const FETCH_VARIANCE_COST_UPDATE_LINE_ERROR =
  'FETCH_VARIANCE_COST_UPDATE_LINE_ERROR';

export const FETCH_VARIANCE_QUANTITY_UPDATE_LINE_REQUEST =
  'FETCH_VARIANCE_QUANTITY_UPDATE_LINE_REQUEST';

export const FETCH_VARIANCE_QUANTITY_UPDATE_LINE_SUCCESS =
  'FETCH_VARIANCE_QUANTITY_UPDATE_LINE_SUCCESS';

export const FETCH_VARIANCE_QUANTITY_UPDATE_LINE_ERROR =
  'FETCH_VARIANCE_QUANTITY_UPDATE_LINE_ERROR';
