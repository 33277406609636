import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/materials`;

const materialsService = {
  getAllMaterials: (params = { limit: 10, offset: 0 }) => {
    return service.get(`${api}?limit=${params.limit}&offset=${params.offset}`);
  },
  getAllMaterialTypes: () => {
    return service.get(`${api}/types`);
  },
  getMaterial: id => {
    return service.get(`${api}/${id}`);
  },
  createMaterial: data => {
    return service.post(`${api}`, data);
  },
  updateMaterial: (id, data) => {
    return service.put(`${api}/${id}`, data);
  },
  deleteMaterial: id => {
    return service.remove(`${api}/${id}`);
  },
  getMaterialProfitCenter: () => {
    return service.get(`${api}/profit-centers-dl`);
  },
  getTypeDl: () => {
    return service.get(`${api}/types-dl`);
  },
  getProcurementTypeData: () => {
    return service.get(`${api}/mtrl-proc-type-dl`);
  },
  getPlantStatusData: () => {
    return service.get(`${api}/lifecycle-status-dl`);
  },
  getPlantSpecificMaterialData: () => {
    return service.get(`${api}/mtrl-plant-status-dl`);
  },
};
export default materialsService;
