import React, { Component, createRef } from 'react';
import {
  Box,
  styled,
  Icon,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { withRouter } from 'react-router';
import {
  shape,
  bool,
  string,
  arrayOf,
  oneOfType,
  number,
  array,
  object,
  func,
  node,
  element,
} from 'prop-types';
import Header from './Header';
import Loader from '../Loader';
import DataTablePagination from './DataTablePagination';
import { Pagination } from '../../common/prop-types';
import Body from './Body';
import Settings from './Settings';
import logo from '../../assets/hpeRobot.svg';
import { isIEBrowser } from '../../common/helpers';

const Base = styled(Box)({
  overflow: 'auto',
  position: 'relative',
});

const ToolBarBase = styled(Box)({
  display: 'flex',
  marginBottom: '20px',
});

const BASE_SIZE = '120px';

class DataTable extends Component {
  static updateColumns = (src, isHierarchical) => {
    // Setup Column Layout
    if (isHierarchical && src.findIndex(x => x.key === 'collapsible') === -1) {
      // eslint-disable-next-line no-param-reassign
      src = src.concat({
        key: 'collapsible',
        type: 'collapsible',
        label: ' ',
        size: 30,
      });
    }
    const columns = src.map(column =>
      column.size === 0
        ? column
        : {
            size: BASE_SIZE,
            ...column,
          }
    );

    // Split pinned, unpinned & actions
    const pinned = isIEBrowser()
      ? []
      : columns.filter(
          column =>
            column.pinned &&
            column.key !== 'actions' &&
            column.key !== 'collapsible'
        );
    const unpinned = isIEBrowser()
      ? columns
      : columns.filter(
          column =>
            !column.pinned &&
            column.key !== 'actions' &&
            column.key !== 'collapsible'
        );
    const actions = isIEBrowser()
      ? []
      : columns.filter(
          column => column.key === 'actions' || column.key === 'collapsible'
        );

    const baseColumns = [...pinned, ...unpinned, ...actions];

    return {
      pinned,
      unpinned,
      actions,
      baseColumns,
      columns: baseColumns.filter(
        // Filter hidden columns
        column => column.size !== 0
      ),
    };
  };

  static getDefaultSorts = updatedColumnsState =>
    updatedColumnsState.baseColumns
      .filter(x => x.sort && x.sort.value)
      .reduce((obj, item) => {
        return [
          ...obj,
          {
            field: item.sort.field,
            value: item.sort.value,
          },
        ];
      }, []);

  static getDefaultFilters = updatedColumnsState =>
    updatedColumnsState.baseColumns
      .filter(
        x =>
          x.filter &&
          x.filter.value !== undefined &&
          x.filter.operator &&
          x.filter.type &&
          x.filter.field
      )
      .reduce((obj, item) => {
        return [
          ...obj,
          {
            field: item.filter.field,
            operator: item.filter.operator,
            type: item.filter.type,
            value: item.filter.value,
          },
        ];
      }, []);

  constructor(props) {
    super(props);
    const {
      match: { path, params },
      columns,
      isPaginated = false,
      isHierarchical = false,
      base = false,
      report,
    } = props;

    // Initialize Local Settings
    const { type = '' } = params;
    this.key = `${path.slice(1)}/${type}`;

    this.ref = createRef();
    this.header = createRef();
    let mapped = columns;
    if (!base) {
      this.settings = JSON.parse(localStorage.getItem(this.key)) || [];
      if (this.settings.length > 0) {
        // insert those columns that are not still in the settings
        columns.forEach(column => {
          const columnIndex = this.settings.findIndex(
            x => x.key === column.key
          );
          if (columnIndex === -1) {
            this.settings.push(column);
          } else {
            const { size, pinned } = this.settings[columnIndex];
            this.settings[columnIndex] = {
              ...column,
              ...(size !== null && size !== undefined ? { size } : {}),
              ...(pinned ? { pinned } : {}),
            };
          }
        });
      } else {
        // if this has never been save then save columns
        this.settings = columns;
      }
      mapped = this.settings;
    }

    const updatedColumnsState = DataTable.updateColumns(mapped, isHierarchical);
    const defaultSorts = DataTable.getDefaultSorts(updatedColumnsState, report);
    const defaultFilters = DataTable.getDefaultFilters(
      updatedColumnsState,
      report
    );
    const payload = {
      pagination: {
        pageNumber: 1,
        pageSize: 40,
        allItems: false,
        isPaginated,
      },
      filter: defaultFilters,
      orderBy: defaultSorts,
    };
    this.fullWidth = false;
    this.state = {
      isHierarchical,
      payload,
      key: this.key,
      ...updatedColumnsState,
    };
  }

  componentDidMount() {
    const {
      onUpdate,
      base,
      report,
      disableStorage,
      calculatedHeight,
    } = this.props;

    const { payload } = this.state;
    const { current } = this.ref;

    if (!base) {
      window.addEventListener('beforeunload', this.writeSettings);
      if (current && !base) {
        const offset = report ? 250 : current.getBoundingClientRect().top;
        if (disableStorage && !calculatedHeight) {
          // for dashboard
          current.style.maxHeight = `none`;
        } else {
          current.style.maxHeight = `calc(100vh - ${offset}px)`;
        }
      }
    } else {
      current.style.maxHeight = `inherit`;
    }

    if (isIEBrowser()) {
      document.addEventListener('scroll', this.onBodyScroll);
      this.onBodyScroll();
    }
    onUpdate(payload);
  }

  componentDidUpdate() {
    const { columns, report } = this.props;
    const { current } = this.ref || '';
    // this is needed in order to  recalculate the scrollheight
    if (current && current.style) {
      current.style.height = '50px';
      current.style.height = `${current.scrollHeight + 21}px`;
      this.fullWidth = current.scrollWidth <= current.offsetWidth;
    }
    if (
      report &&
      columns.length > 0 &&
      this.settings.length !== columns.length
    ) {
      this.settings = columns;
    }
    if (isIEBrowser()) {
      this.onBodyScroll();
    }
  }

  componentWillUnmount() {
    const { base } = this.props;
    if (!base) {
      window.removeEventListener('beforeunload', this.writeSettings);
      this.writeSettings();
    }
    if (isIEBrowser()) {
      document.removeEventListener('scroll', this.onBodyScroll);
    }
  }

  onBodyScroll = () => {
    const { current } = this.ref;
    const { current: header } = this.header;
    const { left, top } = current.getBoundingClientRect();
    header.style.left = `${left}px`;
    header.style.top = `${top}px`;
  };

  onUpdate = columns => {
    const { isHierarchical } = this.state;
    this.settings = columns;
    this.writeSettings();
    this.setState({
      ...DataTable.updateColumns(columns, isHierarchical),
    });
  };

  onResize = (columns, settings) => {
    const { isHierarchical } = this.state;
    this.updateSettings(settings);
    this.setState({
      ...DataTable.updateColumns(columns, isHierarchical),
    });
  };

  static getDerivedStateFromProps(props, state) {
    const { isHierarchical, key } = state;
    const { report, hasDynamicColums, disableStorage } = props;
    let columns = state.baseColumns;

    if (isHierarchical || report || hasDynamicColums || disableStorage) {
      const condition =
        JSON.stringify(
          columns
            .map(x => x.key)
            .filter(x => x !== 'collapsible')
            .sort()
        ) === JSON.stringify(props.columns.map(x => x.key).sort());
      if (condition && !hasDynamicColums) {
        return null;
      }

      let latestSettings = JSON.parse(localStorage.getItem(key)) || [];
      columns = props.columns.map(column => {
        const columnIndex = latestSettings.findIndex(x => x.key === column.key);
        if (columnIndex !== -1) {
          return latestSettings[columnIndex];
        }
        return column;
      });

      if (report && columns.length > 0) {
        if (disableStorage) {
          latestSettings = columns; // disabling storage for dashboard tables
        } else {
          localStorage.setItem(key, JSON.stringify(columns));
          latestSettings = columns;
        }
      }

      columns =
        latestSettings.length > 0
          ? latestSettings.reduce((acc, current) => {
              const column = columns.find(x => x.key === current.key);
              if (column) {
                return [...acc, column];
              }
              return acc;
            }, [])
          : columns;
    }
    columns = columns.map(column => {
      const currentPropColum = props.columns.find(x => x.key === column.key);
      if (column.filter && currentPropColum) {
        column.filter.options =
          !!column.filter.options &&
          column.filter.options.length < currentPropColum.filter.options.length
            ? currentPropColum.filter.options
            : column.filter.options;
      }
      column.render = currentPropColum?.render;
      return column;
    });
    return {
      ...DataTable.updateColumns(columns, isHierarchical),
    };
  }

  writeSettings = () => {
    const previous = JSON.parse(localStorage.getItem(this.key)) || [];
    if (previous !== null) {
      const intersectSettings = previous.reduce((acc, current, index) => {
        if (!acc.find(x => x.key === current.key)) {
          return [...acc.slice(0, index), current, ...acc.slice(index)];
        }
        return acc;
      }, this.settings);
      localStorage.setItem(this.key, JSON.stringify(intersectSettings));
    }
  };

  updateSettings = settings => {
    const keys = Object.keys(settings);
    keys.forEach(key => {
      const currentColumnIndex = this.settings.findIndex(x => x.key === key);
      const { size } = settings[key];
      this.settings[currentColumnIndex] = {
        ...this.settings[currentColumnIndex],
        size: size === null ? BASE_SIZE : size,
      };
    });
  };

  onFiltering = filter => {
    const { onUpdate, report } = this.props;
    const { payload } = this.state;
    payload.filter = filter;
    payload.pagination.pageNumber = 1;
    payload.pagination.infinite = false;

    this.setState({ payload }, () => {
      onUpdate(payload, report);
    });
  };

  onSorting = orderBy => {
    const { onUpdate } = this.props;
    const { payload } = this.state;
    payload.orderBy = orderBy;
    payload.pagination.pageNumber = 1;
    payload.pagination.infinite = false;
    this.setState({ payload }, () => {
      onUpdate(payload);
    });
  };

  onScroll = event => {
    const { onUpdate, data, loading, pagination } = this.props;
    const { payload } = this.state;
    const { currentTarget } = event;

    if (
      currentTarget.scrollTop + currentTarget.clientHeight >=
        currentTarget.scrollHeight - 100 &&
      data.length &&
      !loading &&
      pagination &&
      pagination.hasNext
    ) {
      const curPage = payload.pagination.pageNumber;
      payload.pagination.pageNumber = curPage + 1;
      payload.pagination.infinite = true;
      onUpdate(payload);
    }
  };

  onPagination = value => {
    const { onUpdate } = this.props;
    const { payload } = this.state;
    payload.pagination = { ...payload.pagination, ...value };
    this.setState({ payload }, () => {
      onUpdate(payload);
    });
  };

  render() {
    const { pinned, unpinned, actions, payload } = this.state;
    const {
      data = [],
      loading,
      pagination,
      isPaginated,
      base,
      startAdornments,
      endAdornment,
      isDraggable = false,
      dragHandlers,
      dropboundry,
      onUpdate,
      isHierarchical,
      hasDynamicColums,
      // eslint-disable-next-line
      staticContext,
      // eslint-disable-next-line
      history,
      // eslint-disable-next-line
      location,
      // eslint-disable-next-line
      match,
      // eslint-disable-next-line
      columns,
      // eslint-disable-next-line
      unique,
      // eslint-disable-next-line
      unit,
      report,
      hideSettings,
      disableStorage,
      ...props
    } = this.props;
    return (
      <Box {...props}>
        {!base && (
          <ToolBarBase>
            <Box pr={1} pl={1} flexGrow={1} display="inline-flex">
              {startAdornments &&
                startAdornments.length !== 0 &&
                startAdornments.map((adornment, index) => (
                  // eslint-disable-next-line
                  <Box pr={1} key={index}>
                    {adornment}
                  </Box>
                ))}
            </Box>
            <Box
              p={1}
              pt={2}
              hidden={!pagination?.totalCount}
              flexShrink={2}
              width={300}
              textAlign="center"
              align="center"
              lineHeight={2.5}
            >
              <Typography noWrap>
                Total Records: {pagination?.totalCount}
              </Typography>
            </Box>
            {report && pagination?.totalCountLoading && (
              <Box
                p={1}
                pt={2}
                flexShrink={2}
                width={100}
                textAlign="center"
                align="center"
                lineHeight={2.5}
              >
                <CircularProgress size={25} />
              </Box>
            )}
            {endAdornment && (
              <Box p={1} flexShrink={1}>
                {endAdornment}
              </Box>
            )}
            <Box p={1} flexShrink={0} hidden={hideSettings}>
              <Settings
                pinned={pinned}
                unpinned={unpinned}
                actions={actions}
                onResize={(headers, settings) => {
                  this.onResize(headers, settings);
                  this.writeSettings();
                }}
              />
            </Box>
          </ToolBarBase>
        )}

        <Base ref={this.ref} onScroll={isPaginated ? null : this.onScroll}>
          <Header
            ref={this.header}
            pinned={pinned}
            unpinned={unpinned}
            actions={actions}
            onUpdate={this.onUpdate}
            onResize={this.onResize}
            onFiltering={this.onFiltering}
            onSorting={this.onSorting}
            filters={payload.filter}
            orderBy={payload.orderBy}
            fullWidth={this.fullWidth}
          />
          {(!data.length || isPaginated || !payload.pagination.infinite) &&
          loading ? (
            <Loader />
          ) : (
            <>
              {data && data.length ? (
                <>
                  <Body
                    pinned={pinned}
                    unpinned={unpinned}
                    actions={actions}
                    data={data}
                    isHierarchical={isHierarchical}
                    isDraggable={isDraggable}
                    dragHandlers={dragHandlers}
                    dropboundry={dropboundry}
                    fullWidth={this.fullWidth}
                  />
                  {data.length &&
                  data.length > 0 &&
                  payload.pagination.infinite &&
                  loading &&
                  !isPaginated ? (
                    <Loader
                      style={{
                        opacity: '0.9',
                        position: 'fixed',
                        left: '45%',
                        bottom: '25%',
                      }}
                    />
                  ) : null}
                </>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  style={{ minHeight: base ? '200px' : '300px' }}
                >
                  <Grid container spacing={0} align="center" justify="center">
                    <Box display="block" alignItems="center" m="auto">
                      <Box align="center">
                        <Icon>
                          <img
                            style={base ? { height: '100px' } : {}}
                            src={logo}
                            alt="hpeRobot"
                          />
                        </Icon>
                      </Box>
                      <Box
                        align="center"
                        fontSize={base ? 14 : 21}
                        fontWeight="800"
                        color="text.secondary"
                      >
                        Beep Boop!
                      </Box>
                      <Box
                        align="center"
                        fontSize={base ? 14 : 21}
                        color="text.secondary"
                      >
                        No results.
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              )}
            </>
          )}
        </Base>
        {isPaginated && data.length ? (
          <DataTablePagination
            onPagination={this.onPagination}
            pagination={{ ...payload.pagination, ...pagination }}
            loading={loading}
          />
        ) : null}
      </Box>
    );
  }
}

DataTable.propTypes = {
  columns: arrayOf(
    shape({
      key: string.isRequired,
      label: string,
      size: oneOfType([string, number]),
    })
  ),
  match: shape({
    path: string,
  }),
  // eslint-disable-next-line
  data: array,
  // eslint-disable-next-line
  filters: object,
  onUpdate: func,
  loading: bool,
  isPaginated: bool,
  pagination: Pagination,
  isHierarchical: bool,
  hasDynamicColums: bool,
  base: bool,
  report: string,
  startAdornments: oneOfType([func, node, element]),
  endAdornment: oneOfType([func, node, element]),
  dropboundry: func,
  isDraggable: bool,
  dragHandlers: shape({
    dragStartHandler: func,
    dragOverHandler: func,
    dragEndHandler: func,
    dropHandler: func,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  staticContext: object,
  hideSettings: bool,
  disableStorage: bool,
  calculatedHeight: bool,
};

DataTable.defaultProps = {
  onUpdate: () => {},
};

export default withRouter(DataTable);
