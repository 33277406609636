// Plant Preference List Actions
export const FETCH_PLANT_PREF_REQUEST = 'FETCH_PLANT_PREF_REQUEST';
export const FETCH_PLANT_PREF_SUCCESS = 'FETCH_PLANT_PREF_SUCCESS';
export const FETCH_PLANT_PREF_ERROR = 'FETCH_PLANT_PREF_ERROR';

// Plant Preference Create Actions
export const CREATE_PLANT_PREF_REQUEST = 'CREATE_PLANT_PREF_REQUEST';
export const CREATE_PLANT_PREF_SUCCESS = 'CREATE_PLANT_PREF_SUCCESS';
export const CREATE_PLANT_PREF_ERROR = 'CREATE_PLANT_PREF_ERROR';

// Plant Preference Create Actions
export const DELETE_PLANT_PREF_REQUEST = 'DELETE_PLANT_PREF_REQUEST';
export const DELETE_PLANT_PREF_SUCCESS = 'DELETE_PLANT_PREF_SUCCESS';
export const DELETE_PLANT_PREF_ERROR = 'DELETE_PLANT_PREF_ERROR';

// Plant Preference Create Actions
export const UPDATE_PLANT_PREF_REQUEST = 'UPDATE_PLANT_PREF_REQUEST';
export const UPDATE_PLANT_PREF_SUCCESS = 'UPDATE_PLANT_PREF_SUCCESS';
export const UPDATE_PLANT_PREF_ERROR = 'UPDATE_PLANT_PREF_ERROR';

// Plant Preference Create Actions
export const UPDATE_PLANT_PREF_SORT_REQUEST = 'UPDATE_PLANT_PREF_SORT_REQUEST';
export const UPDATE_PLANT_PREF_SORT_SUCCESS = 'UPDATE_PLANT_PREF_SORT_SUCCESS';
export const UPDATE_PLANT_PREF_SORT_ERROR = 'UPDATE_PLANT_PREF_SORT_ERROR';
