import * as React from 'react';
import { Button as MuiButton, makeStyles, darken } from '@material-ui/core';
import { string, oneOf, node, oneOfType, object } from 'prop-types';

const useStyles = makeStyles(theme => ({
  danger: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: darken(theme.palette.error.main, 0.2),
    },
  },
  underline: {
    textDecoration: 'underline',
  },
}));

const Button = ({ children, btnType, icon, ...props }) => {
  const classes = useStyles();

  switch (btnType) {
    case 'primary':
      return (
        <MuiButton
          variant="contained"
          color="primary"
          {...props}
          endIcon={icon}
        >
          {children}
        </MuiButton>
      );
    case 'secondary':
      return (
        <MuiButton variant="outlined" color="secondary" {...props}>
          {children}
        </MuiButton>
      );
    case 'danger':
      return (
        <MuiButton variant="contained" className={classes.danger} {...props}>
          {children}
        </MuiButton>
      );
    case 'underline':
      return (
        <MuiButton className={classes.underline} {...props} startIcon={icon}>
          {children}
        </MuiButton>
      );
    default:
      return <MuiButton {...props}>{children}</MuiButton>;
  }
};

Button.propTypes = {
  children: oneOfType([object, string]),
  btnType: oneOf(['primary', 'secondary', 'danger', 'underline']),
  icon: node,
};

export default Button;
