import React, { PureComponent } from 'react';
import { Box, styled } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import {
  shape,
  string,
  arrayOf,
  oneOfType,
  number,
  object,
  bool,
} from 'prop-types';
import Row from './Row';
import Cell from './Cell';
import { themeColor } from '../../../common/constants';

const Base = styled(Box)({
  display: 'inline-flex',
  flexDirection: 'row',
  position: 'relative',
  '&::before': {
    content: '" "',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  backgroundColor: themeColor,
  color: 'white',

  '&:hover': {
    filter: 'brightness(85%)',
  },
});

const stickyBaseStyles = {
  position: 'sticky',
  top: '0',
  height: 'auto',
  display: 'flex',
  zIndex: 5,

  background: 'inherit',
  '&::after': {
    background: 'inherit',
  },
};
const LeftPinnedColumns = styled(Box)({
  left: '0',
  borderRight: 'solid 2px #BBB',
  ...stickyBaseStyles,
});

const RightPinnedColumns = styled(Box)({
  right: '0',
  borderLeft: 'solid 4px #BBB',
  ...stickyBaseStyles,
});

const UnPinnedColumns = styled(Box)({
  zIndex: 1,
  display: 'flex',
});

class HierarchicalRow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  render() {
    const { open } = this.state;
    const {
      pinned,
      unpinned,
      actions,
      parentData,
      childrenData,
      fullWidth,
    } = this.props;
    const activeUnpinnedCols = unpinned.filter(column => column.size !== 0);
    const activePinnedCols = pinned.filter(column => column.size !== 0);
    return (
      <>
        <Base style={fullWidth ? { width: '100%' } : {}}>
          {activePinnedCols.length !== 0 && (
            <LeftPinnedColumns>
              {activePinnedCols.map(column => (
                <Cell
                  key={column.key}
                  column={column}
                  data={parentData}
                  open={open}
                  handleCollapsible={() => this.setState({ open: !open })}
                />
              ))}
            </LeftPinnedColumns>
          )}
          <UnPinnedColumns style={fullWidth ? { width: '100%' } : {}}>
            {activeUnpinnedCols.map((column, index) => (
              <Cell
                key={column.key}
                column={column}
                unPinned
                isLastElement={activeUnpinnedCols.length - 1 === index}
                data={parentData}
                open={open}
                handleCollapsible={() => this.setState({ open: !open })}
              />
            ))}
          </UnPinnedColumns>
          {actions.length !== 0 && (
            <RightPinnedColumns>
              {actions
                .filter(column => column.size !== 0)
                .map(column => (
                  <Cell
                    key={column.key}
                    column={column}
                    actions
                    isLastElement
                    data={parentData}
                    open={open}
                    handleCollapsible={() => this.setState({ open: !open })}
                  />
                ))}
            </RightPinnedColumns>
          )}
        </Base>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {open ? (
            <Box>
              {childrenData.map(childRow => (
                <Row
                  key={childRow.id}
                  pinned={pinned}
                  unpinned={unpinned}
                  actions={actions}
                  data={childRow}
                  fullWidth
                />
              ))}
            </Box>
          ) : null}
        </Collapse>
      </>
    );
  }
}

const HeaderColumn = shape({
  key: string.isRequired,
  label: string,
  size: oneOfType([string, number]),
});

HierarchicalRow.propTypes = {
  pinned: arrayOf(HeaderColumn),
  unpinned: arrayOf(HeaderColumn),
  actions: arrayOf(HeaderColumn),
  childrenData: arrayOf(object),
  fullWidth: bool,
  // eslint-disable-next-line react/forbid-prop-types
  parentData: object,
};

export default HierarchicalRow;
