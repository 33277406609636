import * as service from '../common/service';
import { QNP, REBATE } from '../common/constants';

const qnpApi = `${QNP}/program-quotes`;
const rebateApi = `${REBATE}/create-rebate`;

const programQuotesService = {
  getAllProgramQuotes: params => {
    return service.get(`${qnpApi}`, {
      ...params,
    });
  },
  getProgramQuotes: id => {
    return service.get(`${qnpApi}/${id}`);
  },
  createProgramQuotes: data => {
    return service.post(`${qnpApi}/createquote`, data);
  },
  createProgramRebates: data => {
    return service.post(`${rebateApi}/create`, data);
  },
  updateProgramQuotes: (id, data) => {
    return service.put(`${qnpApi}/${id}`, data);
  },
  deleteProgramQuotes: id => {
    return service.remove(`${qnpApi}/${id}`);
  },
  isProcessingProgramQuote: data => {
    return service.post(`${qnpApi}/is-processing-program-quote`, data);
  },
  isProcessingProgramRebate: data => {
    return service.post(`${rebateApi}/is-processing-create-rebate`, data);
  },
};
export default programQuotesService;
