import {
  FETCH_CONDITIONS_LIST_REQUEST,
  FETCH_CONDITIONS_LIST_SUCCESS,
  FETCH_CONDITIONS_LIST_ERROR,
  CREATE_CONDITION_REQUEST,
  CREATE_CONDITION_SUCCESS,
  CREATE_CONDITION_ERROR,
  DELETE_CONDITION_REQUEST,
  DELETE_CONDITION_ERROR,
  UPDATE_CONDITION_REQUEST,
} from './actions';
import { REDUCERS } from '../../common/constants';
import { createReducer } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  list: {
    loading: false,
    error: false,
    response: { data: [], pagination: {}, payload: {} },
  },
};

const reducer = {
  [FETCH_CONDITIONS_LIST_REQUEST]: state => {
    return {
      ...state,
      list: {
        ...state.list,
        ...REDUCERS.LOADING,
        success: false,
      },
    };
  },
  [FETCH_CONDITIONS_LIST_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => {
    return {
      ...state,
      list: {
        ...REDUCERS.SUCCESS,
        response: {
          data: payload?.pagination?.infinite
            ? [...state.list.response.data, ...data]
            : data,
          pagination,
          payload,
        },
      },
    };
  },
  [FETCH_CONDITIONS_LIST_ERROR]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
    },
  }),
  [CREATE_CONDITION_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
      success: false,
    },
  }),
  [CREATE_CONDITION_SUCCESS]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.SUCCESS,
      success: true,
      error: false,
    },
  }),
  [CREATE_CONDITION_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
      success: false,
    },
  }),
  [DELETE_CONDITION_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [DELETE_CONDITION_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [UPDATE_CONDITION_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
};

export default createReducer(schema, reducer);
