/* eslint-disable no-unused-vars */
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, merge } from 'rxjs/operators';
import { dispatch } from '../../common/helpers';
import { pccService } from '../../services';
import { MESSAGES, DEFAULT_PAGINATION_OBJECT } from '../../common/constants';

import {
  FETCH_PCC_LIST_REQUEST,
  FETCH_PCC_LIST_SUCCESS,
  FETCH_PCC_LIST_ERROR,
  CREATE_PCC_REQUEST,
  CREATE_PCC_ERROR,
  DELETE_PCC_REQUEST,
  DELETE_PCC_ERROR,
  UPDATE_PCC_REQUEST,
  UPDATE_PCC_ERROR,
} from './actions';

import {
  SUCCESS_NOTIFICATION_REQUEST,
  ERROR_NOTIFICATION_REQUEST,
} from '../notifications/actions';

export const fetchPccList = (action$, store) => {
  return action$.pipe(
    ofType(FETCH_PCC_LIST_REQUEST),
    mergeMap(({ payload }) =>
      pccService.getAllPCC(payload).pipe(
        map(data =>
          dispatch(FETCH_PCC_LIST_SUCCESS, {
            ...data,
            payload,
          })
        )
      )
    ),
    catchError(err => [dispatch(FETCH_PCC_LIST_ERROR, err)])
  );
};

export const addPcc = action$ =>
  action$.pipe(
    ofType(CREATE_PCC_REQUEST),
    mergeMap(({ payload: { values, payload } }) =>
      pccService.createPCC(values).pipe(
        mergeMap(() => [
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.FORM_SUBMITTED_SUCCESSFULLY,
          }),
          dispatch(FETCH_PCC_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
        ]),
        catchError(error => [
          dispatch(CREATE_PCC_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );
export const deletePcc = action$ =>
  action$.pipe(
    ofType(DELETE_PCC_REQUEST),
    mergeMap(({ payload: { id, payload } }) =>
      pccService.deletePCC(id).pipe(
        mergeMap(() => [
          dispatch(FETCH_PCC_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.DATA_DELETED_SUCCESSFULLY,
          }),
        ]),
        catchError(error => [
          dispatch(DELETE_PCC_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );

export const updatePcc = action$ =>
  action$.pipe(
    ofType(UPDATE_PCC_REQUEST),
    mergeMap(({ payload }) =>
      pccService.updatePCC(payload.values.id, payload.values).pipe(
        mergeMap(() => [
          dispatch(FETCH_PCC_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.FORM_UPDATED_SUCCESSFULLY,
          }),
        ]),
        catchError(error => [
          dispatch(UPDATE_PCC_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, error),
        ])
      )
    )
  );
