import { createReducer } from '../../common/helpers';
import { check } from '../../common/session';

const data = check();

const schema = {
  loading: false,
  error: false,
  data,
};

const reducer = {};

export default createReducer(schema, reducer);
