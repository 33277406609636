import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/plant-preferences`;

const plantPrefService = {
  getAllPlantPref: payload => {
    return service.get(`${api}`, payload);
  },
  getAll: () => {
    return service.get(`${api}`);
  },
  getPlantPref: id => {
    return service.get(`${api}/${id}`);
  },
  createPlantPref: data => {
    return service.post(`${api}`, data);
  },
  updatePlantPref: (id, data) => {
    return service.put(`${api}/${id}`, data);
  },
  deletePlantPref: id => {
    return service.remove(`${api}/${id}`);
  },
  getAllPlantPrefMaterialTypes: () => {
    return service.get(`${api}/material/partTypes`);
  },
};

export default plantPrefService;
