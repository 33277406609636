import React, { useCallback, useEffect } from 'react';
import {
  Box,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FormClose } from 'grommet-icons';
import { func, object, arrayOf, shape, bool, string, number } from 'prop-types';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  VarianceHistory,
  Pagination,
  User,
  Payload,
  SharedMaterials,
  PccPayload,
} from '../../common/prop-types';
import { useCallbackDispatch } from '../../common/hooks';
import DataTable from '../DataTable';
import DisplayLabel from './Components/DisplayLabel';
import Button from '../Button';
import Comments from '../Comments';

import {
  ACCEPT_REQUEST_ACTION_VARIANCE_COST,
  UPDATE_REQUEST_ACTION_VARIANCE_COST,
  REJECT_REQUEST_ACTION_VARIANCE_COST,
  CREATE_COST_VARIANCE_REQUEST,
  ACCEPT_REQUEST_DECREASE_VARIANCE_TYPE,
} from '../../redux/variances/actions';
import { USER_ROLES, COST_DECREASE } from '../../common/constants';
import Loader from '../Loader';

const entityType = 'QuoteVariance';
const VarianceApproval = ({
  closeDrawer,
  selectedVariance = {},
  plants,
  historyProps: { historyData, fetchHistory, historyLoading },
  sharedMaterialsProps: {
    sharedMaterialsData,
    fetchSharedProcuredMaterials,
    sharedMaterialsLoading,
  },
  pccProps: { pccData, fetchPccList },
  userData,
  payload,
  loading,
  type,
}) => {
  const { targetRole } = selectedVariance?.quoteVariance || {};
  const { id, roles } = userData;
  const { pagination, filter, orderBy } = payload;
  pagination.pageNumber = 1;
  pagination.infinite = false;

  const updateVarianceCost = useCallbackDispatch(
    UPDATE_REQUEST_ACTION_VARIANCE_COST
  );
  const acceptVarianceCost = useCallbackDispatch(
    ACCEPT_REQUEST_ACTION_VARIANCE_COST
  );

  const acceptVarianceDecreaseType = useCallbackDispatch(
    ACCEPT_REQUEST_DECREASE_VARIANCE_TYPE
  );

  const rejectVarianceCost = useCallbackDispatch(
    REJECT_REQUEST_ACTION_VARIANCE_COST
  );
  const createCostVariance = useCallbackDispatch(CREATE_COST_VARIANCE_REQUEST);
  const [newCost, setNewCost] = React.useState('');
  const [justification, updateJustification] = React.useState('');
  const onUpdateHistory = useCallback(() => {
    return fetchHistory({
      filter: [
        {
          value: selectedVariance?.id,
          field: 'materialQuoteId',
          operator: 'eq',
        },
      ],
    });
  }, [selectedVariance, fetchHistory]);

  const onUpdateSharedMaterials = useCallback(() => {
    return fetchSharedProcuredMaterials({
      materialId: selectedVariance?.materialId,
      plantId: selectedVariance?.plantId,
    });
  }, [selectedVariance, fetchSharedProcuredMaterials]);

  useEffect(() => {
    fetchPccList({
      filter: [
        {
          field: 'pccMaterialId',
          value: selectedVariance?.materialId,
          operator: 'eq',
        },
        {
          field: 'pccPlantId',
          value: selectedVariance?.plantId,
          operator: 'eq',
        },
        {
          field: 'pccEffectiveFrom',
          value: selectedVariance?.effectiveFrom,
          operator: 'lt',
        },
        {
          field: 'pccEffectiveTo',
          value: selectedVariance?.effectiveFrom,
          operator: 'dateGt',
        },
      ],
    });
  }, [selectedVariance, fetchPccList]);

  const plant = plants.find(x => x.id === selectedVariance?.sourcePlantId)
    ?.code;

  const distributionPlant = plants.find(x => x.id === selectedVariance?.plantId)
    ?.code;

  return (
    <Box width={800} role="presentation">
      <Box display="flex" justifyContent="flex-end" m={2}>
        <IconButton onClick={closeDrawer}>
          <FormClose />
        </IconButton>
      </Box>
      <Box ml={2} mr={2} mb={1} display="flex" flexDirection="row">
        {loading && (
          <Loader
            hideIcon
            style={{
              zIndex: '1000',
              position: 'fixed',
              right: '250px',
              top: '20%',
            }}
          />
        )}
        <Box width="50%">
          <DisplayLabel
            label="Status:"
            first
            value={
              selectedVariance?.quoteVariance?.approved
                ? 'Approved'
                : 'Pending Approval'
            }
            color={
              selectedVariance?.quoteVariance?.approved
                ? 'primary.main'
                : 'error.main'
            }
          />
          <DisplayLabel
            label="Material Number:"
            value={
              (
                selectedVariance?.material?.materialIdentifiers?.find(
                  ({ ownedBy }) => ownedBy === 'hpe'
                ) || {}
              )?.identifier
            }
          />
          <DisplayLabel
            label="Supplier Number:"
            value={
              (
                selectedVariance?.material?.materialIdentifiers?.find(
                  ({ ownedBy }) => ownedBy === 'supplier'
                ) || {}
              )?.identifier
            }
          />
          <DisplayLabel label="Plant:" value={plant} />
          <DisplayLabel label="PCC Amount:" value={pccData[0]?.amount || ''} />
        </Box>
        <Box width="50%" mr={2}>
          <DisplayLabel
            first
            label="Material Type:"
            value={selectedVariance?.material?.type}
          />
          <DisplayLabel
            label="Variance Type:"
            value={selectedVariance?.quoteVariance?.varianceType}
          />
          <DisplayLabel
            label="Cost Field:"
            value={selectedVariance?.costField?.name}
          />
          <DisplayLabel label="Distribution Plant:" value={distributionPlant} />
          <DisplayLabel
            label="Description:"
            value={selectedVariance?.material?.description}
          />
        </Box>
      </Box>
      <Box ml={2} mr={2} mb={1} display="flex" flexDirection="row">
        <Box width="50%" ml={2} mr={2}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="historyPanelbh-content"
              id="historyPanelbh-header"
            >
              Cost History
            </AccordionSummary>
            <AccordionDetails>
              <DataTable
                style={{ maxHeight: '240px', height: '240px' }}
                onUpdate={onUpdateHistory}
                columns={[
                  {
                    key: 'effectiveFrom',
                    label: 'Effective From',
                    size: 145,
                    type: 'date',
                  },
                  {
                    key: 'amount',
                    label: 'Cost',
                    size: 145,
                  },
                ]}
                data={historyData?.slice(0, 6)}
                loading={historyLoading}
                base
              />
            </AccordionDetails>
          </Accordion>
        </Box>
        {(userData.roles.includes(USER_ROLES.SPM) ||
          userData.roles.includes(USER_ROLES.SUPPLIER)) && (
          <Box width="50%" ml={2} mr={2}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="sharedMaterialsbh-content"
                id="sharedMaterialsbh-header"
              >
                Shared Materials
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <DataTable
                    style={{ maxHeight: '240px' }}
                    onUpdate={onUpdateSharedMaterials}
                    columns={[
                      { key: 'name', label: 'Program Name', size: 145 },
                      {
                        key: 'owner.name',
                        label: 'SPM',
                        size: 145,
                      },
                    ]}
                    data={sharedMaterialsData}
                    loading={sharedMaterialsLoading}
                    base
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Box>
      <Box m={2} display="block" flexDirection="row">
        <Box display="flex" flexDirection="row">
          <Box display="flex" width="50%" m={2}>
            <Box component="span" width="40%" color="text.secondary" pr={1}>
              <Box pt={2} fontSize={16}>
                Current ($)
              </Box>
            </Box>
            <Box
              component="span"
              fontWeight="bold"
              display="flex"
              color="accent.main"
            >
              <Box fontSize={24} pt={3.5}>
                $
              </Box>
              <Box fontSize={48}>{selectedVariance?.amount || 0}</Box>
            </Box>
          </Box>
          <Box display="flex" width="50%" m={2}>
            <Box component="span" width="40%" color="text.secondary" pr={1}>
              <Box pt={2} fontSize={16}>
                Last ($)
              </Box>
            </Box>

            <Box component="span" fontWeight="bold" display="flex">
              <Box fontSize={24} pt={3.5}>
                $
              </Box>
              <Box fontSize={48}>
                {selectedVariance?.quoteVariance?.previousAmount || 0}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box display="flex" width="50%" m={2}>
            <Box
              component="span"
              width="40%"
              color="text.secondary"
              pr={1}
              pt={2}
            >
              <Box fontSize={16}>Delta ($)</Box>
              <Box component="span" fontSize={24}>
                {selectedVariance?.quoteVariance?.deltaCost > 0 ? (
                  <ArrowDropUpIcon fontSize="small" color="error" />
                ) : (
                  <ArrowDropDownIcon fontSize="small" color="primary" />
                )}
                {`${selectedVariance?.quoteVariance?.deltaPercent || 0}%`}
              </Box>
            </Box>
            <Box component="span" display="flex" fontSize={48}>
              <Box fontSize={24} pt={3.5}>
                $
              </Box>
              <Box fontSize={48}>
                {Math.abs(selectedVariance?.quoteVariance?.deltaCost || 0)}
              </Box>
            </Box>
          </Box>
          <Box display="flex" width="50%" m={2}>
            <Box component="span" width="40%" color="text.secondary" pr={1}>
              <Box pt={2} fontSize={16}>
                Negotiated Cost($){' '}
                {selectedVariance.negotiatedCostType?.toLowerCase() !==
                'material not found'
                  ? selectedVariance.negotiatedCostType
                  : ''}
              </Box>
            </Box>
            <Box component="span" display="flex" fontSize={48}>
              <Box fontSize={24} pt={3.5}>
                $
              </Box>
              <Box fontSize={48}>{selectedVariance.negotiatedCost}</Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <div
        style={{ width: '100%' }}
        hidden={!roles.includes(USER_ROLES.SUPPLIER)}
        id="scrollable-force-tabpanel-0"
      >
        <Box mr={4} ml={4} mb={1} display="flex" flexDirection="row">
          <Box width="40%">
            <Box display="flex">
              <Box component="span" color="text.secondary" pr={1}>
                <Box pt={2} fontSize={16}>
                  New Cost :
                </Box>
              </Box>
              <Box
                component="span"
                fontWeight="bold"
                display="flex"
                color="accent.main"
                width="50%"
              >
                <OutlinedInput
                  inputProps={{ min: 0 }}
                  id="outlined-adornment-amount"
                  value={newCost}
                  onChange={val => setNewCost(val.target.value)}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  type="number"
                  aria-describedby="outlined-amount-helper-text"
                />
              </Box>
            </Box>
          </Box>
          {newCost && (
            <Box width="60%" className="pl-4">
              <Box display="flex">
                <Box component="span" color="text.secondary" width="30%" pr={1}>
                  <Box pt={2} fontSize={16}>
                    Justification :
                  </Box>
                </Box>
                <Box
                  component="span"
                  fontWeight="bold"
                  display="flex"
                  color="accent.main"
                  width="70%"
                >
                  <Select
                    style={{ width: '100%' }}
                    variant="outlined"
                    value={justification}
                    onChange={val => updateJustification(val.target.value)}
                  >
                    <MenuItem value="inventory">Inventory</MenuItem>
                    <MenuItem value="market_change">Market change</MenuItem>
                    <MenuItem value="adder">Adder</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box mr={4} ml={4} mb={1} display="flex" justifyContent="flex-end">
          <Box mx={1}>
            <Button
              onClick={() => {
                setNewCost('');
                updateJustification('');
              }}
            >
              Cancel
            </Button>
          </Box>
          <Box mx={1}>
            <Button
              type="submit"
              btnType="primary"
              mr={0}
              disabled={loading || !newCost || !justification}
              onClick={() => {
                if (selectedVariance?.quoteVariance?.id) {
                  updateVarianceCost({
                    quoteId: selectedVariance?.id,
                    quoteVarianceId: selectedVariance?.quoteVariance.id,
                    justification,
                    amount: newCost,
                    userId: id,
                    actionType: 'justify-variance',
                    action: {
                      pagination,
                      filter,
                      orderBy,
                    },
                    type,
                    returnObj: true,
                    returnObjType: type,
                  });
                } else {
                  createCostVariance({
                    quoteId: selectedVariance?.id,
                    values: {
                      materialQuoteId: selectedVariance?.id,
                      currAmount: newCost,
                      justification,
                    },
                    payload: {
                      pagination,
                      filter,
                      orderBy,
                    },
                    type,
                    returnObj: true,
                    returnObjType: type,
                  });
                }
                closeDrawer();
              }}
            >
              Submit
            </Button>
          </Box>
          <Box
            mr={1}
            style={
              USER_ROLES.SUPPLIER === targetRole &&
              selectedVariance?.quoteVariance?.varianceType === COST_DECREASE &&
              parseFloat(`${selectedVariance?.amount}`) <
                parseFloat(
                  `${selectedVariance?.quoteVariance?.previousAmount}`
                ) &&
              !selectedVariance?.quoteVariance?.approved
                ? {}
                : { display: 'none' }
            }
          >
            <Button
              btnType="primary"
              type="submit"
              disabled={loading}
              onClick={() => {
                acceptVarianceDecreaseType({
                  quoteVarianceId: selectedVariance?.quoteVariance.id,
                  userId: id,
                  action: {
                    pagination,
                    filter,
                    orderBy,
                  },
                  type,
                });
                closeDrawer();
              }}
            >
              Approve
            </Button>
          </Box>
        </Box>
      </div>
      <div
        role="tabpanel"
        hidden={!roles.includes(USER_ROLES.SPM)}
        id="scrollable-force-tabpanel-1"
        aria-labelledby="scrollable-force-tab-1"
      >
        <Box mr={4} ml={4} mb={1} display="flex" justifyContent="flex-end">
          <Box mr={1}>
            <Button
              btnType="primary"
              type="submit"
              // IF APPROVED OR NOT SPM/ADMIN
              disabled={
                loading ||
                ((selectedVariance?.quoteVariance?.approved ||
                  USER_ROLES.SUPPLIER !== targetRole) &&
                  USER_ROLES.SPM !== targetRole) ||
                (selectedVariance?.quoteVariance?.varianceType ===
                  COST_DECREASE &&
                  parseFloat(`${selectedVariance?.amount}`) <
                    parseFloat(
                      `${selectedVariance?.quoteVariance?.previousAmount}`
                    ) &&
                  USER_ROLES.SUPPLIER === targetRole)
              }
              onClick={() => {
                acceptVarianceCost({
                  quoteVarianceId: selectedVariance?.quoteVariance.id,
                  userId: id,
                  action: {
                    pagination,
                    filter,
                    orderBy,
                  },
                  type,
                });
                closeDrawer();
              }}
            >
              Approve
            </Button>
          </Box>
          <Box>
            <Button
              btnType="primary"
              type="submit"
              disabled={
                loading ||
                ((!selectedVariance?.quoteVariance?.approved ||
                  USER_ROLES.SUPPLIER !== targetRole) &&
                  USER_ROLES.SPM !== targetRole)
              }
              onClick={() => {
                rejectVarianceCost({
                  quoteVarianceId: selectedVariance?.quoteVariance.id,
                  userId: id,
                  action: {
                    pagination,
                    filter,
                    orderBy,
                  },
                  type,
                });
                closeDrawer();
              }}
            >
              Reject
            </Button>
          </Box>
        </Box>
      </div>
      <Comments
        entityId={selectedVariance?.quoteVariance?.id}
        disabled={!selectedVariance?.quoteVariance}
        entityType={entityType}
        userId={id}
        userRoles={roles}
      />
    </Box>
  );
};

VarianceApproval.propTypes = {
  closeDrawer: func,
  historyProps: VarianceHistory,
  sharedMaterialsProps: SharedMaterials,
  pccProps: PccPayload,
  selectedVariance: shape({
    material: shape({
      type: string,
      materialIdentifiers: arrayOf(
        shape({
          ownedBy: string,
          identifier: string,
        })
      ),
    }),
    approved: bool,
    id: string,
    negotiatedPrice: number,
    plantId: string,
  }),
  plants: arrayOf(object),
  pagination: Pagination,
  userData: User,
  payload: Payload,
  loading: bool,
  type: string,
};

export default VarianceApproval;
