import moment from 'moment';
import momentTime from 'moment-timezone';

import {
  dashboardRoutes,
  REDUCER_VARIANCES_TYPES,
  COST_CATEGORY_OPTION,
} from './constants';

export const isURL = str => {
  const pattern = new RegExp(`
  ${'^(https?:\\/\\/)?' /* protocol */}
  ${'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' /* domain name */}
  ${'((\\d{1,3}\\.){3}\\d{1,3}))' /* OR ip (v4) address */}
  ${'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' /* port and path */}
  ${'(\\?[;&a-z\\d%_.~+=-]*)?' /* query string */}
  ${'(\\#[-a-z\\d_]*)?$' /* fragment locator */}
  ${'i'}`);
  // const pattern = new RegExp(`^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$`);
  return !!pattern.test(str);
};

export const isIEBrowser = () => {
  return !!document.documentMode;
};

export const createReducer = (schema, reducer) => (
  initialState = schema,
  action$
) => (reducer[action$.type] || (() => initialState))(initialState, action$);

export const buildRouteParams = (route, ...args) => {
  let i = -1;
  const regex = /(:\w+)/g;
  const final = route
    .split(regex)
    .map(chunk => {
      if (regex.test(chunk)) {
        i += 1;
        return args[i];
      }
      return chunk;
    })
    .join('');
  return final;
};

export const dispatch = (type, payload) => ({ type, payload });

export const getById = (src, id) => {
  if (src && Array.isArray(src) && id) {
    return src.find(current => current.id === id);
  }
  return null;
};

export const generateRandomInputKey = () => Math.random().toString(36);

export const getFormattedDateForForm = (date, formatString) => {
  return moment(date).format(formatString);
};

export const convertDateUTCToCST = (date, formatString) => {
  return momentTime(date)
    .tz('America/Chicago') // Central time zone
    .format(formatString);
};

export const getCurrencyCodeFromObject = currencyObject => {
  return currencyObject.filter(
    (ele, ind) =>
      ind ===
      currencyObject.findIndex(
        elem => elem.code === ele.code && ele.code !== 'USD'
      )
  );
};
export const getUniqeCommodityManagers = commodityList => {
  const commodityManagers = [];
  const uniqueCommodityList = [];
  commodityList.forEach(commodity => {
    if (!commodityManagers.includes(commodity.longName)) {
      commodityManagers.push(commodity.longName);
      uniqueCommodityList.push(commodity);
    }
  });
  return uniqueCommodityList;
};

export const getPastOneDateFromCurrentDate = () => {
  return new Date(new Date().setDate(new Date().getDate() - 1));
};

export const objToParams = obj =>
  Object.entries(obj)
    .map(
      ([key, value]) => `${key}=${encodeURIComponent(JSON.stringify(value))}`
    )
    .join('&');

export const getAuthenticatedRoutesForUserRoles = roles => {
  const routes = dashboardRoutes;
  return routes.filter(
    currentRoute =>
      !currentRoute.requiredUserRoles.length ||
      currentRoute.requiredUserRoles.some(value =>
        (roles || []).includes(value)
      )
  );
};

export const validateCalculatedConditional = (field, operator, param) => {
  switch (operator) {
    case 'gte':
      return field >= param;
    case 'lte':
      return field <= param;
    case 'gt':
      return field > param;
    case 'lt':
      return field < param;
    case 'eq':
      return field === param;
    default:
      return false;
  }
};

export const showMaterialVarianceType = varianceType => {
  switch (varianceType) {
    case 'new':
      return 'New Material';

    case 'deleted':
      return 'Deleted Material';

    case 'Alternate Material':
      return 'Alternate Material';

    default:
      return 'Quantity Change';
  }
};

export const addAllKeyObjToOptions = (array, index, newItem) => {
  return [...array.slice(0, index), newItem, ...array.slice(index)];
};

export const capitalizeFirstLetter = word =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const titleCase = string =>
  string
    .split(' ')
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join(' ');

export const varianceLoadingStatus = (approved = null) => (
  state,
  {
    payload: {
      quoteId,
      bomLineId,
      quoteVarianceId,
      materialVarianceId,
      type,
      updatedObject = null,
    },
  }
) => {
  // eslint-disable-next-line
  if (state[type] !== undefined) {
    const {
      [type]: {
        response: { data },
      },
    } = state;
    const index = data.findIndex(({ quoteVariance, materialVariance, id }) => {
      if (quoteVarianceId) {
        return quoteVariance
          ? quoteVariance.id === quoteVarianceId
          : quoteVarianceId === id;
      }
      if (materialVarianceId) {
        return materialVariance
          ? materialVariance.id === materialVarianceId
          : materialVarianceId === id;
      }
      if (bomLineId) {
        return bomLineId === id;
      }
      if (quoteId) {
        return quoteId === id;
      }
      return false;
    });

    if (index > -1) {
      // eslint-disable-next-line
      const obj = updatedObject || data[index];
      const isLoadingOrNotUpdated = !updatedObject || approved === null;
      switch (true) {
        case isLoadingOrNotUpdated && type === REDUCER_VARIANCES_TYPES.COST:
          obj.quoteVariance.approved = approved;
          break;
        case isLoadingOrNotUpdated && type === REDUCER_VARIANCES_TYPES.BOM:
          obj.materialVariance = {
            ...(obj.materialVariance || {}),
            approved,
          };
          break;
        // else manufacturing, procurement, distribution, quantity
        case isLoadingOrNotUpdated:
          obj.approved = approved;
          break;
        default:
      }

      // eslint-disable-next-line
      data[index] = obj;
    }
    return {
      ...state,
      [type]: {
        // eslint-disable-next-line
        ...state[type],
        response: {
          // eslint-disable-next-line
          ...state[type].response,
          data,
        },
      },
    };
  }
  return { ...state };
};

export const cleanVarianceFilters = payload => {
  payload.filter = payload.filter.filter(obj => {
    if (typeof obj.field === 'object') {
      if (typeof obj.value !== 'boolean') {
        obj.field = obj.field?.null || obj.field;
        return obj;
      }
      obj.field = obj.value ? obj.field.true : obj.field.false;
      return obj;
    }
    return obj;
  });
};

export const negotiatedCostCategoryTypeFilter = negotiatedFilter => {
  const negotiatedCostType = negotiatedFilter.map(filter => {
    return {
      ...filter,
      value:
        filter.value === COST_CATEGORY_OPTION.TK_QoQ
          ? COST_CATEGORY_OPTION.ABC
          : filter.value,
    };
  });
  return negotiatedCostType;
};
