import React, { PureComponent, createRef } from 'react';
import { Box, Typography, styled, Popover } from '@material-ui/core';
import {
  string,
  oneOfType,
  number,
  object,
  func,
  arrayOf,
  bool,
  shape,
} from 'prop-types';
import { StyledIcon } from 'grommet-icons/StyledIcon';
import ColumnFilter from './ColumnFilter';
import LiveFilter from './LiveFilter';
import SortIcon from './Components/SortIcon';

const HIDE_THRESHOLD = 10;

const Base = styled(Box)({
  display: 'flex',
  padding: '0 0 0 4px',
  flexShrink: 0,
  userSelect: 'none',
});

const Border = styled(Box)({
  width: '4px',
  right: 0,
  top: 0,
  bottom: 0,
});
const PopoverStyle = {
  width: '300px',
};

class Column extends PureComponent {
  constructor(props) {
    super(props);

    this.resizable = createRef();
    this.column = createRef();

    this.state = {
      resizing: false,
      popShow: null,
    };
  }

  componentDidMount() {
    const { actions } = this.props;

    if (!actions) {
      window.addEventListener('mousedown', this.onResizeStart);
      window.addEventListener('mouseup', this.onResizeEnd);
      window.addEventListener('mouseleave', this.onResizeEnd);
      window.addEventListener('mousemove', this.onResize);
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;

    if (!actions) {
      window.removeEventListener('mousedown', this.onResizeStart);
      window.removeEventListener('mouseup', this.onResizeEnd);
      window.removeEventListener('mouseleave', this.onResizeEnd);
      window.removeEventListener('mousemove', this.onResize);
    }
  }

  onResizeStart = evt => {
    const { current } = this.resizable;
    if (current.contains(evt.target)) {
      document.body.style.cursor = 'grabbing';
      this.setState({ resizing: true });
    }
  };

  onResize = evt => {
    const { resizing } = this.state;

    if (resizing) {
      const { onResize } = this.props;
      const { current: handler } = this.resizable;
      const { current: column } = this.column;

      const { left } = handler.getBoundingClientRect();
      const { width } = column.getBoundingClientRect();
      const delta = left - evt.x;
      const size = Math.trunc(width - delta);

      onResize(size > HIDE_THRESHOLD ? `${size}px` : `${HIDE_THRESHOLD}px`);
    }
  };

  onResizeEnd = evt => {
    const { resizing } = this.state;

    if (resizing) {
      this.setState({ resizing: false }, () => {
        document.body.style.cursor = 'auto';

        const { onResize } = this.props;
        const { current: handler } = this.resizable;
        const { current: column } = this.column;

        const { left } = handler.getBoundingClientRect();
        const { width } = column.getBoundingClientRect();

        const delta = left - evt.x;
        const size = Math.trunc(width - delta);

        if (size <= HIDE_THRESHOLD) {
          onResize(0);
        }
      });
    }
  };

  handleClick = () => {
    this.setState({ popShow: this.column.current });
  };

  handleClose = () => {
    this.setState({ popShow: null });
  };

  onFiltering = payload => {
    const { filters, onFiltering } = this.props;
    let tmpFilters = filters;
    const { operator, value, field, type, from, to } = payload;

    const currentFilterIndex = tmpFilters.findIndex(x => x.field === field);
    if (value === 'all' || operator === 'all') {
      tmpFilters.splice(currentFilterIndex, 1);
    } else if (currentFilterIndex !== -1) {
      tmpFilters[currentFilterIndex] = {
        ...tmpFilters[currentFilterIndex],
        value,
        operator,
        from,
        to,
      };
    } else {
      tmpFilters = [...tmpFilters, { field, value, operator, type }];
    }
    onFiltering(tmpFilters);
  };

  render() {
    const {
      label,
      id,
      size,
      actions,
      sort = null,
      filter = null,
      filters,
      onSorting,
      orderBy,
      pinned,
      draggable,
      onDragStart,
      isLastElement,
    } = this.props;
    const { popShow, resizing } = this.state;

    const open = Boolean(popShow);
    const popId = open ? 'simple-popover' : undefined;
    const currentSortIndex = orderBy.findIndex(
      x => sort && x.field === sort.field
    );
    const currentFilterIndex = filters?.findIndex(
      x => !!filter && x.field === filter.field
    );
    // As triple filter came last and it is different to others
    // lets just keep the field as object even when we have one selected
    const currentActiveFilter = filters.find(
      x =>
        !!filter &&
        (x.field === filter.field ||
          (x.type === 'triple' &&
            Object.values(filter.field).includes(x.field)))
    );
    if (currentActiveFilter && filter && filter.type === 'triple') {
      currentActiveFilter.field = filter.field;
      currentActiveFilter.default = filter.default;
    }
    return (
      <Base
        ref={this.column}
        style={{
          width: size,
        }}
        aria-describedby={popId}
      >
        <Typography
          style={{
            flex: 1,
            fontSize: currentFilterIndex !== -1 ? 17.5 : 17,
          }}
          variant={currentFilterIndex !== -1 ? 'h6' : 'inherit'}
          pt={2}
          noWrap
          draggable={resizing ? null : draggable}
          onClick={
            sort
              ? () => {
                  let tmpOrderBy = orderBy;
                  if (currentSortIndex === -1) {
                    tmpOrderBy = [...orderBy, { ...sort, value: 'ASC' }];
                  } else if (tmpOrderBy[currentSortIndex].value === 'ASC') {
                    tmpOrderBy[currentSortIndex].value = 'DESC';
                  } else {
                    tmpOrderBy.splice(currentSortIndex, 1);
                  }

                  return onSorting(tmpOrderBy);
                }
              : null
          }
          onDragStart={evt => {
            onDragStart(evt, id, !!pinned);
          }}
          className="draggable"
        >
          {size !== `${HIDE_THRESHOLD}px`
            ? label ||
              id
                .replace(/([A-Z])/g, ' $1')
                .toLowerCase()
                .split(' ')
                .map(text => `${text.charAt(0).toUpperCase()}${text.slice(1)}`)
                .join(' ')
            : ' '}
          {!actions && (sort || !!orderBy[currentSortIndex]) ? (
            <SortIcon orderBy={orderBy[currentSortIndex]?.value || null} />
          ) : null}
        </Typography>
        {!!filter && (
          <>
            <Box
              component={StyledIcon}
              cursor="pointer"
              viewBox="0 0 24 24"
              a11yTitle="Filter"
              size="small"
              onClick={this.handleClick}
              p={0}
            >
              <polygon
                style={{ fill: currentFilterIndex !== -1 ? 'black' : 'none' }}
                stroke="#000"
                strokeWidth="2"
                points="3 6 10 13 10 21 14 21 14 13 21 6 21 3 3 3"
              />
            </Box>
            <Popover
              id={popId}
              open={open}
              style={PopoverStyle}
              anchorEl={popShow}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {filter.type === 'input' ? (
                <LiveFilter
                  onClose={this.handleClose}
                  label={label}
                  filter={currentActiveFilter || filter}
                  options={filter.options || []}
                  onFiltering={payload => this.onFiltering(payload)}
                />
              ) : (
                <ColumnFilter
                  label={label}
                  filter={currentActiveFilter || filter}
                  options={filter.options || []}
                  onFiltering={payload => {
                    this.onFiltering(payload);
                    this.handleClose();
                  }}
                />
              )}
            </Popover>
          </>
        )}
        {!actions && (
          <Border
            ref={this.resizable}
            style={{
              cursor: !resizing ? 'col-resize' : 'none',
              borderRight: isLastElement ? 'none' : 'solid 2px #BBB',
            }}
          />
        )}
      </Base>
    );
  }
}

Column.propTypes = {
  id: string.isRequired,
  label: string,
  size: oneOfType([string, number]),
  filter: shape({
    options: arrayOf(object),
    type: string,
    key: string,
  }),
  sort: shape({
    value: string,
    field: string,
  }),
  onFiltering: func,
  onSorting: func,
  filters: arrayOf(object),
  orderBy: arrayOf(object),
  onResize: func,
  actions: bool,
  draggable: bool,
  onDragStart: func,
  pinned: bool,
  isLastElement: bool,
};

export default Column;
