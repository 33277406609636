import React from 'react';
import { Typography, Box, Checkbox as Base } from '@material-ui/core';
import {
  string,
  oneOfType,
  number,
  func,
  object,
  array,
  bool,
} from 'prop-types';
import { Pin } from 'grommet-icons';
import { isIEBrowser } from '../../../common/helpers';

const getLabel = ({ key, label }) =>
  label ||
  key
    .replace(/([A-Z])/g, ' $1')
    .toLowerCase()
    .split(' ')
    .map(text => `${text.charAt(0).toUpperCase()}${text.slice(1)}`)
    .join(' ');

const Checkbox = ({ size, column, src, actions, pinned, onToggle }) => {
  const checked = size !== 0;
  const extended = checked ? { size: 0 } : {};

  return (
    <Box
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Base
        checked={checked}
        color="primary"
        onChange={() => {
          onToggle(
            src.map(current =>
              current.key === column.key ? { ...column, ...extended } : current
            ),
            { [column.key]: { size: checked ? 0 : null } }
          );
        }}
        disabled={actions}
      />
      <Typography noWrap>{getLabel(column)}</Typography>
      <Box flex={1} />
      {!isIEBrowser() && (
        <Box ml={1} style={{ marginTop: '-6px' }}>
          <Pin size="18px" color={pinned ? '#666' : '#DDD'} />
        </Box>
      )}
    </Box>
  );
};

Checkbox.propTypes = {
  size: oneOfType([number, string]),
  // eslint-disable-next-line
  column: object,
  // eslint-disable-next-line
  src: array,
  actions: bool,
  pinned: bool,
  onToggle: func,
};

export default Checkbox;
