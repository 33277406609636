export const GET_COMMENT_LIST_REQUEST = 'GET_COMMENT_LIST_REQUEST';
export const GET_COMMENT_LIST_SUCCESS = 'GET_COMMENT_LIST_SUCCESS';
export const GET_COMMENT_LIST_ERROR = 'GET_COMMENT_LIST_ERROR';

export const GET_COMMENTS_LIST_REQUEST = 'GET_COMMENTS_LIST_REQUEST';
export const GET_COMMENTS_LIST_SUCCESS = 'GET_COMMENTS_LIST_SUCCESS';
export const GET_COMMENTS_LIST_ERROR = 'GET_COMMENTS_LIST_ERROR';

export const ADD_COMMENT_REQUEST = 'ADD_COMMENT_REQUEST';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_ERROR = 'ADD_COMMENT_ERROR';
