export const ROOT = '/';
export const AUTH = '/auth';
export const SAML = '/saml';

export const DASHBOARD = '/dashboard';

export const PROGRAM_ROOT = '/programs';
export const PROGRAM_DETAILS = '/programs/:id';
export const PROGRAM_MATERIALS = '/programs/:id/materials';

export const ADMIN_ROOT = '/admin';
export const ADMIN_PROGRAMS = '/admin/programs';
export const ADMIN_PLANTS = '/admin/plants';
export const ADMIN_COST_GROUP = '/admin/cost_group';
export const ADMIN_NEGOTIATED_COST_UPLOAD = '/admin/negotiated_cost_upload';
export const ADMIN_VENDOR_MAPS = '/admin/vendor_maps';

export const BOM_ROOT = '/bom';

export const COST_ROOT = '/cost';
export const COST_EXCHANGE = '/cost/exchange';
export const COST_FINANCIAL_BURDEN = '/cost/financial_burden';
export const COST_PCC = '/cost/pcc';
export const COST_REBATES = '/cost/rebates';
export const COST_PLANT_PREF = '/cost/plant_preference';
export const COST_CONDITIONS = '/cost/conditions';
export const COST_COMMODITIES = '/cost/commodities';

export const DATA_ROOT = '/data';
export const LOGIN = '/login';

export const QUOTES_SCHEDULES_ROOT = '/quote-schedules';
export const QUOTES_SCHEDULES = '/quote-schedules/:id?';
export const QUOTE_SCHEDULES_LIST = '/schedule-list';

export const VARIANCE_ROOT = '/variances';
export const VARIANCE_COST = '/variances/cost';
export const VARIANCE_QUANTITY = '/variances/quantity';
export const VARIANCE_PRIMARY_ALTERNATE_TAB = '/variances/primaryAlternateTab';
export const QUOTE_VARIANCE = '/variances/:type/view/quotes/:id/:varianceType?';
export const QUOTE_ROOT = '/quotes';
export const QUOTE_DETAILS = '/quotes/:id';
export const QUOTE = '/quotes/:id/view';
export const QUOTE_PROCUREMENT = '/quotes/:id/view/procurement';
export const QUOTE_MANUFACTURING = '/quotes/:id/view/manufacturing';
export const QUOTE_MANUFACTURING_BOM_VIEW =
  '/quotes/:programId/view/:materialId/bom/:costFieldName/:plantId';
export const QUOTE_DISTRIBUTION = '/quotes/:id/view/distribution';

export const REPORT_ROOT = '/reports-ui';
export const REPORT_DOWNLOAD = '/reports-ui/download/:id';
export const REPORT_TEMPLATES = '/reports-ui/templates';
export const REPORT_CUSTOM = '/reports-ui/custom';
export const REPORT_DETAILS = '/reports-ui/templates/:type/view';
