import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/exchange-rate`;

const exchangeService = {
  getAllExchange: payload => {
    return service.get(`${api}`, payload);
  },
  createExchange: data => {
    return service.post(`${api}`, data);
  },
  updateExchange: (id, data) => {
    return service.put(`${api}/${id}`, data);
  },
  deleteExchange: id => {
    return service.remove(`${api}/${id}`);
  },
};

export default exchangeService;
