import {
  CREATE_PLANT_PREF_REQUEST,
  CREATE_PLANT_PREF_SUCCESS,
  CREATE_PLANT_PREF_ERROR,
  FETCH_PLANT_PREF_REQUEST,
  FETCH_PLANT_PREF_SUCCESS,
  FETCH_PLANT_PREF_ERROR,
  UPDATE_PLANT_PREF_REQUEST,
  UPDATE_PLANT_PREF_SUCCESS,
  UPDATE_PLANT_PREF_ERROR,
  UPDATE_PLANT_PREF_SORT_REQUEST,
  UPDATE_PLANT_PREF_SORT_SUCCESS,
  UPDATE_PLANT_PREF_SORT_ERROR,
  DELETE_PLANT_PREF_REQUEST,
  DELETE_PLANT_PREF_ERROR,
} from './actions';
import { REDUCERS } from '../../common/constants';
import { createReducer } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  list: {
    loading: false,
    error: false,
    success: false,
    response: { data: [], pagination: {} },
  },
};

const reducer = {
  [CREATE_PLANT_PREF_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
      success: false,
    },
  }),
  [CREATE_PLANT_PREF_SUCCESS]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.SUCCESS,
      success: true,
      error: false,
    },
  }),
  [CREATE_PLANT_PREF_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
      success: false,
    },
  }),
  [FETCH_PLANT_PREF_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_PLANT_PREF_SUCCESS]: (state, { payload: response }) => ({
    ...state,
    list: {
      ...REDUCERS.SUCCESS,
      success: false,
      response,
    },
  }),
  [FETCH_PLANT_PREF_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [UPDATE_PLANT_PREF_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [UPDATE_PLANT_PREF_SUCCESS]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.SUCCESS,
    },
  }),
  [UPDATE_PLANT_PREF_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [UPDATE_PLANT_PREF_SORT_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [UPDATE_PLANT_PREF_SORT_SUCCESS]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.SUCCESS,
    },
  }),
  [UPDATE_PLANT_PREF_SORT_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [DELETE_PLANT_PREF_REQUEST]: state => ({
    ...state,
    list: {
      ...REDUCERS.LOADING,
      response: state.list.response,
    },
  }),

  [DELETE_PLANT_PREF_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
};

export default createReducer(schema, reducer);
