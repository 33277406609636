// Cost Sequence Group Actions
export const FETCH_COST_SEQUENCE_LIST_REQUEST =
  'FETCH_COST_SEQUENCE_LIST_REQUEST';
export const FETCH_COST_SEQUENCE_LIST_SUCCESS =
  'FETCH_COST_SEQUENCE_LIST_SUCCESS';
export const FETCH_COST_SEQUENCE_LIST_ERROR = 'FETCH_COST_SEQUENCE_LIST_ERROR';

export const RESET_SEQUENCE_DATA = 'RESET_SEQUENCE_DATA';
export const RESET_SEQUENCE_SUCCESS = 'RESET_SEQUENCE_SUCCESS';

export const CREATE_COST_SEQUENCE_REQUEST = 'CREATE_COST_SEQUENCE_REQUEST';
export const CREATE_COST_SEQUENCE_SUCCESS = 'CREATE_COST_SEQUENCE_SUCCESS';
export const CREATE_COST_SEQUENCE_ERROR = 'CREATE_COST_SEQUENCE_ERROR';

export const UPDATE_COST_SEQUENCE_REQUEST = 'UPDATE_COST_SEQUENCE_REQUEST';
export const UPDATE_COST_SEQUENCE_SUCCESS = 'UPDATE_COST_SEQUENCE_SUCCESS';
export const UPDATE_COST_SEQUENCE_ERROR = 'UPDATE_COST_SEQUENCE_ERROR';

export const DELETE_COST_SEQUENCE_REQUEST = 'DELETE_COST_SEQUENCE_REQUEST';
export const DELETE_COST_SEQUENCE_SUCCESS = 'DELETE_COST_SEQUENCE_SUCCESS';
export const DELETE_COST_SEQUENCE_ERROR = 'DELETE_COST_SEQUENCE_ERROR';
