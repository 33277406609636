/* eslint-disable react/no-array-index-key */
import React from 'react';
import { string } from 'prop-types';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router';
import BreadcrumbItem from './BreadcrumbItem';
import { URL_ALIASES } from '../../../common/breadcrumbAliases';

const Breadcrumb = ({ route }) => {
  const params = useParams();

  const { levels } = URL_ALIASES.find(({ url }) => url === route) || {
    levels: [],
  };
  const breadcrumbComponents = levels.map((level, idx, arr) => {
    const last = idx === arr.length - 1;
    if (typeof level === 'string') {
      return <BreadcrumbItem label={level} key={idx} last={last} />;
    }
    const { label, link, alias, ...rest } = level;

    const keys = Object.keys(rest);
    const id = params[keys[0]];

    const url =
      keys.length > 0
        ? Object.entries(rest).reduce((acc, [key]) => {
            return acc.replace(`:${(alias && alias[key]) || key}`, params[key]);
          }, link || '')
        : link;

    return (
      <BreadcrumbItem
        label={label || localStorage.getItem(id) || id}
        link={url}
        key={idx}
        last={last}
      />
    );
  });

  return (
    <Box mb={2} ml={3} mr={3} fontSize={12}>
      {breadcrumbComponents}
    </Box>
  );
};

Breadcrumb.propTypes = {
  route: string,
};

export default Breadcrumb;
