import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/vendor-maps`;

export const getAllVendorMaps = payload => service.get(`${api}`, payload);

export const getVendorMap = id => service.get(`${api}/${id}`);

export const createVendorMap = data => service.post(`${api}`, data);

export const updateVendorMap = (id, data) => service.put(`${api}/${id}`, data);

export const deleteVendorMap = id => service.remove(`${api}/${id}`);

export default {
  getAllVendorMaps,
  getVendorMap,
  createVendorMap,
  updateVendorMap,
  deleteVendorMap,
};
