// REPORT Actions
export const FETCH_REPORT_REQUEST = 'FETCH_REPORT_REQUEST';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_ERROR = 'FETCH_REPORT_ERROR';

export const FETCH_REPORT_DETAIL_REQUEST = 'FETCH_REPORT_DETAIL_REQUEST';
export const FETCH_REPORT_DETAIL_SUCCESS = 'FETCH_REPORT_DETAIL_SUCCESS';
export const FETCH_REPORT_DETAIL_ERROR = 'FETCH_REPORT_DETAIL_ERROR';

export const DOWNLOAD_REPORT_REQUEST = 'DOWNLOAD_REPORT_REQUEST';
export const DOWNLOAD_REPORT_SUCCESS = 'DOWNLOAD_REPORT_SUCCESS';
export const DOWNLOAD_REPORT_ERROR = 'DOWNLOAD_REPORT_ERROR';

export const FETCH_REPORT_FILTER_REQUEST = 'FETCH_REPORT_FILTER_REQUEST';
export const FETCH_REPORT_FILTER_SUCCESS = 'FETCH_REPORT_FILTER_SUCCESS';
export const FETCH_REPORT_FILTER_ERROR = 'FETCH_REPORT_FILTER_ERROR';

export const RESET_REPORT_DATA_REQUEST = 'RESET_REPORT_DATA_REQUEST';
export const RESET_REPORT_DATA_SUCCESS = 'RESET_REPORT_DATA_SUCCESS';
export const RESET_REPORT_DATA_ERROR = 'RESET_REPORT_DATA_ERROR';

export const FETCH_REPORT_PROFIT_CENTER_REQUEST =
  'FETCH_REPORT_PROFIT_CENTER_REQUEST';
export const FETCH_REPORT_TYPE_REQUEST = 'FETCH_REPORT_TYPE_REQUEST';
export const FETCH_REPORT_PROCUREMENT_TYPE_REQUEST =
  'FETCH_REPORT_PROCUREMENT_TYPE_REQUEST';
export const FETCH_REPORT_X_PLANT_STATUS_REQUEST =
  'FETCH_REPORT_X_PLANT_STATUS_REQUEST';
export const FETCH_REPORT_PLANT_SPECIFIC_MATERIAL_REQUEST =
  'FETCH_REPORT_PLANT_SPECIFIC_MATERIAL_REQUEST';
export const FILTER_REPORT_API_SUCCESS = 'FETCH_REPORT_PROFIT_CENTER_SUCCESS';
export const FILTER_REPORT_API_ERROR = 'FETCH_REPORT_PROFIT_CENTER_ERROR';
export const NEGOTIATED_COST_DATE_REQUEST = 'NEGOTIATED_COST_DATE_REQUEST';
export const NEGOTIATED_COST_DATE_SUCCESS = 'NEGOTIATED_COST_DATE_SUCCESS';
export const NEGOTIATED_COST_DATE_ERROR = 'NEGOTIATED_COST_DATE_ERROR';

export const FETCH_RUN_DATE_REQUEST = 'FETCH_RUN_DATE_REQUEST';
export const FETCH_RUN_DATE_SUCCESS = 'FETCH_RUN_DATE_SUCCESS';
export const FETCH_RUN_DATE_ERROR = 'FETCH_RUN_DATE_ERROR';

export const FETCH_TOTAL_COUNT_REQUEST = 'FETCH_TOTAL_COUNT_REQUEST';
export const FETCH_TOTAL_COUNT_SUCCESS = 'FETCH_TOTAL_COUNT_SUCCESS';
export const FETCH_TOTAL_COUNT_ERROR = 'FETCH_TOTAL_COUNT_ERROR';

export const UPDATE_TOTAL_COUNT_REQUEST = 'UPDATE_TOTAL_COUNT_REQUEST';
export const UPDATE_TOTAL_COUNT_SUCCESS = 'UPDATE_TOTAL_COUNT_SUCCESS';

export const EMAIL_REPORT_REQUEST = 'EMAIL_REPORT_REQUEST';

export const DOWNLOAD_SAVED_REPORT_REQUEST = 'DOWNLOAD_SAVED_REPORT_REQUEST';
export const DOWNLOAD_SAVED_REPORT_SUCCESS = 'DOWNLOAD_SAVED_REPORT_SUCCESS';
export const DOWNLOAD_SAVED_REPORT_ERROR = 'DOWNLOAD_SAVED_REPORT_ERROR';

export const EMAIL_CONFIRMATION_STATUS = 'EMAIL_CONFIRMATION_STATUS';
export const RESET_EMAIL_CONFIRMATION_REQUEST =
  'RESET_EMAIL_CONFIRMATION_REQUEST';
export const RESET_EMAIL_CONFIRMATION_SUCCESS =
  'RESET_EMAIL_CONFIRMATION_SUCCESS';

export const DOWNLOAD_MASTER_DATA_REPORT_REQUEST =
  'DOWNLOAD_MASTER_DATA_REPORT_REQUEST';
export const DOWNLOAD_MASTER_DATA_REPORT_SUCCESS =
  'DOWNLOAD_MASTER_DATA_REPORT_SUCCESS';
export const DOWNLOAD_MASTER_DATA_REPORT_ERROR =
  'DOWNLOAD_MASTER_DATA_REPORT_ERROR';
