// PCC List Actions
export const FETCH_PCC_LIST_REQUEST = 'FETCH_PCC_LIST_REQUEST';
export const FETCH_PCC_LIST_SUCCESS = 'FETCH_PCC_LIST_SUCCESS';
export const FETCH_PCC_LIST_ERROR = 'FETCH_PCC_LIST_ERROR';

// Create PCC
export const CREATE_PCC_REQUEST = 'CREATE_PCC_REQUEST';
export const CREATE_PCC_SUCCESS = 'CREATE_PCC_SUCCESS';
export const CREATE_PCC_ERROR = 'CREATE_PCC_ERROR';

// Delete PCC
export const DELETE_PCC_REQUEST = 'DELETE_PCC_REQUEST';
export const DELETE_PCC_SUCCESS = 'DELETE_PCC_SUCCESS';
export const DELETE_PCC_ERROR = 'DELETE_PCC_ERROR';

// Update PCC
export const UPDATE_PCC_REQUEST = 'UPDATE_PCC_REQUEST';
export const UPDATE_PCC_SUCCESS = 'UPDATE_PCC_SUCCESS';
export const UPDATE_PCC_ERROR = 'UPDATE_PCC_ERROR';
