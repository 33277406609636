import {
  GET_COMMENT_LIST_REQUEST,
  GET_COMMENT_LIST_SUCCESS,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  GET_COMMENTS_LIST_REQUEST,
  GET_COMMENTS_LIST_SUCCESS,
} from './actions';
import { REDUCERS } from '../../common/constants';
import { createReducer } from '../../common/helpers';

const schema = {
  loading: false,
  response: { data: [], pagination: {} },
};

const reducer = {
  [GET_COMMENT_LIST_REQUEST]: state => {
    return {
      ...state,
      ...REDUCERS.LOADING,
      response: { data: [], pagination: {}, ...REDUCERS.LOADING },
    };
  },
  [GET_COMMENT_LIST_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      response: { ...payload, ...REDUCERS.SUCCESS },
    };
  },
  [GET_COMMENTS_LIST_REQUEST]: state => {
    return {
      ...state,
      ...REDUCERS.LOADING,
      response: { data: [], pagination: {}, ...REDUCERS.LOADING },
    };
  },
  [GET_COMMENTS_LIST_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      response: { ...payload, ...REDUCERS.SUCCESS },
    };
  },
  [ADD_COMMENT_REQUEST]: state => {
    return {
      ...state,
      ...REDUCERS.LOADING,
    };
  },
  [ADD_COMMENT_SUCCESS]: state => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
    };
  },
};

export default createReducer(schema, reducer);
