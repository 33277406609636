import React, { useReducer, useEffect } from 'react';
import { Box, Switch, ButtonGroup } from '@material-ui/core';
import { object, func, arrayOf } from 'prop-types';
import { SubtractCircle, StatusGood } from 'grommet-icons';

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Select from '../../Select';
import Toggle from '../../Toggle';
import TextFilter from './Filters/TextFilter';
import NumberFilter from './Filters/NumberFilter';
import DateFilter from './Filters/DateFilter';
import Button from '../../Button';
import { themeColor } from '../../../common/constants';

const reducer = (state, obj) => ({ ...state, ...obj });

const validateSubmit = state => {
  if (state.operator && state.operator === 'all') {
    return false;
  }

  if (state.type === 'bool' || state.type === 'triple') {
    return false;
  }

  if (state.value.length === 0 && state.operator !== 'between') {
    return true;
  }

  return state.operator === 'between'
    ? !state.from || !state.to
    : !state.value || !state.operator;
};

const ColumnFilter = ({ filter, onFiltering, options }) => {
  const [state, dispatch] = useReducer(reducer, {
    value:
      // eslint-disable-next-line no-nested-ternary
      filter.type === 'multi-select' ? [] : filter.type === 'bool' ? false : '',
    operator: 'eq',
    ...filter,
  });
  useEffect(() => {
    if (filter.operator === 'between') {
      filter.value = '';
    }
    dispatch(filter);
  }, [filter]);

  return (
    <Box width="250px">
      <Box p={3}>
        <Box>
          {(!filter.type ||
            filter.type === 'select' ||
            filter.type === 'autocomplete' ||
            filter.type === 'multi-select') && (
            <Select
              options={options}
              valueKey="id"
              valueLabel="value"
              onChange={value =>
                dispatch({
                  value,
                  operator: filter.type === 'multi-select' ? 'in' : 'eq',
                })
              }
              value={state.value}
              variant="outlined"
              label="Choose"
              type="multi-select"
            />
          )}
          {filter.type === 'toggle' && (
            <Toggle
              options={options}
              valueKey="id"
              valueLabel="value"
              onChange={value => dispatch({ value, operator: 'eq' })}
              value={state[filter.field] || ''}
              variant="outlined"
              fullWidth
            />
          )}
          {filter.type === 'text' && (
            <TextFilter
              onChange={value => dispatch(value)}
              value={state.value}
              operator={state.operator}
            />
          )}
          {filter.type === 'date' && (
            <DateFilter
              onChange={value => dispatch(value)}
              value={state.value}
              from={state.from}
              to={state.to}
              operator={state.operator}
            />
          )}
          {filter.type === 'number' && (
            <NumberFilter
              onChange={value => dispatch(value)}
              value={state.value}
              from={state.from}
              to={state.to}
              operator={state.operator}
            />
          )}
          {filter.type === 'bool' && (
            <Box display="flex">
              <Box m="auto">
                <Switch
                  size="small"
                  color="primary"
                  checked={state.value || false}
                  onChange={event =>
                    dispatch({ value: event.target.checked, operator: 'eq' })
                  }
                />
              </Box>
            </Box>
          )}
          {filter.type === 'triple' && (
            // if triple is undefined ? target role supplier : approved
            <Box display="flex">
              <Box m="auto">
                <ButtonGroup color="primary">
                  <Button
                    onClick={() => dispatch({ value: true, operator: 'eq' })}
                    disabled={state.value === true}
                    style={{
                      backgroundColor: state.value === true ? themeColor : '',
                    }}
                  >
                    <StatusGood
                      color={state.value === true ? 'white' : '#999999'}
                    />
                  </Button>
                  <Button
                    onClick={() => dispatch({ value: false, operator: 'eq' })}
                    disabled={state.value === false}
                    style={{
                      backgroundColor: state.value === false ? themeColor : '',
                    }}
                  >
                    <SubtractCircle
                      color={state.value === false ? 'white' : '#999999'}
                    />
                  </Button>
                  <Button
                    onClick={() => dispatch({ value: null, operator: 'eq' })}
                    disabled={state.value === null}
                    style={{
                      backgroundColor:
                        state.value === null || state.value === filter.default
                          ? themeColor
                          : '',
                    }}
                  >
                    <AssignmentIndIcon
                      style={{
                        color:
                          state.value === null || state.value === filter.default
                            ? 'white'
                            : '#999999',
                      }}
                    />
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <Box
          component={Button}
          width="50%"
          btnType="secondary"
          onClick={() => {
            if (filter.type === 'triple') {
              onFiltering({
                ...state,
                value: 'all',
                field: state.field.true,
              });
              onFiltering({
                ...state,
                value: 'all',
                field: state.field.null,
              });
              return;
            }
            onFiltering({
              ...state,
              value: 'all',
              // missing ternary
            });
          }}
        >
          Reset
        </Box>
        <Box
          component={Button}
          width="50%"
          btnType="primary"
          disabled={validateSubmit(state)}
          onClick={() => {
            if (filter.type === 'triple') {
              const val =
                state.value !== null && state.value !== filter.default
                  ? state.value
                  : filter.default;
              const isBool = typeof val === 'boolean';
              const current = isBool ? state.field.true : state.field.null;
              const previous = !isBool ? state.field.true : state.field.null;
              if (previous !== current) {
                // Remove Previous
                onFiltering({
                  ...state,
                  value: 'all',
                  field: previous,
                });
              }
              // Set New
              onFiltering({
                ...state,
                value: val,
                field: current,
              });

              return;
            }

            onFiltering({
              ...state,
              value:
                state.operator === 'between'
                  ? [state.from, state.to]
                  : state.value,
            });
          }}
        >
          Apply Filter
        </Box>
      </Box>
    </Box>
  );
};

ColumnFilter.propTypes = {
  onFiltering: func,
  // eslint-disable-next-line react/forbid-prop-types
  filter: object,
  options: arrayOf(object),
};
export default ColumnFilter;
