import React, { Suspense, lazy } from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import {
  DASHBOARD,
  PROGRAM_ROOT,
  ADMIN_ROOT,
  DATA_ROOT,
  BOM_ROOT,
  COST_ROOT,
  VARIANCE_ROOT,
  QUOTES_SCHEDULES_ROOT,
  QUOTE_ROOT,
  QUOTE_MANUFACTURING_BOM_VIEW,
  QUOTE_VARIANCE,
  REPORT_ROOT,
  REPORT_DETAILS,
  ROOT,
  LOGIN,
  SAML,
} from './common/routes';
import { Loader, Layout, Route } from './components';

const Dashboard = lazy(() => import('./views/Dashboard'));
const Program = lazy(() => import('./views/Program'));
const Data = lazy(() => import('./views/Data'));
const Admin = lazy(() => import('./views/Admin'));
const BOM = lazy(() => import('./views/BOM'));
const Cost = lazy(() => import('./views/Cost'));
const Variance = lazy(() => import('./views/Variances'));
const QuoteSchedule = lazy(() => import('./views/QuoteSchedule'));
const Quote = lazy(() => import('./views/Quote'));
const Report = lazy(() => import('./views/Report'));
const ReportDetail = lazy(() => import('./views/ReportDetail'));
const Login = lazy(() => import('./views/Login'));
const Saml = lazy(() => import('./views/Saml'));

const App = () => {
  const { pathname } = useLocation();
  let newPathname = PROGRAM_ROOT;
  if (pathname && pathname !== 'undefined') {
    newPathname = pathname;
  }
  let defaultRoute = sessionStorage?.getItem('defaultRoute');
  if (!defaultRoute || defaultRoute === 'undefined') {
    defaultRoute = DASHBOARD;
  }
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path={LOGIN} component={Login} />
        <Route path={SAML} component={Saml} />
        <Route
          path={ROOT}
          redirectedLink={newPathname}
          component={() => (
            <Layout>
              <Switch>
                <Route path={DASHBOARD} component={Dashboard} />
                <Route path={PROGRAM_ROOT} component={Program} />
                <Route path={ADMIN_ROOT} component={Admin} />
                <Route path={BOM_ROOT} component={BOM} />
                <Route path={QUOTE_MANUFACTURING_BOM_VIEW} component={BOM} />
                <Route path={QUOTE_VARIANCE} component={Variance} />
                <Route path={QUOTES_SCHEDULES_ROOT} component={QuoteSchedule} />
                <Route path={COST_ROOT} component={Cost} />
                <Route path={VARIANCE_ROOT} component={Variance} />
                <Route path={QUOTE_ROOT} component={Quote} />
                <Route path={BOM_ROOT} component={BOM} />
                <Route path={DATA_ROOT} component={Data} />
                <Route path={REPORT_DETAILS} component={ReportDetail} />
                <Route path={REPORT_ROOT} component={Report} />
                <Redirect to={defaultRoute} />
              </Switch>
            </Layout>
          )}
        />
      </Switch>
    </Suspense>
  );
};

export default App;
