import {
  CREATE_EXCHANGE_REQUEST,
  CREATE_EXCHANGE_SUCCESS,
  CREATE_EXCHANGE_ERROR,
  FETCH_EXCHANGE_LIST_REQUEST,
  FETCH_EXCHANGE_LIST_SUCCESS,
  FETCH_EXCHANGE_LIST_ERROR,
  UPDATE_EXCHANGE_REQUEST,
  UPDATE_EXCHANGE_ERROR,
  DELETE_EXCHANGE_REQUEST,
  DELETE_EXCHANGE_SUCCESS,
  DELETE_EXCHANGE_ERROR,
} from './actions';
import { REDUCERS, DATE_FORMAT_STRINGS } from '../../common/constants';
import { createReducer, getFormattedDateForForm } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  list: {
    loading: false,
    error: false,
    success: false,
    response: {
      data: [],
      pagination: {},
      payload: { filter: [], orderBy: [], pagination: {} },
    },
  },
};

const reducer = {
  [CREATE_EXCHANGE_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
      success: false,
    },
  }),
  [CREATE_EXCHANGE_SUCCESS]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.SUCCESS,
      success: true,
      error: false,
    },
  }),
  [CREATE_EXCHANGE_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
      success: false,
    },
  }),
  [FETCH_EXCHANGE_LIST_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_EXCHANGE_LIST_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => {
    const filteredList = data
      ? data.map(val => {
          val.effectiveDate = getFormattedDateForForm(
            val.effectiveDate,
            DATE_FORMAT_STRINGS.TABLE_DATE_FORMAT
          );
          val.baseCurrency = 'USD';
          return val;
        })
      : [];
    return {
      ...state,
      list: {
        ...REDUCERS.SUCCESS,
        success: false,
        response: {
          data: payload?.pagination?.infinite
            ? [...state.list.response.data, ...filteredList]
            : filteredList,
          pagination,
          payload,
        },
      },
    };
  },
  [FETCH_EXCHANGE_LIST_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [UPDATE_EXCHANGE_REQUEST]: state => ({
    ...state,
    list: {
      ...REDUCERS.LOADING,
      response: state.list.response,
    },
  }),
  [UPDATE_EXCHANGE_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [DELETE_EXCHANGE_REQUEST]: state => ({
    ...state,
    list: {
      ...REDUCERS.LOADING,
      response: state.list.response,
    },
  }),

  [DELETE_EXCHANGE_SUCCESS]: (state, { payload: data }) => {
    return {
      ...state,
      list: {
        ...REDUCERS.SUCCESS,
        response: {
          data: state.list.response.data.filter(item => {
            return item.id !== data.id;
          }),
        },
      },
    };
  },

  [DELETE_EXCHANGE_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
};

export default createReducer(schema, reducer);
