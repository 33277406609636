// Financial burden get Actions
export const GET_FINANCIAL_BURDEN_REQUEST = 'GET_FINANCIAL_BURDEN_REQUEST';
export const GET_FINANCIAL_BURDEN_SUCCESS = 'GET_FINANCIAL_BURDEN_SUCCESS';
export const GET_FINANCIAL_BURDEN_ERROR = 'GET_FINANCIAL_BURDEN_ERROR';

// Financial burden create Actions
export const CREATE_FINANCIAL_BURDEN_REQUEST =
  'CREATE_FINANCIAL_BURDEN_REQUEST';
export const CREATE_FINANCIAL_BURDEN_SUCCESS =
  'CREATE_FINANCIAL_BURDEN_SUCCESS';
export const CREATE_FINANCIAL_BURDEN_ERROR = 'CREATE_FINANCIAL_BURDEN_ERROR';

// Financial burden update Actions
export const UPDATE_FINANCIAL_BURDEN_REQUEST =
  'UPDATE_FINANCIAL_BURDEN_REQUEST';
export const UPDATE_FINANCIAL_BURDEN_SUCCESS =
  'UPDATE_FINANCIAL_BURDEN_SUCCESS';
export const UPDATE_FINANCIAL_BURDEN_ERROR = 'UPDATE_FINANCIAL_BURDEN_ERROR';

// Financial burden delete Actions
export const DELETE_FINANCIAL_BURDEN_REQUEST =
  'DELETE_FINANCIAL_BURDEN_REQUEST';
export const DELETE_FINANCIAL_BURDEN_SUCCESS =
  'DELETE_FINANCIAL_BURDEN_SUCCESS';
export const DELETE_FINANCIAL_BURDEN_ERROR = 'DELETE_FINANCIAL_BURDEN_ERROR';
