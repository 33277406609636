// Program List Actions
export const FETCH_PROGRAM_LIST_REQUEST = 'FETCH_PROGRAM_LIST_REQUEST';
export const FETCH_PROGRAM_LIST_SUCCESS = 'FETCH_PROGRAM_LIST_SUCCESS';
export const FETCH_PROGRAM_LIST_ERROR = 'FETCH_PROGRAM_LIST_ERROR';

// Program Material List Actions
export const FETCH_PROGRAM_MATERIAL_LIST_REQUEST =
  'FETCH_PROGRAM_MATERIAL_LIST_REQUEST';
export const FETCH_PROGRAM_MATERIAL_LIST_SUCCESS =
  'FETCH_PROGRAM_MATERIAL_LIST_SUCCESS';
export const FETCH_PROGRAM_MATERIAL_LIST_ERROR =
  'FETCH_PROGRAM_MATERIAL_LIST_ERROR';

export const CREATE_QUOTE_REQUEST = 'CREATE_QUOTE_REQUEST';
export const CREATE_QUOTE_SUCESSS = 'CREATE_QUOTE_SUCESSS';
export const CREATE_QUOTE_ERROR = 'CREATE_QUOTE_ERROR';

// Create Quote Validation Action
export const IS_PROCESSING_PROGRAM_QUOTE_REQUEST =
  'IS_PROCESSING_PROGRAM_QUOTE_REQUEST';
export const IS_PROCESSING_PROGRAM_QUOTE_SUCESSS =
  'IS_PROCESSING_PROGRAM_QUOTE_SUCESSS';
export const IS_PROCESSING_PROGRAM_QUOTE_ERROR =
  'IS_PROCESSING_PROGRAM_QUOTE_ERROR';

// Create Rebate Validation Action
export const IS_PROCESSING_PROGRAM_REBATE_REQUEST =
  'IS_PROCESSING_PROGRAM_REBATE_REQUEST';
export const IS_PROCESSING_PROGRAM_REBATE_SUCESSS =
  'IS_PROCESSING_PROGRAM_REBATE_SUCESSS';
export const IS_PROCESSING_PROGRAM_REBATE_ERROR =
  'IS_PROCESSING_PROGRAM_REBATE_ERROR';

// Create Rebate Action
export const CREATE_REBATE_REQUEST = 'CREATE_REBATE_REQUEST';
export const CREATE_REBATE_SUCESSS = 'CREATE_REBATE_SUCESSS';
export const CREATE_REBATE_ERROR = 'CREATE_QUOTE_ERROR';

// Create Program
export const CREATE_PROGRAM_REQUEST = 'CREATE_PROGRAM_REQUEST';
export const CREATE_PROGRAM_SUCCESS = 'CREATE_PROGRAM_SUCCESS';
export const CREATE_PROGRAM_ERROR = 'CREATE_PROGRAM_ERROR';

// Update Program
export const UPDATE_PROGRAM_REQUEST = 'UPDATE_PROGRAM_REQUEST';
export const UPDATE_PROGRAM_SUCCESS = 'UPDATE_PROGRAM_SUCCESS';
export const UPDATE_PROGRAM_ERROR = 'UPDATE_PROGRAM_ERROR';

// Delete Program
export const DELETE_PROGRAM_REQUEST = 'DELETE_PROGRAM_REQUEST';
export const DELETE_PROGRAM_SUCCESS = 'DELETE_PROGRAM_SUCCESS';
export const DELETE_PROGRAM_ERROR = 'DELETE_PROGRAM_ERROR';
