import * as service from '../common/service';
import { REPORT } from '../common/constants';
import { negotiatedCostCategoryTypeFilter } from '../common/helpers';

const api = `${REPORT}/report`;

const reportsService = {
  getAllReports: () => {
    return service.get(`${api}/available`);
  },
  getReportDetail: ({ id, payload }) => {
    if (id === 'negotiated-cost') {
      const costCategory = payload.filter.filter(
        val => val.field === 'negotiatedCostCategory'
      );
      const costCategoryType = negotiatedCostCategoryTypeFilter(costCategory);

      payload.filter = payload.filter.filter(
        val => val.field !== 'negotiatedCostCategory'
      );
      return service.post(
        `${api}/${id}/${costCategoryType[0].value.toLowerCase()}`,
        payload
      );
    }
    if (id === 'variance-history') {
      const materialNumber = payload.filter.filter(
        val => val.field === 'materialNumber'
      );
      payload.filter = payload.filter.filter(
        val => val.field !== 'materialNumber'
      );
      return service.post(`${api}/${id}/${materialNumber[0].value}`, payload);
    }
    return service.post(`${api}/${id}`, payload);
  },
  emailReport: ({ id, filter, orderBy, email, userName }) => {
    if (id === 'variance-history') {
      const materialNumber = filter.filter(
        val => val.field === 'materialNumber'
      );
      const updatedFilter = filter.filter(
        val => val.field !== 'materialNumber'
      );
      return service.post(`${api}/${id}/${materialNumber[0].value}/download`, {
        filter: updatedFilter,
        orderBy,
        // selectedFields,
        email,
        userName,
        emailConfirmation: true,
      });
    }
    if (id === 'negotiated-cost') {
      const costCategory = filter.filter(
        val => val.field === 'negotiatedCostCategory'
      );
      const updatedFilter = filter.filter(
        val => val.field !== 'negotiatedCostCategory'
      );
      return service.file(
        `${api}/${id}/${costCategory[0].value.toLowerCase()}/download`,
        {
          filter: updatedFilter,
          orderBy,
          // selectedFields,
          email,
          userName,
          emailConfirmation: true,
        }
      );
    }
    return service.post(`${api}/${id}/download`, {
      filter,
      orderBy,
      // selectedFields,
      email,
      userName,
      emailConfirmation: true,
    });
  },
  downloadReport: ({
    id,
    filter,
    orderBy,
    selectedFields,
    email,
    userName,
  }) => {
    if (id === 'negotiated-cost') {
      const costCategory = filter.filter(
        val => val.field === 'negotiatedCostCategory'
      );
      const updatedFilter = filter.filter(
        val => val.field !== 'negotiatedCostCategory'
      );
      return service.file(
        `${api}/${id}/${costCategory[0].value.toLowerCase()}/download`,
        {
          filter: updatedFilter,
          orderBy,
          selectedFields,
          emailConfirmation: false,
        }
      );
    }
    if (id === 'variance-history') {
      const materialNumber = filter.filter(
        val => val.field === 'materialNumber'
      );
      const updatedFilter = filter.filter(
        val => val.field !== 'materialNumber'
      );
      return service.file(`${api}/${id}/${materialNumber[0].value}/download`, {
        filter: updatedFilter,
        orderBy,
        selectedFields,
        email,
        userName,
        emailConfirmation: false,
      });
    }
    if (id === 'structured-quote') {
      return service.fileBlob(`${api}/${id}/download`, {
        filter,
        orderBy,
        selectedFields,
        email,
        userName,
        emailConfirmation: false,
      });
    }
    return service.file(`${api}/${id}/download`, {
      filter,
      orderBy,
      selectedFields,
      email,
      userName,
      emailConfirmation: false,
    });
  },
  getReportFilter: ({ id }) => {
    return service.get(`${api}/${id}/describe-filters`);
  },
  getNegotiatedCostDates: payload => {
    return service.get(
      `${api}/negotiated-cost/${payload.toLowerCase()}/available-dates`
    );
  },
  fetchRunDateForReports: () => {
    return service.get(`${api}/run-date`);
  },
  fetchTotalCountForReports: ({ id, payload }) => {
    return service.post(`${api}/${id}/count`, payload);
  },
  downloadSavedReport: payload => {
    return service.getFile(`${api}/download/?fileName=${payload}`);
  },
  downloadMasterDataReport: payload => {
    return service.getFile(`${api}/download-master-data/?category=${payload}`);
  },
};
export default reportsService;
