import React from 'react';

import { node, bool, func, string } from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import commonStyles from '../../assets/jss/common';

export default function DialogBox({
  heading,
  show,
  toggleShow,
  content,
  width,
}) {
  const useCommonStyles = makeStyles(commonStyles);
  const commonClasses = useCommonStyles();
  return (
    <>
      {show && (
        <Dialog
          onClose={() => toggleShow()}
          open={show}
          maxWidth={width || 'sm'}
          fullWidth
          onEscapeKeyDown={() => toggleShow()}
          scroll="body"
          PaperProps={{ square: true }}
        >
          <DialogTitle
            id="simple-dialog-title"
            className={commonClasses.DialogLabelStyle}
          >
            {heading}
          </DialogTitle>
          <DialogContent>{content}</DialogContent>
        </Dialog>
      )}
    </>
  );
}

DialogBox.propTypes = {
  show: bool,
  toggleShow: func,
  content: node,
  heading: string,
  width: string,
};
