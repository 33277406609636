/* eslint-disable no-unused-vars */

import { combineEpics } from 'redux-observable';
import * as programs from './programs/epics';
import * as data from './data/epics';
import * as shared from './shared/epics';
import * as bom from './bom/epics';
import * as quoteSchedule from './quoteSchedule/epics';
import * as cost from './cost/epics';
import * as quotes from './quotes/epics';
import * as variances from './variances/epics';
import * as pcc from './pcc/epics';
import * as plants from './plants/epics';
import * as notifications from './notifications/epics';
import * as financialBurden from './financialBurden/epics';
import * as plantPreferences from './plantPreferences/epics';
import * as conditions from './conditions/epics';
import * as comments from './comments/epics';
import * as audits from './audits/epics';
import * as rebates from './rebates/epics';
import * as report from './report/epics';
import * as commodity from './commodity/epics';
import * as dashboard from './dashboard/epics';
import * as costGroupSequence from './costGroupSequence/epics';
import * as vendorMap from './vendorMap/epics';

export default combineEpics(
  ...Object.entries({
    ...programs,
    ...data,
    ...shared,
    ...bom,
    ...cost,
    ...quotes,
    ...variances,
    ...pcc,
    ...quotes,
    ...plants,
    ...quoteSchedule,
    ...notifications,
    ...financialBurden,
    ...plantPreferences,
    ...conditions,
    ...comments,
    ...audits,
    ...rebates,
    ...report,
    ...commodity,
    ...dashboard,
    ...costGroupSequence,
    ...vendorMap,
  }).map(([key, value]) => value)
);
