import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/programs`;

export const getAllPrograms = payload => service.get(`${api}`, payload);

export const getProgram = id => service.get(`${api}/${id}`);

export const createProgram = data => service.post(`${api}`, data);

export const updateProgram = (id, data) => service.put(`${api}/${id}`, data);

export const deleteProgram = id => service.remove(`${api}/${id}`);

export const getAllMaterialsByProgramId = payload =>
  service.get(`${api}/${payload.id}/materials`, payload);

export const getAllPMMaterialTypes = () => service.get(`${api}/part-types`);

export default {
  getAllPrograms,
  getProgram,
  createProgram,
  updateProgram,
  deleteProgram,
  getAllMaterialsByProgramId,
  getAllPMMaterialTypes,
};
