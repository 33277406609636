import React from 'react';
import { string, bool, func } from 'prop-types';
import {
  Tooltip,
  Switch as ToggleSwitch,
  FormControlLabel,
} from '@material-ui/core';

const ConditionalSwitcher = ({
  value,
  onChange,
  tooltipText,
  label,
  disabled = false,
}) => (
  <Tooltip title={tooltipText}>
    <FormControlLabel
      control={
        <ToggleSwitch
          color="primary"
          checked={value}
          onChange={onChange}
          disabled={disabled}
        />
      }
      label={label}
    />
  </Tooltip>
);
ConditionalSwitcher.propTypes = {
  value: bool,
  onChange: func,
  tooltipText: string,
  label: string,
  disabled: bool,
};

export default ConditionalSwitcher;
