/* eslint-disable no-unused-vars */
import { ofType } from 'redux-observable';
import {
  mergeMap,
  map,
  catchError,
  switchMap,
  debounceTime,
  merge,
} from 'rxjs/operators';

import { of } from 'rxjs';
import { programsService, programQuotesService } from '../../services';
import { dispatch } from '../../common/helpers';
import { MESSAGES, DEFAULT_PAGINATION_OBJECT } from '../../common/constants';

import {
  FETCH_PROGRAM_LIST_REQUEST,
  FETCH_PROGRAM_LIST_SUCCESS,
  FETCH_PROGRAM_LIST_ERROR,
  FETCH_PROGRAM_MATERIAL_LIST_REQUEST,
  FETCH_PROGRAM_MATERIAL_LIST_SUCCESS,
  FETCH_PROGRAM_MATERIAL_LIST_ERROR,
  CREATE_QUOTE_REQUEST,
  CREATE_QUOTE_SUCESSS,
  CREATE_QUOTE_ERROR,
  CREATE_REBATE_REQUEST,
  CREATE_REBATE_SUCESSS,
  CREATE_REBATE_ERROR,
  CREATE_PROGRAM_REQUEST,
  CREATE_PROGRAM_ERROR,
  UPDATE_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_REQUEST,
  DELETE_PROGRAM_ERROR,
  DELETE_PROGRAM_REQUEST,
  IS_PROCESSING_PROGRAM_QUOTE_REQUEST,
  IS_PROCESSING_PROGRAM_QUOTE_ERROR,
  IS_PROCESSING_PROGRAM_QUOTE_SUCESSS,
  IS_PROCESSING_PROGRAM_REBATE_REQUEST,
  IS_PROCESSING_PROGRAM_REBATE_SUCESSS,
  IS_PROCESSING_PROGRAM_REBATE_ERROR,
} from './actions';

import {
  SUCCESS_NOTIFICATION_REQUEST,
  ERROR_NOTIFICATION_REQUEST,
} from '../notifications/actions';

export const fetchProgramList = (action$, store) =>
  action$.pipe(
    ofType(FETCH_PROGRAM_LIST_REQUEST),
    debounceTime(250),
    switchMap(({ payload }) =>
      programsService.getAllPrograms(payload).pipe(
        map(data =>
          dispatch(FETCH_PROGRAM_LIST_SUCCESS, {
            ...data,
            payload,
          })
        )
      )
    ),
    catchError(err => [dispatch(FETCH_PROGRAM_LIST_ERROR, err)])
  );

export const fetchMaterialList = (action$, store) =>
  action$.pipe(
    ofType(FETCH_PROGRAM_MATERIAL_LIST_REQUEST),
    mergeMap(({ payload }) =>
      programsService.getAllMaterialsByProgramId(payload).pipe(
        map(({ data, ...response }) => {
          return dispatch(FETCH_PROGRAM_MATERIAL_LIST_SUCCESS, {
            data: data.map(({ plant, distributionPlant, ...rest }) => ({
              ...rest,
              plant: (plant && plant.code) || 'HPE',
              distributionPlant: distributionPlant && distributionPlant.code,
            })),
            payload,
            ...response,
          });
        })
      )
    ),
    catchError(err => [dispatch(FETCH_PROGRAM_MATERIAL_LIST_ERROR, err)])
  );

export const createQuote = (action$, state) =>
  action$.pipe(
    ofType(CREATE_QUOTE_REQUEST),
    mergeMap(({ payload: { values, payload } }) => {
      const {
        user: {
          data: { emailAddress, commonName },
        },
      } = state.value;

      values.email = emailAddress;
      values.userName = commonName;

      return programQuotesService.createProgramQuotes(values).pipe(
        mergeMap(() => [
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.FORM_SUBMITTED_SUCCESSFULLY,
          }),
          dispatch(CREATE_QUOTE_SUCESSS),
          dispatch(FETCH_PROGRAM_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
        ]),
        catchError(error => {
          return [
            dispatch(CREATE_QUOTE_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, error),
          ];
        })
      );
    })
  );

export const createRebateQuote = (action$, state) =>
  action$.pipe(
    ofType(CREATE_REBATE_REQUEST),
    mergeMap(({ payload: { values, payload } }) => {
      const {
        user: {
          data: { emailAddress, commonName },
        },
      } = state.value;

      values.email = emailAddress;
      values.userName = commonName;
      return programQuotesService.createProgramRebates(values).pipe(
        mergeMap(() => [
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.FORM_SUBMITTED_SUCCESSFULLY,
          }),
          dispatch(CREATE_REBATE_SUCESSS),
          dispatch(FETCH_PROGRAM_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
        ]),
        catchError(error => {
          return [
            dispatch(CREATE_REBATE_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, error),
          ];
        })
      );
    })
  );

export const createProgram = action$ =>
  action$.pipe(
    ofType(CREATE_PROGRAM_REQUEST),
    mergeMap(({ payload: { payload, values } }) => {
      const now = new Date().toISOString();
      const program = {
        ...values,
        createdAt: now,
        updatedAt: now,
        isDeleted: false,
        active: true,
        businessProcess: null,
        processSchedule: null,
        annualVolume: null,
      };
      return programsService.createProgram(program).pipe(
        mergeMap(() => [
          dispatch(FETCH_PROGRAM_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.FORM_UPDATED_SUCCESSFULLY,
          }),
        ]),
        catchError(err => [
          dispatch(CREATE_PROGRAM_ERROR, err),
          dispatch(ERROR_NOTIFICATION_REQUEST, err),
        ])
      );
    })
  );

export const updateProgram = action$ =>
  action$.pipe(
    ofType(UPDATE_PROGRAM_REQUEST),
    mergeMap(({ payload: { payload, values } }) => {
      const now = new Date().toISOString();
      const program = {
        createdAt: now,
        updatedAt: now,
        isDeleted: false,
        active: true,
        businessProcess: null,
        processSchedule: null,
        annualVolume: null,
        ...values,
      };
      return programsService.updateProgram(values.id, program).pipe(
        mergeMap(() => [
          dispatch(FETCH_PROGRAM_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.FORM_UPDATED_SUCCESSFULLY,
          }),
        ]),
        catchError(err => [
          dispatch(CREATE_PROGRAM_ERROR, err),
          dispatch(ERROR_NOTIFICATION_REQUEST, err),
        ])
      );
    })
  );

export const removeProgram = action$ =>
  action$.pipe(
    ofType(DELETE_PROGRAM_REQUEST),
    mergeMap(({ payload: { id, payload } }) =>
      programsService.deleteProgram(id).pipe(
        mergeMap(() => [
          dispatch(FETCH_PROGRAM_LIST_REQUEST, {
            ...payload,
            pagination: DEFAULT_PAGINATION_OBJECT,
          }),
          dispatch(SUCCESS_NOTIFICATION_REQUEST, {
            message: MESSAGES.DATA_DELETED_SUCCESSFULLY,
          }),
        ]),
        catchError(err => [
          dispatch(DELETE_PROGRAM_ERROR, err),
          dispatch(ERROR_NOTIFICATION_REQUEST, err),
        ])
      )
    )
  );

export const isProcessingProgramQuote = action$ =>
  action$.pipe(
    ofType(IS_PROCESSING_PROGRAM_QUOTE_REQUEST),
    mergeMap(({ payload: { values } }) =>
      programQuotesService.isProcessingProgramQuote(values).pipe(
        map(data =>
          dispatch(IS_PROCESSING_PROGRAM_QUOTE_SUCESSS, {
            isProcessingPQ: data.response.response.isProcessingProgramQuote,
          })
        )
      )
    ),
    catchError(err => [dispatch(IS_PROCESSING_PROGRAM_QUOTE_ERROR, err)])
  );

export const isProcessingProgramRebate = action$ =>
  action$.pipe(
    ofType(IS_PROCESSING_PROGRAM_REBATE_REQUEST),
    mergeMap(({ payload: { values } }) =>
      programQuotesService.isProcessingProgramRebate(values).pipe(
        map(data =>
          dispatch(IS_PROCESSING_PROGRAM_REBATE_SUCESSS, {
            isProcessingPQ: data.response.response.isProcessingCreateRebate,
          })
        )
      )
    ),
    catchError(err => [dispatch(IS_PROCESSING_PROGRAM_REBATE_ERROR, err)])
  );
