import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/partners`;

const suppliersService = {
  getAllSuppliers: (params = { limit: 10, offset: 0 }) => {
    return service.get(`${api}?limit=${params.limit}&offset=${params.offset}`);
  },
  getSupplier: id => {
    return service.get(`${api}/${id}`);
  },
  createSupplier: data => {
    return service.post(`${api}`, data);
  },
  partnerWithRelation: () => {
    return service.get(`${api}/partners-with-relations`);
  },
  updateSupplier: (id, data) => {
    return service.put(`${api}/${id}`, data);
  },
  deleteSupplier: id => {
    return service.remove(`${api}/${id}`);
  },
};
export default suppliersService;
