import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/file-upload`;

const dataUploadService = {
  getAllUploadFiles: payload => service.get(`${api}`, payload),
};

export default dataUploadService;
