import React from 'react';
import {
  string,
  object,
  arrayOf,
  func,
  array,
  oneOfType,
  bool,
} from 'prop-types';
import { MenuItem, Select } from '@material-ui/core';

const CustomSelect = ({
  type,
  selected,
  options = [],
  valueKey,
  valueLabel,
  onChange,
  value,
  variant,
  multiple,
  ...props
}) => {
  return (
    <Select
      variant={variant}
      multiple={multiple}
      value={selected[valueKey] || ''}
      onChange={evt => {
        onChange(evt.target.value, evt);
      }}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }}
      {...props}
    >
      {options.map(data => (
        <MenuItem key={data.id} value={data[valueKey]}>
          {data[valueLabel]}
        </MenuItem>
      ))}
    </Select>
  );
};

CustomSelect.propTypes = {
  selected: oneOfType([array, string, object]),
  onChange: func,
  options: arrayOf(object).isRequired,
  valueKey: string,
  valueLabel: string,
  type: string,
  value: oneOfType([array, string]),
  variant: string,
  multiple: bool,
};

export default CustomSelect;
