import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}`;

const dashboardService = {
  getSummaryData: payload => {
    return service.post(`${api}/dashboard/getCount`, payload);
  },

  getingestedDates: () => {
    return service.get(`${api}/dashboard/ingestedDates`);
  },

  getCSSData: () => {
    return service.get(`${api}/dashboard/cssPullDate`);
  },

  getSummaryRebatesData: () => {
    return service.get(`${api}/dashboard/rebatesDate`);
  },
};

export default dashboardService;
