// Create Exchange
export const CREATE_EXCHANGE_REQUEST = 'CREATE_EXCHANGE_REQUEST';
export const CREATE_EXCHANGE_SUCCESS = 'CREATE_EXCHANGE_SUCCESS';
export const CREATE_EXCHANGE_ERROR = 'CREATE_EXCHANGE_ERROR';

// Delete Plant
export const DELETE_EXCHANGE_REQUEST = 'DELETE_EXCHANGE_REQUEST';
export const DELETE_EXCHANGE_SUCCESS = 'DELETE_EXCHANGE_SUCCESS';
export const DELETE_EXCHANGE_ERROR = 'DELETE_EXCHANGE_ERROR';

// Exchange List Actions
export const FETCH_EXCHANGE_LIST_REQUEST = 'FETCH_EXCHANGE_LIST_REQUEST';
export const FETCH_EXCHANGE_LIST_SUCCESS = 'FETCH_EXCHANGE_LIST_SUCCESS';
export const FETCH_EXCHANGE_LIST_ERROR = 'FETCH_EXCHANGE_LIST_ERROR';

// Update Plant
export const UPDATE_EXCHANGE_REQUEST = 'UPDATE_EXCHANGE_REQUEST';
export const UPDATE_EXCHANGE_SUCCESS = 'UPDATE_EXCHANGE_SUCCESS';
export const UPDATE_EXCHANGE_ERROR = 'UPDATE_EXCHANGE_ERROR';
