import {
  FETCH_PROGRAM_LIST_REQUEST,
  FETCH_PROGRAM_LIST_SUCCESS,
  FETCH_PROGRAM_LIST_ERROR,
  FETCH_PROGRAM_MATERIAL_LIST_REQUEST,
  FETCH_PROGRAM_MATERIAL_LIST_SUCCESS,
  FETCH_PROGRAM_MATERIAL_LIST_ERROR,
  CREATE_QUOTE_REQUEST,
  CREATE_QUOTE_SUCESSS,
  CREATE_QUOTE_ERROR,
  CREATE_REBATE_REQUEST,
  CREATE_REBATE_SUCESSS,
  CREATE_REBATE_ERROR,
  CREATE_PROGRAM_REQUEST,
  CREATE_PROGRAM_ERROR,
  CREATE_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_ERROR,
  UPDATE_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_REQUEST,
  DELETE_PROGRAM_ERROR,
  DELETE_PROGRAM_SUCCESS,
  DELETE_PROGRAM_REQUEST,
  IS_PROCESSING_PROGRAM_QUOTE_REQUEST,
  IS_PROCESSING_PROGRAM_QUOTE_ERROR,
  IS_PROCESSING_PROGRAM_QUOTE_SUCESSS,
  IS_PROCESSING_PROGRAM_REBATE_REQUEST,
  IS_PROCESSING_PROGRAM_REBATE_SUCESSS,
  IS_PROCESSING_PROGRAM_REBATE_ERROR,
} from './actions';
import { REDUCERS } from '../../common/constants';
import { createReducer } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  list: {
    loading: false,
    error: false,
    response: { data: [], pagination: {} },
  },
  materials: {
    loading: false,
    error: false,
    response: { data: [], pagination: {} },
  },
  loadingQuoteCreation: false,
  loadingRebateCreation: false,
  isProcessingPQ: false,
  uuids: new Set(),
};

const reducer = {
  // CREATE PROGRAM
  [CREATE_PROGRAM_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [CREATE_PROGRAM_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [CREATE_PROGRAM_SUCCESS]: state => ({
    ...state,
    list: {
      ...REDUCERS.LOADING,
      data: state.list.data,
    },
  }),
  // UPDATE PROGRAM
  [UPDATE_PROGRAM_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [UPDATE_PROGRAM_SUCCESS]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.SUCCESS,
    },
  }),
  [UPDATE_PROGRAM_ERROR]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
    },
  }),
  // DELETE PROGRAM
  [DELETE_PROGRAM_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [DELETE_PROGRAM_SUCCESS]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.SUCCESS,
    },
  }),
  [DELETE_PROGRAM_ERROR]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
    },
  }),

  // Program List
  [FETCH_PROGRAM_LIST_REQUEST]: state => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_PROGRAM_LIST_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => {
    return {
      ...state,
      list: {
        ...REDUCERS.SUCCESS,
        response: {
          pagination,
          data: payload?.pagination?.infinite
            ? [...state.list.response.data, ...data]
            : data,
          payload,
        },
      },
      materials: {
        error: false,
        loading: false,
        response: { data: [], pagination: {} },
      },
      uuids: data.map(({ id, name }) => ({ id, name })),
    };
  },
  [FETCH_PROGRAM_LIST_ERROR]: (state, { payload: error }) => ({
    ...state,
    list: {
      ...state.list,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  [FETCH_PROGRAM_MATERIAL_LIST_REQUEST]: state => ({
    ...state,
    materials: {
      ...state.materials,
      ...REDUCERS.LOADING,
    },
  }),
  [FETCH_PROGRAM_MATERIAL_LIST_SUCCESS]: (
    state,
    { payload: { data, pagination, payload } }
  ) => {
    return {
      ...state,
      materials: {
        ...REDUCERS.SUCCESS,
        response: {
          pagination,
          data: payload?.pagination?.infinite
            ? [...state.materials.response.data, ...data]
            : data,
          payload,
        },
      },
      list: {
        error: false,
        loading: false,
        response: { data: [], pagination: {} },
      },
    };
  },
  [FETCH_PROGRAM_MATERIAL_LIST_ERROR]: (state, { payload: error }) => ({
    ...state,
    materials: {
      ...state.materials,
      ...REDUCERS.ERROR,
      error,
    },
  }),
  // quotes creation
  [CREATE_QUOTE_REQUEST]: state => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [CREATE_QUOTE_SUCESSS]: state => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
    };
  },
  [CREATE_QUOTE_ERROR]: state => {
    return {
      ...state,
      ...REDUCERS.ERROR,
    };
  },
  // rebate creation
  [CREATE_REBATE_REQUEST]: state => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [CREATE_REBATE_SUCESSS]: state => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
    };
  },
  [CREATE_REBATE_ERROR]: state => {
    return {
      ...state,
      ...REDUCERS.ERROR,
    };
  },
  // quotes creation
  [IS_PROCESSING_PROGRAM_QUOTE_REQUEST]: state => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [IS_PROCESSING_PROGRAM_QUOTE_SUCESSS]: (state, { payload }) => {
    return {
      ...state,
      isProcessingPQ: payload.isProcessingPQ,
      ...REDUCERS.SUCCESS,
    };
  },
  [IS_PROCESSING_PROGRAM_QUOTE_ERROR]: state => {
    return {
      ...state,
      ...REDUCERS.ERROR,
    };
  },
  // rebates creation
  [IS_PROCESSING_PROGRAM_REBATE_REQUEST]: state => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [IS_PROCESSING_PROGRAM_REBATE_SUCESSS]: (state, { payload }) => {
    return {
      ...state,
      isProcessingPQ: payload.isProcessingPQ,
      ...REDUCERS.SUCCESS,
    };
  },
  [IS_PROCESSING_PROGRAM_REBATE_ERROR]: state => {
    return {
      ...state,
      ...REDUCERS.ERROR,
    };
  },
};

export default createReducer(schema, reducer);
