import * as service from '../common/service';
import { QNP } from '../common/constants';

const api = `${QNP}/cost-conditions`;

const conditionsService = {
  getAll: payload => {
    return service.get(`${api}`, payload);
  },
  getCostConditions: () => {
    return service.get(`${api}/getCostConditions`);
  },
  createCondition: data => {
    return service.post(`${api}`, data);
  },
  updateCondition: (id, data) => {
    return service.put(`${api}/${id}`, data);
  },
  deleteCondition: id => {
    return service.remove(`${api}/${id}`);
  },
};

export default conditionsService;
