import * as service from '../common/service';
import { QNP } from '../common/constants';

const auditAPI = `${QNP}/variance-resolution/audit`;
const auditsService = {
  getAuditList: params => {
    return service.get(`${auditAPI}`, { ...params });
  },
};
export default auditsService;
